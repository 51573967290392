import { Week } from '../types/week';

const validateTimeRegex = new RegExp(
  '^((1[0-2]|0?[1-9]):([0-5][0-9])([AaPp][Mm]))$'
);
export const isValidTime = (value): boolean => {
  return validateTimeRegex.test(value);
};

export const getHours = (): string[] => {
  const hours = [];
  ['am', 'pm'].forEach((meridiem) => {
    for (let i = 0; i < 12; i++) {
      for (let j = 0; j <= 3; j++) {
        if (j === 0 || j === 3) {
          const hour = i !== 0 ? (i < 10 ? `0${i}` : i) : '12';
          hours.push(`${hour}:${j}0${meridiem}`);
        }
      }
    }
  });
  return hours;
};

export const weekDayList = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const getTodayDateAtMidnight = (
  dayTime: 'end' | 'start' = 'start'
): {
  timestamp: number;
  dateObj: Date;
} => {
  const isDayStart = dayTime === 'start';
  const today = new Date();
  today.setHours(isDayStart ? 0 : 23, isDayStart ? 0 : 59, 0, 0);
  return {
    timestamp: today.getTime(),
    dateObj: today,
  };
};

export const getDateNDaysAgo = (days: number): number => {
  const today = getTodayDateAtMidnight('end').dateObj;
  return today.setDate(today.getDate() - days);
};

export const getWeekData = (): Week[] => {
  const today = new Date();
  const currentYear = today.getFullYear() - 1;

  const weekData = [];

  // Generate data from start of the year to now
  const currentDate = new Date(currentYear, 0, 1);

  while (currentDate <= today) {
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + 1);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 0, 0);

    const week = {
      startOfTheWeek: startOfWeek.toLocaleDateString('en-US', {
        weekday: 'long',
      }),
      startOfTheWeekDate: startOfWeek.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      endOfTheWeek: endOfWeek.toLocaleDateString('en-US', { weekday: 'long' }),
      endOfTheWeekDate: endOfWeek.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }),
    };

    weekData.push(week);

    currentDate.setDate(currentDate.getDate() + 7);
  }
  return weekData;
};

export const timeZoneFix = (timestamp: number): number => {
  const timeZoneOffset = new Date(timestamp).getTimezoneOffset();
  return timestamp - timeZoneOffset * 60000;
};

export const getLastMonthSameDay = (today: Date): Date => {
  const lastMonth = new Date(today);

  // Set the month to the previous month
  lastMonth.setMonth(today.getMonth() - 1);

  // Make sure to adjust the year if necessary
  // (e.g., if today is January and you set the month to December)
  if (today.getMonth() === 0) {
    lastMonth.setFullYear(today.getFullYear() - 1);
  }

  return lastMonth;
};
