import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TicketDate} from '../../../../../../../common/types/user';
import {EventTimePipe} from '../../../../../../../common/pipes/event-duration.pipe';

@Component({
  selector: 'app-ticket-preview-details',
  templateUrl: './ticket-preview-details.component.html',
  standalone: true,
  imports: [CommonModule, EventTimePipe],
  styleUrls: ['./ticket-preview-details.component.scss'],
})
export class TicketPreviewDetailsComponent {
  @Input() public ticketDate: TicketDate;
  @Input() public ticketsCount: number;
  @Input() public seatsAvailable: number;
  @Input() public sharedSeatsCount: number;

  constructor() {
  }

}

