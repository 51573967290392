import { from, mergeMap, Observable, tap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AUTH_HEADER, GUEST_ID, SKIP_AUTH_HEADER } from '../constants/headers';
import { AuthService } from '../services/auth.service';
import { PopupService } from '../services/popup.service';
import { catchError } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private popupService: PopupService,
    private storageService: StorageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.has(SKIP_AUTH_HEADER)) {
      req = req.clone({ headers: req.headers.delete(SKIP_AUTH_HEADER) });
      return next.handle(req);
    } else {
      const token = this.authService.token;
      if (token) {
        const guest: string = this.storageService.getFromStorage(GUEST_ID);
        req = req.clone({
          headers: guest
            ? req.headers.set(GUEST_ID, guest)
            : req.headers.set(AUTH_HEADER, token),
        });
      }
    }
    return next.handle(req).pipe(
      catchError((err) => {
        if (err.status === 401) {
          return from(this.authService.refreshToken()).pipe(
            tap(
              () =>
                (req = req.clone({
                  headers: req.headers.set(AUTH_HEADER, this.authService.token),
                }))
            ),
            mergeMap(() => next.handle(req))
          );
        } else if (err.status > 401 && err.status !== 404) {
          const { message } = err.error;
          this.popupService.showErrorResponsePopup(
            message.length < 60
              ? message
              : 'Something went wrong. Please try again.'
          );
        }
        return throwError(err);
      })
    );
  }
}
