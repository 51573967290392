import { Component, Input, OnInit } from '@angular/core';
import { PredictionAnswers, UserPrediction } from '../../types/predictions';
import { CommonModule } from '@angular/common';
import { LoaderService } from '../../services/loader.service';
import { filter, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { Pageable } from '../../../common/types/pageable';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AnswerPipe } from '../../pipes/answer-pipe.pipe';
import { VenuePredictAndWinService } from '../../services/venue-predict-and-win.service';

@Component({
  selector: 'app-predict-and-win-history',
  templateUrl: './predict-and-win-history.component.html',
  styleUrls: ['./predict-and-win-history.component.scss'],
  standalone: true,
  imports: [CommonModule, InfiniteScrollModule, AnswerPipe],
})
export class PredictAndWinHistoryComponent
  extends BaseComponent
  implements OnInit
{
  @Input() public isMobile = true;
  public myPredictions: Pageable<UserPrediction>;
  public predictionsAnswersList: PredictionAnswers[] = [];
  private currentPage = 0;
  constructor(
    private loaderService: LoaderService,
    private predictAndWinService: VenuePredictAndWinService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  public onScrollDown(): void {
    if (
      !this.myPredictions ||
      this.myPredictions?.total === this.myPredictions?.items?.length
    ) {
      return;
    }
    this.currentPage++;
    this.predictAndWinService
      .getUserPredictionsFilteredByStatus(
        this.predictAndWinService.currentLeaderBoardId,
        this.currentPage
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: Pageable<UserPrediction>) =>
          (this.myPredictions = {
            ...res,
            items: [...this.myPredictions.items, ...res.items],
          })
      );
  }

  private init() {
    this.loaderService.show();
    this.predictAndWinService
      .getUserPredictionsFilteredByStatus(
        this.predictAndWinService.currentLeaderBoardId
      )
      .pipe(
        filter((res: Pageable<UserPrediction>) => {
          this.myPredictions = res;
          return !!res.items.length;
        }),
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe();
  }
}
