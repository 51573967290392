import {Injectable} from '@angular/core';
import {Observable, Subject, zip} from 'rxjs';
import {PurchasedTicket, ReceivedTicket, SharedTicket} from '../types/user';
import {environment} from '../../../environments/environment';
import {TicketStatus} from '../enum/ticket-status';
import {ResponseMessage} from '../types/response-message';
import {ShareFormData} from '../../mobile/modules/main/pages/season-ticket/pages/season-ticket-share/share-form';
import {HttpClient} from '@angular/common/http';
import {LoaderService} from './loader.service';
import {finalize} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  private setUserAllTickets: Subject<
    [PurchasedTicket[], SharedTicket[], ReceivedTicket[]]
  > = new Subject<[PurchasedTicket[], SharedTicket[], ReceivedTicket[]]>();

  constructor(private http: HttpClient, private loaderService: LoaderService) {
  }

  public getUserPurchasedTickets(): Observable<PurchasedTicket[]> {
    return this.http.get<PurchasedTicket[]>(
      `${environment.apiUrl}/client/my-tickets`
    );
  }

  public getUserSharedTickets(): Observable<SharedTicket[]> {
    return this.http.get<SharedTicket[]>(
      `${environment.apiUrl}/client/my-sent-tickets`
    );
  }

  public getUserReceivedTickets(): Observable<ReceivedTicket[]> {
    return this.http.get<ReceivedTicket[]>(
      `${environment.apiUrl}/client/my-received-tickets`
    );
  }

  public getUserReceivedTicketById(
    ticketId: string
  ): Observable<ReceivedTicket> {
    return this.http.get<ReceivedTicket>(
      `${environment.apiUrl}/client/my-received-tickets/${ticketId}`
    );
  }

  public getUserAllTickets(): Observable<
    [PurchasedTicket[], SharedTicket[], ReceivedTicket[]]
  > {
    this.loaderService.show();
    return zip([
      this.getUserPurchasedTickets(),
      this.getUserSharedTickets(),
      this.getUserReceivedTickets(),
    ]).pipe(finalize(() => this.loaderService.hide()));
  }

  public getUserTicketsById(ticketId: string): Observable<PurchasedTicket> {
    return this.http.get<PurchasedTicket>(
      `${environment.apiUrl}/client/my-tickets/${ticketId}`
    );
  }

  public changeReceivedTicketStatus(
    ticketId: string,
    status: TicketStatus
  ): Observable<ResponseMessage> {
    return this.http.put<ResponseMessage>(
      `${environment.apiUrl}/client/my-received-tickets/${ticketId}/status`,
      {status}
    );
  }

  public callBackSharedTicket(ticketId: string): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(
      `${environment.apiUrl}/client/my-sent-tickets/${ticketId}/cancellation`,
      {}
    );
  }

  public shareTicket(
    ticketId: string,
    guestInfo: ShareFormData,
    seatsCount: number,
    dates: string[]
  ): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(
      `${environment.apiUrl}/client/my-tickets/${ticketId}/share`,
      {
        seatsCount,
        dates,
        status: TicketStatus.pending,
        guestFirstName: guestInfo.firstName,
        guestLastName: guestInfo.lastName,
        guestPhone: guestInfo.phone,
      }
    );
  }
}
