import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Event } from '../../../../../../../common/types/event';
import { PopupService } from '../../../../../../../common/services/popup.service';
import { BaseComponent } from '../../../../../../../common/components/base/base.component';
import { filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SanitizeHtmlPipe } from '../../../../../../../common/pipes/sanitize-html.pipe';
import { AssetsUrlPipe } from '../../../../../../../common/pipes/assets-url.pipe';
import { EventTimePipe } from '../../../../../../../common/pipes/event-duration.pipe';
import { EventDetailsDesktopComponent } from '../../../../../../../desktop/modules/main-desktop/components/event-details-desktop/event-details-desktop.component';
import { EventDatePipe } from '../../../../../../../common/pipes/event-date.pipe';
import { UserService } from '../../../../../../../common/services/user.service';

@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    SanitizeHtmlPipe,
    AssetsUrlPipe,
    EventTimePipe,
    EventDetailsDesktopComponent,
    EventDatePipe,
  ],
})
export class EventItemComponent extends BaseComponent {
  @Input() public isMobile: boolean = true;
  @Input() public event: Event;
  @Input() public homeScreen = false;
  @Input() public showTitle = true;
  @Input() public currentSelectedDate: string;

  @Output() public showEventDetails$: EventEmitter<Event> =
    new EventEmitter<Event>();

  constructor(
    private popupService: PopupService,
    private router: Router,
    private userService: UserService
  ) {
    super();
  }

  public showEventDetailsPopup(): void {
    this.popupService
      .showEventDetailsPopup(this.event)
      .action$.pipe(
        filter((res) => !!res),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() =>
        this.router.navigateByUrl(`/my/season-tickets/dates/${this.event.id}`)
      );
  }

  public handleBuyNowClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (!this.event.sellable) {
      this.handleItemSelect();
      return;
    }

    if (!this.userService.user) {
      this.router.navigate(['/auth'], {
        queryParams: { redirectTo: this.router.url },
      });
      return;
    }

    if (this.isMobile) {
      this.router.navigateByUrl(`/my/season-tickets/dates/${this.event.id}`);
    } else {
      const category = this.event.category;
      const categoryId = typeof category === 'string' ? category : category.id;
      this.router.navigate(['/my/season-tickets'], {
        queryParams: { eventId: this.event.id, categoryId },
      });
    }
  }

  public handleItemSelect(): void {
    if (this.isMobile) {
      this.showEventDetailsPopup();
    } else {
      this.showEventDetails$.emit(this.event);
    }
  }
}
