import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupService } from '../../../services/popup.service';
import { TournamentLeaderboard } from '../../../types/sharp-sports/tournament-leaderboard';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-builder-leaderboard-shared',
  templateUrl: './builder-leaderboard-shared.component.html',
  styleUrls: ['./builder-leaderboard-shared.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class BuilderLeaderboardSharedComponent {
  @Input() isMobile = true;
  @Input() leaderboard: TournamentLeaderboard[];
  public userId = this.userService.user.id;

  constructor(private userService: UserService) {}
}
