import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SharpSportsService } from '../../../../../../../common/services/sharp-sports.service';

@Component({
  selector: 'app-builder-dashboard-desktop',
  templateUrl: './builder-dashboard-desktop.component.html',
  styleUrls: ['./builder-dashboard-desktop.component.css'],
})
export class BuilderDashboardDesktopComponent {
  public pageName = '';

  constructor(router: Router, public sharpSportsService: SharpSportsService) {
    router.events
      .pipe(filter((val: RouterEvent) => val instanceof NavigationEnd))
      .subscribe((e: RouterEvent) => {
        const pages = e.url.split('/');
        const lastPage = pages.pop();
        lastPage.includes('?')
          ? pages.push(lastPage.slice(0, lastPage.indexOf('?')))
          : pages.push(lastPage);

        this.pageName = pages.includes('overview')
          ? pages[pages.length - 2]
          : pages.pop();
      });
  }
}
