import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SanitizeHtmlPipe} from '../../../pipes/sanitize-html.pipe';
import {BasePopupComponent} from '../../base/base-popup/base-popup.component';
import {AssetsUrlPipe} from '../../../pipes/assets-url.pipe';
import {EventDetailsBaseComponent} from '../../../base-controllers/event-details-base.component';

@Component({
  selector: 'app-event-details-popup',
  standalone: true,
  templateUrl: './event-details-popup.component.html',
  imports: [CommonModule, SanitizeHtmlPipe, BasePopupComponent, AssetsUrlPipe],
  styleUrls: ['./event-details-popup.component.css'],
})
export class EventDetailsPopupComponent extends EventDetailsBaseComponent {

}
