import {Component, OnInit} from '@angular/core';
import {LayoutDecorator} from '../../../../../../../common/helpers/layout.decorator';
import {
  SeasonTicketReservationBaseComponent
} from '../../../../../../../common/base-controllers/season-ticket-reservation-base.component';
import {TicketType} from '../../../../../../../common/types/ticket-type';
import {ActivatedRoute, Router} from '@angular/router';
import {TicketsService} from '../../../../../../../common/services/tickets.service';
import {PopupService} from '../../../../../../../common/services/popup.service';
import {LayoutConfigService} from '../../../../../../../common/services/layout-config.service';

@Component({
  selector: 'app-season-ticket-reservation',
  templateUrl: './season-ticket-reservation.component.html',
  styleUrls: ['./season-ticket-reservation.component.scss'],
})
@LayoutDecorator('layoutConfigService', {showHeader: true, showFooter: false})
export class SeasonTicketReservationComponent extends SeasonTicketReservationBaseComponent implements OnInit {
  public currentSelectedTicketType: string;
  public ticketType = TicketType;

  constructor(
    private activatedRoute: ActivatedRoute,
    router: Router,
    ticketService: TicketsService,
    popupService: PopupService,
    layoutConfigService: LayoutConfigService
  ) {
    super(
      router,
      ticketService,
      popupService,
      layoutConfigService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.fetchFilterType();
  }

  public handleSelectTicketType(ticketType: string): void {
    this.currentSelectedTicketType = ticketType;
  }

  private fetchFilterType(): void {
    const filterType =
      this.activatedRoute.snapshot.queryParamMap.get('filterType');
    this.currentSelectedTicketType = filterType || this.filterType[0].value;
  }

}
