import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONTENT_TYPE, SKIP_AUTH_HEADER } from '../constants/headers';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private api = 'https://webflow.com/api/v1/form';
  private id = '6255da0784fc09bdc402210f';

  constructor(private httpClient: HttpClient) {}

  public emailSubscription(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${this.api}/${this.id}`, payload, {
      headers: {
        [SKIP_AUTH_HEADER]: '',
        [CONTENT_TYPE]: 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
  }
}
