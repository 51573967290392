import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { HearFromProsComponent } from '../../../../../common/components/news/hear-from-pros/hear-from-pros.component';
import { HearFromTheProsDesktopPageComponent } from './pages/hear-from-the-pros-desktop-page/hear-from-the-pros-desktop-page.component';
import { CommunityItemsDesktopComponent } from './pages/community-items-desktop/community-items-desktop.component';
import { CommunityDesktopRoutingModule } from './community-desktop-routing.module';
import { BreadcrumbDesktopComponent } from '../../../components/layout/breadcrumb/breadcrumb-desktop.component';

@NgModule({
  imports: [
    //modules
    CommonModule,
    RouterModule,
    CommunityDesktopRoutingModule,
    //components
    BreadcrumbDesktopComponent,
    HearFromProsComponent,
  ],
  declarations: [
    //components
    HearFromTheProsDesktopPageComponent,
    CommunityItemsDesktopComponent,
  ],
})
export class CommunityDesktopModule {}
