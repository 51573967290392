import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { OpenSettledCurrentBetSlips } from '../../../types/sharp-sports/bet-slips';
import { BetSlipsType } from '../../../enum/bet-slip-type';
import { UnitUsd } from '../../../enum/unit-usd';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { AddProfitStyleClassPipe } from '../../../pipes/builder/add-profit-style-class.pipe';
import { BetSlipItemComponent } from '../bet-slip-item/bet-slip-item.component';

@Component({
  selector: 'app-builder-today-bets-shared',
  templateUrl: './builder-today-bets-shared.component.html',
  styleUrls: ['./builder-today-bets-shared.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    AddProfitStyleClassPipe,
    BetSlipItemComponent,
  ],
})
export class BuilderTodayBetsSharedComponent {
  @Input() userCurrentBetSlips$: Observable<OpenSettledCurrentBetSlips>;
  @Input() currentBetSlipType: BetSlipsType;
  @Input() isMobile = true;
  @Input() unitSize: number;
  @Input() currentCurrency: UnitUsd = UnitUsd.usd;
  @Output() close$: EventEmitter<null> = new EventEmitter<null>();
  public betSlipsType = BetSlipsType;
  public unitUsd = UnitUsd;

  constructor(private router: Router) {}

  public unitsUsdSwitcher(): void {
    this.currentCurrency =
      this.currentCurrency === UnitUsd.usd ? UnitUsd.unit : UnitUsd.usd;
  }

  public navigateBack() {
    this.isMobile
      ? this.router.navigateByUrl('/my/builder/dashboard')
      : this.close$.emit(null);
  }
}
