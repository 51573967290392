import { AfterViewInit, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StorageService } from '../../common/services/storage.service';

@Component({
  selector: 'app-seven-rooms-widget',
  templateUrl: './seven-rooms-widget.component.html',
  styleUrls: ['./seven-rooms-widget.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SevenRoomsWidgetComponent implements AfterViewInit {
  constructor(private storageService: StorageService) {}

  ngAfterViewInit() {
    const container = document.getElementById('seven-room-container');
    container.appendChild(this.storageService.getSevenRoomsButtonRef());
  }
}
