import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PrivacyComponent } from './privacy.component';
import { HeaderComponent } from '../components/layout/header/header.component';
import { FooterComponent } from '../components/layout/footer/footer.component';
import { BreadcrumbComponent } from '../components/layout/breadcrumb/breadcrumb.component';
import { privacyBreadcrumb } from '../../../common/constants/breadcrumbs';

const routes: Routes = [
  {
    path: '',
    component: PrivacyComponent,
    data: { breadcrumb: privacyBreadcrumb },
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
  ],
  declarations: [PrivacyComponent],
})
export class PrivacyModule {}
