import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../../common/components/base/base.component';
import { RestaurantMenuService } from '../../../../../common/services/restaurant-menu.service';
import { Observable } from 'rxjs';
import { RestaurantMenu } from '../../../../../common/types/restaurant-menu';

@Component({
  selector: 'app-restaurant-page',
  templateUrl: './restaurant-desktop-page.component.html',
  styleUrls: ['./restaurant-desktop-page.component.scss'],
})
export class RestaurantDesktopPageComponent
  extends BaseComponent
  implements OnInit
{
  public menuData$: Observable<RestaurantMenu[]>;

  constructor(private restaurantMenuService: RestaurantMenuService) {
    super();
  }

  ngOnInit(): void {
    this.menuData$ = this.restaurantMenuService.getMenus();
  }
}
