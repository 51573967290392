import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { SharpSportsService } from '../services/sharp-sports.service';
import { finalize, map } from 'rxjs/operators';
import { UserBookAccounts } from '../types/sharp-sports/sport-books';
import { LoaderService } from '../services/loader.service';

@Injectable({
  providedIn: 'root',
})
export class BankrollBuilderGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private sharpSportsService: SharpSportsService,
    private loaderService: LoaderService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkSpotBooksConnection(route);
  }

  private checkSpotBooksConnection(
    route: ActivatedRouteSnapshot
  ): Observable<any> {
    if (!!this.userService.user?.sharpsportId) {
      this.loaderService.show();
      return this.sharpSportsService
        .getUserAccounts(this.userService.user?.id)
        .pipe(
          map(
            (account: UserBookAccounts[]) =>
              account?.length > 0 ||
              this.sharpSportsService.skippedAccountConnect ||
              this.redirect(route)
          ),
          finalize(() => this.loaderService.hide())
        );
    }
    return of(
      this.sharpSportsService.skippedAccountConnect || this.redirect(route)
    );
  }

  private redirect(route: ActivatedRouteSnapshot): UrlTree | Promise<boolean> {
    const venueId = route.queryParamMap.get('venueId');
    const redirectUrl = route.queryParamMap.get('redirectTo');
    if (redirectUrl && !venueId) {
      this.sharpSportsService.redirectUrl = redirectUrl;
    }
    if (venueId) {
      return this.router.navigate(['/my/builder/intro'], {
        queryParams: {
          redirectTo: `my/builder/dashboard/leaderboard?venueId=${venueId}`,
        },
      });
    } else {
      return this.router.parseUrl('/my/builder/intro');
    }
  }
}
