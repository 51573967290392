import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'month',
  standalone: true,
})
export class MonthPipe implements PipeTransform {
  transform(value: (string | { date: string })[]): string[] {
    const year = this.getDate(value[0])[0];
    return Array.from(new Set(value
      .map((date: string | { date: string }) => `${year}-${this.getDate(date)[1]}-01`)))
      .filter((d) => new Date(d).toString() !== 'Invalid Date');
  }

  private getDate(value: string | { date: string }): string[] {
    return (typeof value === 'object' ? value.date : value).split('-');
  }

}
