import { Pipe, PipeTransform } from '@angular/core';
import {SharedTicket, TicketDate} from '../types/user';

@Pipe({
  name: 'sharedTicketsCount',
  standalone: true,
})
export class SharedTicketsCountPipe implements PipeTransform {
  transform(sharedTickets: SharedTicket[], date: TicketDate): number {
    return sharedTickets?.reduce((count: number, st: SharedTicket) => {
      if (st.dates.some((d: TicketDate) => d.date === date.date)) {
        count += st.seatsCount;
      }
      return count;
    }, 0);
  }
}
