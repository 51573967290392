import { Component, OnInit } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';

@Component({
  selector: 'app-community-items-desktop',
  templateUrl: './community-items-desktop.component.html',
  styleUrls: ['./community-items-desktop.component.css'],
})
export class CommunityItemsDesktopComponent implements OnInit {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;

  public communities = [
    {
      name: 'Bet Lab',
      navigateTo: '',
      imageSrc: '',
    },
    {
      name: 'Community Chat',
      navigateTo: '',
      imageSrc: '/assets/community/chat.jpg',
    },
    {
      name: 'Games',
      navigateTo: '/my/predict-and-win',
      imageSrc: '/assets/community/games.jpg',
    },
    {
      name: 'Hear from the Pros',
      navigateTo: '/my/community/hear-pros',
      imageSrc: '/assets/community/pros.jpg',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
