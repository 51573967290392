import { EventEmitter, Injectable } from '@angular/core';
import { PopupsManagerService } from './popups-manager.service';
import { AccountUpgradeComponent } from '../../mobile/modules/main/pages/account/components/account-upgrade/account-upgrade.component';
import { CardBenefitsComponent } from '../../mobile/modules/main/pages/account/components/card-benefits/card-benefits.component';
import { EditAccountComponent } from '../../mobile/modules/main/pages/account/components/edit-account/edit-account.component';
import { ImageCropPopupComponent } from '../components/popups/image-crop-popup/image-crop-popup.component';
import { SeasonTicketAreaDetailsPopupComponent } from '../../mobile/modules/main/pages/season-ticket/components/season-ticket-area-details-popup/season-ticket-area-details-popup.component';
import { Area, AreaDetails } from '../types/area';
import { ActionResponsePopupComponent } from '../components/popups/action-response-popup/action-response-popup.component';
import { TicketDatesPopupComponent } from '../../mobile/modules/main/pages/season-ticket/components/ticket-dates-popup/ticket-dates-popup.component';
import { ResponseType } from '../enum/popup-types';
import { TicketStatus } from '../enum/ticket-status';
import { ReceivedTicketPopupComponent } from '../components/popups/received-ticket-popup/received-ticket-popup.component';
import { ReceivedTicket } from '../types/user';
import { VerificationPopupComponent } from '../components/popups/verification-popup/verification-popup.component';
import { WelcomePageComponent } from '../components/popups/welcome-page/welcome-page.component';
import { EventDetailsPopupComponent } from '../components/popups/event-details-popup/event-details-popup.component';
import { Event } from '../types/event';
import { EventsFilterPopupComponent } from '../components/popups/events-filter-popup/events-filter-popup.component';
import { ProsNews } from '../types/pro-news';
import { NewsDetailsPopupComponent } from '../components/popups/news-details-popup/news-details-popup.component';
import { PredictionResultPopupComponent } from '../components/popups/prediction-result-popup/prediction-result-popup.component';
import { UserPrediction } from '../types/predictions';
import { ImagePopupComponent } from '../components/popups/image-popup/image-popup.component';
import { DynamicPopup } from '../types/dynamicPopup';
import { BuilderFilterPopupComponent } from '../components/popups/builder-filter-popup/builder-filter-popup.component';
import { BuilderAccountSelectPopupComponent } from '../components/popups/builder-account-select-popup/builder-account-select-popup.component';
import { DownloadAppPopupComponent } from '../components/popups/download-app-popup/download-app-popup.component';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  constructor(private popupsManagerService: PopupsManagerService) {}

  public showAccountUpgradePopup(): AccountUpgradeComponent {
    return this.popupsManagerService.show<AccountUpgradeComponent>(
      AccountUpgradeComponent
    ).instance;
  }

  public showCardBenefitsPopup(): CardBenefitsComponent {
    return this.popupsManagerService.show<CardBenefitsComponent>(
      CardBenefitsComponent
    ).instance;
  }

  public showEditAccountPopup(): EditAccountComponent {
    return this.popupsManagerService.show<EditAccountComponent>(
      EditAccountComponent
    ).instance;
  }

  public showCropImagePopup(
    imageChangedEvent: string,
    isMobile: boolean = true
  ): ImageCropPopupComponent {
    return this.popupsManagerService.show<ImageCropPopupComponent>(
      ImageCropPopupComponent,
      {
        inputs: { imageChangedEvent, isMobile },
      }
    ).instance;
  }

  public showImagePopup(popup: DynamicPopup): ImagePopupComponent {
    return this.popupsManagerService.show<ImagePopupComponent>(
      ImagePopupComponent,
      {
        inputs: { popup },
        outputs: {
          close$: new EventEmitter(),
        },
      }
    ).instance;
  }

  public showAreaDetailsPopup(
    area: Area | AreaDetails
  ): SeasonTicketAreaDetailsPopupComponent {
    return this.popupsManagerService.show<SeasonTicketAreaDetailsPopupComponent>(
      SeasonTicketAreaDetailsPopupComponent,
      { inputs: { area } }
    ).instance;
  }

  public showCheckoutSuccessPopup(
    isMobile: boolean
  ): ActionResponsePopupComponent {
    return this.popupsManagerService.show<ActionResponsePopupComponent>(
      ActionResponsePopupComponent,
      {
        inputs: {
          responseType: ResponseType.Success,
          title: 'Ticket',
          action: 'Confirmed!',
          description: `You can now <strong class="c-text-1">Share</strong> event tickets with your friends!`,
          isMobile,
        },
      }
    ).instance;
  }

  public showCheckoutErrorPopup(
    isMobile: boolean
  ): ActionResponsePopupComponent {
    return this.popupsManagerService.show<ActionResponsePopupComponent>(
      ActionResponsePopupComponent,
      {
        inputs: {
          responseType: ResponseType.Error,
          title: 'Ticket',
          action: 'Error!',
          description: 'Something went wrong. Please try again.',
          isMobile,
        },
      }
    ).instance;
  }

  public showSharedSuccessPopup(
    name: string,
    surname: string,
    isMobile: boolean
  ): ActionResponsePopupComponent {
    return this.popupsManagerService.show<ActionResponsePopupComponent>(
      ActionResponsePopupComponent,
      {
        inputs: {
          isMobile,
          responseType: ResponseType.Success,
          title: 'Your Ticket is',
          action: 'Shared!',
          description: `You <strong class="c-text-1">Shared</strong> your ticket with <strong class="c-text-1">${name} ${surname}!</strong>`,
        },
      }
    ).instance;
  }

  public showLocationPermissionPopup(
    isMobile: boolean
  ): ActionResponsePopupComponent {
    return this.popupsManagerService.show<ActionResponsePopupComponent>(
      ActionResponsePopupComponent,
      {
        inputs: {
          isMobile,
          responseType: ResponseType.Permission,
          title: 'Terms and Conditions',
          description: `<div class="fs-lg lh-lg fw-500 font-ibm c-text-1 mb-18">To continue, let your device turn on location using Google’s Location Service.</div>`,
          confirmText: 'OK',
          rejectText: 'Cancel',
          icon: '<span class="bicon bicon-location-arrow-o c-primary"></span>',
        },
      }
    ).instance;
  }

  public showLocationPermissionRejectPopup(
    isMobile: boolean
  ): ActionResponsePopupComponent {
    return this.popupsManagerService.show<ActionResponsePopupComponent>(
      ActionResponsePopupComponent,
      {
        inputs: {
          isMobile,
          responseType: ResponseType.Error,
          title: 'Connect Error',
          description: `<div class="fs-lg lh-lg fw-500 font-ibm c-text-1 mb-18">You can't join without location detection!</div>`,
        },
      }
    ).instance;
  }

  public showVipMembershipFormSubmitPopup(
    isMobile: boolean
  ): ActionResponsePopupComponent {
    return this.popupsManagerService.show<ActionResponsePopupComponent>(
      ActionResponsePopupComponent,
      {
        inputs: {
          isMobile,
          responseType: ResponseType.Success,
          title: 'Thank you!',
          action: 'We received your request.',
        },
      }
    ).instance;
  }

  public showPredictionAnswerPopup(
    responseType: ResponseType,
    answer: string = '',
    reward?: number,
    isMobile: boolean = true
  ): ActionResponsePopupComponent {
    return this.popupsManagerService.show<ActionResponsePopupComponent>(
      ActionResponsePopupComponent,
      {
        inputs: {
          responseType,
          title: `You picked <span class="text-uppercase c-primary">"${answer}"</span>`,
          description: `A correct answer wins`,
          reward: reward || null,
          isMobile,
        },
      }
    ).instance;
  }

  public showPredictionResultPopup(
    userPrediction: UserPrediction,
    answer: string,
    isMobile: boolean = true
  ): PredictionResultPopupComponent {
    return this.popupsManagerService.show<PredictionResultPopupComponent>(
      PredictionResultPopupComponent,
      {
        inputs: { userPrediction, answer, isMobile },
        outputs: {
          action$: new EventEmitter(),
        },
      }
    ).instance;
  }

  public showReceivedAcceptOrDeclinePopup(
    firstName: string,
    lastName: string,
    status: TicketStatus,
    isMobile: boolean = true
  ): ActionResponsePopupComponent {
    const action = status === TicketStatus.accepted ? 'accepted' : 'declined';
    return this.popupsManagerService.show<ActionResponsePopupComponent>(
      ActionResponsePopupComponent,
      {
        inputs: {
          isMobile,
          responseType:
            status === TicketStatus.accepted
              ? ResponseType.Success
              : ResponseType.Error,
          title: 'Ticket',
          action: `${action}!`,
          description: `You <strong class="c-text-1">${action} ${firstName} ${lastName} Shared</strong> ticket!`,
        },
      }
    ).instance;
  }

  public showTicketCallbackPopup(
    firstName: string,
    lastName: string,
    isMobile: boolean
  ): ActionResponsePopupComponent {
    return this.popupsManagerService.show<ActionResponsePopupComponent>(
      ActionResponsePopupComponent,
      {
        inputs: {
          isMobile,
          responseType: ResponseType.Info,
          title: 'Ticket',
          action: 'Call back!',
          description: `Do you want call back shared ticket with <strong class="c-text-1">${firstName} ${lastName}?</strong>`,
          rejectText: 'Not now',
          confirmText: 'Yes',
        },
      }
    ).instance;
  }

  public removeSportBookAccountConfirmPopup(
    accountName: string,
    stateName: string,
    isMobile: boolean
  ): ActionResponsePopupComponent {
    return this.popupsManagerService.show<ActionResponsePopupComponent>(
      ActionResponsePopupComponent,
      {
        inputs: {
          isMobile,
          responseType: ResponseType.Info,
          title: 'Remove account',
          description: `Do you want to remove your <strong class="c-text-1">${accountName}-${stateName}</strong> account?`,
          rejectText: 'Cancel',
          confirmText: 'Remove',
        },
      }
    ).instance;
  }

  public showErrorResponsePopup(
    description: string = 'Something went wrong. Please try again.'
  ): ActionResponsePopupComponent {
    return this.popupsManagerService.show<ActionResponsePopupComponent>(
      ActionResponsePopupComponent,
      {
        inputs: {
          responseType: ResponseType.Error,
          action: 'Error!',
          description,
        },
      }
    ).instance;
  }

  public showSelectedDatesPopup(dates: string[]): TicketDatesPopupComponent {
    return this.popupsManagerService.show<TicketDatesPopupComponent>(
      TicketDatesPopupComponent,
      { inputs: { dates } }
    ).instance;
  }

  public showReceivedTicketPopup(
    receivedTicket: ReceivedTicket,
    isMobile
  ): ReceivedTicketPopupComponent {
    return this.popupsManagerService.show<ReceivedTicketPopupComponent>(
      ReceivedTicketPopupComponent,
      { inputs: { receivedTicket, isMobile } }
    ).instance;
  }

  public showVerificationPopup(
    phoneNumber: string,
    isMobile: boolean = true
  ): VerificationPopupComponent {
    return this.popupsManagerService.show<VerificationPopupComponent>(
      VerificationPopupComponent,
      {
        inputs: { phoneNumber, isMobile },
        outputs: {
          verificationCode: new EventEmitter(),
        },
      }
    ).instance;
  }

  public showWelcomePagePopup(): WelcomePageComponent {
    return this.popupsManagerService.show<WelcomePageComponent>(
      WelcomePageComponent,
      {
        outputs: {
          action$: new EventEmitter(),
        },
      }
    ).instance;
  }

  public showEventDetailsPopup(event: Event): EventDetailsPopupComponent {
    return this.popupsManagerService.show<EventDetailsPopupComponent>(
      EventDetailsPopupComponent,
      {
        inputs: {
          event,
        },
        outputs: {
          action$: new EventEmitter(),
        },
      }
    ).instance;
  }

  public showDownloadAppPopup(id: string = ''): DownloadAppPopupComponent {
    return this.popupsManagerService.show<DownloadAppPopupComponent>(
      DownloadAppPopupComponent,
      {
        inputs: {
          id,
        },
      }
    ).instance;
  }

  public showEventFilterPopup(
    keys: string[],
    filteredKeys: string[]
  ): EventsFilterPopupComponent {
    return this.popupsManagerService.show<EventsFilterPopupComponent>(
      EventsFilterPopupComponent,
      {
        inputs: {
          keys,
          filteredKeys,
        },
        outputs: {
          action$: new EventEmitter(),
        },
      }
    ).instance;
  }

  public showNewsDetailsPopup(news: ProsNews): NewsDetailsPopupComponent {
    return this.popupsManagerService.show<NewsDetailsPopupComponent>(
      NewsDetailsPopupComponent,
      {
        inputs: {
          news,
        },
        outputs: {
          action$: new EventEmitter(),
        },
      }
    ).instance;
  }

  public showBuilderFilterPopup(leagueFilters): BuilderFilterPopupComponent {
    return this.popupsManagerService.show<BuilderFilterPopupComponent>(
      BuilderFilterPopupComponent,
      {
        inputs: {
          leagueFilters,
        },
        outputs: {
          submit$: new EventEmitter(),
        },
      }
    ).instance;
  }

  public showBuilderAccountPopup(): BuilderAccountSelectPopupComponent {
    return this.popupsManagerService.show<BuilderAccountSelectPopupComponent>(
      BuilderAccountSelectPopupComponent,
      {
        inputs: {},
        outputs: {
          action$: new EventEmitter(),
        },
      }
    ).instance;
  }
}
