export const environment = {
  production: false,
  apiUrl: 'https://dev-api.bankrollclub.com/dev',
  firebase: {
    apiKey: 'AIzaSyCd5Ml-R-OPqGb9bIA-wbuDA3tc2bPSJ5Y',
    authDomain: 'bankroll-dev-b7acb.firebaseapp.com',
    projectId: 'bankroll-dev-b7acb',
    storageBucket: 'bankroll-dev-b7acb.appspot.com',
    messagingSenderId: '869666952461',
    appId: '1:869666952461:web:8be6493cda9b9ea5aa8c44',
    measurementId: 'G-JS4JP3NE64',
  },
  stripeKey:
    'pk_test_51M4r51E4aTs58kaMY1E8Dc7XmR4HWq9WxrIWSq2QbFr361O0fqiFi3c7T6YOgVyXe6CnFxGfQNyoMtjbI0eeqejZ00VbdNkU10',
  bigGameRoomResyKey: 'xcBf2sPVwUrrn0H2VK2IiDvHBdaUdVxl',
  diningRoomResyKey: 'Ihi4Syx7ghFYpXQkRwCAQeZ9TwD9QaYF',
  recaptcha: {
    siteKey: '6LeC4CkUAAAAAK39iB_y_XhgS1EhvArMwecdZmCr',
  },
  club: false,
  isLive: false,
  sharpSportsToken: '14e809bb00084064f6dda182c6848c10212e5c7d',
};
