import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  areaSelectDesktopBreadcrumb,
  buyBreadcrumb,
  checkoutDesktopBreadcrumb,
  checkoutGeneralDesktopBreadcrumb,
  myReservationsBreadcrumb,
  ticketsDesktopBreadcrumb,
} from '../../../../../common/constants/breadcrumbs';
import { BuySeasonTicketDesktopComponent } from './pages/buy-season-ticket-desktop/buy-season-ticket-desktop.component';
import { SeasonTicketAreaSelectDesktopPageComponent } from './pages/season-ticket-area-select-desktop-page/season-ticket-area-select-desktop-page.component';
import { CheckoutSeasonTicketDesktopPageComponent } from './pages/checkout-season-ticket-desktop-page/checkout-season-ticket-desktop-page.component';
import { SeasonTicketReservationDesktopComponent } from './pages/season-ticket-reservation-desktop/season-ticket-reservation-desktop.component';
import { SeasonTicketPreviewDesktopComponent } from './pages/season-ticket-preview-desktop/season-ticket-preview-desktop.component';
import { SeasonTicketConfigActivatorGuard } from '../../../../../common/guards/season-ticket-config-activator.guard';

const routes: Routes = [
  {
    path: '',
    data: { breadcrumb: buyBreadcrumb, state: true },
    component: BuySeasonTicketDesktopComponent,
    canDeactivate: [SeasonTicketConfigActivatorGuard],
  },
  {
    path: 'area-select/:eventId',
    data: { breadcrumb: areaSelectDesktopBreadcrumb, state: true },
    component: SeasonTicketAreaSelectDesktopPageComponent,
    canDeactivate: [SeasonTicketConfigActivatorGuard],
  },
  {
    path: 'ticket-view/:eventId/:areaId/:tableId',
    data: { breadcrumb: checkoutDesktopBreadcrumb, state: true },
    component: CheckoutSeasonTicketDesktopPageComponent,
    canDeactivate: [SeasonTicketConfigActivatorGuard],
  },
  {
    path: 'ticket-view/:eventId',
    data: {
      breadcrumb: checkoutGeneralDesktopBreadcrumb,
      state: true,
      isGeneral: true,
    },
    component: CheckoutSeasonTicketDesktopPageComponent,
    canDeactivate: [SeasonTicketConfigActivatorGuard],
  },
  {
    path: 'reservations',
    data: { breadcrumb: myReservationsBreadcrumb },
    component: SeasonTicketReservationDesktopComponent,
    canActivate: [SeasonTicketConfigActivatorGuard],
  },
  {
    path: 'ticket/:ticketId',
    data: { breadcrumb: ticketsDesktopBreadcrumb },
    component: SeasonTicketPreviewDesktopComponent,
    canActivate: [SeasonTicketConfigActivatorGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class SeasonTicketDesktopRoutingModule {}
