import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { LoaderService } from '../../services/loader.service';
import { User } from '../../types/user';
import { LeaderboardScores } from '../../types/leaderboards';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Pageable } from '../../../common/types/pageable';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UserService } from '../../services/user.service';
import { VenuePredictAndWinService } from '../../services/venue-predict-and-win.service';

@Component({
  selector: 'app-question-leaderboard',
  templateUrl: './question-leaderboard.component.html',
  styleUrls: ['./question-leaderboard.component.scss'],
  standalone: true,
  imports: [CommonModule, InfiniteScrollModule],
})
export class QuestionLeaderboardComponent
  extends BaseComponent
  implements OnInit
{
  @Input() public isMobile = true;
  public user: User = this.userService.user;
  public leaderboard: Pageable<LeaderboardScores>;
  private currentPage = 0;
  private leaderboardId: string;

  constructor(
    private predictAndWinService: VenuePredictAndWinService,
    private userService: UserService,
    private loaderService: LoaderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  public onScrollDown(): void {
    if (
      !this.leaderboard ||
      this.leaderboard?.total === this.leaderboard?.items?.length
    ) {
      return;
    }
    this.currentPage++;
    this.fetchLeaderboardScores().subscribe(
      (res: Pageable<LeaderboardScores>) =>
        (this.leaderboard = {
          ...res,
          items: [...this.leaderboard.items, ...res.items],
        })
    );
  }

  private init() {
    this.leaderboardId = this.predictAndWinService.currentLeaderBoardId;
    if (!this.leaderboardId) return;
    this.loaderService.show();
    this.fetchLeaderboardScores()
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe(
        (res: Pageable<LeaderboardScores>) => (this.leaderboard = res)
      );
  }

  private fetchLeaderboardScores(): Observable<Pageable<LeaderboardScores>> {
    return this.predictAndWinService
      .getLeaderboardScores(this.leaderboardId, this.currentPage)
      .pipe(takeUntil(this.unsubscribe$));
  }
}
