import { Component, OnInit } from '@angular/core';
import { DynamicPopupService } from '../../../common/services/dynamic-popup.service';
import { PageCode } from '../../../common/enum/page-code';

@Component({
  selector: 'app-space-desktop',
  templateUrl: './space-desktop.component.html',
  styleUrls: ['./space-desktop.component.scss'],
})
export class SpaceDesktopComponent implements OnInit {
  constructor(private dynamicPopupService: DynamicPopupService) {}

  ngOnInit() {
    this.dynamicPopupService.showPagePopups(PageCode.theSpace);
  }
}
