import {Pipe, PipeTransform} from '@angular/core';
import {MenuPages} from '../types/menu-items';

@Pipe({
  name: 'menuItemsFilter',
  standalone: true
})
export class MenuItemsFilter implements PipeTransform {
  public notVisibleMenuItems = ['My Tickets'];

  transform(value: MenuPages[], isLogin): MenuPages[] {
    if (!value || !value?.length) return [];
    return isLogin ? value :
      value.filter((v) => !this.notVisibleMenuItems.includes(v.name));
  }
}
