import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-base-popup',
  templateUrl: './base-popup.component.html',
  styleUrls: ['./base-popup.component.scss'],
  standalone: true,
})
export class BasePopupComponent implements OnInit, OnDestroy {
  @Input() public title: string;
  @Output() public close$: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    document.body.classList.add('no-scroll');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('no-scroll');
  }

  public closePopup(): void {
    this.close$.emit();
  }
}
