import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {OutsideClickDirective} from '../../directives/outside-click.directive';
import {getHours, isValidTime} from '../../helpers/date-time';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimePickerComponent),
    },
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    OutsideClickDirective
  ]
})
export class TimePickerComponent implements ControlValueAccessor {

  public show: boolean;
  public hours: string[] = getHours();
  public hoursFormControl: FormControl = new FormControl();
  private defaultTime: string = '12:00am';

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onChange: (arg) => void = (arg) => {
  };

  writeValue(v): void {
    this.handleOptionSelect(v);
  }

  public handleInputBlur(input: HTMLInputElement): void {
    input.blur();
    const hoursValue = this.getValidTime();
    if (hoursValue) {
      this.hoursFormControl.setValue(hoursValue);
      this.onChange(hoursValue);
    }
  }

  public getValidTime(): string {
    if (isValidTime(this.hoursFormControl.value)) {
      return this.hoursFormControl.value;
    }
    return this.defaultTime;
  }

  public handleOptionSelect(timePicker: string): void {
    this.show = false;
    this.hoursFormControl.setValue(timePicker);
    this.onChange(timePicker);
  }

}
