import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BankrollBuilderComponent } from './bankroll-builder.component';
import { BankrollBuilderRoutingModule } from './bankroll-builder-routing.module';
import { RouterModule } from '@angular/router';
import { BuilderDashboardComponent } from './pages/builder-dashboard/builder-dashboard.component';
import { BuilderOverviewComponent } from './pages/builder-dashboard/pages/builder-overview/builder-overview.component';
import { BuilderMyBetsComponent } from './pages/builder-dashboard/pages/builder-my-bets/builder-my-bets.component';
import { BuilderOverviewHomeComponent } from './pages/builder-dashboard/pages/builder-overview/pages/builder-overview-home/builder-overview-home.component';
import { BuilderHistoryComponent } from './pages/builder-dashboard/pages/builder-overview/pages/builder-history/builder-history.component';
import { BuilderIntroComponent } from './pages/builder-intro/builder-intro.component';
import { BreadcrumbComponent } from '../../../components/layout/breadcrumb/breadcrumb.component';
import { SharpSportsComponent } from './pages/sharp-sports/sharp-sports.component';
import { BookSelectComponent } from './pages/sharp-sports/pages/book-select/book-select.component';
import { RegionSelectComponent } from './pages/sharp-sports/pages/region-select/region-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BuilderLeaderboardComponent } from './pages/builder-dashboard/pages/builder-leaderboard/builder-leaderboard.component';
import { BuilderAccountsComponent } from './pages/builder-accounts/builder-accounts.component';
import { BuilderTodayBetsComponent } from './pages/builder-dashboard/pages/builder-overview/pages/builder-today-bets/builder-today-bets.component';
import { BetSlipItemComponent } from '../../../../../common/components/bankroll-builder/bet-slip-item/bet-slip-item.component';
import { AddProfitStyleClassPipe } from '../../../../../common/pipes/builder/add-profit-style-class.pipe';
import { BuilderIntroSharedComponent } from '../../../../../common/components/bankroll-builder/builder-inro-shared/builder-intro-shared.component';
import { BookSelectSharedComponent } from '../../../../../common/components/bankroll-builder/book-select-shared/book-select-shared.component';
import { RegionSelectSharedComponent } from '../../../../../common/components/bankroll-builder/region-select-shared/region-select-shared.component';
import { BuilderOverviewSharedComponent } from '../../../../../common/components/bankroll-builder/builder-overview-shared/builder-overview-shared.component';
import { BuilderTodayBetsSharedComponent } from '../../../../../common/components/bankroll-builder/builder-today-bets-shared/builder-today-bets-shared.component';
import { BuilderHistorySharedComponent } from '../../../../../common/components/bankroll-builder/builder-history-shared/builder-history-shared.component';
import { BuilderAccountSharedComponent } from '../../../../../common/components/bankroll-builder/builder-account-shared/builder-account-shared.component';
import { BuilderMyBetsSharedComponent } from '../../../../../common/components/bankroll-builder/builder-my-bets-shared/builder-my-bets-shared.component';
import { DateTimerPipe } from '../../../../../common/pipes/date-timer.pipe';
import { ImageUrlFormatterPipe } from '../../../../../common/pipes/builder/image-url-formatter.pipe';
import { BuilderLeaderboardSharedComponent } from '../../../../../common/components/bankroll-builder/builder-leaderboard-shared/builder-leaderboard-shared.component';
import { SelectVenueSharedComponent } from '../../../../../common/components/bankroll-builder/select-venue-shared/select-venue-shared.component';
import { LeaderboardPrizesComponent } from '../../../../../common/components/bankroll-builder/leaderboard-prizes/leaderboard-prizes.component';
import { ClockCountDownComponent } from '../../../../../common/components/bankroll-builder/clock-count-down/clock-count-down.component';
import { ShowConnectPipe } from '../../../../../common/pipes/builder/show-connect.pipe';
import { ContestRulesComponent } from './pages/contest-rules/contest-rules.component';

@NgModule({
  imports: [
    //modules
    CommonModule,
    BankrollBuilderRoutingModule,
    RouterModule,
    ReactiveFormsModule,
    //components
    BreadcrumbComponent,
    BetSlipItemComponent,
    BuilderIntroSharedComponent,
    BookSelectSharedComponent,
    RegionSelectSharedComponent,
    BuilderOverviewSharedComponent,
    BuilderTodayBetsSharedComponent,
    BuilderHistorySharedComponent,
    BuilderAccountSharedComponent,
    BuilderMyBetsSharedComponent,
    BuilderLeaderboardSharedComponent,
    SelectVenueSharedComponent,
    LeaderboardPrizesComponent,
    ClockCountDownComponent,
    //Pipes
    AddProfitStyleClassPipe,
    DateTimerPipe,
    ImageUrlFormatterPipe,
    ShowConnectPipe,
  ],
  declarations: [
    //components
    BankrollBuilderComponent,
    BuilderDashboardComponent,
    BuilderLeaderboardComponent,
    BuilderOverviewComponent,
    BuilderMyBetsComponent,
    BuilderOverviewHomeComponent,
    BuilderAccountsComponent,
    BuilderHistoryComponent,
    BuilderIntroComponent,
    SharpSportsComponent,
    BookSelectComponent,
    RegionSelectComponent,
    BuilderTodayBetsComponent,
    ContestRulesComponent,
  ],
  providers: [DatePipe],
})
export class BankrollBuilderModule {}
