import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview-summary',
  templateUrl: './builder-overview.component.html',
  styleUrls: ['./builder-overview.component.scss'],
})
export class BuilderOverviewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
