import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  community,
  fromPros,
} from '../../../../../common/constants/breadcrumbs';
import { CommunityItemsDesktopComponent } from './pages/community-items-desktop/community-items-desktop.component';
import { HearFromTheProsDesktopPageComponent } from './pages/hear-from-the-pros-desktop-page/hear-from-the-pros-desktop-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      // {
      //   path: '',
      //   component: CommunityItemsDesktopComponent,
      //   data: { breadcrumb: community },
      // },
      {
        path: '',
        redirectTo: 'hear-pros',
        pathMatch: 'full',
      },
      {
        path: 'hear-pros',
        component: HearFromTheProsDesktopPageComponent,
        data: { breadcrumb: fromPros },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class CommunityDesktopRoutingModule {}
