import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
} from '@angular/router';
import { SeasonTicketsPages } from '../enum/page-state';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SeasonTicketConfigActivatorGuard
  implements CanDeactivate<unknown>, CanActivate
{
  constructor(private storageService: StorageService) {}

  canActivate(route: ActivatedRouteSnapshot): true {
    if (!route.data?.state) {
      this.removeTicketPurchaseStates();
    }
    return true;
  }

  canDeactivate(component: unknown, route: ActivatedRouteSnapshot): true {
    if (!route.data?.state) {
      this.removeTicketPurchaseStates();
    }
    return true;
  }

  private removeTicketPurchaseStates(): void {
    this.storageService.removeFromStorage('dates');
    this.storageService.removeFromStorage(SeasonTicketsPages.buyTicket);
    this.storageService.removeFromStorage(SeasonTicketsPages.selectedAreaId);
  }
}
