import { EventDate } from '../types/event';

export const getCalendars = (dates: EventDate[]): Set<string> => {
  return new Set<string>(
    dates.map((eventDate: EventDate) => {
      const [year, month] = eventDate.date.split('-');
      return `${year}-${month}`;
    })
  );
};
