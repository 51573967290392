import { Injectable } from '@angular/core';
import { UserUpdateReq, User } from '../types/user';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of, zip } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SKIP_AUTH_HEADER } from '../constants/headers';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  private _user?: User;

  get user(): User {
    return this._user;
  }

  set user(value: User) {
    this._user = value;
  }

  public getUser(): Observable<User> {
    return this.http
      .get<User>(`${environment.apiUrl}/client/me`)
      .pipe(tap((user: User) => (this.user = user)));
  }

  public updateUser(payload: UserUpdateReq): Observable<{
    message: string;
  }> {
    return this.http.put<{
      message: string;
    }>(`${environment.apiUrl}/client/me`, payload);
  }

  public getUserRecords(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/client/me/records`);
  }

  public uploadImage(file: File) {
    return this.getUserImageUploadUrl(file.type, file.name).pipe(
      switchMap(({ url }) => zip(of(url), this.uploadS3(url, file)))
    );
  }

  private getUserImageUploadUrl(
    contentType: string,
    fileName: string
  ): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(
      `${environment.apiUrl}/client/user-image-upload-url?contentType=${contentType}&fileName=${fileName}`
    );
  }

  private uploadS3(url: string, file: File) {
    return this.http.put(url, file, {
      headers: {
        [SKIP_AUTH_HEADER]: '',
      },
    });
  }
}
