import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PrivateEventsDesktopComponent } from './private-events-desktop.component';
import { TripleseatComponent } from '../../../components/tripleseat/tripleseat.component';
import { privateEventsBreadcrumb } from '../../../common/constants/breadcrumbs';
import { HeaderDesktopComponent } from '../components/layout/header/header-desktop.component';
import { FooterDesktopComponent } from '../components/layout/footer/footer-desktop.component';
import { BreadcrumbDesktopComponent } from '../components/layout/breadcrumb/breadcrumb-desktop.component';
import { AssetsUrlPipe } from '../../../common/pipes/assets-url.pipe';

const routes: Routes = [
  {
    path: '',
    component: PrivateEventsDesktopComponent,
    data: { breadcrumb: privateEventsBreadcrumb },
  },
];

@NgModule({
  declarations: [PrivateEventsDesktopComponent],
  imports: [
    // Modules
    CommonModule,
    RouterModule.forChild(routes),
    // Components
    HeaderDesktopComponent,
    FooterDesktopComponent,
    BreadcrumbDesktopComponent,
    TripleseatComponent,
    // Pipes
    AssetsUrlPipe,
  ],
})
export class PrivateEventsDesktopModule {}
