import { Component, OnInit } from '@angular/core';
import { BankrollStoryBaseComponent } from '../../../common/base-controllers/bankroll-story-base.component';
import { BreadcrumbBackground } from '../../../common/enum/breadcrumb-background';
import { GeocodingService } from '../../../common/services/geocoder.service';
import { StaticPagesService } from '../../../common/services/static-pages.service';
import { StaticPages } from '../../../common/types/static-pages';
import { StaticPagesId } from '../../../common/enum/static-pages-id';
import { DynamicPopupService } from '../../../common/services/dynamic-popup.service';
import { LoaderService } from '../../../common/services/loader.service';

@Component({
  selector: 'app-bankroll-story',
  templateUrl: './bankroll-story-desktop.component.html',
  styleUrls: ['./bankroll-story-desktop.component.css'],
})
export class BankrollStoryDesktopComponent
  extends BankrollStoryBaseComponent
  implements OnInit
{
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  override pageId = StaticPagesId.aboutBankroll;
  override staticPageData: StaticPages;

  constructor(
    geocodingService: GeocodingService,
    staticPagesService: StaticPagesService,
    loaderService: LoaderService,
    dynamicPopupService: DynamicPopupService
  ) {
    super(
      geocodingService,
      loaderService,
      staticPagesService,
      dynamicPopupService
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
