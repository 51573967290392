import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storagePrefix = 'bankroll.app.';
  private sevenRoomsButtonRef: HTMLElement;

  public saveToStorage<T>(
    key: string,
    value: T,
    storage = sessionStorage
  ): void {
    try {
      storage.setItem(`${this.storagePrefix}.${key}`, JSON.stringify(value));
    } catch (e) {
      console.error(value);
      throw new Error('Error while storing ' + key);
    }
  }

  public getFromStorage<T>(key: string): T | string | null {
    const fullKey = `${this.storagePrefix}.${key}`;
    const stringData =
      localStorage.getItem(fullKey) || sessionStorage.getItem(fullKey);
    if (!stringData) {
      return null;
    }
    try {
      return JSON.parse(stringData);
    } catch (e) {
      return stringData;
    }
  }

  public removeFromStorage(key: string): void {
    localStorage.removeItem(`${this.storagePrefix}.${key}`);
    sessionStorage.removeItem(`${this.storagePrefix}.${key}`);
  }

  public removeMultipleFromStorage(keys: string[]): void {
    keys.forEach((k) => localStorage.removeItem(`${this.storagePrefix}.${k}`));
    keys.forEach((k) =>
      sessionStorage.removeItem(`${this.storagePrefix}.${k}`)
    );
  }

  public storeButtonRef(): void {
    this.sevenRoomsButtonRef = document.getElementById('sr-events-root');
  }

  public getSevenRoomsButtonRef(): HTMLElement {
    return this.sevenRoomsButtonRef;
  }
}
