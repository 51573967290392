import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { timer } from 'rxjs';
import { BaseComponent } from '../../base/base.component';
import { takeUntil } from 'rxjs/operators';
import { PopupsManagerService } from '../../../services/popups-manager.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  standalone: true,
  styleUrls: ['./welcome-page.component.css'],
})
export class WelcomePageComponent extends BaseComponent implements OnInit {
  @Output() public action$: EventEmitter<void>;

  constructor(private popupsManagerService: PopupsManagerService) {
    super();
  }

  ngOnInit(): void {
    timer(7000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.action$.emit();
        this.popupsManagerService.hide();
      });
  }
}
