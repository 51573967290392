import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BaseComponent } from '../components/base/base.component';
import { Calendar, Day } from '../types/calendar';
import { SeasonTicketsService } from '../services/season-tickets.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../services/loader.service';
import { Event, EventDate } from '../types/event';
import { finalize, takeUntil } from 'rxjs/operators';
import { CalendarItem } from '../components/season-ticket-calendar/calendar-item';
import { getDatesInMonth } from '../helpers/date-timer';
import { getCalendars } from '../helpers/calendar';
import { StorageService } from '../services/storage.service';

@Component({
  template: '',
})
export abstract class SeasonTicketDatesBaseComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() public currentEvent: Event;
  @Output() public submit$: EventEmitter<void> = new EventEmitter<void>();
  public calendarMap = new Map<string, Calendar>();
  public calendars: string[];
  public loading = true;
  public event: Event;
  public dates: string[] = [];
  public isSelectedAll: boolean;
  public selectableDaysCount = 0;

  protected constructor(
    private seasonTicketsService: SeasonTicketsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.currentEvent) {
      this.init();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.currentEvent.currentValue) {
      this.event = this.currentEvent;
      this.selectableDaysCount = this.currentEvent.dates.length;
      this.generateCalendar(this.event.dates);
    }
  }

  public fetchDatesAndNavigateToAreas(): void {
    if (!this.dates?.length) {
      return;
    }
    this.storageService.saveToStorage('dates', this.dates);
    if (this.event.type === 'general') {
      this.router.navigateByUrl(
        `/my/season-tickets/ticket-view/${this.event.id}`
      );
    } else {
      this.router.navigateByUrl(
        `/my/season-tickets/area-select/${this.event.id}`
      );
    }
    this.submit$.emit();
  }

  private generateCalendar(dates: EventDate[]): void {
    this.calendarMap.clear();
    const calendars = getCalendars(dates);
    this.setCalendarDays(calendars, dates);
    this.calendars = Array.from(this.calendarMap.keys());
    this.dates = this.getSelectedDates();
    this.isSelectedAll = this.event.dates.length === this.dates.length;
    this.setEmptyDays();
  }

  private setCalendarDays(
    calendars: Set<string>,
    highlightedDates: EventDate[]
  ): void {
    Array.from(calendars).forEach((calendar: string) => {
      const monthYear = calendar.split('-');
      const [year, month] = monthYear;
      this.calendarMap.set(calendar, {
        days: getDatesInMonth(
          month,
          year,
          highlightedDates.map((e) => e.date),
          this.storageService
        ),
        startIndex: new Date(+year, +month - 1, 1).getDay(),
      });
    });
  }

  private setEmptyDays(): void {
    this.calendars.forEach((calendar) => {
      const emptyDays = new Array(
        this.calendarMap.get(calendar).startIndex
      ).fill({
        day: null,
        highlighted: false,
      });
      this.calendarMap.get(calendar).days.unshift(...emptyDays);
    });
  }

  private init(): void {
    const eventId = this.activatedRoute.snapshot.params.eventId;
    if (!eventId) {
      return;
    }
    this.loaderService.show();
    this.seasonTicketsService
      .getEvent(eventId)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe((events: Event) => {
        this.event = events;
        this.selectableDaysCount = events.dates.length;
        this.generateCalendar(events.dates);
      });
  }

  public handleSelectDate(calendarItem: CalendarItem): void {
    const day: Day = this.calendarMap
      .get(calendarItem.key)
      .days.find((s) => s.day == calendarItem.day);
    day.selected = !day.selected;
    this.dates = this.getSelectedDates();
    this.isSelectedAll = this.selectableDaysCount === this.dates.length;
  }

  public toggleSelectedDates(): void {
    this.isSelectedAll = !this.isSelectedAll;
    Array.from(this.calendarMap.keys()).forEach((key) => {
      this.calendarMap
        .get(key)
        .days.forEach(
          (date) => date.highlighted && (date.selected = this.isSelectedAll)
        );
    });
    this.dates = this.isSelectedAll ? this.getSelectedDates() : [];
  }

  private getSelectedDates(): string[] {
    const dates = [];
    this.calendarMap.forEach((value: Calendar) =>
      value.days
        .filter((day: Day) => day.selected)
        .forEach((d: Day) => dates.push(d.day))
    );
    return dates;
  }
}
