import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials',
  standalone: true,
})
export class NameInitialsPipe implements PipeTransform {
  transform(firstName: string, lastName: string): string {
    return `${firstName[0]}.${lastName[0]}.`;
  }
}
