export enum PopupTypes {
  answerSuccess = 'answerSuccess',
  answerError = 'answerError',
}

export enum AccountPopupTypes {
  upgrade = 'upgrade',
  cardBenefits = 'cardBenefits',
  edit = 'edit',
}

export enum ResponseType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Permission = 'permission',
}
