import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpaceComponent } from './space.component';
import { RouterModule, Routes } from '@angular/router';
import { SpaceAreaSelectComponent } from './pages/space-area-select/space-area-select.component';
import { SpaceAreaViewComponent } from './pages/space-area-view/space-area-view.component';
import { AreaViewComponent } from '../../../common/components/area-view/area-view.component';
import { SeasonTicketAreaSelectComponent } from '../../../common/components/season-ticket-area-select/season-ticket-area-select.component';
import { SeasonTicketAreaDetailsPopupComponent } from '../main/pages/season-ticket/components/season-ticket-area-details-popup/season-ticket-area-details-popup.component';
import { BasePopupComponent } from '../../../common/components/base/base-popup/base-popup.component';
import {
  spaceAreaSelect,
  spaceAreaView,
} from '../../../common/constants/breadcrumbs';
import { BreadcrumbComponent } from '../components/layout/breadcrumb/breadcrumb.component';
import { FooterDesktopComponent } from '../../../desktop/modules/components/layout/footer/footer-desktop.component';
import { HeaderComponent } from '../components/layout/header/header.component';

const routes: Routes = [
  {
    path: '',
    component: SpaceComponent,
    children: [
      {
        path: 'area-select',
        data: { breadcrumb: spaceAreaSelect },
        component: SpaceAreaSelectComponent,
      },
      {
        path: 'area-view/:areaId',
        data: { breadcrumb: spaceAreaView },
        component: SpaceAreaViewComponent,
      },
      {
        path: '',
        redirectTo: 'area-select',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    HeaderComponent,
    FooterDesktopComponent,
    AreaViewComponent,
    SeasonTicketAreaSelectComponent,
    BasePopupComponent,
    SeasonTicketAreaDetailsPopupComponent,
    BreadcrumbComponent,
  ],
  declarations: [
    SpaceComponent,
    SpaceAreaSelectComponent,
    SpaceAreaViewComponent,
  ],
})
export class SpaceModule {}
