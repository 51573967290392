import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../common/guards/auth.guard';
import { UnauthorizedGuard } from '../common/guards/unauthorized.guard';
import { ReceivedTicketGuard } from '../common/guards/received-ticket.guard';
import { MobileComponent } from './mobile.component';

const authorizedRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'welcome-drinks',
    loadChildren: () =>
      import('./modules/welcome-drink-page/welcome-drink-page.module').then(
        (m) => m.WelcomeDrinkPageModule
      ),
  },
];

const unauthorizedRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'schedule',
    loadChildren: () =>
      import(
        './modules/season-ticket-schedule/season-ticket-schedule.module'
      ).then((m) => m.SeasonTicketScheduleModule),
  },
  {
    path: 'menu',
    loadChildren: () =>
      import('./modules/menu/menu.module').then((m) => m.MenuModule),
  },
  {
    path: 'static',
    loadChildren: () =>
      import('./modules/static-pages/static-pages.module').then(
        (m) => m.StaticPagesModule
      ),
  },
  {
    path: 'private-events',
    loadChildren: () =>
      import('./modules/private-events/private-events.module').then(
        (m) => m.PrivateEventsModule
      ),
  },
  {
    path: 'space',
    loadChildren: () =>
      import('./modules/space/space.module').then((m) => m.SpaceModule),
  },
  {
    path: 'partners',
    loadChildren: () =>
      import('./modules/partners/partners.module').then(
        (m) => m.PartnersModule
      ),
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./modules/privacy/privacy.module').then((m) => m.PrivacyModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        loadChildren: () =>
          import('./modules/bankroll-story/bankroll-story.module').then(
            (m) => m.BankrollStoryModule
          ),
      },
      {
        path: 'privacy',
        loadChildren: () =>
          import('./modules/privacy/privacy.module').then(
            (m) => m.PrivacyModule
          ),
      },
    ]),
  ],
  exports: [RouterModule],
})
export class MobileRoutingModule {}
