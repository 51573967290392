import { Pipe, PipeTransform } from '@angular/core';
import { EventWithCategory } from '../../mobile/modules/season-ticket-schedule/event';

@Pipe({
  name: 'filterCount',
  standalone: true,
})
export class FilterCountPipe implements PipeTransform {
  transform(
    filteredEvents: EventWithCategory,
    events: EventWithCategory
  ): number {
    if (!events || !filteredEvents) return;
    const eventsCount = Object.keys(events).length || 0;
    const filteredCount = Object.keys(filteredEvents).length || 0;
    return eventsCount === filteredCount ? 0 : filteredCount;
  }
}
