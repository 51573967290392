import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-static-pages',
  templateUrl: './static-pages.component.html',
  styleUrls: ['./static-pages.component.css'],
})
export class StaticPagesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
