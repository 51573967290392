import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RootRoutingModule } from './root-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from '../../environments/environment';
import { AuthInterceptor } from '../common/interceptors/auth';
import { userInitializer } from '../common/initializers/user-initializer';
import { AuthService } from '../common/services/auth.service';
import { RootComponent } from './root.component';
import { LoaderComponent } from '../common/components/loader/loader.component';
import { StorageService } from '../common/services/storage.service';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { DynamicPopupService } from '../common/services/dynamic-popup.service';
import { dynamicPopupInitializer } from '../common/initializers/dynamic-popup';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [RootComponent],
  bootstrap: [RootComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    CommonModule,
    RootRoutingModule,
    LoaderComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: userInitializer,
      multi: true,
      deps: [AuthService, StorageService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: dynamicPopupInitializer,
      multi: true,
      deps: [DynamicPopupService],
    },
    CookieService,
  ],
})
export class RootModule {}
