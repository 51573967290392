import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticDesktopPagesComponent } from './static-desktop-pages.component';
import { RouterModule } from '@angular/router';
import { InfoDesktopComponent } from './pages/info-desktop/info-desktop.component';
import { StaticDesktopPagesRoutingModule } from './static-desktop-pages-routing.module';
import { TicketEventsInfoDesktopComponent } from './pages/ticket-events-info/ticket-events-info-desktop.component';
import { SeasonTicketsInfoDesktopComponent } from './pages/season-tickets-info/season-tickets-info-desktop.component';
import { VipMembershipsInfoDesktopComponent } from './pages/vip-memberships-info/vip-memberships-info-desktop.component';
import { HeaderDesktopComponent } from '../components/layout/header/header-desktop.component';
import { FooterDesktopComponent } from '../components/layout/footer/footer-desktop.component';
import { BreadcrumbDesktopComponent } from '../components/layout/breadcrumb/breadcrumb-desktop.component';
import { VipMembershipFormComponent } from '../../../components/vip-membership-form/vip-membership-form.component';
import { AssetsUrlPipe } from '../../../common/pipes/assets-url.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HeaderDesktopComponent,
    FooterDesktopComponent,
    StaticDesktopPagesRoutingModule,
    BreadcrumbDesktopComponent,
    VipMembershipFormComponent,
    AssetsUrlPipe,
  ],
  declarations: [
    StaticDesktopPagesComponent,
    InfoDesktopComponent,
    TicketEventsInfoDesktopComponent,
    SeasonTicketsInfoDesktopComponent,
    VipMembershipsInfoDesktopComponent,
  ],
})
export class StaticDesktopPagesModule {}
