import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { BankrollBuilderDesktopComponent } from './bankroll-builder-desktop.component';
import { BankrollBuilderGuard } from '../../../../../common/guards/bankroll-builder.guard';
import { builder } from '../../../../../common/constants/breadcrumbs';
import { BuilderDashboardDesktopComponent } from './pages/builder-dashboard-desktop/builder-dashboard-desktop.component';
import { BuilderOverviewDesktopComponent } from './pages/builder-dashboard-desktop/pages/builder-overview-desktop/builder-overview-desktop.component';
import { BuilderBetsDesktopComponent } from './pages/builder-dashboard-desktop/pages/builder-bets-desktop/builder-bets-desktop.component';
import { BuilderIntroDesktopComponent } from './pages/builder-intro-desktop/builder-intro-desktop.component';
import { BuilderAccountDesktopComponent } from './pages/builder-account-desktop/builder-account-desktop.component';
import { BuilderLeaderboardDesktopComponent } from './pages/builder-dashboard-desktop/pages/builder-leaderboard-desktop/builder-leaderboard-desktop.component';
import { ContestRulesDesktopComponent } from './pages/contest-rules-desktop/contest-rules-desktop.component';

const routes: Routes = [
  {
    path: '',
    component: BankrollBuilderDesktopComponent,
    canActivate: [BankrollBuilderGuard],
    data: { breadcrumb: builder },
    children: [
      {
        path: 'dashboard',
        component: BuilderDashboardDesktopComponent,
        data: { breadcrumb: builder },
        children: [
          {
            path: 'overview',
            component: BuilderOverviewDesktopComponent,
            data: { breadcrumb: builder },
          },
          {
            path: 'bets',
            component: BuilderBetsDesktopComponent,
            data: { breadcrumb: builder },
          },
          {
            path: 'leaderboard',
            component: BuilderLeaderboardDesktopComponent,
            data: { breadcrumb: builder },
          },
          {
            path: '',
            redirectTo: 'overview',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'intro',
    component: BuilderIntroDesktopComponent,
    data: { breadcrumb: builder },
  },
  {
    path: 'accounts',
    component: BuilderAccountDesktopComponent,
    data: { breadcrumb: builder },
  },
  {
    path: 'contest-rules',
    component: ContestRulesDesktopComponent,
    data: { breadcrumb: builder },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class BankrollBuilderDesktopRoutingModule {}
