import { Component } from '@angular/core';

@Component({
  selector: 'app-builder-account-desktop',
  templateUrl: './builder-account-desktop.component.html',
  styleUrls: ['./builder-account-desktop.component.css'],
})
export class BuilderAccountDesktopComponent {
  public showBuilderAccountPopup = false;
}
