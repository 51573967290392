import { Pipe, PipeTransform } from '@angular/core';
import { Bets, BetSlips } from '../../types/sharp-sports/bet-slips';

@Pipe({
  name: 'getBetsFromBetSlips',
  standalone: true,
})
export class GetBetsFromBetSlipsPipe implements PipeTransform {
  transform(betSlips: BetSlips[]): Bets[] {
    return betSlips.reduce((allBets: Bets[], bet: BetSlips) => {
      allBets.push(...bet.bets);
      return allBets;
    }, []);
  }
}
