import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutDecorator } from '../../../../../../../common/helpers/layout.decorator';
import { LayoutConfigService } from '../../../../../../../common/services/layout-config.service';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';
import { ActivatedRoute } from '@angular/router';

@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
@Component({
  selector: 'app-checkout-season-ticket-page',
  templateUrl: './checkout-season-ticket-page.component.html',
  styleUrls: ['./checkout-season-ticket-page.component.scss'],
})
export class CheckoutSeasonTicketPageComponent implements OnInit, OnDestroy {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  public isGeneral = this.activatedRoute.snapshot.data.isGeneral;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {}
  ngOnDestroy() {}
}
