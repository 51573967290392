import { Pipe, PipeTransform } from '@angular/core';
import { PredictionAnswers } from '../types/predictions';

@Pipe({
  name: 'answer',
  standalone: true,
})
export class AnswerPipe implements PipeTransform {
  transform(answerId: string, allAnswers: PredictionAnswers[]): string {
    return (
      allAnswers?.find((answer: PredictionAnswers) => answer.id === answerId)
        ?.name || ''
    );
  }
}
