export const alphabeticalSort = <T>(data: T[], key: string): T[] =>
  data.sort((a: T, b: T) => {
    switch (true) {
      case a[key] < b[key]:
        return -1;
      case a[key] > b[key]:
        return 1;
      default:
        return 0;
    }
  });
