import { Pipe, PipeTransform } from '@angular/core';
import { Assets } from '../types/assets';

@Pipe({
  name: 'assetIsImage',
  standalone: true,
})
export class AssetIsImagePipe implements PipeTransform {
  transform(value: Assets): boolean {
    const key = Object.keys(value)[0];
    return value[key].code === 'images';
  }
}
