import { Component, EventEmitter, Output, Input } from '@angular/core';
import { PopupsManagerService } from '../../../services/popups-manager.service';
import { CommonModule } from '@angular/common';
import { UserPrediction } from '../../../types/predictions';

@Component({
  selector: 'app-prediction-result-popup',
  templateUrl: './prediction-result-popup.component.html',
  styleUrls: ['./prediction-result-popup.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class PredictionResultPopupComponent {
  @Input() public isMobile = true;
  @Input() public answer: string;
  @Input() public userPrediction: UserPrediction;
  @Output() public action$: EventEmitter<void> = new EventEmitter();

  constructor(private popupsManagerService: PopupsManagerService) {}

  public closePopup(): void {
    this.action$.emit();
    this.popupsManagerService.hide();
  }
}
