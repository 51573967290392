import { Component, Input } from '@angular/core';
import { TicketStatus } from '../../../../../../../common/enum/ticket-status';
import { BaseComponent } from '../../../../../../../common/components/base/base.component';
import {TicketDate} from '../../../../../../../common/types/user';

@Component({
  template: ``,
})
export class BaseTicketComponent extends BaseComponent {
  @Input() public index: number = 1;
  @Input() public date?: TicketDate;
  @Input() public withoutShadow = false;
  @Input() public sharedTicketsCount: number;

  public flipped: boolean;
  public status = TicketStatus;
  public ticketStatusNaming: Record<TicketStatus, string> = {
    rejected: 'declined',
    pending: 'pending',
    confirmed: 'accepted',
    callback: 'called back',
  };

  constructor() {
    super();
  }

  public toggleTicketFlip($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.flipped = !this.flipped;
  }
}
