import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedTicket } from '../../../../../../../common/types/user';
import { PopupService } from '../../../../../../../common/services/popup.service';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { BaseTicketComponent } from '../base-ticket/base-ticket.component';
import { TicketsService } from '../../../../../../../common/services/tickets.service';
import { AsyncPipe, CommonModule, TitleCasePipe } from '@angular/common';
import { EventNameAsyncPipe } from '../../../../../../../common/pipes/event-name-async.pipe';
import { TicketPreviewDetailsComponent } from '../ticket-preview-details/ticket-preview-details.component';
import { TicketPresentQrComponent } from '../ticket-present-qr/ticket-present-qr.component';

@Component({
  selector: 'app-ticket-shared',
  templateUrl: './ticket-shared.component.html',
  standalone: true,
  styleUrls: ['./ticket-shared.component.scss'],
  imports: [
    CommonModule,
    TitleCasePipe,
    EventNameAsyncPipe,
    AsyncPipe,
    TicketPreviewDetailsComponent,
    TicketPresentQrComponent,
  ],
})
export class TicketSharedComponent extends BaseTicketComponent {
  @Input() public ticket: SharedTicket;
  @Input() public isMobile: boolean = true;
  @Output() public updateTickets$: EventEmitter<void> =
    new EventEmitter<void>();

  constructor(
    private popupService: PopupService,
    private ticketsService: TicketsService
  ) {
    super();
  }

  public handleShowCallBackPopup($event: MouseEvent): void {
    if (this.ticket.canceled) return;
    $event.stopPropagation();
    $event.preventDefault();
    const { guestFirstName, guestLastName } = this.ticket;
    this.popupService
      .showTicketCallbackPopup(guestFirstName, guestLastName, this.isMobile)
      .action$.pipe(
        filter((res) => !!res),
        switchMap(() =>
          this.ticketsService.callBackSharedTicket(this.ticket.id)
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.updateTickets$.emit());
  }
}
