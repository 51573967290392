import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PrivateEventsComponent } from './private-events.component';
import { TripleseatComponent } from '../../../components/tripleseat/tripleseat.component';
import { privateEventsBreadcrumb } from '../../../common/constants/breadcrumbs';
import { BreadcrumbComponent } from '../components/layout/breadcrumb/breadcrumb.component';
import { HeaderComponent } from '../components/layout/header/header.component';
import { FooterComponent } from '../components/layout/footer/footer.component';
import { AssetsUrlPipe } from '../../../common/pipes/assets-url.pipe';

const routes: Routes = [
  {
    path: '',
    component: PrivateEventsComponent,
    data: { breadcrumb: privateEventsBreadcrumb },
  },
];

@NgModule({
  declarations: [PrivateEventsComponent],
  imports: [
    // Modules
    CommonModule,
    RouterModule.forChild(routes),
    // Components
    TripleseatComponent,
    BreadcrumbComponent,
    HeaderComponent,
    FooterComponent,
    // Pipes
    AssetsUrlPipe,
  ],
})
export class PrivateEventsModule {}
