import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventNameAsyncPipe } from '../../../../../common/pipes/event-name-async.pipe';
import { StringToDatePipe } from '../../../../../common/pipes/string-to-date.pipe';
import { NextTicketBaseComponent } from '../../../../../common/base-controllers/next-ticket.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-next-ticket',
  standalone: true,
  imports: [CommonModule, EventNameAsyncPipe, StringToDatePipe, RouterLink],
  templateUrl: './next-ticket.component.html',
  styleUrls: ['./next-ticket.component.css'],
})
export class NextTicketComponent extends NextTicketBaseComponent {}
