import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription, zip } from 'rxjs';
import { ProsNews, ProsNewsType } from '../../../types/pro-news';
import { Pageable } from '../../../types/pageable';
import { LoaderService } from '../../../services/loader.service';
import { CommunityService } from '../../../services/community.service';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { NewsItemComponent } from '../news-item/news-item.component';
import { BaseComponent } from '../../base/base.component';
import { NewsTypePipe } from '../../../pipes/news-type.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AssetsUrlPipe } from '../../../pipes/assets-url.pipe';
import { SanitizeHtmlPipe } from '../../../pipes/sanitize-html.pipe';
import { EventDetailsDesktopComponent } from '../../../../desktop/modules/main-desktop/components/event-details-desktop/event-details-desktop.component';

@Component({
  selector: 'app-hear-from-pros',
  templateUrl: './hear-from-pros.component.html',
  styleUrls: ['./hear-from-pros.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    NewsItemComponent,
    NewsTypePipe,
    InfiniteScrollModule,
    AssetsUrlPipe,
    SanitizeHtmlPipe,
    EventDetailsDesktopComponent,
  ],
})
export class HearFromProsComponent extends BaseComponent implements OnInit {
  @Input() public isMobile = true;
  @Input() public selectedNews: ProsNews;
  public subscription: Subscription;
  public prosNews: Pageable<ProsNews>;
  public isLoading = false;
  private currentPage = 0;
  public types: ProsNewsType[];

  constructor(
    private loader: LoaderService,
    private communityService: CommunityService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    this.loader.show();
    zip(
      this.communityService.getAllProsNews(),
      this.communityService.getProsNewsTypes()
    )
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loader.hide())
      )
      .subscribe(([news, types]: [Pageable<ProsNews>, ProsNewsType[]]) => {
        this.prosNews = news;
        this.types = types;
      });
  }

  public onScrollDown(): void {
    this.isLoading = true;
    if (this.currentPage + 1 >= (this.prosNews?.pages || 1) && this.isLoading) {
      return;
    }
    this.currentPage++;
    this.communityService
      .getAllProsNews(this.currentPage)
      .pipe(
        filter((res: Pageable<ProsNews>) => !!res),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((prosNews: Pageable<ProsNews>) => {
        this.prosNews = {
          ...prosNews,
          items: [...this.prosNews.items, ...prosNews.items],
        };
        this.isLoading = false;
      });
  }

  public closeSelectedNews(): void {
    this.communityService.selectNewsHandler(null);
  }
}
