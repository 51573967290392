import { Pipe, PipeTransform } from '@angular/core';
import { Table } from '../types/area';

@Pipe({
  name: 'seatsCount',
  standalone: true,
})
export class SeatsCountPipe implements PipeTransform {
  transform(tables: Table[]): number {
    return tables
      ?.filter((t) => t.available)
      ?.reduce((count: number, item: Table) => count + item.seatsCount, 0);
  }
}
