import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main.component';
import { AccountComponent } from './pages/account/account.component';
import { AboutComponent } from './pages/about/about.component';
import { CommunityComponent } from './pages/community/community.component';
import { ForumsComponent } from './pages/forums/forums.component';
import { ConciergeComponent } from './pages/concierge/concierge.component';
import { VenueComponent } from './pages/venue/venue.component';
import { TeamComponent } from './pages/team/team.component';
import { CareersComponent } from './pages/careers/careers.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: 'community',
        component: CommunityComponent,
        loadChildren: () =>
          import('./pages/community/community.module').then(
            (m) => m.CommunityModule
          ),
      },
      {
        path: 'builder',
        loadChildren: () =>
          import('./pages/bankroll-builder/bankroll-builder.module').then(
            (m) => m.BankrollBuilderModule
          ),
      },
      {
        path: 'account',
        component: AccountComponent,
      },
      {
        path: 'forums',
        component: ForumsComponent,
      },
      {
        path: 'concierge',
        component: ConciergeComponent,
      },
      {
        path: 'venue',
        component: VenueComponent,
      },
      {
        path: 'team',
        component: TeamComponent,
      },
      {
        path: 'careers',
        component: CareersComponent,
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
      },
      {
        path: 'season-tickets',
        loadChildren: () =>
          import('./pages/season-ticket/season-ticket.module').then(
            (m) => m.SeasonTicketModule
          ),
      },
      {
        path: 'predict-and-win/:venueId',
        loadChildren: () =>
          import(
            './pages/predict-and-win-page/predict-and-win-page.module'
          ).then((m) => m.PredictAndWinPageModule),
      },
      {
        path: 'select-venue',
        loadChildren: () =>
          import('./pages/select-venue/select-venue.module').then(
            (m) => m.SelectVenueModule
          ),
      },
      {
        path: '',
        redirectTo: 'account',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class MainRoutingModule {}
