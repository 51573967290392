import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopupsManagerService } from '../../../services/popups-manager.service';
import { CommonModule, DatePipe } from '@angular/common';
import { SharpSportsService } from '../../../services/sharp-sports.service';
import { BetSlipsFilter } from '../../../types/sharp-sports/bet-slips';
import { getDateNDaysAgo } from '../../../helpers/date-time';
import { ColorGeneratorPipe } from '../../../pipes/builder/color-generator.pipe';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { BetSlipsFilterCountPipe } from '../../../pipes/builder/bet-slips-filter-count.pipe';
import { BetSlipsType } from '../../../enum/bet-slip-type';
import { ImageUrlFormatterPipe } from '../../../pipes/builder/image-url-formatter.pipe';

@Component({
  selector: 'app-builder-filter-popup',
  templateUrl: './builder-filter-popup.component.html',
  styleUrls: ['./builder-filter-popup.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ColorGeneratorPipe,
    ReactiveFormsModule,
    BetSlipsFilterCountPipe,
    ColorGeneratorPipe,
    ImageUrlFormatterPipe,
  ],
  providers: [DatePipe],
})
export class BuilderFilterPopupComponent {
  @Input() isMobile = true;
  @Input() public leagueFilters: string[] = [];
  @Output() submit$: EventEmitter<void> = new EventEmitter<void>();
  public colors: string[] = [];
  public marketTypesFilter: string[] = [
    'spread',
    'moneyline',
    'total',
    '3-way',
  ];
  public typeFilter: string[] = ['single', 'parlay'];
  public typeOutcome: string[] = ['win', 'loss', 'push'];
  betSlipsType = BetSlipsType;
  betSlipsTypes = Object.values(BetSlipsType);
  public now: Date = new Date();
  public startDateControl = this.fb.control(
    this.dateToStringFormatter(
      this.sharpSportsService.selectedFilters.startDate
    )
  );
  public endDateControl = this.fb.control(
    this.dateToStringFormatter(this.sharpSportsService.selectedFilters.endDate)
  );
  public notFoundImages: string[] = [];

  constructor(
    private popupsManagerService: PopupsManagerService,
    public sharpSportsService: SharpSportsService,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {}

  public closePopup() {
    this.popupsManagerService.hide();
  }

  public selectFilter(
    key: keyof Omit<BetSlipsFilter, 'startDate' | 'endDate' | 'status'>,
    value: string
  ): void {
    const filter: string[] = this.sharpSportsService.selectedFilters[key];
    if (filter.includes(value)) {
      filter.splice(filter.indexOf(value), 1);
    } else {
      filter.push(value);
    }
    this.sharpSportsService.selectedFilters = structuredClone(
      this.sharpSportsService.selectedFilters
    );
    if (!this.isMobile) {
      this.submitFilter();
    }
  }

  public submitFilter(): void {
    this.sharpSportsService.selectedFilters.startDate = Date.parse(
      this.startDateControl.value
    );
    this.sharpSportsService.selectedFilters.endDate = Date.parse(
      this.endDateControl.value
    );
    this.submit$.emit();
    if (this.isMobile) {
      this.closePopup();
    }
  }

  public clearFilters() {
    this.sharpSportsService.selectedFilters = {
      league: [],
      marketProposition: [],
      outcome: [],
      type: [],
      startDate: getDateNDaysAgo(7),
      endDate: getDateNDaysAgo(1),
      status: BetSlipsType.open,
    };
    this.startDateControl.setValue(
      this.dateToStringFormatter(
        this.sharpSportsService.selectedFilters.startDate
      )
    );
    this.endDateControl.setValue(
      this.dateToStringFormatter(
        this.sharpSportsService.selectedFilters.endDate
      )
    );
    if (!this.isMobile) {
      this.submit$.emit();
    }
  }

  private dateToStringFormatter(date: number): string {
    return this.datePipe.transform(date, 'y-MM-dd');
  }

  public statusChange(bt: BetSlipsType) {
    this.sharpSportsService.selectedFilters.status = bt;
    this.sharpSportsService.selectedFilters = structuredClone(
      this.sharpSportsService.selectedFilters
    );
    this.submit$.emit();
  }

  public imageNotFoundHandler(name: string) {
    this.notFoundImages.push(name);
  }

  public onDateChange() {
    if (this.isMobile) {
      return;
    }
    this.submitFilter();
  }
}
