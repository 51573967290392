import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CommunityRoutingModule } from './community-routing.module';
import { HearFromTheProsPageComponent } from './pages/hear-from-the-pros/hear-from-the-pros-page.component';
import { NgModule } from '@angular/core';
import { CommunityItemsComponent } from './pages/community-items/community-items.component';
import { ItemsFilterComponent } from '../season-ticket/components/items-filter/items-filter.component';
import { HearFromProsComponent } from '../../../../../common/components/news/hear-from-pros/hear-from-pros.component';
import { BreadcrumbComponent } from '../../../components/layout/breadcrumb/breadcrumb.component';

@NgModule({
  imports: [
    //modules
    CommonModule,
    RouterModule,
    CommunityRoutingModule,
    ItemsFilterComponent,
    //components
    BreadcrumbComponent,
    HearFromProsComponent,
  ],
  declarations: [
    //components
    HearFromTheProsPageComponent,
    CommunityItemsComponent,
  ],
})
export class CommunityModule {}
