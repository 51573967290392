import { Pipe, PipeTransform } from '@angular/core';
import { ProsNews, ProsNewsType } from '../types/pro-news';

@Pipe({
  name: 'newsType',
  standalone: true,
})
export class NewsTypePipe implements PipeTransform {
  transform(types: ProsNewsType[], news: ProsNews): ProsNewsType {
    return types?.find((t: ProsNewsType) => t.id === news.typeId) || null;
  }
}
