import { Pipe, PipeTransform } from '@angular/core';
import { TextRect } from '../../../types/area';

@Pipe({
  name: 'tableNumberStyle',
  standalone: true,
})
export class TableNumberStylePipe implements PipeTransform {
  transform(rect: TextRect, parentRotation: number = 0): string {
    return `
      transform: translate(calc(${rect.left}px - 50%), calc(${
      rect.top
    }px - 50%)) rotate(${rect.rotation - parentRotation}deg)
      `;
  }
}
