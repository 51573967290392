import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UnitUsd } from '../../../enum/unit-usd';
import { BetSlipsType } from '../../../enum/bet-slip-type';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { IsYesterdayPipe } from '../../../pipes/builder/is-yesterday.pipe';
import { AddProfitStyleClassPipe } from '../../../pipes/builder/add-profit-style-class.pipe';
import { Observable } from 'rxjs';
import { UserBookAccounts } from '../../../types/sharp-sports/sport-books';
import {
  DailyBetSips,
  OpenSettledCurrentBetSlips,
} from '../../../types/sharp-sports/bet-slips';
import { PopupService } from '../../../services/popup.service';
import { AccountNotVerifiedPipe } from '../../../pipes/builder/account-not-verified.pipe';
import { ColorGeneratorPipe } from '../../../pipes/builder/color-generator.pipe';
import { ImageUrlFormatterPipe } from '../../../pipes/builder/image-url-formatter.pipe';
import { environment } from '../../../../../environments/environment';
import { openSharpSports } from '../../../helpers/postMessage';
import { TOKEN } from '../../../constants/storage-keys';
import { UserService } from '../../../services/user.service';
import { StorageService } from '../../../services/storage.service';
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'app-builder-overview-shared',
  templateUrl: './builder-overview-shared.component.html',
  styleUrls: ['./builder-overview-shared.component.css'],
  standalone: true,
  imports: [
    // Modules
    CommonModule,
    RouterModule,
    // Pipes
    IsYesterdayPipe,
    AddProfitStyleClassPipe,
    AccountNotVerifiedPipe,
    ColorGeneratorPipe,
    ImageUrlFormatterPipe,
  ],
})
export class BuilderOverviewSharedComponent {
  @Input() isMobile = true;
  @Input() userBookAccounts$: Observable<UserBookAccounts[]>;
  @Input() userCurrentBetSlips$: Observable<OpenSettledCurrentBetSlips>;
  @Input() betSlipsHistory$: Observable<DailyBetSips[]>;
  @Input() unitSize: number;
  @Output() navigateToTodayBets$: EventEmitter<BetSlipsType> =
    new EventEmitter<BetSlipsType>();
  @Output() navigateToHistory$: EventEmitter<void> = new EventEmitter<void>();
  @Output() todayCurrency$: EventEmitter<UnitUsd> = new EventEmitter<UnitUsd>();
  public currentTodayCurrency: UnitUsd = UnitUsd.usd;
  public currentHistoryCurrency: UnitUsd = UnitUsd.usd;
  public unitUsd = UnitUsd;
  public betSlipsType = BetSlipsType;
  public notFoundImages: string[] = [];

  constructor(
    private router: Router,
    private popupService: PopupService,
    private userService: UserService,
    private storageService: StorageService,
    private loaderService: LoaderService
  ) {}

  public navigateToTodayBets(type: BetSlipsType): void {
    this.isMobile
      ? this.router.navigate(['/my/builder/dashboard/overview/today-bets'], {
          queryParams: { type },
        })
      : this.navigateToTodayBets$.emit(type);
  }

  public navigateToHistory() {
    this.isMobile
      ? this.router.navigateByUrl('/my/builder/dashboard/overview/history')
      : this.navigateToHistory$.emit();
  }

  public addAccount(url = '/my/builder/sharp-sports/book-select') {
    if (environment.production) {
      this.isMobile
        ? this.router.navigateByUrl(url)
        : this.popupService.showBuilderAccountPopup();
    } else {
      this.loaderService.show();
      openSharpSports({
        uid: this.userService.user?.id,
        token: this.storageService.getFromStorage(TOKEN),
      });
    }
  }

  public todayCurrencyChange(currency: UnitUsd): void {
    this.currentTodayCurrency = currency;
    this.todayCurrency$.emit(currency);
  }

  public imageNotFoundHandler(name: string) {
    this.notFoundImages.push(name);
  }
}
