import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '../base/base.component';
import { firstValueFrom, timer, zip } from 'rxjs';
import { Prediction, UserPrediction } from '../../types/predictions';
import { LeaderboardScores } from '../../types/leaderboards';
import { Pageable } from '../../types/pageable';
import { User } from '../../types/user';
import { LoaderService } from '../../services/loader.service';
import { PopupService } from '../../services/popup.service';
import { finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { ResponseType } from '../../enum/popup-types';
import { RouterModule } from '@angular/router';
import { DateTimerPipe } from '../../pipes/date-timer.pipe';
import { AssetsUrlPipe } from '../../pipes/assets-url.pipe';
import { AnswerPipe } from '../../pipes/answer-pipe.pipe';
import { UserService } from '../../services/user.service';
import { VenuePredictAndWinService } from '../../services/venue-predict-and-win.service';
import { PredictionTournament } from '../../types/venue-prediction';

@Component({
  selector: 'app-predict-and-win',
  templateUrl: './predict-and-win.component.html',
  styleUrls: ['./predict-and-win.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    DateTimerPipe,
    AssetsUrlPipe,
    AnswerPipe,
  ],
})
export class PredictAndWinComponent extends BaseComponent implements OnInit {
  @Input() public isMobile = true;
  @Input() public currentLeaderboard: PredictionTournament;
  @Input() public venueId: string;
  @Input() public disableButtons = false;
  public currentSliderIndex = 0;
  public translate = 'translate3d(0, 0, 0)';
  public predictions: Partial<Prediction>[] = [];
  public user: User = this.userService.user;
  public myScore: number;
  public incrementValue = 0;
  public showIncrementAnimation = false;
  private unseenPredictions: UserPrediction[];
  private swipeCoord?: [number, number];
  private swipeTime?: number;

  constructor(
    private predictAndWinService: VenuePredictAndWinService,
    private loaderService: LoaderService,
    private userService: UserService,
    private popupService: PopupService
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchCurrentLeaderboardAndUserPredictions();
    this.fetchAllPredictions();
  }

  private fetchCurrentLeaderboardAndUserPredictions(): void {
    zip(
      this.predictAndWinService.getLeaderboardScores(
        this.currentLeaderboard.id
      ),
      this.predictAndWinService.getUserPredictionsFilteredByStatus(
        this.currentLeaderboard.id,
        0,
        'resolved',
        20
      )
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([leaderboardScores, predictionsRes]: [
          Pageable<LeaderboardScores>,
          Pageable<UserPrediction>
        ]) => {
          this.predictAndWinService.currentLeaderBoardId =
            this.currentLeaderboard?.id;
          this.unseenPredictions = predictionsRes?.items.filter(
            (res: UserPrediction) => !res.isSeen
          );
          this.myScore =
            leaderboardScores?.items.find(
              (board: LeaderboardScores) => board.user.id === this.user.id
            )?.score || 0;
          this.userResolvedPredictionsHandler();
        }
      );
  }

  public slide(step: number): void {
    this.currentSliderIndex = Math.max(
      Math.min(this.currentSliderIndex + step, this.predictions.length - 1),
      0
    );
    this.translate = `translate3d(${this.currentSliderIndex * -100}%, 0, 0)`;
  }

  public handleTimerEnd(): void {}

  public handleAnswerClick(
    prediction: Partial<Prediction>,
    answer: string,
    answered: boolean
  ): void {
    if (answered) {
      return;
    }
    this.loaderService.show();
    this.predictAndWinService
      .submitAnswer({ predictionId: prediction.id, userAnswer: answer })
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe({
        next: () => {
          this.fetchAllPredictions(true);
          this.popupService.showPredictionAnswerPopup(
            ResponseType.Success,
            answer,
            prediction.reward,
            this.isMobile
          );
        },
        error: () => console.error(ResponseType.Error),
      });
  }

  private fetchAllPredictions(update: boolean = false): void {
    this.loaderService.show();
    this.predictAndWinService
      .getPredictions(this.currentLeaderboard.id)
      .pipe(
        map((res: Pageable<Prediction>) => res.items),
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe((res) => {
        if (update) {
          this.predictions = [];
        }
        const ad = this.currentLeaderboard.ads.map((a) => ({
          question: a.description,
          assets: a.assets,
          title: 'Ad',
          answers: [],
        }));
        this.predictions.push(...res, ...ad);
      });
  }

  private async userResolvedPredictionsHandler(): Promise<void> {
    if (!this.unseenPredictions?.length) {
      return;
    }
    this.calculateScore();
    await this.unseenPredictionsHandler(this.unseenPredictions);
    this.incrementUserScore();
  }

  private incrementUserScore(): void {
    if (this.incrementValue === 0) {
      return;
    }
    this.showIncrementAnimation = true;
    timer(600)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => (this.myScore += this.incrementValue));
  }

  private async showPredictionResultPopup(
    prediction: UserPrediction
  ): Promise<{ message: string }> {
    return firstValueFrom(
      this.popupService
        .showPredictionResultPopup(
          prediction,
          prediction.userAnswer,
          this.isMobile
        )
        .action$.pipe(
          switchMap(() =>
            this.predictAndWinService.markPredictionAsSeen(prediction.id)
          ),
          takeUntil(this.unsubscribe$)
        )
    );
  }

  private async unseenPredictionsHandler(
    unseenResItems: UserPrediction[]
  ): Promise<void> {
    for (const unseenRes of unseenResItems) {
      await this.showPredictionResultPopup(unseenRes);
    }
  }

  private calculateScore(): void {
    this.incrementValue = this.unseenPredictions.reduce(
      (rewardCount: number, item: UserPrediction) => {
        rewardCount += item.userReward;
        return rewardCount;
      },
      0
    );
    this.myScore -= this.incrementValue;
  }

  swipe(e: TouchEvent, when: string): void {
    if (!this.isMobile) {
      return;
    }
    const coord: [number, number] = [
      e.changedTouches[0].pageX,
      e.changedTouches[0].pageY,
    ];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [
        coord[0] - this.swipeCoord[0],
        coord[1] - this.swipeCoord[1],
      ];
      const duration = time - this.swipeTime;
      if (
        duration < 1000 &&
        Math.abs(direction[0]) > 30 &&
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        if (direction[0] < 0) {
          //next
          this.slide(1);
        } else {
          //previous
          this.slide(-1);
        }
      }
    }
  }
}
