import { Component } from '@angular/core';
import { SeasonTicketScheduleBaseComponent } from '../../../common/base-controllers/season-ticket-schedule-base.component';
import { Event } from '../../../common/types/event';
import { SeasonTicketsService } from '../../../common/services/season-tickets.service';
import { LoaderService } from '../../../common/services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../common/services/user.service';

@Component({
  selector: 'app-season-ticket-schedule-desktop-page',
  templateUrl: './season-ticket-schedule-desktop-page.component.html',
  styleUrls: ['./season-ticket-schedule-desktop-page.component.css'],
})
export class SeasonTicketScheduleDesktopPageComponent extends SeasonTicketScheduleBaseComponent {
  public showFilters = false;

  constructor(
    seasonTicketsService: SeasonTicketsService,
    loaderService: LoaderService,
    activatedRoute: ActivatedRoute,
    userService: UserService
  ) {
    super(seasonTicketsService, loaderService, activatedRoute, userService);
  }

  public handleShowEventDetails(event: Event): void {
    this.eventDetails = event;
  }

  public closeEventDetails(): void {
    this.eventDetails = null;
  }

  public filterClickHandler(): void {
    this.showFilters = !this.showFilters;
  }

  public calendarViewCheckboxHandler(): void {
    super.calendarViewCheckboxHandler();
    if (this.showCalendarView) {
      this.eventDetails = null;
    }
  }
}
