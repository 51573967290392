import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  standalone: true,
  imports: [CommonModule],
  styleUrls: ['./ticket-details.component.scss'],
})
export class TicketDetailsComponent {
  @Input() public areaName: string;
  @Input() public tableCode: string;
  @Input() public seatsCount: number;
  @Input() public price: number;

  constructor() {
  }

}

