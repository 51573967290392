import { Component } from '@angular/core';
import { LayoutConfigService } from '../../../../common/services/layout-config.service';

@Component({
  selector: 'app-menu-mobile-layout',
  templateUrl: './menu-mobile-layout.component.html',
  styleUrls: ['./menu-mobile-layout.component.css'],
})
export class MenuMobileLayoutComponent {
  public layoutConfig$ = this.layoutConfigService.layoutConfig;
  constructor(private layoutConfigService: LayoutConfigService) {}
}
