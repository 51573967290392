import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VenueLeaderboardService } from '../../../services/venue-leaderboard.service';
import { LoaderService } from '../../../services/loader.service';
import { Observable } from 'rxjs';
import { Venue } from '../../../types/sharp-sports/venues';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';
import { AssetsUrlPipe } from '../../../pipes/assets-url.pipe';
import { ShowConnectPipe } from '../../../pipes/builder/show-connect.pipe';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-select-venue-shared',
  templateUrl: './select-venue-shared.component.html',
  styleUrls: ['./select-venue-shared.component.css'],
  standalone: true,
  imports: [CommonModule, AssetsUrlPipe, ShowConnectPipe, RouterLink],
})
export class SelectVenueSharedComponent
  extends BaseComponent
  implements OnInit
{
  @Input() isMobile = true;
  @Input() showContestRules = true;
  @Output() venueSelect$: EventEmitter<Venue> = new EventEmitter<Venue>();
  public venues: Observable<Venue[]> = this.venueLeaderboardService
    .getVenues()
    .pipe(
      takeUntil(this.unsubscribe$),
      finalize(() => this.loaderService.hide())
    );

  constructor(
    private venueLeaderboardService: VenueLeaderboardService,
    private loaderService: LoaderService
  ) {
    super();
  }

  ngOnInit() {
    this.loaderService.show();
  }

  public selectVenueHandler(venue: Venue): void {
    this.venueSelect$.emit(venue);
  }
}
