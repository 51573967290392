import { ElementRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ReaderMode } from '../enum/reader.enum';
import { CanvasEventSubParam, CanvasTransformConfig } from '../types/reader';

@Injectable({
  providedIn: 'root',
})
export class ReaderService {
  public canvasTransformConfig: CanvasTransformConfig = {
    scale: 1,
    translate: {
      x: 0,
      y: 0,
    },
  };
  public lastPage = false;
  public wrapper?: ElementRef<HTMLCanvasElement> | undefined;
  public currentMode: ReaderMode = ReaderMode.OnePage;
  public currentPage!: number;
  public images!: HTMLImageElement[];
  public currentImage!: HTMLImageElement;
  public mouseActive = false;
  public canvasElement?: ElementRef<HTMLCanvasElement>;
  private canvasComponentEventSub = new Subject<CanvasEventSubParam>();
  public canvasComponentEvent$ = this.canvasComponentEventSub.asObservable();
  public loading = true;

  public canvasSubjectHandler(subParam: CanvasEventSubParam): void {
    this.canvasComponentEventSub.next(subParam);
  }
}
