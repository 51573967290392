export enum PredictTabs {
  predictAndWin = 'PredictAndWin',
  leaderBoard = 'Leaderboard',
  history = 'History',
}

export enum PredictTabsDesktop {
  leaderBoard = 'Leaderboard',
  history = 'History',
}
