import { NgModule } from '@angular/core';
import { BuySeasonTicketDesktopComponent } from './pages/buy-season-ticket-desktop/buy-season-ticket-desktop.component';
import { SeasonTicketDesktopRoutingModule } from './season-ticket-desktop-routing.module';
import { SeasonTicketDesktopComponent } from './season-ticket-desktop.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SeasonTicketItemComponent } from '../../../../../common/components/season-ticket/season-ticket-item.component';
import { SeasonTicketAreaSelectDesktopPageComponent } from './pages/season-ticket-area-select-desktop-page/season-ticket-area-select-desktop-page.component';
import { SeasonTicketDatesDesktopPageComponent } from './pages/season-ticket-dates-desktop-page/season-ticket-dates-desktop-page.component';
import { SeasonTicketCalendarComponent } from '../../../../../common/components/season-ticket-calendar/season-ticket-calendar.component';
import { CheckoutSeasonTicketDesktopPageComponent } from './pages/checkout-season-ticket-desktop-page/checkout-season-ticket-desktop-page.component';
import { CheckoutSeasonTicketComponent } from '../../../../../common/components/checkout-season-ticket/checkout-season-ticket.component';
import { EventsFilterPopupComponent } from '../../../../../common/components/popups/events-filter-popup/events-filter-popup.component';
import { FilterCountPipe } from '../../../../../common/pipes/filter-count.pipe';
import { EventItemComponent } from '../../../../../mobile/modules/main/pages/season-ticket/components/event-item/event-item.component';
import { EventDetailsDesktopComponent } from '../../components/event-details-desktop/event-details-desktop.component';
import { SeasonTicketReservationDesktopComponent } from './pages/season-ticket-reservation-desktop/season-ticket-reservation-desktop.component';
import { ItemsFilterComponent } from '../../../../../mobile/modules/main/pages/season-ticket/components/items-filter/items-filter.component';
import { TicketSharedComponent } from '../../../../../mobile/modules/main/pages/season-ticket/components/ticket-shared/ticket-shared.component';
import { TicketReceivedComponent } from '../../../../../mobile/modules/main/pages/season-ticket/components/ticket-received/ticket-received.component';
import { SeasonTicketPreviewDesktopComponent } from './pages/season-ticket-preview-desktop/season-ticket-preview-desktop.component';
import { TicketFilterPipe } from '../../../../../common/pipes/ticket-filter.pipe';
import { SharedTicketsCountPipe } from '../../../../../common/pipes/shared-tickets-count.pipe';
import { TicketPurchasedComponent } from '../../../../../mobile/modules/main/pages/season-ticket/components/ticket-purchased/ticket-purchased.component';
import { ShareFormAndTicketComponent } from '../../../../../common/components/share-form/share-form-and-ticket.component';
import { CalendarViewPageComponent } from '../../../../../common/components/calendar-view-page/calendar-view-page.component';
import { KeysOtpPipe } from '../../../../../common/pipes/key-otp.pipe';
import { ConcatPipe } from '../../../../../common/pipes/concat.pipe';
import { StripeComponent } from '../../../../../common/components/stripe-popup/stripe.component';
import { SeasonTicketConfigActivatorGuard } from '../../../../../common/guards/season-ticket-config-activator.guard';
import { SeasonTicketAreaSelectDesktopComponent } from '../../../components/season-ticket-area-select-desktop/season-ticket-area-select-desktop.component';
import { BreadcrumbComponent } from '../../../../../mobile/modules/components/layout/breadcrumb/breadcrumb.component';
import { BreadcrumbDesktopComponent } from '../../../components/layout/breadcrumb/breadcrumb-desktop.component';

@NgModule({
  imports: [
    // Modules
    CommonModule,
    SeasonTicketDesktopRoutingModule,
    RouterModule,
    // Components
    SeasonTicketItemComponent,
    BreadcrumbDesktopComponent,
    TicketPurchasedComponent,
    EventsFilterPopupComponent,
    CalendarViewPageComponent,
    //Pipes
    FilterCountPipe,
    EventItemComponent,
    EventDetailsDesktopComponent,
    SeasonTicketCalendarComponent,
    CheckoutSeasonTicketComponent,
    ItemsFilterComponent,
    BreadcrumbComponent,
    TicketSharedComponent,
    TicketReceivedComponent,
    TicketFilterPipe,
    SharedTicketsCountPipe,
    ShareFormAndTicketComponent,
    KeysOtpPipe,
    ConcatPipe,
    StripeComponent,
    SeasonTicketAreaSelectDesktopComponent,
  ],
  declarations: [
    SeasonTicketDesktopComponent,
    BuySeasonTicketDesktopComponent,
    SeasonTicketPreviewDesktopComponent,
    SeasonTicketDatesDesktopPageComponent,
    SeasonTicketReservationDesktopComponent,
    CheckoutSeasonTicketDesktopPageComponent,
    SeasonTicketAreaSelectDesktopPageComponent,
  ],
  providers: [SeasonTicketConfigActivatorGuard],
})
export class SeasonTicketDesktopModule {}
