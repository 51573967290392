import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutDecorator } from '../../../../../../../common/helpers/layout.decorator';
import { LayoutConfigService } from '../../../../../../../common/services/layout-config.service';

@Component({
  selector: 'app-sharp-sports',
  templateUrl: './sharp-sports.component.html',
  styleUrls: ['./sharp-sports.component.scss'],
})
@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
export class SharpSportsComponent implements OnInit, OnDestroy {
  constructor(private layoutConfigService: LayoutConfigService) {}

  ngOnInit() {}
  ngOnDestroy() {}
}
