import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { BasePopupComponent } from '../../base/base-popup/base-popup.component';
import { PopupsManagerService } from '../../../services/popups-manager.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-image-crop-popup',
  templateUrl: './image-crop-popup.component.html',
  styleUrls: ['./image-crop-popup.component.scss'],
  standalone: true,
  imports: [CommonModule, BasePopupComponent, ImageCropperModule],
})
export class ImageCropPopupComponent {
  @Input() public imageChangedEvent: string;
  @Input() public isMobile: boolean = true;
  @Output() public crop$: EventEmitter<string> = new EventEmitter<string>();

  public croppedImage = '';

  constructor(private popupsManagerService: PopupsManagerService) {}

  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  public closePopup(): void {
    this.popupsManagerService.hide();
  }

  public cropImage(): void {
    this.crop$.emit(this.croppedImage);
    this.closePopup();
  }

  public changeImage(): void {
    this.crop$.emit(null);
    this.closePopup();
  }
}
