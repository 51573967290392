import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutDecorator } from '../../../../../common/helpers/layout.decorator';
import { LayoutConfigService } from '../../../../../common/services/layout-config.service';
import { BreadcrumbBackground } from '../../../../../common/enum/breadcrumb-background';
import { DynamicPopupService } from '../../../../../common/services/dynamic-popup.service';
import { PageCode } from '../../../../../common/enum/page-code';

@Component({
  selector: 'app-bankroll-builder',
  templateUrl: './bankroll-builder.component.html',
  styleUrls: ['./bankroll-builder.component.scss'],
})
@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
export class BankrollBuilderComponent implements OnInit, OnDestroy {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  constructor(
    private layoutConfigService: LayoutConfigService,
    private dynamicPopupService: DynamicPopupService
  ) {}

  ngOnInit() {
    this.dynamicPopupService.showPagePopups(PageCode.builder);
  }

  ngOnDestroy() {}
}
