import { Component, OnInit } from '@angular/core';
import { BreadcrumbBackground } from '../../../common/enum/breadcrumb-background';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  constructor() {}

  ngOnInit(): void {}
}
