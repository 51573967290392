import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankrollStoryComponent } from './bankroll-story.component';
import { RouterModule, Routes } from '@angular/router';
import { storyBreadcrumb } from '../../../common/constants/breadcrumbs';
import { HeaderComponent } from '../components/layout/header/header.component';
import { FooterComponent } from '../components/layout/footer/footer.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { BreadcrumbComponent } from '../components/layout/breadcrumb/breadcrumb.component';
import { AssetsUrlPipe } from '../../../common/pipes/assets-url.pipe';
import { CamelCaseToTextPipe } from '../../../common/pipes/camel-case-to-text.pipe';

const routes: Routes = [
  {
    path: '',
    component: BankrollStoryComponent,
    data: { breadcrumb: storyBreadcrumb },
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    HeaderComponent,
    FooterComponent,
    GoogleMapsModule,
    BreadcrumbComponent,
    AssetsUrlPipe,
    CamelCaseToTextPipe,
  ],
  declarations: [BankrollStoryComponent],
})
export class BankrollStoryModule {}
