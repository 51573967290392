import { Component, Input } from '@angular/core';
import { AssetsUrlPipe } from '../../pipes/assets-url.pipe';
import { CommonModule } from '@angular/common';
import { PredictionTournament } from '../../types/venue-prediction';

@Component({
  selector: 'app-predict-prize',
  templateUrl: './predict-prize.component.html',
  styleUrls: ['./predict-prize.component.css'],
  standalone: true,
  imports: [CommonModule, AssetsUrlPipe],
})
export class PredictPrizeComponent {
  @Input() predictionTournament: PredictionTournament;
  @Input() isMobile = true;
}
