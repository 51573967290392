import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StaticDesktopPagesComponent } from './static-desktop-pages.component';
import {
  infoSeasonTickets,
  infoTicketEvents,
  infoVipMembership,
} from '../../../common/constants/breadcrumbs';
import { SeasonTicketsInfoDesktopComponent } from './pages/season-tickets-info/season-tickets-info-desktop.component';
import { TicketEventsInfoDesktopComponent } from './pages/ticket-events-info/ticket-events-info-desktop.component';
import { VipMembershipsInfoDesktopComponent } from './pages/vip-memberships-info/vip-memberships-info-desktop.component';

const routes: Routes = [
  {
    path: '',
    component: StaticDesktopPagesComponent,
    children: [
      {
        path: 'season-tickets-info',
        component: SeasonTicketsInfoDesktopComponent,
        data: { breadcrumb: infoSeasonTickets },
      },
      {
        path: 'ticket-events-info',
        component: TicketEventsInfoDesktopComponent,
        data: { breadcrumb: infoTicketEvents },
      },
      {
        path: 'vip-membership-info',
        component: VipMembershipsInfoDesktopComponent,
        data: { breadcrumb: infoVipMembership },
      },
      {
        path: '',
        redirectTo: 'season-tickets-info',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class StaticDesktopPagesRoutingModule {}
