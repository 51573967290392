import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutDecorator } from '../../../../../../../common/helpers/layout.decorator';
import { LayoutConfigService } from '../../../../../../../common/services/layout-config.service';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';

@Component({
  selector: 'app-hear-from-the-pros-page',
  templateUrl: './hear-from-the-pros-page.component.html',
  styleUrls: ['./hear-from-the-pros-page.component.css'],
})
@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
export class HearFromTheProsPageComponent implements OnInit, OnDestroy {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;

  constructor(private layoutConfigService: LayoutConfigService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
