import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutDecorator } from '../../../../../common/helpers/layout.decorator';
import { BreadcrumbBackground } from '../../../../../common/enum/breadcrumb-background';
import { PredictTabsDesktop } from '../../../../../common/enum/predict-tabs';
import { VenuePredictAndWinBaseComponent } from '../../../../../common/base-controllers/venue-predict-and-win-base.component';

@Component({
  selector: 'app-predict-and-win-desktop-page',
  templateUrl: './predict-and-win-desktop-page.component.html',
  styleUrls: ['./predict-and-win-desktop-page.component.scss'],
})
@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
export class PredictAndWinDesktopPageComponent
  extends VenuePredictAndWinBaseComponent
  implements OnInit, OnDestroy
{
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  public predictTabs = PredictTabsDesktop;
  public tabs: PredictTabsDesktop[] = Object.values(PredictTabsDesktop);
  public currentTab: PredictTabsDesktop = PredictTabsDesktop.leaderBoard;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.init();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  handleTimerEnd(): void {}
}
