import { Component } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../common/enum/breadcrumb-background';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css'],
})
export class InfoComponent {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
}
