import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../../../../../common/services/auth.service';
import {
  PurchasedTicket,
  TicketDate,
  User,
} from '../../../../../../../common/types/user';
import { Router } from '@angular/router';
import { BaseTicketComponent } from '../base-ticket/base-ticket.component';
import { CommonModule } from '@angular/common';
import { DatesComponent } from '../dates/dates.component';
import { TicketDetailsComponent } from '../../../../../../../common/components/ticket-details/ticket-details.component';
import { TicketPreviewDetailsComponent } from '../ticket-preview-details/ticket-preview-details.component';
import { TicketPresentQrComponent } from '../ticket-present-qr/ticket-present-qr.component';
import { AreaTableNumberPipe } from '../../../../../../../common/pipes/area-table-number.pipe';
import { IsNotExpiredPipe } from '../../../../../../../common/pipes/is-not-expired.pipe';
import { UserService } from '../../../../../../../common/services/user.service';

@Component({
  selector: 'app-ticket-purchased',
  templateUrl: './ticket-purchased.component.html',
  standalone: true,
  imports: [
    CommonModule,
    DatesComponent,
    TicketDetailsComponent,
    TicketPreviewDetailsComponent,
    TicketPresentQrComponent,
    AreaTableNumberPipe,
    IsNotExpiredPipe,
  ],
  styleUrls: ['./ticket-purchased.component.scss'],
})
export class TicketPurchasedComponent
  extends BaseTicketComponent
  implements OnInit
{
  @Input() public ticket: PurchasedTicket;
  @Input() public isMobile: boolean = true;
  @Input() public selected: boolean = false;

  @Output() public ticketAction$: EventEmitter<{
    ticketDate: TicketDate;
    sharedTicketsCount: number;
  }> = new EventEmitter<{
    ticketDate: TicketDate;
    sharedTicketsCount: number;
  }>();

  public availableSeats: number;

  public user: User = this.userService.user;

  constructor(private router: Router, private userService: UserService) {
    super();
  }

  ngOnInit() {
    this.availableSeats =
      this.ticket?.table?.seatsCount - this.sharedTicketsCount;
  }

  public navigateToShareForm($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    if (this.availableSeats === 0) return;
    if (this.isMobile) {
      this.router.navigate(
        [`/my/season-tickets/share-ticket/${this.ticket.id}`],
        {
          queryParams: {
            date: JSON.stringify(this.date),
            sharedTicketsCount: this.sharedTicketsCount,
          },
        }
      );
    } else {
      this.ticketAction$.emit({
        ticketDate: this.ticket.dates[this.index - 1],
        sharedTicketsCount: this.sharedTicketsCount,
      });
    }
  }
}
