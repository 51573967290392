import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';

export const updateBreadcrumb = (
  keyName: string,
  value: string,
  ngDynamicBreadcrumbService: NgDynamicBreadcrumbService
): void => {
  const breadcrumb = {
    [keyName]: value,
  };
  ngDynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
};
