import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { getDaysInMonth, getMonths, getYears } from '../../helpers/date-timer';

@Component({
  selector: 'app-filter-by-date',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './filter-by-date.component.html',
  styleUrls: ['./filter-by-date.component.css'],
})
export class FilterByDateComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() public isMobile: boolean = true;

  @Input()
  public set endYear(year: number) {
    if (!year) return;
    this.years = getYears(year);
  }

  @Input() public date: string;

  @Output() public action$: EventEmitter<string> = new EventEmitter<string>();

  public dateForm: FormGroup;
  public currentDate: {
    month: number;
    year: number;
  } = {
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  };
  public days: string[] = [];
  public years: number[] = [];
  public months: string[] = getMonths();
  public isFormSubmitted: boolean = false;

  constructor(private formBuilder: NonNullableFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.fetchAndSetDays(this.currentDate.year, this.currentDate.month);
    this.initForm();
    this.subscribeMonthValueChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.date?.currentValue) {
      if (this.date) {
        const [year, month, day] = this.date.split('-');
        this.dateForm.patchValue({
          year,
          month,
          day,
        });
      } else {
        this.dateForm.reset();
      }
      this.isFormSubmitted = this.dateForm.valid;
    }
  }

  private fetchAndSetDays(year: string | number, month: string | number): void {
    const days: any[] = [];
    const daysInMonth = getDaysInMonth(+year, +month);
    daysInMonth.forEach((day: string) => days.push(day));
    this.days = days;
    const dayForm = this.dateForm?.get('day');
    if (dayForm && !days.includes(dayForm.value)) {
      dayForm.setValue('', { emitEvent: false });
    }
  }

  private initForm(): void {
    this.dateForm = this.formBuilder.group({
      year: new FormControl('', Validators.required),
      month: new FormControl('', Validators.required),
      day: new FormControl('', Validators.required),
    });
  }

  private subscribeMonthValueChanges(): void {
    this.dateForm.valueChanges
      .pipe(
        filter(({ month }) => !!month),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(({ month, year }) =>
        this.fetchAndSetDays(year || this.currentDate.year, month)
      );
  }

  public handleFormSubmit(): void {
    if (!this.dateForm.valid) return;
    this.isFormSubmitted = true;
    const { year, month, day } = this.dateForm.getRawValue();
    this.action$.emit(`${year}-${month}-${day}`);
  }

  public resetDate(): void {
    this.dateForm.setValue({
      year: '',
      month: '',
      day: '',
    });
    this.isFormSubmitted = false;
    this.action$.emit();
  }
}
