import { Component, OnInit } from '@angular/core';
import { PopupsManagerService } from '../../../../../../../common/services/popups-manager.service';

@Component({
  selector: 'app-card-benefits',
  templateUrl: './card-benefits.component.html',
  styleUrls: ['./card-benefits.component.scss'],
})
export class CardBenefitsComponent implements OnInit {
  public cards: string[] = ['bronze', 'silver', 'gold', 'platinum'];
  public activeCard: string = this.cards[0];
  public activeCardDropdown: string = this.cards[0];

  constructor(private popupsManagerService: PopupsManagerService) {}

  ngOnInit() {}

  public handleCardDropdownEvent(selectedCard: string): void {
    this.activeCardDropdown = selectedCard;
  }

  public handleCardToggle(selectedCard: string): void {
    this.activeCard = selectedCard;
  }

  public closePopup(): void {
    this.popupsManagerService.hide();
  }
}
