import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';
import { LayoutDecorator } from '../../../../../../../common/helpers/layout.decorator';
import { LayoutConfigService } from '../../../../../../../common/services/layout-config.service';
import {
  Area,
  AreaDetails,
  AreaDetailsEvent,
} from '../../../../../../../common/types/area';
import { PopupService } from '../../../../../../../common/services/popup.service';

@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
@Component({
  selector: 'app-season-ticket-area-select-page',
  templateUrl: './season-ticket-area-select-page.component.html',
  styleUrls: ['./season-ticket-area-select-page.component.scss'],
})
export class SeasonTicketAreaSelectPageComponent implements OnInit, OnDestroy {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public showAreaDetailsPopup({ event, area }: AreaDetailsEvent): void {
    event?.stopPropagation();
    this.popupService.showAreaDetailsPopup(area);
  }
}
