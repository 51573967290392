import {Component} from '@angular/core';
import {LayoutDecorator} from '../../../../../../../common/helpers/layout.decorator';
import {DatePipe} from '@angular/common';
import {
  SeasonTicketPreviewBaseComponent
} from '../../../../../../../common/base-controllers/season-ticket-preview-base.component';

@Component({
  selector: 'app-season-ticket-preview',
  templateUrl: './season-ticket-preview.component.html',
  styleUrls: ['./season-ticket-preview.component.scss'],
  providers: [DatePipe],
})
@LayoutDecorator('layoutConfigService', {showHeader: true, showFooter: false})
export class SeasonTicketPreviewComponent
  extends SeasonTicketPreviewBaseComponent {

}
