import { Component } from '@angular/core';
import { Venue } from '../../../../../common/types/sharp-sports/venues';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-venue-desktop',
  templateUrl: './select-venue-desktop.component.html',
  styleUrls: ['./select-venue-desktop.component.css'],
})
export class SelectVenueDesktopComponent {
  constructor(private router: Router) {}

  public onVenueSelect(venue: Venue) {
    this.router.navigateByUrl(`/my/predict-and-win/${venue.id}`);
  }
}
