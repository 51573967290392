import { Day } from '../types/calendar';
import { StorageService } from '../services/storage.service';

export interface DateTimer {
  seconds: string;
  minutes: string;
  hours: string;
  days: string;
}

export const dateTimerCalculator = (endDay: number): DateTimer => {
  const milliSecondsInASecond = 1000;
  const hoursInADay = 24;
  const minutesInAnHour = 60;
  const secondsInAMinute = 60;

  const timeDifference = endDay - Date.now();

  const days = Math.floor(
    timeDifference /
      (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay)
  );

  const hours = Math.floor(
    (timeDifference /
      (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
      hoursInADay
  );

  const minutes = Math.floor(
    (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
      secondsInAMinute
  );

  const seconds =
    Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;

  return {
    seconds: `${singleDigitFormatting(seconds)}`,
    minutes: `${singleDigitFormatting(minutes)}`,
    hours: `${singleDigitFormatting(hours)}`,
    days: `${singleDigitFormatting(days)}`,
  };
};

export const setWeekName = (date: Date): string => {
  const weeks = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return weeks[date.getDay()];
};

export const getDatesInMonth = (
  month: string,
  year: string,
  highlightedDates: string[],
  storageService: StorageService
): Day[] => {
  const selectedDates: string[] =
    (storageService.getFromStorage('dates') as string[]) ?? [];
  const days: Day[] = [];
  const daysInMonth = getAllDatesInMonth(+year, +month);
  daysInMonth.forEach((date: string) => {
    const highlighted = highlightedDates.includes(date);
    const selected =
      selectedDates.length > 0 ? selectedDates.includes(date) : highlighted;
    days.push({
      day: date,
      selected,
      highlighted,
    });
  });
  return days;
};

export const getAllDatesInMonth = (year: number, month: number): string[] => {
  const date = new Date(year, month - 1, 1);
  const days: string[] = [];
  while (date.getMonth() + 1 === month) {
    const day = date.getDate();
    days.push(
      `${year}-${month < 10 ? '0' + month : month}-${
        day < 10 ? '0' + day : day
      }`
    );
    date.setDate(day + 1);
  }
  return days;
};

export const getDaysInMonth = (year: number, month: number): string[] => {
  const date = new Date(year, month - 1, 1);
  const days: string[] = [];
  while (date.getMonth() + 1 === month) {
    const day = date.getDate();
    days.push(`${day < 10 ? '0' + day : day}`);
    date.setDate(day + 1);
  }
  return days;
};

export const getYears = (endYear: number): number[] => {
  const startYear = new Date().getFullYear();
  return [...Array(endYear - startYear + 1)].map((_, i) => startYear + i);
};

export const getMonths = (): string[] =>
  [...Array(12)].map((_, i) => (i > 8 ? `${i + 1}` : `0${i + 1}`));

export const singleDigitFormatting = (digit: number): string =>
  `${digit < 10 ? '0' : ''}${digit}`;
