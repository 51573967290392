import { Component, EventEmitter, Output, Input } from '@angular/core';
import { PopupsManagerService } from '../../../services/popups-manager.service';
import { OtpInputComponent } from '../../otp-input.component/otp-input.component';
import { KeysOtpPipe } from '../../../pipes/key-otp.pipe';
import { CommonModule } from '@angular/common';
import { ResendVerificationCodeComponent } from '../../resend-verification-code/resend-verification-code.component';
import { BasePopupComponent } from '../../base/base-popup/base-popup.component';

@Component({
  selector: 'app-verification-popup',
  templateUrl: './verification-popup.component.html',
  styleUrls: ['./verification-popup.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    OtpInputComponent,
    ResendVerificationCodeComponent,
    BasePopupComponent,
  ],
  providers: [KeysOtpPipe],
})
export class VerificationPopupComponent {
  @Input() phoneNumber;
  @Input() isMobile = true;
  @Output() verificationCode$: EventEmitter<string> =
    new EventEmitter<string>();
  private code: string;
  public inputsQuantity = 6;
  public buttonIsDisabled = true;
  public submitted = false;

  constructor(private popupsManagerService: PopupsManagerService) {}

  public closePopup(): void {
    this.popupsManagerService.hide();
  }

  public inputValueChange($event: string) {
    if ($event?.length === 6) {
      this.buttonIsDisabled = false;
      this.code = $event;
      this.verifyAndUpdateNumber();
    } else {
      this.buttonIsDisabled = true;
    }
  }

  public verifyAndUpdateNumber(): void {
    if (!this.submitted) {
      this.submitted = true;
      this.verificationCode$.emit(this.code);
      this.popupsManagerService.hide();
    }
  }
}
