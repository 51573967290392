import { Component } from '@angular/core';
import { SeasonTicketDatesBaseComponent } from '../../../../../../../common/base-controllers/season-ticket-dates-base.component';
import { SeasonTicketsService } from '../../../../../../../common/services/season-tickets.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../../../../../../../common/services/loader.service';
import { StorageService } from '../../../../../../../common/services/storage.service';

@Component({
  selector: 'app-season-ticket-dates-desktop-page',
  templateUrl: './season-ticket-dates-desktop-page.component.html',
  styleUrls: ['./season-ticket-dates-desktop-page.component.scss'],
})
export class SeasonTicketDatesDesktopPageComponent extends SeasonTicketDatesBaseComponent {
  constructor(
    seasonTicketsService: SeasonTicketsService,
    activatedRoute: ActivatedRoute,
    router: Router,
    loaderService: LoaderService,
    storageService: StorageService
  ) {
    super(
      seasonTicketsService,
      activatedRoute,
      router,
      loaderService,
      storageService
    );
  }
}
