import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { IncludePipe } from '../../pipes/include.pipe';

@Component({
  selector: 'app-event-filter-by-category',
  standalone: true,
  imports: [CommonModule, TitleCasePipe, IncludePipe],
  templateUrl: './event-filter-by-category.component.html',
  styleUrls: ['./event-filter-by-category.component.css'],
})
export class EventFilterByCategoryComponent implements OnInit {
  @Input() public isMobile: boolean = true;
  @Input() public keys: string[];
  @Input() public filteredKeys: string[];

  @Output() public action$: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  public isSelectedAll: boolean;

  ngOnInit() {
    this.isSelectedAll = !this.filteredKeys.length;
  }

  public toggleFilterItems(key: string): void {
    this.isSelectedAll = false;
    if (this.filteredKeys.includes(key)) {
      this.filteredKeys = this.filteredKeys.filter((k) => k !== key);
    } else {
      this.filteredKeys = [...this.filteredKeys, key];
    }
    if (!this.filteredKeys.length) {
      this.handleSelectAll();
    }
    if (!this.isMobile) {
      this.handleFilter();
    }
  }

  public handleFilter(): void {
    if (!this.filteredKeys.length && !this.isSelectedAll) {
      return;
    }
    this.action$.emit(
      this.isSelectedAll && !this.filteredKeys.length
        ? this.keys
        : this.filteredKeys
    );
  }

  public handleSelectAll(): void {
    this.isSelectedAll = true;
    this.filteredKeys = [];
    if (!this.isMobile) {
      this.handleFilter();
    }
  }
}
