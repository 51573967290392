import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Action } from '../../../enum/action';
import { PopupsManagerService } from '../../../services/popups-manager.service';
import { CommonModule } from '@angular/common';
import { ProsNews } from '../../../types/pro-news';
import { SanitizeHtmlPipe } from '../../../pipes/sanitize-html.pipe';
import { BasePopupComponent } from '../../base/base-popup/base-popup.component';
import { AssetsUrlPipe } from '../../../pipes/assets-url.pipe';

@Component({
  selector: 'app-event-details-popup',
  standalone: true,
  templateUrl: './news-details-popup.component.html',
  imports: [CommonModule, SanitizeHtmlPipe, BasePopupComponent, AssetsUrlPipe],
  styleUrls: ['./news-details-popup.component.css'],
})
export class NewsDetailsPopupComponent {
  @Input() public news: ProsNews;
  @Output() public action$: EventEmitter<Action>;

  public isPlaying = false;

  constructor(private popupsManagerService: PopupsManagerService) {}

  public handleBuyEvent(): void {
    this.action$.emit(Action.Submit);
    this.popupsManagerService.hide();
  }

  public close(): void {
    this.popupsManagerService.hide();
  }

  public play(videoRef: HTMLVideoElement): void {
    this.isPlaying = !this.isPlaying;
    videoRef.play();
  }
}
