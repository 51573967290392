import { Pipe, PipeTransform } from '@angular/core';
import { EventDate } from '../types/event';

@Pipe({
  name: 'dateObjToString',
  standalone: true,
})
export class DateObjToStringPipe implements PipeTransform {
  transform(date: EventDate[]): string[] {
    return date.map((d) => d.date);
  }
}
