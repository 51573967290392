import { Component } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';

@Component({
  selector: 'app-builder-dashboard',
  templateUrl: './builder-dashboard.component.html',
  styleUrls: ['./builder-dashboard.component.scss'],
})
export class BuilderDashboardComponent {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
}
