import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { DynamicPopup, PopupQueue } from '../types/dynamicPopup';
import { Pageable } from '../types/pageable';
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { PageCode } from '../enum/page-code';
import { PopupService } from './popup.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class DynamicPopupService {
  private popupQueue: PopupQueue = this.generateDataObject();
  private cookieKey = 'popup';

  constructor(
    private httpClient: HttpClient,
    private popupService: PopupService,
    private cookieService: CookieService
  ) {}

  public showPagePopups(page: PageCode): void {
    this.checkAndCreateCookieStore();
    const pagePopupQueue = this.popupQueue[page];
    for (const popup of pagePopupQueue) {
      if (this.checkForShowing(popup)) {
        this.incrementPopupStats(popup.id, 'totalAppearing')
          .pipe(take(1))
          .subscribe();
        this.popupService.showImagePopup(popup);
        if (popup.appearOnlyOnce) {
          this.addInCookieSeenList(popup);
        }
      }
    }
  }

  private checkAndCreateCookieStore(): void {
    if (this.cookieService.check(this.cookieKey)) {
      return;
    }
    this.cookieService.set(this.cookieKey, JSON.stringify([]));
  }

  private checkForShowing(popup: DynamicPopup): boolean {
    if (!popup.appearOnlyOnce) {
      return true;
    }
    const seenPopups = JSON.parse(
      this.cookieService.get(this.cookieKey)
    ) as string[];
    return !seenPopups.includes(popup.id);
  }

  private addInCookieSeenList(popup: DynamicPopup): void {
    const seenPopups = JSON.parse(
      this.cookieService.get(this.cookieKey)
    ) as string[];
    seenPopups.push(popup.id);
    this.cookieService.set(this.cookieKey, JSON.stringify(seenPopups));
  }

  public initializeDynamicPopups(): Observable<DynamicPopup[]> {
    return this.getAllPopups().pipe(
      tap((dynamicPopups: DynamicPopup[]) => {
        dynamicPopups.forEach((popup: DynamicPopup) => {
          this.addPopupsInPages(popup);
        });
      })
    );
  }

  private getAllPopups(): Observable<DynamicPopup[]> {
    return this.httpClient
      .get<Pageable<DynamicPopup>>(`${environment.apiUrl}/client/pop-up`)
      .pipe(map((res: Pageable<DynamicPopup>) => res.items));
  }

  public incrementPopupStats(
    popupId: string,
    stat: 'totalAppearing' | 'totalClicks'
  ): Observable<{
    message: string;
  }> {
    return this.httpClient.post<{ message: string }>(
      `${environment.apiUrl}/client/pop-up/${popupId}/increment-properties?property=${stat}`,
      null
    );
  }

  private generateDataObject(): PopupQueue {
    return Object.values(PageCode).reduce(
      (obj: PopupQueue, code: string) => ({ ...obj, [code]: [] }),
      {} as PopupQueue
    );
  }

  private addPopupsInPages(popup: DynamicPopup): void {
    popup.pagesAppear.forEach((page: string) => {
      this.popupQueue[page]?.push(popup);
    });
  }
}
