import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanitizeHtmlPipe } from '../../../../../common/pipes/sanitize-html.pipe';
import { BasePopupComponent } from '../../../../../common/components/base/base-popup/base-popup.component';
import { AssetsUrlPipe } from '../../../../../common/pipes/assets-url.pipe';
import { EventDetailsBaseComponent } from '../../../../../common/base-controllers/event-details-base.component';
import { Router } from '@angular/router';
import { PopupsManagerService } from '../../../../../common/services/popups-manager.service';
import { ProsNews } from '../../../../../common/types/pro-news';
import { UserService } from '../../../../../common/services/user.service';

@Component({
  selector: 'app-event-details-desktop',
  standalone: true,
  templateUrl: './event-details-desktop.component.html',
  imports: [CommonModule, SanitizeHtmlPipe, BasePopupComponent, AssetsUrlPipe],
  styleUrls: ['./event-details-desktop.component.css'],
})
export class EventDetailsDesktopComponent extends EventDetailsBaseComponent {
  @Input() news: ProsNews;
  public user = inject(UserService).user;

  constructor(
    private router: Router,
    popupsManagerService: PopupsManagerService
  ) {
    super(popupsManagerService);
  }

  public navigateToBuyEvent(): void {
    if (!this.user) {
      this.router.navigateByUrl('/auth');
      return;
    }
    this.router.navigate(['/my/season-tickets'], {
      queryParams: { event: JSON.stringify(this.event) },
    });
  }
}
