import { DynamicPopupService } from '../services/dynamic-popup.service';
import { firstValueFrom, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const dynamicPopupInitializer =
  (dynamicPopupService: DynamicPopupService): any =>
  () =>
    firstValueFrom(
      from(
        dynamicPopupService
          .initializeDynamicPopups()
          .pipe(catchError(() => of(null)))
      )
    );
