import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../common/enum/breadcrumb-background';
import { VenuePredictAndWinBaseComponent } from '../../../../../common/base-controllers/venue-predict-and-win-base.component';
import { PredictTabs } from '../../../../../common/enum/predict-tabs';

@Component({
  selector: 'app-predict-and-win-page',
  templateUrl: './predict-and-win-page.component.html',
  styleUrls: ['./predict-and-win-page.component.scss'],
})
export class PredictAndWinPageComponent
  extends VenuePredictAndWinBaseComponent
  implements OnInit, OnDestroy
{
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  public predictTabs = PredictTabs;
  public currentTab = PredictTabs.predictAndWin;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.init();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  handleTimerEnd(): void {}
}
