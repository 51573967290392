import { FormControl } from '@angular/forms';

export const phonePrefixHandler = (phoneNumberControl: FormControl) => {
  const controlValue = phoneNumberControl.value;
  const firstTwoNumbers = controlValue.slice(0, 2);
  if (firstTwoNumbers === '+1') {
    return;
  }
  if (controlValue[0] !== '+' || controlValue[1] !== '1') {
    phoneNumberControl.setValue(`+1${controlValue.slice(1)}`);
    return;
  }
};
