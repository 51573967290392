import { Component, OnInit } from '@angular/core';
import {
  PurchasedReceivedTickets,
  PurchasedTicket,
  ReceivedTicket,
  TicketDate,
} from '../types/user';
import { BaseComponent } from '../components/base/base.component';
import { LoaderService } from '../services/loader.service';
import { TicketsService } from '../services/tickets.service';
import { zip } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

interface EventDate {
  date: string;
  eventName: string;
}

@Component({
  template: '',
})
export abstract class NextTicketBaseComponent
  extends BaseComponent
  implements OnInit
{
  public nextTicket: EventDate;

  public constructor(
    private loaderService: LoaderService,
    private ticketsService: TicketsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchUserTickets();
  }

  private fetchUserTickets(): void {
    this.loaderService.show();
    zip([
      this.ticketsService.getUserPurchasedTickets(),
      this.ticketsService.getUserReceivedTickets(),
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe(
        (tickets: PurchasedReceivedTickets) =>
          (this.nextTicket = this.findNearestGame(tickets))
      );
  }

  private findNearestGame(tickets: PurchasedReceivedTickets): EventDate {
    const eventData: EventDate[] = [
      ...this.getPurchasedEventDate(tickets[0]),
      ...this.getPurchasedEventDate(tickets[1]),
    ];
    const today = Date.now();
    const availableDates = eventData.filter((e) => Date.parse(e.date) > today);
    const [nextTicket] = availableDates.sort(
      (a: EventDate, b: EventDate) => Date.parse(a.date) - Date.parse(b.date)
    );
    return nextTicket;
  }

  private getPurchasedEventDate(
    ticket: Array<PurchasedTicket | ReceivedTicket>
  ): EventDate[] {
    const tickets: EventDate[] = [];
    ticket.forEach((value: PurchasedTicket | ReceivedTicket) =>
      value.dates.forEach((d: TicketDate) => {
        const eventName = value.event.name;
        tickets.push({ date: d.date, eventName });
      })
    );
    return tickets;
  }
}
