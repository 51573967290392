import { Component, Input, OnInit } from '@angular/core';
import { BetSlipsType } from '../../../../../../../../../../../common/enum/bet-slip-type';
import { ActivatedRoute } from '@angular/router';
import { getTodayDateAtMidnight } from '../../../../../../../../../../../common/helpers/date-time';
import { finalize, shareReplay, takeUntil } from 'rxjs/operators';
import { SharpSportsUserMetadata } from '../../../../../../../../../../../common/types/sharp-sports/sharp-sports';
import { LoaderService } from '../../../../../../../../../../../common/services/loader.service';
import { SharpSportsService } from '../../../../../../../../../../../common/services/sharp-sports.service';
import { Observable } from 'rxjs';
import { OpenSettledCurrentBetSlips } from '../../../../../../../../../../../common/types/sharp-sports/bet-slips';
import { UserService } from '../../../../../../../../../../../common/services/user.service';
import { BaseComponent } from '../../../../../../../../../../../common/components/base/base.component';

@Component({
  selector: 'app-builder-today-bets',
  templateUrl: './builder-today-bets.component.html',
  styleUrls: ['./builder-today-bets.component.css'],
})
export class BuilderTodayBetsComponent extends BaseComponent implements OnInit {
  public userCurrentBetSlips$: Observable<OpenSettledCurrentBetSlips>;
  public unitSize: number;

  public currentBetSlipType: BetSlipsType =
    (this.activatedRoute.snapshot.queryParamMap.get('type') as BetSlipsType) ||
    BetSlipsType.open;

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private sharpSportsService: SharpSportsService
  ) {
    super();
  }

  ngOnInit() {
    this.loaderService.show();
    const userId = this.userService.user.id;
    this.userCurrentBetSlips$ = this.sharpSportsService
      .getUserCurrentBetSlips(userId, getTodayDateAtMidnight('start').timestamp)
      .pipe(
        shareReplay(1),
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      );
    this.sharpSportsService
      .getUserMetadata(userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: SharpSportsUserMetadata) => (this.unitSize = res.unitSize)
      );
  }
}
