import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PredictAndWinService } from '../../../../../common/services/predict-and-win.service';
import { predictDesktop } from '../../../../../common/constants/breadcrumbs';
import { PredictAndWinComponent } from '../../../../../common/components/predict-and-win/predict-and-win.component';
import { PredictAndWinHistoryComponent } from '../../../../../common/components/predict-and-win-history/predict-and-win-history.component';
import { QuestionLeaderboardComponent } from '../../../../../common/components/question-leaderboard/question-leaderboard.component';
import { PredictAndWinDesktopPageComponent } from './predict-and-win-desktop-page.component';
import { BreadcrumbDesktopComponent } from '../../../components/layout/breadcrumb/breadcrumb-desktop.component';
import { AssetsUrlPipe } from '../../../../../common/pipes/assets-url.pipe';
import { PredictPrizeComponent } from '../../../../../common/components/predict-prize/predict-prize.component';
import { PredictCountDownComponent } from '../../../../../common/components/predict-count-down/predict-count-down.component';
import { ClockCountDownComponent } from '../../../../../common/components/bankroll-builder/clock-count-down/clock-count-down.component';

const routes: Routes = [
  {
    path: '',
    data: { breadcrumb: predictDesktop },
    component: PredictAndWinDesktopPageComponent,
  },
];

@NgModule({
  imports: [
    // Modules
    CommonModule,
    RouterModule.forChild(routes),
    // Components
    BreadcrumbDesktopComponent,
    PredictAndWinComponent,
    PredictAndWinHistoryComponent,
    QuestionLeaderboardComponent,
    PredictPrizeComponent,
    PredictCountDownComponent,
    ClockCountDownComponent,
    // Pipes
    AssetsUrlPipe,
  ],
  declarations: [PredictAndWinDesktopPageComponent],
  providers: [PredictAndWinService],
})
export class PredictAndWinDesktopPageModule {}
