import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '../types/event';

@Pipe({
  name: 'eventName',
  standalone: true,
})
export class EventNamePipe implements PipeTransform {
  transform(eventId: string, allEvents: Event[]): string {
    return allEvents?.find((e: Event) => e?.id === eventId)?.name || '-';
  }
}
