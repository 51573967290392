import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReaderDefaultComponent } from './components/reader-default/reader-default.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReaderCanvasComponent } from './components/reader-canvas/reader-canvas.component';

@NgModule({
  declarations: [ReaderDefaultComponent, ReaderCanvasComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [ReaderDefaultComponent],
})
export class ReaderModule {}
