import { Component, OnInit } from '@angular/core';
import { BreadcrumbBackground } from '../enum/breadcrumb-background';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../components/base/base.component';
import { TicketsData } from '../../mobile/modules/main/pages/season-ticket/pages/season-ticket-reservation/ticket-data';
import { TicketType } from '../types/ticket-type';
import { Router } from '@angular/router';
import { TicketsService } from '../services/tickets.service';
import { PopupService } from '../services/popup.service';
import { LayoutConfigService } from '../services/layout-config.service';
import { PurchasedTicket, ReceivedTicket } from '../types/user';
import { TicketStatus } from '../enum/ticket-status';
import { switchMap } from 'rxjs';

@Component({
  template: ``,
})
export abstract class SeasonTicketReservationBaseComponent
  extends BaseComponent
  implements OnInit
{
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  public tickets: TicketsData;
  public filterType = [
    { value: TicketType.all },
    { value: TicketType.purchased },
    { value: TicketType.shared },
    { value: TicketType.received },
  ];
  public ticketTypes: string[] = Object.values(TicketType);

  public constructor(
    private router: Router,
    private ticketService: TicketsService,
    private popupService: PopupService,
    private layoutConfigService: LayoutConfigService
  ) {
    super();
  }

  ngOnInit() {
    this.initTickets();
  }

  public navigateToTicket(ticket: PurchasedTicket): void {
    if (ticket.event.type === 'general') {
      return;
    }
    this.router.navigateByUrl(`/my/season-tickets/ticket/${ticket.id}`);
  }

  public showReceivedTicketPopup(
    ticket: ReceivedTicket,
    isMobile: boolean = true
  ): void {
    if (ticket.status !== TicketStatus.pending) {
      return;
    }
    this.popupService
      .showReceivedTicketPopup(ticket, isMobile)
      .action$.pipe(
        filter((status: TicketStatus) => !!status),
        switchMap(
          (status) =>
            this.popupService.showReceivedAcceptOrDeclinePopup(
              ticket.source.firstName,
              ticket.source.lastName,
              status,
              isMobile
            ).action$
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.initTickets());
  }

  public initTickets(): void {
    this.tickets = {} as TicketsData;
    this.ticketService
      .getUserAllTickets()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([purchased, shared, received]) =>
          (this.tickets = { purchased, shared, received })
      );
  }
}
