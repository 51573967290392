import { Pipe, PipeTransform } from '@angular/core';
import { Assets } from '../types/assets';

@Pipe({
  name: 'assetsUrl',
  standalone: true,
})
export class AssetsUrlPipe implements PipeTransform {
  transform(assets: Assets, code: string): string {
    if (!assets) {
      return;
    }
    const placeHolder =
      code === 'images' ? 'https://via.placeholder.com/600x600.png' : '';
    return (
      Object.values(assets).find((item) => item.code === code)?.url ||
      placeHolder
    );
  }
}
