import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MainDesktopRoutingModule } from './main-desktop-routing.module';
import { MainDesktopComponent } from './main-desktop.component';
import { RouterModule } from '@angular/router';
import { EventNameAsyncPipe } from '../../../common/pipes/event-name-async.pipe';
import { StringToDatePipe } from '../../../common/pipes/string-to-date.pipe';
import { PredictAndWinService } from '../../../common/services/predict-and-win.service';
import { SeasonTicketConfigActivatorGuard } from '../../../common/guards/season-ticket-config-activator.guard';
import { HeaderDesktopComponent } from '../components/layout/header/header-desktop.component';
import { NextTicketComponent } from '../../../mobile/modules/components/layout/next-ticket/next-ticket.component';
import { FooterDesktopComponent } from '../components/layout/footer/footer-desktop.component';
import { BreadcrumbDesktopComponent } from '../components/layout/breadcrumb/breadcrumb-desktop.component';
import { NextTicketDesktopComponent } from '../components/layout/next-ticket-desktop/next-ticket-desktop.component';
import { ResyButtonComponent } from '../../../components/resy-button/resy-button.component';
import { AccountDesktopComponent } from './pages/account-desktop/account-desktop.component';

@NgModule({
  imports: [
    // Modules
    CommonModule,
    FormsModule,
    RouterModule,
    MainDesktopRoutingModule,
    // Components
    HeaderDesktopComponent,
    FooterDesktopComponent,
    BreadcrumbDesktopComponent,
    EventNameAsyncPipe,
    StringToDatePipe,
    NextTicketComponent,
    NextTicketDesktopComponent,
    ResyButtonComponent,
  ],
  declarations: [MainDesktopComponent, AccountDesktopComponent],
  providers: [PredictAndWinService, SeasonTicketConfigActivatorGuard],
})
export class MainDesktopModule {}
