import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupsManagerService } from '../../../services/popups-manager.service';
import { AssetsUrlPipe } from '../../../pipes/assets-url.pipe';
import { DynamicPopup } from '../../../types/dynamicPopup';
import { DynamicPopupService } from '../../../services/dynamic-popup.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss'],
  standalone: true,
  imports: [CommonModule, AssetsUrlPipe],
})
export class ImagePopupComponent implements OnInit, OnDestroy {
  @Input() public popup: DynamicPopup;
  @Output() public close$: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private popupsManagerService: PopupsManagerService,
    private dynamicPopupService: DynamicPopupService
  ) {}

  ngOnInit(): void {
    document.body.classList.add('no-scroll');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('no-scroll');
  }

  public close(): void {
    this.close$.emit();
    this.popupsManagerService.hide();
  }

  public handleClick(): void {
    this.dynamicPopupService
      .incrementPopupStats(this.popup.id, 'totalClicks')
      .pipe(take(1))
      .subscribe();
  }
}
