import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPagesComponent } from './static-pages.component';
import { RouterModule } from '@angular/router';
import { InfoComponent } from './pages/info/info.component';
import { StaticPagesRoutingModule } from './static-pages-routing.module';
import { VipMembershipsInfoComponent } from './pages/vip-memberships-info/vip-memberships-info.component';
import { TicketEventsInfoComponent } from './pages/ticket-events-info/ticket-events-info.component';
import { SeasonTicketsInfoComponent } from './pages/season-tickets-info/season-tickets-info.component';
import { HeaderComponent } from '../components/layout/header/header.component';
import { BreadcrumbComponent } from '../components/layout/breadcrumb/breadcrumb.component';
import { FooterComponent } from '../components/layout/footer/footer.component';
import { VipMembershipFormComponent } from '../../../components/vip-membership-form/vip-membership-form.component';
import { AssetsUrlPipe } from '../../../common/pipes/assets-url.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StaticPagesRoutingModule,
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    VipMembershipFormComponent,
    AssetsUrlPipe,
  ],
  declarations: [
    StaticPagesComponent,
    InfoComponent,
    VipMembershipsInfoComponent,
    TicketEventsInfoComponent,
    SeasonTicketsInfoComponent,
  ],
})
export class StaticPagesModule {}
