import { Component, Input } from '@angular/core';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FireBaseService } from '../../services/fire-base.service';
import { BaseComponent } from '../base/base.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-resend-verification-code',
  templateUrl: './resend-verification-code.component.html',
  styleUrls: ['./resend-verification-code.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ResendVerificationCodeComponent extends BaseComponent {
  @Input() phoneNumber;
  public showResendButton = true;

  constructor(private fireBaseService: FireBaseService) {
    super();
  }

  public sendVerificationCode(): void {
    this.showResendButton = false;
    this.fireBaseService
      .signInWithPhoneNumber(this.phoneNumber)
      .catch((err) => console.error(err));
    const timer$ = timer(10000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.showResendButton = true;
        timer$.unsubscribe();
      });
  }
}
