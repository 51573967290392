import {LayoutConfigService} from '../services/layout-config.service';
import {LayoutConfig} from '../types/layout-cofig.type';

/**
 * The target component should implement OnInit, OnDestroy interfaces.
 *
 * @param serviceName
 * @param config
 * @constructor
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const LayoutDecorator = (serviceName: string, config: LayoutConfig) => (constructor) => {

  const origInit = constructor.prototype.ngOnInit;
  constructor.prototype.ngOnInit = function() {
    const service: LayoutConfigService = this[serviceName];
    if (service) {
      service.changeConfig(config);
    }
    origInit.apply(this);
  };

  // Destroy
  const orig = constructor.prototype.ngOnDestroy;
  constructor.prototype.ngOnDestroy = function() {
    const service: LayoutConfigService = this[serviceName];
    if (service) {
      service.changeConfig(LayoutConfigService.defaultConfig);
    }
    orig.apply(this);
  };
};
