import { AfterViewInit, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

declare let resyWidget: any;

@Component({
  selector: 'app-resy-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './resy-button.component.html',
  styleUrls: ['./resy-button.component.css'],
})
export class ResyButtonComponent implements AfterViewInit {
  @Input() public venueId: number;
  @Input() public apiKey: string;
  @Input() public buttonId: string;
  @Input() public link: string;

  ngAfterViewInit() {
    const button = document.getElementById(this.buttonId);
    if (!resyWidget || !button) return;
    resyWidget.addButton(button, {
      venueId: this.venueId,
      apiKey: this.apiKey,
      replace: true,
    });
  }
}
