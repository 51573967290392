import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainDesktopComponent } from './main-desktop.component';
import { SeasonTicketConfigActivatorGuard } from '../../../common/guards/season-ticket-config-activator.guard';
import { AccountDesktopComponent } from './pages/account-desktop/account-desktop.component';

const routes: Routes = [
  {
    path: '',
    component: MainDesktopComponent,
    children: [
      {
        path: '',
        redirectTo: 'account',
        pathMatch: 'full',
      },
      {
        path: 'account',
        component: AccountDesktopComponent,
      },
      {
        path: 'season-tickets',
        loadChildren: () =>
          import(
            './pages/season-ticket-desktop/season-ticket-desktop.module'
          ).then((m) => m.SeasonTicketDesktopModule),
        canDeactivate: [SeasonTicketConfigActivatorGuard],
      },
      {
        path: 'predict-and-win/:venueId',
        loadChildren: () =>
          import(
            './pages/predict-and-win-desktop-page/predict-and-win-desktop-page.module'
          ).then((m) => m.PredictAndWinDesktopPageModule),
      },
      {
        path: 'select-venue',
        loadChildren: () =>
          import(
            './pages/select-venue-desktop/select-venue-desktop.module'
          ).then((m) => m.SelectVenueDesktopModule),
      },
      {
        path: 'community',
        loadChildren: () =>
          import('./pages/community-desktop/community-desktop.module').then(
            (m) => m.CommunityDesktopModule
          ),
      },
      {
        path: 'builder',
        loadChildren: () =>
          import(
            './pages/bankroll-builder-desktop/bankroll-builder-desktop.module'
          ).then((m) => m.BankrollBuilderDesktopModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class MainDesktopRoutingModule {}
