import { Component } from '@angular/core';
import { LayoutDecorator } from '../../../../../../../common/helpers/layout.decorator';
import { SeasonTicketPreviewBaseComponent } from '../../../../../../../common/base-controllers/season-ticket-preview-base.component';
import { DatePipe } from '@angular/common';
import { TicketDate } from '../../../../../../../common/types/user';

@Component({
  selector: 'app-season-ticket-preview-desktop',
  templateUrl: './season-ticket-preview-desktop.component.html',
  styleUrls: ['./season-ticket-preview-desktop.component.scss'],
  providers: [DatePipe],
})
@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
export class SeasonTicketPreviewDesktopComponent extends SeasonTicketPreviewBaseComponent {
  public ticketDate: TicketDate;
  public sharedTicketsCount: number;

  public handleSelectTicketToShare({ ticketDate, sharedTicketsCount }): void {
    this.ticketDate = ticketDate;
    this.sharedTicketsCount = sharedTicketsCount;
  }
}
