import { Pipe, PipeTransform } from '@angular/core';
import { SeasonTicketsService } from '../services/season-tickets.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pageable } from '../types/pageable';
import { Event } from '../types/event';

@Pipe({
  name: 'eventNameAsync',
  standalone: true,
})
export class EventNameAsyncPipe implements PipeTransform {
  constructor(private seasonTicketsService: SeasonTicketsService) {}

  transform(eventId: string): Observable<string> {
    return this.seasonTicketsService.events.pipe(
      map(
        (events: Pageable<Event>) =>
          events.items.find((event) => event.id === eventId)?.name || '-'
      )
    );
  }
}
