import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimerPipe } from '../../../pipes/date-timer.pipe';
import { VenueTournament } from '../../../types/sharp-sports/venue-tournament';
import { AssetsUrlPipe } from '../../../pipes/assets-url.pipe';
import { User } from '../../../types/user';
import { RouterModule } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AssetIsImagePipe } from '../../../pipes/asset-is-image.pipe';

@Component({
  selector: 'app-leaderboard-prizes',
  templateUrl: './leaderboard-prizes.component.html',
  styleUrls: ['./leaderboard-prizes.component.css'],
  standalone: true,
  imports: [
    // Modules
    CommonModule,
    RouterModule,
    // Pipes
    DateTimerPipe,
    AssetsUrlPipe,
    AssetIsImagePipe,
  ],
})
export class LeaderboardPrizesComponent implements AfterViewInit, OnDestroy {
  @Input() isMobile = true;
  @Input() tournament: VenueTournament;
  @Input() showTimer = false;
  @Input() winner: User;
  @Input() isWinnerView = false;
  @Output() tournamentEnd$: EventEmitter<void> = new EventEmitter<void>();
  public currentSliderIndex = 0;
  private currentVideoIndex = 0;
  public translate = `translateX(0)`;
  private prizeQrToggleSub!: Subscription;
  private autoSliderStep = 1;
  private swipeCoord?: [number, number];
  private swipeTime?: number;
  private currentVideo: HTMLVideoElement;

  private videoRef!: QueryList<ElementRef<HTMLVideoElement>>;
  @ViewChildren('videoPlayer')
  get videoPlayerRef(): QueryList<ElementRef<HTMLVideoElement>> | undefined {
    return this.videoRef;
  }
  set videoPlayerRef(
    videos: QueryList<ElementRef<HTMLVideoElement>> | undefined
  ) {
    this.videoRef = videos;
  }

  ngAfterViewInit(): void {
    if (this.tournament) {
      this.prizeQrSliderInterval(this.tournament.slideTime);
      this.checkForVideo(this.autoSliderStep, true);
    }
  }

  public handleTimerEnd(): void {
    this.tournamentEnd$.emit();
  }

  public slide(step: number, byClick = false) {
    this.currentSliderIndex = Math.max(
      Math.min(
        this.currentSliderIndex + step,
        this.tournament?.severalPrizes.length - 1
      ),
      0
    );
    this.translate = `translateX(${this.currentSliderIndex * -100}%)`;
    if (byClick) {
      this.currentVideo?.pause();
      this.prizeQrSliderInterval(this.tournament.slideTime);
      this.checkForVideo(step);
    }
  }

  ngOnDestroy(): void {
    this.prizeQrToggleSub?.unsubscribe();
    this.currentVideo?.removeEventListener('loadeddata', this.play);
    this.currentVideo?.removeEventListener('ended', this.endedListener);
  }

  private prizeQrSliderInterval(sec: number): void {
    if (this.prizeQrToggleSub && !this.prizeQrToggleSub?.closed) {
      return;
    }
    const timeStamp = sec * 1000;
    this.prizeQrToggleSub = interval(timeStamp)
      .pipe(shareReplay(1))
      .subscribe(() => {
        if (
          this.currentSliderIndex ===
          this.tournament?.severalPrizes.length - 1
        ) {
          this.autoSliderStep = -1;
        }
        if (this.currentSliderIndex === 0) {
          this.autoSliderStep = 1;
        }
        this.slide(this.autoSliderStep);
        this.checkForVideo(this.autoSliderStep);
      });
  }

  private checkForVideo(step: number, isFirstCheck = false): void {
    const asset = this.tournament.severalPrizes[this.currentSliderIndex].assets;
    const key = Object.keys(asset)[0];
    if (asset[key].code === 'videos' && !!asset[key].url) {
      if (!isFirstCheck) {
        this.currentVideoIndex = Math.max(
          Math.min(this.currentSliderIndex + step, this.videoRef.length - 1),
          0
        );
      }
      this.playVideo();
    }
  }

  private playVideo(): void {
    this.currentVideo = this.videoRef.get(this.currentVideoIndex).nativeElement;
    this.currentVideo.addEventListener('ended', this.endedListener.bind(this));
    this.prizeQrToggleSub?.unsubscribe();

    if (this.currentVideo.readyState === 4) {
      this.currentVideo.play();
    } else {
      this.currentVideo.addEventListener('loadeddata', this.play.bind(this));
    }
  }

  private endedListener(): void {
    this.prizeQrSliderInterval(this.tournament.slideTime);
    this.currentVideo.removeEventListener(
      'ended',
      this.endedListener.bind(this)
    );
  }

  private play(): void {
    this.currentVideo.play();
    this.currentVideo.removeEventListener('loadeddata', this.play);
  }

  swipe(e: TouchEvent, when: string): void {
    if (!this.isMobile) {
      return;
    }
    const coord: [number, number] = [
      e.changedTouches[0].pageX,
      e.changedTouches[0].pageY,
    ];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [
        coord[0] - this.swipeCoord[0],
        coord[1] - this.swipeCoord[1],
      ];
      const duration = time - this.swipeTime;
      if (
        duration < 1000 &&
        Math.abs(direction[0]) > 30 &&
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        if (direction[0] < 0) {
          //next
          this.slide(1, true);
        } else {
          //previous
          this.slide(-1, true);
        }
      }
    }
  }
}
