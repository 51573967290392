import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONTENT_TYPE, SKIP_AUTH_HEADER } from '../constants/headers';
import { environment } from '../../../environments/environment';
import {
  BookRegion,
  SportBook,
  UserBookAccounts,
} from '../types/sharp-sports/sport-books';
import { Pageable } from '../types/pageable';
import { map, shareReplay, tap } from 'rxjs/operators';
import {
  BetSkipsGraphRecord,
  BetSlips,
  BetSlipsFilter,
  BetSlipsGroups,
  DailyBetSips,
  OpenSettledCurrentBetSlips,
} from '../types/sharp-sports/bet-slips';
import {
  InternalBettor,
  SharpSportsUserMetadata,
} from '../types/sharp-sports/sharp-sports';
import { getDateNDaysAgo, getTodayDateAtMidnight } from '../helpers/date-time';
import { BetSlipsType } from '../enum/bet-slip-type';

@Injectable({
  providedIn: 'root',
})
export class SharpSportsService {
  private apiUrl = `${environment.apiUrl}/client/sharpsports`;
  public selectedFilters: BetSlipsFilter = {
    league: [],
    marketProposition: [],
    outcome: [],
    type: [],
    startDate: getDateNDaysAgo(7),
    endDate: getTodayDateAtMidnight('end').timestamp,
    status: BetSlipsType.open,
  };
  public skippedAccountConnect = false;
  public redirectUrl?: string;
  public connectedAccountsSize = 0;

  constructor(private httpClient: HttpClient) {}

  public getPopupCid(token: string, body: string): Observable<{ cid: string }> {
    return this.httpClient.post<{ cid: string }>(
      'https://api.sharpsports.io/v1/context',
      body,
      {
        headers: {
          [SKIP_AUTH_HEADER]: '',
          ['Authorization']: `Token ${token}`,
          [CONTENT_TYPE]: 'application/json',
        },
      }
    );
  }

  public getSportBooks(): Observable<SportBook[]> {
    return this.httpClient
      .get<Pageable<SportBook>>(
        `${this.apiUrl}/books?page.index=0&page.size=100`
      )
      .pipe(map((res: Pageable<SportBook>) => res.items));
  }

  public getBookRegions(
    bookId: string,
    status: 'active' | 'unsupported'
  ): Observable<BookRegion[]> {
    return this.httpClient
      .get<Pageable<BookRegion>>(
        `${this.apiUrl}/book-regions?page.index=0&page.size=100&book=${bookId}&status=${status}`
      )
      .pipe(map((res: Pageable<BookRegion>) => res.items));
  }

  public getUserAccounts(userId: string): Observable<UserBookAccounts[]> {
    return this.httpClient
      .get<Pageable<UserBookAccounts>>(
        `${this.apiUrl}/bettors/${userId}/accounts?page.index=0&page.size=100`
      )
      .pipe(
        tap((a) => (this.connectedAccountsSize = a.items.length)),
        shareReplay(1),
        map((res: Pageable<UserBookAccounts>) => res.items)
      );
  }

  public deleteUserAccount(id: string): Observable<{
    message: string;
  }> {
    return this.httpClient.delete<{ message: string }>(
      `${this.apiUrl}/accounts/${id}`
    );
  }

  public reVerifyUserAccount(id: string): Observable<{
    message: string;
  }> {
    return this.httpClient.post<{ message: string }>(
      `${this.apiUrl}/accounts/${id}/re-verify`,
      null
    );
  }

  public getUserCurrentBetSlips(
    userId: string,
    timeStamp: number
  ): Observable<OpenSettledCurrentBetSlips> {
    return this.httpClient.get<OpenSettledCurrentBetSlips>(
      `${this.apiUrl}/bettors/${userId}/bet-slips-current?currentDate=${timeStamp}`
    );
  }

  public getUserMetadata(userId: string): Observable<SharpSportsUserMetadata> {
    return this.httpClient.get<SharpSportsUserMetadata>(
      `${this.apiUrl}/bettors/${userId}/metadata`
    );
  }

  public checkForSharpSportsUser(userId: string): Observable<boolean> {
    return this.httpClient
      .get<InternalBettor>(`${this.apiUrl}/internal-bettors/${userId}`)
      .pipe(map((res: InternalBettor) => !!res?.id));
  }

  public getBetSlipsByDate(
    startDate: number,
    endDate: number,
    filterParam: string = ''
  ): Observable<Pageable<BetSlips>> {
    return this.httpClient.get<Pageable<BetSlips>>(
      `${this.apiUrl}/bettors/bet-slips?startDate=${startDate}&endDate=${endDate}&page.size=20${filterParam}`
    );
  }

  public getDailyBetSlips(
    startDate: number,
    endDate: number
  ): Observable<DailyBetSips[]> {
    return this.httpClient.get<DailyBetSips[]>(
      `${this.apiUrl}/bettors/daily-bet-slips?startDate=${startDate}&endDate=${endDate}`
    );
  }

  public getBetSlipsGraph(
    startDate: number,
    endDate: number,
    filterParam: string = ''
  ): Observable<BetSkipsGraphRecord> {
    return this.httpClient.get<BetSkipsGraphRecord>(
      `${this.apiUrl}/bettors/bet-slips-graph?startDate=${startDate}&endDate=${endDate}${filterParam}`
    );
  }

  public getGroupedBetSlips(
    startDate: number,
    endDate: number
  ): Observable<BetSlipsGroups> {
    return this.httpClient.get<BetSlipsGroups>(
      `${this.apiUrl}/bettors/grouped-bet-slips?startDate=${startDate}&endDate=${endDate}`
    );
  }
}
