import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pageable } from '../types/pageable';
import {
  AnswerRequest,
  Prediction,
  PredictionAnswers,
  UserPrediction,
  UserPredictionStatus,
} from '../types/predictions';
import { environment } from '../../../environments/environment';
import { Leaderboard, LeaderboardScores } from '../types/leaderboards';
import { ResponseView } from '../enum/response-view';
import { finalize, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoaderService } from './loader.service';

@Injectable()
export class PredictAndWinService {
  public currentLeaderBoardId: string;
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private loaderService: LoaderService
  ) {}

  public getPredictions(): Observable<Pageable<Prediction>> {
    return this.httpClient.get<Pageable<Prediction>>(
      `${environment.apiUrl}/client/predictions?page.index=0&page.size=1000`
    );
  }

  public getPredictionAnswers(): Observable<PredictionAnswers[]> {
    return this.httpClient
      .get<Pageable<PredictionAnswers>>(
        `${environment.apiUrl}/client/prediction-answers`
      )
      .pipe(map((res: Pageable<PredictionAnswers>) => res.items));
  }

  public getLeaderboards(): Observable<Pageable<Leaderboard>> {
    return this.httpClient.get<Pageable<Leaderboard>>(
      `${environment.apiUrl}/client/question-leaderboards?page.index=0&page.size=1000`
    );
  }

  public getCurrentLeaderboard(): Observable<Leaderboard> {
    return this.httpClient
      .get<Pageable<Leaderboard>>(
        `${environment.apiUrl}/client/question-leaderboards?page.index=0&page.size=1000`
      )
      .pipe(
        map((leaderboards: Pageable<Leaderboard>) => {
          const now = Date.now();
          return leaderboards.items.find(
            (board: Leaderboard) => now >= board.startAt && now <= board.endAt
          );
        })
      );
  }

  public submitAnswer(answer: AnswerRequest): Observable<{ message: string }> {
    return this.httpClient.post<{ message: string }>(
      `${environment.apiUrl}/client/my-predictions`,
      answer
    );
  }

  public getLeaderboardScores(
    leaderboardId: string,
    currentPage: number = 0,
    resView: ResponseView = ResponseView.full
  ): Observable<Pageable<LeaderboardScores>> {
    return this.httpClient.get<Pageable<LeaderboardScores>>(
      `${environment.apiUrl}/client/question-leaderboards/${leaderboardId}/scores?page.index=${currentPage}&page.size=10&response.view=${resView}`
    );
  }

  public getUserPredictionsFilteredByStatus(
    page: number = 0,
    status: UserPredictionStatus = 'resolved',
    pageSize: number = 10
  ): Observable<Pageable<UserPrediction>> {
    return this.httpClient
      .get<Pageable<UserPrediction>>(
        `${environment.apiUrl}/client/my-predictions?page.index=${page}&page.size=${pageSize}`
      )
      .pipe(
        map((res: Pageable<UserPrediction>) => ({
          ...res,
          items: res.items.filter(
            (item: UserPrediction) => item.status === status
          ),
        }))
      );
  }

  public markPredictionAsSeen(
    predictionId: string
  ): Observable<{ message: string }> {
    return this.httpClient.put<{ message: string }>(
      `${environment.apiUrl}/client/my-predictions/${predictionId}/see`,
      null
    );
  }

  public checkNotifications(): void {
    this.getUserPredictionsFilteredByStatus(0, 'resolved', 20).subscribe(
      (res: Pageable<UserPrediction>) => {
        if (
          res?.items.some((prediction: UserPrediction) => !prediction.isSeen)
        ) {
          this.router.navigateByUrl('my/predict-and-win');
        }
      }
    );
  }
}
