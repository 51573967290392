import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Event } from '../../types/event';
import { SeasonTicketsService } from '../../services/season-tickets.service';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { regexp } from '../../validators/regexp';
import { BaseComponent } from '../base/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-general-ticket-form',
  templateUrl: './general-ticket-form.component.html',
  styleUrls: ['./general-ticket-form.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
})
export class GeneralTicketFormComponent
  extends BaseComponent
  implements OnInit
{
  @Input() public event: Event;
  @Input() public isMobile = true;
  @Output() public valueChange$: EventEmitter<number> =
    new EventEmitter<number>();
  public seatCountFormControl = this.formBuilder.control(1, [
    Validators.pattern(regexp.positiveWholeNumber),
  ]);
  public submitted = false;

  constructor(
    private seasonTicketsService: SeasonTicketsService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.seatCountFormControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        this.valueChange$.next(val);
      });
  }

  public submitSeatCount(): void {
    const seatsCount = this.seatCountFormControl.value;
    if (seatsCount) {
      this.seasonTicketsService.submitGeneralForm({
        seatsCount,
        showStripe: true,
      });
      this.submitted = true;
      this.seatCountFormControl.disable();
    }
  }

  public editSeatCount(): void {
    const seatsCount = this.seatCountFormControl.value;
    this.seasonTicketsService.submitGeneralForm({
      seatsCount,
      showStripe: false,
    });
    this.submitted = false;
    this.seatCountFormControl.enable();
  }
}
