import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GoogleSheet, GoogleSheetEmailBody } from '../types/google-sheet';

@Injectable({
  providedIn: 'root',
})
export class GoogleSheetService {
  constructor(private httpClient: HttpClient) {}

  public addGoogleSheet(
    rowData: GoogleSheet[]
  ): Observable<{ message: string }> {
    return this.httpClient.post<{ message: string }>(
      `${environment.apiUrl}/client/google-sheet`,
      rowData
    );
  }

  public sendEmail(
    emailData: GoogleSheetEmailBody
  ): Observable<{ message: string }> {
    return this.httpClient.post<{ message: string }>(
      `${environment.apiUrl}/client/send-email`,
      emailData
    );
  }
}
