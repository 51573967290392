import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToText',
  standalone: true,
})
export class CamelCaseToTextPipe implements PipeTransform {
  transform(str: string): string {
    const upperCaseCharIndex = str
      .split('')
      .findIndex((char: string) => char === char.toUpperCase());
    if (upperCaseCharIndex === -1) {
      return `${str[0].toUpperCase()}${str.slice(1)}`;
    }
    return `${str[0].toUpperCase()}${str.slice(1, upperCaseCharIndex)} ${str
      .slice(upperCaseCharIndex)
      .toLowerCase()}`;
  }
}
