import {Component, OnInit} from '@angular/core';

import {
  BuySeasonTicketBaseComponent
} from '../../../../../../../common/base-controllers/buy-season-ticket-base.component';
import {SeasonTicketsService} from '../../../../../../../common/services/season-tickets.service';
import {Router} from '@angular/router';
import {LoaderService} from '../../../../../../../common/services/loader.service';

@Component({
  selector: 'app-buy-season-ticket',
  templateUrl: './buy-season-ticket.component.html',
  styleUrls: ['./buy-season-ticket.component.scss'],
})
export class BuySeasonTicketComponent extends BuySeasonTicketBaseComponent implements OnInit {
  constructor(
    seasonTicketsService: SeasonTicketsService,
    router: Router,
    loaderService: LoaderService
  ) {
    super(seasonTicketsService, router, loaderService);
  }

  ngOnInit(): void {
    this.getEventsAndCategories().subscribe();
  }
}
