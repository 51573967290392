import {Component, OnInit} from '@angular/core';
import {BreadcrumbBackground} from '../enum/breadcrumb-background';
import {finalize, takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../components/base/base.component';
import {PurchasedTicket, SharedTicket} from '../types/user';
import {TicketsService} from '../services/tickets.service';
import {ActivatedRoute} from '@angular/router';
import {LayoutConfigService} from '../services/layout-config.service';
import {DatePipe} from '@angular/common';
import {LoaderService} from '../services/loader.service';
import {zip} from 'rxjs';
import {TicketStatus} from '../enum/ticket-status';
import {FilterConfig} from '../../mobile/modules/main/pages/season-ticket/components/items-filter/filter-config';

@Component({
  template: ``,
})
export abstract class SeasonTicketPreviewBaseComponent
  extends BaseComponent
  implements OnInit {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  public ticket: PurchasedTicket;
  public currentSelectedMonth: string;
  public filterType: FilterConfig[] = [];
  public sharedTickets: SharedTicket[];

  constructor(
    private ticketService: TicketsService,
    private activatedRoute: ActivatedRoute,
    private layoutConfigService: LayoutConfigService,
    private datePipe: DatePipe,
    private loaderService: LoaderService
  ) {
    super();
  }

  ngOnInit() {
    this.fetchTickets();
  }

  public handleSelectTicketType(ticketType: string): void {
    this.currentSelectedMonth = ticketType;
  }

  private fetchTickets(): void {
    const ticketId = this.activatedRoute.snapshot.paramMap.get('ticketId');
    if (!ticketId) {
      return;
    }
    this.loaderService.show();
    zip([
      this.ticketService.getUserTicketsById(ticketId),
      this.ticketService.getUserSharedTickets(),
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe(
        ([ticket, sharedTicket]: [PurchasedTicket, SharedTicket[]]) => {
          this.sharedTickets = sharedTicket.filter(
            (st: SharedTicket) =>
              st.ticket === ticket.id &&
              (st.status === TicketStatus.accepted ||
                st.status === TicketStatus.pending)
          );
          this.ticket = ticket;
          this.fetchFilterItems();
          if (this.filterType?.length) {
            this.currentSelectedMonth = this.filterType[0].value;
          }
        }
      );
  }

  private fetchFilterItems(): void {
    this.ticket.dates.forEach((d: string | {date: string}) => {
      const value = this.datePipe.transform(typeof d === 'object' ? d.date : d, 'MMM');
      if (
        !this.filterType.some(
          (config: FilterConfig) => config && config.value === value
        )
      ) {
        this.filterType.push({value});
      }
    });
  }

}
