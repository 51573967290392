import { NgModule } from '@angular/core';
import { MobileRoutingModule } from './mobile-routing.module';
import { BaseComponent } from '../common/components/base/base.component';
import { LoaderComponent } from '../common/components/loader/loader.component';
import { MobileComponent } from './mobile.component';

@NgModule({
  declarations: [BaseComponent, MobileComponent],
  imports: [MobileRoutingModule, LoaderComponent],
  providers: [],
  bootstrap: [],
})
export class MobileModule {}
