import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticPages } from '../types/static-pages';
import { Pageable } from '../types/pageable';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StaticPagesService {
  public staticPages: StaticPages[];

  constructor(private httpclient: HttpClient) {}

  public getStaticPages(): Observable<Pageable<StaticPages>> {
    return this.httpclient
      .get<Pageable<StaticPages>>(`${environment.apiUrl}/client/static-pages`)
      .pipe(tap((res) => (this.staticPages = res.items)));
  }
}
