import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from '../../services/loader.service';
import { LoaderState } from '../../types/loader-state';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class LoaderComponent extends BaseComponent implements OnInit {
  public show = false;

  constructor(
    private loaderService: LoaderService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.loaderService.loaderState
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state: LoaderState) => {
        this.show = state.show;
        this.cd.detectChanges();
      });
  }
}
