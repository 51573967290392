import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuDesktopComponent } from './menu-desktop.component';
import { RestaurantDesktopPageComponent } from './pages/restaurant-page/restaurant-desktop-page.component';
import { menu, restaurant } from '../../../common/constants/breadcrumbs';
import { RestaurantDesktopMenuPageComponent } from './pages/restaurant-menu-page/restaurant-desktop-menu-page.component';

const routes: Routes = [
  {
    path: '',
    component: MenuDesktopComponent,
    children: [
      {
        path: '',
        data: { breadcrumb: restaurant },
        component: RestaurantDesktopPageComponent,
      },
      {
        path: ':id',
        data: { breadcrumb: menu },
        component: RestaurantDesktopMenuPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class MenuDesktopRoutingModule {}
