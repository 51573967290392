import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../components/base/base.component';
import { VENUE_ID } from '../constants/storage-keys';
import { VenueTournament } from '../types/sharp-sports/venue-tournament';
import {
  TournamentLeaderboard,
  TournamentWinner,
} from '../types/sharp-sports/tournament-leaderboard';
import { User } from '../types/user';
import { interval, of, Subscription, timer, zip } from 'rxjs';
import { PopupService } from '../services/popup.service';
import { VenueLeaderboardService } from '../services/venue-leaderboard.service';
import { LoaderService } from '../services/loader.service';
import { StorageService } from '../services/storage.service';
import { Geofence, Venue } from '../types/sharp-sports/venues';
import {
  catchError,
  filter,
  finalize,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { BuilderLeaderboardTabs } from '../enum/builder-leaderboard';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { SharpSportsService } from '../services/sharp-sports.service';
import { UserBookAccounts } from '../types/sharp-sports/sport-books';
import { getUserCoords } from '../helpers/postMessage';
import { CommunicationService } from '../services/communication.service';
import { GeoLocationCoord } from '../types/event';
import { isMobileOrTablet } from '../helpers/point';

enum PageView {
  leaderboard = 'leaderboard',
  nextLeaderboard = 'next',
  winner = 'winner',
  noData = 'no-data',
}

@Component({
  template: ``,
})
export abstract class BuilderLeaderboardBaseComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public builderLeaderboardTabs = BuilderLeaderboardTabs;
  public currentTab = BuilderLeaderboardTabs.prizes;
  public currentView = PageView.nextLeaderboard;
  public pageView = PageView;
  public selectedVenueId: string =
    this.activatedRoute.snapshot.queryParams?.venueId ||
    this.storageService.getFromStorage(VENUE_ID) ||
    null;
  public currentTournament: VenueTournament;
  public lastFinishedTournament: VenueTournament;
  public nextTournament: VenueTournament;
  public currentLeaderboard: TournamentLeaderboard[];
  public lastFinishedLeaderboard: TournamentLeaderboard[];
  public winner: User;
  private showingWinnerBannerTimerSub: Subscription;
  public isMobile = isMobileOrTablet();
  public now: number;
  private user: User = this.userService.user;
  private leaderboardReFetchSub!: Subscription;
  private twoAndHalfMinutesTimestamp = 60 * 2.5 * 1000;
  public userHasConnectedAccount = false;

  protected constructor(
    private popupService: PopupService,
    private venueLeaderboardService: VenueLeaderboardService,
    private loaderService: LoaderService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private sharpSportsService: SharpSportsService,
    private communicationService: CommunicationService
  ) {
    super();
  }

  ngOnInit() {
    this.sharpSportsService
      .getUserAccounts(this.user.id)
      .pipe(map((a: UserBookAccounts[]) => a?.length > 0))
      .subscribe((res: boolean) => (this.userHasConnectedAccount = res));
    if (!this.selectedVenueId) {
      return;
    }
    this.getTournaments();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.storageService.removeFromStorage(VENUE_ID);
    this.resetPageData();
  }

  public connectToLeaderBoard(): void {
    if (!this.userHasConnectedAccount) {
      this.router.navigateByUrl('my/builder/intro');
      return;
    }
    this.loaderService.show();
    this.communicationService.geolocationData
      .pipe(
        tap((data) => {
          this.connectRequest(data);
        }),
        catchError((message: string) => {
          this.loaderService.hide();
          this.popupService.showLocationPermissionRejectPopup(this.isMobile);
          return of();
        })
      )
      .subscribe();
    getUserCoords();
    // this.popupService
    //   .showLocationPermissionPopup(this.isMobile)
    //   .action$.subscribe((res: ActionType) => {
    //     if (res === ActionType.Reject) {
    //       this.popupService.showLocationPermissionRejectPopup(this.isMobile);
    //       return;
    //     }
    //   });
  }

  private connectRequest(geoLoc: GeoLocationCoord): void {
    const payload: Geofence = {
      latitude: geoLoc.latitude,
      longitude: geoLoc.longitude,
    };
    this.venueLeaderboardService
      .connectToTournament(this.currentTournament.id, payload)
      .pipe(
        switchMap(() =>
          this.venueLeaderboardService.getTournamentLeaderboard(
            this.currentTournament?.id
          )
        ),
        takeUntil(this.unsubscribe$),
        finalize(() => {
          this.loaderService.hide();
        })
      )
      .subscribe((current: TournamentLeaderboard[]) => {
        // Todo
        // if (current.length > 1) {
        //   this.sortLeaderboard(current);
        // }
        this.currentLeaderboard = current;
        if (this.isMobile) {
          this.currentTab = BuilderLeaderboardTabs.leaderboard;
        }
      });
  }

  private getTournaments(): void {
    this.loaderService.show();
    this.venueLeaderboardService
      .getTournaments(this.selectedVenueId)
      .pipe(
        switchMap((t: VenueTournament[]) => {
          this.findCurrentLeaderboard(t);
          return zip(
            this.currentTournament
              ? this.venueLeaderboardService.getTournamentLeaderboard(
                  this.currentTournament?.id
                )
              : of(null),
            this.lastFinishedTournament
              ? this.venueLeaderboardService.getTournamentLeaderboard(
                  this.lastFinishedTournament?.id
                )
              : of(null)
          );
        }),
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe(([current, last]) => {
        if (current) {
          // Todo
          // if (current.length > 1) {
          //   this.sortLeaderboard(current);
          // }
          this.currentLeaderboard = current;
          this.reFetchLeaderboardData();
        }
        if (last) {
          this.lastFinishedLeaderboard = last;
          if (this.isMobile) {
            this.currentTab = BuilderLeaderboardTabs.prizes;
          }
          this.checkForFinishedLeaderboards();
          this.findWinner();
          return;
        }
        this.setCurrentView();
      });
  }

  private sortLeaderboard(current: TournamentLeaderboard[]): void {
    const me = current.splice(
      current.findIndex(
        (c: TournamentLeaderboard) => c.user.id === this.user.id
      ),
      1
    )[0];
    for (let i = 0; i < current.length; i++) {
      if (me.score > current[0].score) {
        current.unshift(me);
        break;
      }
      if (current[i].score === me.score) {
        current.splice(i, 0, me);
        break;
      }
      if (i === current.length - 1) {
        current.push(me);
        break;
      }
    }
  }

  private setCurrentView(): void {
    switch (true) {
      case !!this.currentLeaderboard:
        this.currentView = PageView.leaderboard;
        if (this.isMobile) {
          this.currentTab = this.isConnectedToLeaderboard()
            ? BuilderLeaderboardTabs.leaderboard
            : BuilderLeaderboardTabs.prizes;
        }
        break;
      case !!this.nextTournament:
        this.currentView = PageView.nextLeaderboard;
        if (this.isMobile) {
          this.currentTab = BuilderLeaderboardTabs.prizes;
        }
        break;
      default:
        this.currentView = PageView.noData;
        if (this.isMobile) {
          this.currentTab = BuilderLeaderboardTabs.prizes;
        }
    }
  }

  private checkForFinishedLeaderboards(): void {
    this.now = Date.now();
    if (
      !this.lastFinishedLeaderboard &&
      this.lastFinishedTournament.tournamentEndDate +
        this.lastFinishedTournament.postTournamentViewTime * 1000 <
        this.now
    ) {
      return;
    }
    this.currentView = PageView.winner;
    this.showingWinnerBannerTimerSub = timer(
      this.lastFinishedTournament.tournamentEndDate +
        this.lastFinishedTournament.postTournamentViewTime * 1000 -
        this.now
    ).subscribe(() => {
      this.setCurrentView();
    });
  }

  private findCurrentLeaderboard(tournament: VenueTournament[]): void {
    const now = Date.now();

    const sortedByStartDate = tournament.sort(
      (a: VenueTournament, b: VenueTournament) =>
        a.tournamentStartDate - b.tournamentStartDate
    );

    const sortedByEndDate = tournament.sort(
      (a: VenueTournament, b: VenueTournament) =>
        b.tournamentEndDate - a.tournamentEndDate
    );

    const currentTournamentIndex = sortedByStartDate.findIndex(
      (t: VenueTournament) =>
        t.tournamentEndDate > now && now > t.tournamentStartDate
    );

    const nextTournamentIndex = sortedByStartDate.findIndex(
      (t: VenueTournament) => t.tournamentStartDate > now
    );

    const lastFinishedTournamentIndex = sortedByEndDate.findIndex(
      (t: VenueTournament) => now > t.tournamentEndDate
    );

    if (currentTournamentIndex !== -1) {
      this.currentTournament = tournament[currentTournamentIndex];
    }

    if (nextTournamentIndex !== -1) {
      this.nextTournament = tournament[nextTournamentIndex];
    }

    if (lastFinishedTournamentIndex !== -1) {
      this.lastFinishedTournament = tournament[lastFinishedTournamentIndex];
    }
  }

  public venueSelectHandler(venue: Venue): void {
    this.selectedVenueId = venue.id;
    this.storageService.saveToStorage(VENUE_ID, this.selectedVenueId);
    this.loaderService.show();
    this.getTournaments();
  }

  private findWinner() {
    if (
      this.currentView === this.pageView.leaderboard ||
      (this.lastFinishedLeaderboard?.length || 0) === 0
    ) {
      this.setCurrentView();
      return;
    }
    this.loaderService.show();
    this.venueLeaderboardService
      .getTournamentWinner(this.lastFinishedLeaderboard[0]?.tournamentId)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => !!res || this.setCurrentView()),
        filter((res) => !!res),
        finalize(() => this.loaderService.hide())
      )
      .subscribe((res: TournamentWinner) => (this.winner = res.user));
  }

  public onNextEventClockEnd(): void {
    this.resetPageData();
    this.getTournaments();
  }

  public onTournamentEnd(): void {
    this.resetPageData();
    this.getTournaments();
  }

  public backToSelectVenues(): void {
    this.storageService.removeFromStorage(VENUE_ID);
    this.selectedVenueId = null;
    this.resetPageData();
    if (this.activatedRoute.snapshot.queryParams?.venueId) {
      this.router.navigateByUrl('my/builder/dashboard/leaderboard');
    }
  }

  private resetPageData(): void {
    this.currentTournament = null;
    this.lastFinishedTournament = null;
    this.currentLeaderboard = null;
    this.lastFinishedLeaderboard = null;
    this.winner = null;
    this.nextTournament = null;
    this.showingWinnerBannerTimerSub?.unsubscribe();
    this.leaderboardReFetchSub?.unsubscribe();
  }

  private isConnectedToLeaderboard(): boolean {
    if (!this.currentLeaderboard) {
      return false;
    }
    return this.currentLeaderboard.some(
      (leaderboard) => leaderboard.user.id === this.user.id
    );
  }

  private reFetchLeaderboardData(): void {
    this.leaderboardReFetchSub = interval(this.twoAndHalfMinutesTimestamp)
      .pipe(
        shareReplay(1),
        takeWhile(() => !!this.currentTournament?.id),
        switchMap(() =>
          this.currentTournament
            ? this.venueLeaderboardService.getTournamentLeaderboard(
                this.currentTournament?.id
              )
            : of(null)
        ),
        finalize(() => {
          this.leaderboardReFetchSub.unsubscribe();
        })
      )
      .subscribe((updatedCurrentLeaderboard) => {
        // Todo
        // if (updatedCurrentLeaderboard.length > 1) {
        //   this.sortLeaderboard(updatedCurrentLeaderboard);
        // }
        this.currentLeaderboard = updatedCurrentLeaderboard;
      });
  }

  @HostListener('document:visibilitychange')
  private onTabFocus(): void {
    if (
      document.visibilityState === 'visible' &&
      !!this.lastFinishedLeaderboard
    ) {
      this.showingWinnerBannerTimerSub.unsubscribe();
      this.checkForFinishedLeaderboards();
    }
  }
}
