import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FireBaseService {
  public confirm: (
    verificationCode: string
  ) => Promise<firebase.auth.UserCredential>;
  public phoneRegistered = false;
  public currentRecaptcha: firebase.auth.RecaptchaVerifier;

  constructor(public auth: AngularFireAuth) {}

  public signInWithPhoneNumber(phoneNumber: string): Promise<void | Error> {
    if (this.currentRecaptcha) {
      this.currentRecaptcha.clear();
      this.currentRecaptcha = null;
    }
    return this.auth
      .signInWithPhoneNumber(
        phoneNumber,
        this.currentRecaptcha || this.recaptchaVerifier()
      )
      .then(({ confirm }) => {
        this.confirm = confirm;
      })
      .catch((e) => new Error(e));
  }

  public async signOut(): Promise<void> {
    await this.auth.signOut();
  }

  public verifyPhoneNumber(phoneNumber: string): Promise<string> {
    const appVerifier = this.recaptchaVerifier();

    const provider = new firebase.auth.PhoneAuthProvider();
    return provider.verifyPhoneNumber(phoneNumber, appVerifier);
  }

  public fireBaseUser(): Observable<firebase.User> {
    return this.auth.user;
  }

  private recaptchaVerifier(): firebase.auth.RecaptchaVerifier {
    this.currentRecaptcha = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      }
    );
    return this.currentRecaptcha;
  }
}
