import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProsNewsType, ProsNews } from '../types/pro-news';
import { Pageable } from '../types/pageable';
import { map } from 'rxjs/operators';
import { FilterConfig } from '../../mobile/modules/main/pages/season-ticket/components/items-filter/filter-config';

@Injectable({
  providedIn: 'root',
})
export class CommunityService {
  private selectNews: Subject<ProsNews> = new Subject<ProsNews>();
  public selectNews$: Observable<ProsNews> = this.selectNews.asObservable();

  constructor(private httpClient: HttpClient) {}

  public getProsNewsTypes(): Observable<ProsNewsType[]> {
    return this.httpClient
      .get<Pageable<ProsNewsType>>(
        `${environment.apiUrl}/client/pros-news-types`
      )
      .pipe(map((data: Pageable<ProsNewsType>) => data.items));
  }

  public getProsNewsByTypeId(
    id: string,
    page: number = 0,
    size: number = 10
  ): Observable<Pageable<ProsNews>> {
    return this.httpClient.get<Pageable<ProsNews>>(
      `${environment.apiUrl}/client/pros-news/types/${id}?page.index=${page}&page.size=${size}`
    );
  }

  public getAllProsNews(
    page: number = 0,
    size: number = 10
  ): Observable<Pageable<ProsNews>> {
    return this.httpClient.get<Pageable<ProsNews>>(
      `${environment.apiUrl}/client/pros-news?page.index=${page}&page.size=${size}`
    );
  }

  public selectNewsHandler(news: ProsNews | null): void {
    this.selectNews.next(news);
  }
}
