import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventTime',
  standalone: true,
})
export class EventTimePipe implements PipeTransform {
  transform(minutes: number, duration?: number): string {
    const hour = Math.floor((duration ? minutes + duration : minutes) / 60);
    const minute = Math.floor((duration ? minutes + duration : minutes) % 60);
    return `${this.getHour(hour)}:${this.getMinute(minute)} 
    ${this.getMeridian(hour)}`;
  }

  private getHour(hour: number): string {
    return `${hour < 10 ? '0' + hour : hour % 12}`;
  }

  private getMinute(minute: number): string {
    return `${minute < 10 ? '0' + minute : minute}`;
  }

  private getMeridian(hour: number): string {
    return `${hour >= 12 ? 'PM' : 'AM'}`;
  }
}
