import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WelcomeDrinkComponent } from '../../../common/welcome-drink/welcome-drink.component';
import { WelcomeDrinkDesktopPageComponent } from './welcome-drink-desktop-page.component';
import { HeaderDesktopComponent } from '../components/layout/header/header-desktop.component';
import { FooterDesktopComponent } from '../components/layout/footer/footer-desktop.component';

const route = [
  {
    path: '',
    component: WelcomeDrinkDesktopPageComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WelcomeDrinkComponent,
    HeaderDesktopComponent,
    FooterDesktopComponent,
    RouterModule.forChild(route),
  ],
  declarations: [WelcomeDrinkDesktopPageComponent],
  providers: [],
})
export class WelcomeDrinkDesktopPageModule {}
