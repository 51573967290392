import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../../common/components/base/base.component';
import { RestaurantMenuService } from '../../../../../common/services/restaurant-menu.service';
import { Assets } from '../../../../../common/types/assets';
import { RestaurantMenu } from '../../../../../common/types/restaurant-menu';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { updateBreadcrumb } from '../../../../../common/helpers/breadcrumb';

@Component({
  selector: 'app-restaurant-menu-page',
  templateUrl: './restaurant-desktop-menu-page.component.html',
  styleUrls: ['./restaurant-desktop-menu-page.component.scss'],
})
export class RestaurantDesktopMenuPageComponent
  extends BaseComponent
  implements OnInit
{
  public assets: Assets;

  constructor(
    private activatedRoute: ActivatedRoute,
    private restaurantMenuService: RestaurantMenuService,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService
  ) {
    super();
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params.id;
    this.restaurantMenuService
      .getMenuById(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: RestaurantMenu) => {
        this.assets = res.assets;
        updateBreadcrumb('menu', res.name, this.ngDynamicBreadcrumbService);
      });
  }
}
