import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { fromEvent } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import { map, takeUntil } from 'rxjs/operators';
import { CarouselItemDirective } from './carousel-item.directive';
import { Direction } from './direction';

@Component({
  selector: 'app-carousel-wrapper',
  templateUrl: './carousel-wrapper.component.html',
  standalone: true,
  imports: [CommonModule],
  styleUrls: ['./carousel-wrapper.component.scss'],
})
export class CarouselWrapperComponent extends BaseComponent implements OnInit {
  @ViewChild('carouselWrapper', { read: ElementRef, static: true })
  public carouselWrapper: ElementRef<HTMLUListElement>;

  @ContentChild(CarouselItemDirective, { read: ElementRef })
  private carouselItem!: ElementRef;

  @Input() public wrapperClass: string;
  @Input() public itemsLength: number;
  @Input() public elementsDistance = 3;

  public direction = Direction;
  public canScrollLeft = false;
  public canScrollRight = true;
  public nextIndex = 0;

  constructor() {
    super();
  }

  ngOnInit(): void {
    const element = this.carouselWrapper.nativeElement.children[0];
    fromEvent(element, 'scroll')
      .pipe(
        map(({ target }) => (target as HTMLDivElement).scrollLeft),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((scrollLeft) => {
        this.canScrollLeft = scrollLeft > 0;
        this.canScrollRight =
          scrollLeft + element.getBoundingClientRect().width <
          element.scrollWidth;
      });
  }

  public handleNavigationClick(direction: Direction, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.carouselWrapper || !this.carouselItem) {
      return;
    }
    const scrollWrapper = this.carouselWrapper.nativeElement.children[0];
    const listItemWidth =
      this.carouselItem.nativeElement?.getBoundingClientRect().width ?? 0;
    const showedItemsCount = Math.ceil(
      scrollWrapper.getBoundingClientRect().width / listItemWidth
    );
    this.nextIndex = Math.min(
      showedItemsCount,
      Math.max(0, this.nextIndex + direction)
    );
    scrollWrapper?.scroll(
      this.nextIndex * (listItemWidth + this.elementsDistance),
      0
    );
  }
}
