import {Component} from '@angular/core';
import {LayoutDecorator} from '../../../../../../../common/helpers/layout.decorator';
import {
  SeasonTicketReservationBaseComponent
} from '../../../../../../../common/base-controllers/season-ticket-reservation-base.component';
import {ReceivedTicket} from '../../../../../../../common/types/user';

@Component({
  selector: 'app-season-ticket-reservation-desktop',
  templateUrl: './season-ticket-reservation-desktop.component.html',
  styleUrls: ['./season-ticket-reservation-desktop.component.scss'],
})
@LayoutDecorator('layoutConfigService', {showHeader: true, showFooter: false})
export class SeasonTicketReservationDesktopComponent extends SeasonTicketReservationBaseComponent {
  public showReceivedTicketDesktopPopup(ticket: ReceivedTicket): void {
    this.showReceivedTicketPopup(ticket, false);
  }
}
