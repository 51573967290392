import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '../../base/base.component';
import { UserBookAccounts } from '../../../types/sharp-sports/sport-books';
import { SharpSportsService } from '../../../services/sharp-sports.service';
import { UserService } from '../../../services/user.service';
import { PopupService } from '../../../services/popup.service';
import { LoaderService } from '../../../services/loader.service';
import { ActionType } from '../../popups/action-response-popup/action-type';
import { Observable, switchMap } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Router, RouterModule } from '@angular/router';
import { ColorGeneratorPipe } from '../../../pipes/builder/color-generator.pipe';
import { ImageUrlFormatterPipe } from '../../../pipes/builder/image-url-formatter.pipe';
import { environment } from '../../../../../environments/environment';
import { openSharpSports } from '../../../helpers/postMessage';
import { TOKEN } from '../../../constants/storage-keys';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-builder-account-shared',
  templateUrl: './builder-account-shared.component.html',
  styleUrls: ['./builder-account-shared.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ColorGeneratorPipe,
    ImageUrlFormatterPipe,
  ],
})
export class BuilderAccountSharedComponent
  extends BaseComponent
  implements OnInit
{
  @Input() isMobile = true;
  @Output() addAccount$: EventEmitter<void> = new EventEmitter<void>();
  public userBookAccounts: UserBookAccounts[];
  public notFoundImages: string[] = [];

  constructor(
    private sharpSportsService: SharpSportsService,
    private userService: UserService,
    private popupService: PopupService,
    private loaderService: LoaderService,
    private router: Router,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit() {
    this.loaderService.show();
    this.getUserAccounts().subscribe();
  }

  public removeAccount(
    bookName: string,
    stateName: string,
    accountId: string
  ): void {
    this.popupService
      .removeSportBookAccountConfirmPopup(bookName, stateName, this.isMobile)
      .action$.subscribe((action: ActionType) => {
        if (action === ActionType.Reject) {
          return;
        }
        this.sharpSportsService
          .deleteUserAccount(accountId)
          .pipe(
            switchMap(() => this.getUserAccounts()),
            takeUntil(this.unsubscribe$)
          )
          .subscribe();
      });
  }

  private getUserAccounts(): Observable<UserBookAccounts[]> {
    return this.sharpSportsService
      .getUserAccounts(this.userService.user.id)
      .pipe(
        tap((res: UserBookAccounts[]) => (this.userBookAccounts = res)),
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      );
  }

  public addAccountClickHandler() {
    if (environment.production) {
      this.isMobile
        ? this.router.navigateByUrl('/my/builder/sharp-sports/book-select')
        : this.addAccount$.emit();
    } else {
      this.loaderService.show();
      openSharpSports({
        uid: this.userService.user?.id,
        token: this.storageService.getFromStorage(TOKEN),
      });
    }
  }

  public reVerifyAccount(accountId: string): void {
    this.sharpSportsService
      .reVerifyUserAccount(accountId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.loaderService.show();
        this.getUserAccounts().subscribe();
      });
  }

  public imageNotFoundHandler(name: string) {
    this.notFoundImages.push(name);
  }
}
