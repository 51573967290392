export enum ReaderMode {
  OnePage = 'one-page',
  TwoPage = 'two-page',
  Panel = 'panel',
  Guided = 'guided',
}

export enum Navigation {
  Left = 1,
  Right,
}

export enum ReaderAction {
  ZoomIn = 1,
  ZoomOut,
  Exit,
  Panel,
  OnePage,
  TwoPages,
  Guided,
}

export enum ReaderCanvasEvents {
  FitCanvasInPage = 1,
  ZoomIn,
  ZoomOut,
  InitializeCanvasContext,
  InitDragScroll,
  NavigateLeft,
  NavigateRight,
  JumpToExactPage,
  InitCurrentPage,
  DrawFirstPanel,
  DrawCurrentPanel,
  UpdatePageForMode,
}
