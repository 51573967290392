export class Point {
  constructor(public x: number = 0, public y: number = 0) {}

  public set(x: number, y: number): void {
    this.x = x;
    this.y = y;
  }

  public setTo(value: number): void {
    this.x = value;
    this.y = value;
  }
}

import isMobile from 'is-mobile';
export const isMobileOrTablet = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
  console.log('isTablet', isTablet);
  if (!isMobile() && !isTablet) {
    return false;
  }
  if (isTablet && screen.availHeight < screen.availWidth) {
    return false;
  }
  return true;
};
