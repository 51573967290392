import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';
import { LayoutDecorator } from '../../../../../../../common/helpers/layout.decorator';

@Component({
  selector: 'app-area-view-page',
  templateUrl: './area-view-page.component.html',
  styleUrls: ['./area-view-page.component.scss'],
})
@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
export class AreaViewPageComponent implements OnInit, OnDestroy {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;

  ngOnInit() {}

  ngOnDestroy() {}
}
