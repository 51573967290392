import { Component } from '@angular/core';
import { User } from '../types/user';
import { BaseComponent } from '../components/base/base.component';
import { UserService } from '../services/user.service';
import { environment } from '../../../environments/environment';

@Component({
  template: '',
})
export abstract class AccountBaseComponent extends BaseComponent {
  public user: User = this.userService.user;
  public bigGameRoomResyKey = environment.bigGameRoomResyKey;

  public constructor(private userService: UserService) {
    super();
  }
}
