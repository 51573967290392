import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupsManagerService } from '../../../services/popups-manager.service';
import { ResponseType } from '../../../enum/popup-types';
import { SanitizeHtmlPipe } from '../../../pipes/sanitize-html.pipe';
import { ActionType } from './action-type';
import { isMobileOrTablet } from '../../../helpers/point';

@Component({
  selector: 'app-action-response-popup',
  templateUrl: './action-response-popup.component.html',
  styleUrls: ['./action-response-popup.component.scss'],
  standalone: true,
  imports: [CommonModule, SanitizeHtmlPipe],
})
export class ActionResponsePopupComponent {
  @Input() public responseType: ResponseType;
  @Input() public title: string;
  @Input() public action: string;
  @Input() public description: string;
  @Input() public rejectText = 'OK';
  @Input() public confirmText?: string;
  @Input() public reward?: number;
  @Input() public icon?: string;

  @Output() action$: EventEmitter<ActionType> = new EventEmitter<ActionType>();

  public isMobile: boolean = isMobileOrTablet();
  public ActionType = ActionType;
  public responseEnum = ResponseType;
  public responseText = {
    success: 'Confirmed',
    error: 'Declined',
    info: 'info',
    'error network': 'Error',
  };

  constructor(private popupsManagerService: PopupsManagerService) {}

  public handleAction(action: ActionType = ActionType.Reject): void {
    this.popupsManagerService.hide();
    this.action$?.emit(action);
  }
}
