import { Pipe, PipeTransform } from '@angular/core';
import { UserBookAccounts } from '../../types/sharp-sports/sport-books';

@Pipe({
  name: 'accountNotVerified',
  standalone: true,
})
export class AccountNotVerifiedPipe implements PipeTransform {
  transform(accounts: UserBookAccounts[]): boolean {
    return accounts.some((account: UserBookAccounts) => !account.verified);
  }
}
