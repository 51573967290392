import { Component } from '@angular/core';
import { HomeBaseComponent } from '../../../common/base-controllers/home-base.component';
import { SeasonTicketsService } from '../../../common/services/season-tickets.service';
import { LoaderService } from '../../../common/services/loader.service';
import { SubscriptionService } from '../../../common/services/subscription.service';
import { DynamicPopupService } from '../../../common/services/dynamic-popup.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends HomeBaseComponent {
  constructor(
    seasonTicketsService: SeasonTicketsService,
    loaderService: LoaderService,
    subscriptionService: SubscriptionService,
    dynamicPopup: DynamicPopupService
  ) {
    super(
      seasonTicketsService,
      loaderService,
      subscriptionService,
      dynamicPopup
    );
  }
}
