import { Component } from '@angular/core';

@Component({
  selector: 'app-builder-intro-desktop',
  templateUrl: './builder-intro-desktop.component.html',
  styleUrls: ['./builder-intro-desktop.component.css'],
})
export class BuilderIntroDesktopComponent {
  public showBuilderAccountPopup = false;
}
