import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';
import { LayoutDecorator } from '../../../../../../../common/helpers/layout.decorator';
import { LayoutConfigService } from '../../../../../../../common/services/layout-config.service';

@Component({
  selector: 'app-community-items',
  templateUrl: './community-items.component.html',
  styleUrls: ['./community-items.component.css'],
})
@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
export class CommunityItemsComponent implements OnInit, OnDestroy {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;

  public communities = [
    {
      name: 'Bet Lab',
      navigateTo: '',
      imageSrc: '',
    },
    {
      name: 'Community Chat',
      navigateTo: '',
      imageSrc: '/assets/community/chat.jpg',
    },
    {
      name: 'Games',
      navigateTo: '/my/predict-and-win',
      imageSrc: '/assets/community/games.jpg',
    },
    {
      name: 'Hear from the Pros',
      navigateTo: '/my/community/hear-pros',
      imageSrc: '/assets/community/pros.jpg',
    },
  ];

  constructor(private layoutConfigService: LayoutConfigService) {}

  ngOnInit() {}

  ngOnDestroy() {}
}
