import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarItem } from './calendar-item';
import { Calendar, Day } from '../../types/calendar';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-season-ticket-calendar',
  templateUrl: './season-ticket-calendar.component.html',
  styleUrls: ['./season-ticket-calendar.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SeasonTicketCalendarComponent implements OnInit {
  @Input() public calendar: Calendar;
  @Input() public yearmonthPair: string;
  @Input() public isMobile = true;

  @Output() public action$: EventEmitter<CalendarItem> =
    new EventEmitter<CalendarItem>();

  public year: string;
  public monthDate: string;
  public month: string;
  public weeks: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  ngOnInit(): void {
    const [year, month] = this.yearmonthPair.split('-');
    this.year = year;
    this.month = month;
    this.monthDate = `${this.yearmonthPair}-1`;
  }

  public selectDate(date: Day): void {
    if (!date.highlighted) return;
    this.action$.emit({ day: date.day, key: `${this.year}-${this.month}` });
  }
}
