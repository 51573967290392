import { Component } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../../../../../common/enum/breadcrumb-background';

@Component({
  selector: 'app-book-select',
  templateUrl: './book-select.component.html',
  styleUrls: ['./book-select.component.css'],
})
export class BookSelectComponent {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
}
