import { Component, Input } from '@angular/core';
import { ReceivedTicket } from '../../../../../../../common/types/user';
import { BaseTicketComponent } from '../base-ticket/base-ticket.component';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { EventNameAsyncPipe } from '../../../../../../../common/pipes/event-name-async.pipe';
import { TicketPresentQrComponent } from '../ticket-present-qr/ticket-present-qr.component';
import { TicketPreviewDetailsComponent } from '../ticket-preview-details/ticket-preview-details.component';

@Component({
  selector: 'app-ticket-received',
  templateUrl: './ticket-received.component.html',
  standalone: true,
  styleUrls: ['./ticket-received.component.scss'],
  imports: [
    CommonModule,
    TitleCasePipe,
    EventNameAsyncPipe,
    TicketPresentQrComponent,
    TicketPreviewDetailsComponent,
  ],
})
export class TicketReceivedComponent extends BaseTicketComponent {
  @Input() public ticket: ReceivedTicket;
  @Input() public isMobile: boolean = true;
  @Input() public canToggleQr = true;
}
