export class KeyboardUtil {

  static ifBackspaceOrDelete(event: KeyboardEvent) {
    return this.ifKey(event, 'Backspace;Delete;Del');
  }

  static ifRightArrow(event: KeyboardEvent) {
    return this.ifKey(event, 'ArrowRight;Right');
  }

  static ifLeftArrow(event: KeyboardEvent) {
    return this.ifKey(event, 'ArrowLeft;Left');
  }

  static ifSpacebar(event: KeyboardEvent) {
    return this.ifKey(event, 'Spacebar; ');
  }

  static ifKey(event: KeyboardEvent, keys: string): boolean {
    const keysToCheck = keys.split(';');
    return keysToCheck.some(k => k === event.key);
  }
}
