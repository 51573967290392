export enum PaymentState {
  success = 1,
  fail,
}

export interface StripePaymentResponse {
  state: PaymentState;
  message?: string;
}

export interface StripePayment {
  paymentIntent: { id: string };
}
