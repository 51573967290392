import { Component, OnInit } from '@angular/core';
import { PageCode } from '../../../../../common/enum/page-code';
import { DynamicPopupService } from '../../../../../common/services/dynamic-popup.service';

@Component({
  selector: 'app-bankroll-builder-desktop',
  templateUrl: './bankroll-builder-desktop.component.html',
  styleUrls: ['./bankroll-builder-desktop.component.css'],
})
export class BankrollBuilderDesktopComponent implements OnInit {
  constructor(private dynamicPopupService: DynamicPopupService) {}

  ngOnInit(): void {
    this.dynamicPopupService.showPagePopups(PageCode.builder);
  }
}
