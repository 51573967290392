import { Component } from '@angular/core';
import { PopupsManagerService } from '../../../../../../../common/services/popups-manager.service';

@Component({
  selector: 'app-account-upgrade',
  templateUrl: './account-upgrade.component.html',
  styleUrls: ['./account-upgrade.component.scss'],
})
export class AccountUpgradeComponent {
  constructor(private popupsManagerService: PopupsManagerService) {}

  public closePopup(): void {
    this.popupsManagerService.hide();
  }
}
