import { NgModule } from '@angular/core';

import { FilterByDateComponent } from '../../../common/components/filter-by-date/filter-by-date.component';
import { EventFilterByCategoryComponent } from '../../../common/components/event-filter-by-category/event-filter-by-category.component';
import { CommonModule } from '@angular/common';
import { FilterCountPipe } from '../../../common/pipes/filter-count.pipe';
import { CalendarViewPageComponent } from '../../../common/components/calendar-view-page/calendar-view-page.component';
import { eventBreadcrumb } from '../../../common/constants/breadcrumbs';
import { RouterModule } from '@angular/router';
import { ConcatPipe } from '../../../common/pipes/concat.pipe';
import { SeasonTicketScheduleComponent } from './season-ticket-schedule.component';
import { EventItemComponent } from '../main/pages/season-ticket/components/event-item/event-item.component';
import { EventDetailsDesktopComponent } from '../../../desktop/modules/main-desktop/components/event-details-desktop/event-details-desktop.component';
import { KeysOtpPipe } from '../../../common/pipes/key-otp.pipe';
import { ScheduleMobileLayoutComponent } from './schedule-mobile-layout/schedule-mobile-layout.component';
import { BreadcrumbComponent } from '../components/layout/breadcrumb/breadcrumb.component';
import { HeaderComponent } from '../components/layout/header/header.component';
import { FooterComponent } from '../components/layout/footer/footer.component';
import { EventIsEmptyPipe } from '../../../common/pipes/event-is-empty.pipe';

const routes = [
  {
    path: '',
    component: SeasonTicketScheduleComponent,
    data: { breadcrumb: eventBreadcrumb },
  },
];

@NgModule({
  imports: [
    // Modules
    CommonModule,
    RouterModule.forChild(routes),
    // Components
    BreadcrumbComponent,
    FilterByDateComponent,
    EventFilterByCategoryComponent,
    EventItemComponent,
    CalendarViewPageComponent,
    EventDetailsDesktopComponent,
    HeaderComponent,
    FooterComponent,
    // Pipes
    FilterCountPipe,
    ConcatPipe,
    KeysOtpPipe,
    EventIsEmptyPipe,
  ],
  declarations: [SeasonTicketScheduleComponent, ScheduleMobileLayoutComponent],
})
export class SeasonTicketScheduleModule {}
