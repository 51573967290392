import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Pageable } from '../types/pageable';
import { Geofence, Venue } from '../types/sharp-sports/venues';
import { VenueTournament } from '../types/sharp-sports/venue-tournament';
import {
  TournamentLeaderboard,
  TournamentWinner,
} from '../types/sharp-sports/tournament-leaderboard';

@Injectable({
  providedIn: 'root',
})
export class VenueLeaderboardService {
  private apiUrl = `${environment.apiUrl}/client`;

  constructor(private httpClient: HttpClient) {}

  public getVenues(): Observable<Venue[]> {
    return this.httpClient
      .get<Pageable<Venue>>(`${this.apiUrl}/venues?page.index=0&page.size=1000`)
      .pipe(map((res: Pageable<Venue>) => res.items));
  }

  public getTournaments(venueId: string): Observable<VenueTournament[]> {
    return this.httpClient
      .get<Pageable<VenueTournament>>(
        `${this.apiUrl}/venues/${venueId}/tournaments?page.index=0&page.size=1000`
      )
      .pipe(map((res: Pageable<VenueTournament>) => res.items));
  }

  public getTournamentLeaderboard(
    tournamentId: string
  ): Observable<TournamentLeaderboard[]> {
    return this.httpClient.get<TournamentLeaderboard[]>(
      `${this.apiUrl}/tournaments/${tournamentId}/leaderboard?page.index=0&page.size=1000`
    );
  }

  public connectToTournament(
    tournamentId: string,
    geolocation: Geofence
  ): Observable<{
    message: string;
  }> {
    return this.httpClient.post<{
      message: string;
    }>(`${this.apiUrl}/tournaments/${tournamentId}/connect`, geolocation);
  }

  public getTournamentWinner(
    tournamentId: string
  ): Observable<TournamentWinner> {
    return this.httpClient.get<TournamentWinner>(
      `${this.apiUrl}/tournaments/${tournamentId}/winners`
    );
  }
}
