import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colorGenerator',
  standalone: true,
})
export class ColorGeneratorPipe implements PipeTransform {
  private colors: string[] = [
    '#D20A0A',
    '#062345',
    '#059a0b',
    '#265F80',
    '#505494',
    '#fa8f1e',
    '#751858',
    '#003A73',
    '#A82814',
    '#5ebef6',
    '#f8d600',
    '#c50b8c',
    '#b69f05',
    '#0af612',
  ];
  transform(style: string): string {
    return style + this.colors[Math.floor(Math.random() * this.colors.length)];
  }
}
