export const MENU_ITEMS = [
  {
    title: 'Predict and win',
    route: '/my/select-venue',
    pages: [],
  },
  {
    title: 'Leaderboard',
    route: '/my/builder/dashboard/leaderboard',
    pages: [],
  },
  {
    title: 'Bet Lab',
    pages: [],
    route: '/my/builder/dashboard/overview',
  },
];

export const UNAUTHORIZED_MENU_ITEMS = [
  {
    title: 'Events',
    pages: [
      {
        name: 'Schedule',
        route: '/schedule',
      },
      {
        name: 'Ticketed Events',
        route: '/static/ticket-events-info',
      },
      {
        name: 'Private Events',
        route: '/private-events',
      },
    ],
  },
  {
    title: 'Dining & Reservations',
    pages: [],
    route: '/menu',
  },
  {
    title: 'Memberships',
    pages: [
      {
        name: 'VIP',
        route: '/static/vip-membership-info',
      },
      {
        name: 'Season Tickets',
        route: '/static/season-tickets-info',
      },
    ],
  },
  {
    title: 'Venue',
    pages: [
      {
        name: 'The Space',
        route: 'space',
      },
      {
        name: 'About Bankroll',
        route: '/story',
      },
    ],
  },
];

export const UNAUTHORIZED_MOBILE_MENU_ITEMS = [
  {
    title: 'Events',
    pages: [
      {
        name: 'Schedule',
        route: '/schedule',
      },
      {
        name: 'Ticketed Events',
        route: '/static/ticket-events-info',
      },
      {
        name: 'Private Events',
        route: '/private-events',
      },
    ],
  },
  {
    title: 'Dining',
    pages: [
      {
        name: 'Reservations',
        route: '/menu',
      },
    ],
    route: '',
  },
  {
    title: 'Memberships',
    pages: [
      {
        name: 'VIP',
        route: '/static/vip-membership-info',
      },
      {
        name: 'Season Tickets',
        route: '/static/season-tickets-info',
      },
    ],
  },
  {
    title: 'Venue',
    pages: [
      {
        name: 'The Space',
        route: 'space',
      },
      {
        name: 'About Bankroll',
        route: '/story',
      },
    ],
  },
];
