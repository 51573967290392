import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Event} from '../types/event';
import {Action} from '../enum/action';
import {PopupsManagerService} from '../services/popups-manager.service';

@Component({
  template: ``,
})
export abstract class EventDetailsBaseComponent {
  @Input() public event: Event;
  @Output() public action$: EventEmitter<Action>;

  public isPlaying = false;

  public constructor(private popupsManagerService: PopupsManagerService) {
  }

  public handleBuyEvent(): void {
    this.action$.emit(Action.Submit);
    this.popupsManagerService.hide();
  }

  public close(): void {
    this.popupsManagerService.hide();
  }

  public play(videoRef: HTMLVideoElement): void {
    this.isPlaying = !this.isPlaying;
    videoRef.play();
  }
}
