import { Component, Input } from '@angular/core';
import { User } from '../../../../../../../common/types/user';
import { Event } from '../../../../../../../common/types/event';
import { Table } from '../../../../../../../common/types/area';
import { TicketDetailsComponent } from '../../../../../../../common/components/ticket-details/ticket-details.component';
import { DatesComponent } from '../dates/dates.component';
import { AreaTableNumberPipe } from '../../../../../../../common/pipes/area-table-number.pipe';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  standalone: true,
  imports: [TicketDetailsComponent, DatesComponent, AreaTableNumberPipe],
})
export class TicketComponent {
  @Input() public user: User;
  @Input() public event: Event;
  @Input() public isMobile = true;
  @Input() public areaName: string;
  @Input() public selectedTable: Table;
  @Input() public subTotal: number;
  @Input() public dates: string[];
}
