import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbBackground } from '../../../../../../../../../common/enum/breadcrumb-background';

@Component({
  selector: 'app-region-select',
  templateUrl: './region-select.component.html',
  styleUrls: ['./region-select.component.css'],
})
export class RegionSelectComponent {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  public bookId = this.activatedRoute.snapshot.paramMap.get('bookId');

  constructor(private activatedRoute: ActivatedRoute) {}
}
