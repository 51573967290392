import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { SportBook } from '../../../types/sharp-sports/sport-books';
import { SharpSportsService } from '../../../services/sharp-sports.service';
import { LoaderService } from '../../../services/loader.service';
import { debounceTime, finalize, map, takeUntil } from 'rxjs/operators';
import { alphabeticalSort } from '../../../helpers/alphabetical-sort';
import { BaseComponent } from '../../base/base.component';
import { ColorGeneratorPipe } from '../../../pipes/builder/color-generator.pipe';
import { ImageUrlFormatterPipe } from '../../../pipes/builder/image-url-formatter.pipe';

@Component({
  selector: 'app-book-select-shared',
  templateUrl: './book-select-shared.component.html',
  styleUrls: ['./book-select-shared.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ColorGeneratorPipe,
    ImageUrlFormatterPipe,
  ],
})
export class BookSelectSharedComponent extends BaseComponent implements OnInit {
  @Input() isMobile = true;
  @Output() bookSelect$: EventEmitter<string> = new EventEmitter<string>();
  @Output() closePopup$: EventEmitter<void> = new EventEmitter<void>();
  private allSportBooks: SportBook[] = [];
  public filteredSportBooks: SportBook[];
  public searchFormControl = this.fb.control('');
  public notFoundImages: string[] = [];

  constructor(
    private sharpSportsService: SharpSportsService,
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.loaderService.show();
    this.sharpSportsService
      .getSportBooks()
      .pipe(
        map((s: SportBook[]) => alphabeticalSort<SportBook>(s, 'name')),
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe((res: SportBook[]) => {
        this.allSportBooks = res;
        this.filteredSportBooks = res;
      });
    this.searchFormControl.valueChanges
      .pipe(debounceTime(300), takeUntil(this.unsubscribe$))
      .subscribe((val: string) => {
        if (!val) {
          this.filteredSportBooks = this.allSportBooks;
          return;
        }
        const value = val.toLowerCase();
        this.filteredSportBooks = this.allSportBooks.filter((book: SportBook) =>
          book?.name.toLowerCase().includes(value)
        );
      });
  }

  public navigateToRegionSelect(bookId: string) {
    if (this.isMobile) {
      this.router.navigateByUrl(
        `/my/builder/sharp-sports/region-select/${bookId}`
      );
    } else {
      this.bookSelect$.emit(bookId);
    }
  }

  public closeButtonClickHandler() {
    this.isMobile
      ? this.router.navigateByUrl('/my/builder/dashboard')
      : this.closePopup$.emit();
  }

  public imageNotFoundHandler(name: string) {
    this.notFoundImages.push(name);
  }
}
