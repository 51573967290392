import { NgModule } from '@angular/core';
import { MenuComponent } from './menu.component';
import { CommonModule } from '@angular/common';
import { MenuRoutingModule } from './menu-routing.module';
import { RestaurantPageComponent } from './pages/restaurant-page/restaurant-page.component';
import { RouterModule } from '@angular/router';
import { RestaurantMenuPageComponent } from './pages/restaurant-menu-page/restaurant-menu-page.component';
import { RestaurantComponent } from '../../../common/components/menu/restaurant/restaurant.component';
import { MenuMobileLayoutComponent } from './menu-layout/menu-mobile-layout.component';
import { HeaderComponent } from '../components/layout/header/header.component';
import { FooterComponent } from '../components/layout/footer/footer.component';
import { BreadcrumbComponent } from '../components/layout/breadcrumb/breadcrumb.component';
import { MenuImagesSliderComponent } from '../../../common/components/menu/menu-images-slider/menu-images-slider.component';

@NgModule({
  declarations: [
    MenuComponent,
    RestaurantPageComponent,
    RestaurantMenuPageComponent,
    MenuMobileLayoutComponent,
  ],
  imports: [
    // Modules
    CommonModule,
    MenuRoutingModule,
    RouterModule,
    // Components
    RestaurantComponent,
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    MenuImagesSliderComponent,
  ],
})
export class MenuModule {}
