import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { profile } from '../../../common/constants/breadcrumbs';
import { ProfileDesktopComponent } from './profile-desktop.component';
import { ProfileLayoutComponent } from './profile-layout/profile-layout.component';
import { BreadcrumbDesktopComponent } from '../components/layout/breadcrumb/breadcrumb-desktop.component';
import { HeaderDesktopComponent } from '../components/layout/header/header-desktop.component';
import { FooterDesktopComponent } from '../components/layout/footer/footer-desktop.component';
import { AssetsUrlPipe } from '../../../common/pipes/assets-url.pipe';

const route = [
  {
    path: '',
    component: ProfileDesktopComponent,
    data: { breadcrumb: profile },
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(route),
    BreadcrumbDesktopComponent,
    HeaderDesktopComponent,
    FooterDesktopComponent,
    AssetsUrlPipe,
  ],
  declarations: [ProfileDesktopComponent, ProfileLayoutComponent],
  providers: [],
})
export class ProfileDesktopModule {}
