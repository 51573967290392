import { Component, OnInit } from '@angular/core';
import { LayoutConfigService } from '../../../common/services/layout-config.service';
import { PredictAndWinService } from '../../../common/services/predict-and-win.service';

@Component({
  selector: 'app-main-desktop',
  templateUrl: './main-desktop.component.html',
  styleUrls: ['./main-desktop.component.scss'],
})
export class MainDesktopComponent implements OnInit {
  public layoutConfig$ = this.layoutConfigService.layoutConfig;
  constructor(
    private layoutConfigService: LayoutConfigService,
    private predictAndWinService: PredictAndWinService
  ) {}

  ngOnInit(): void {
    // this.predictAndWinService.checkNotifications();
  }
}
