import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankrollStoryDesktopComponent } from './bankroll-story-desktop.component';
import { RouterModule, Routes } from '@angular/router';
import { storyBreadcrumb } from '../../../common/constants/breadcrumbs';
import { GoogleMapsModule } from '@angular/google-maps';
import { HeaderDesktopComponent } from '../components/layout/header/header-desktop.component';
import { FooterDesktopComponent } from '../components/layout/footer/footer-desktop.component';
import { BreadcrumbDesktopComponent } from '../components/layout/breadcrumb/breadcrumb-desktop.component';
import { AssetsUrlPipe } from '../../../common/pipes/assets-url.pipe';
import { CamelCaseToTextPipe } from '../../../common/pipes/camel-case-to-text.pipe';

const routes: Routes = [
  {
    path: '',
    component: BankrollStoryDesktopComponent,
    data: { breadcrumb: storyBreadcrumb },
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    HeaderDesktopComponent,
    FooterDesktopComponent,
    GoogleMapsModule,
    BreadcrumbDesktopComponent,
    AssetsUrlPipe,
    CamelCaseToTextPipe,
  ],
  declarations: [BankrollStoryDesktopComponent],
})
export class BankrollStoryDesktopModule {}
