import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { FindNearestEventPipe } from '../../../common/pipes/find-nearest-event.pipe';
import { AssetsUrlPipe } from '../../../common/pipes/assets-url.pipe';
import { EventTimePipe } from '../../../common/pipes/event-duration.pipe';
import { EventItemComponent } from '../main/pages/season-ticket/components/event-item/event-item.component';
import { HomeCardsComponent } from './components/home-cards/home-cards.component';
import { HeaderComponent } from '../components/layout/header/header.component';
import { FooterComponent } from '../components/layout/footer/footer.component';
import { ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
];

@NgModule({
  declarations: [HomeComponent, HomeCardsComponent],
  imports: [
    // Modules
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    // Components
    HeaderComponent,
    FooterComponent,
    EventItemComponent,
    // Pipes
    AssetsUrlPipe,
    FindNearestEventPipe,
    EventTimePipe,
  ],
})
export class HomeModule {}
