import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './profile.component';
import { RouterModule } from '@angular/router';
import { profile } from '../../../common/constants/breadcrumbs';
import { BreadcrumbComponent } from '../components/layout/breadcrumb/breadcrumb.component';
import { AssetsUrlPipe } from '../../../common/pipes/assets-url.pipe';

const route = [
  {
    path: '',
    component: ProfileComponent,
    data: { breadcrumb: profile },
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(route),
    BreadcrumbComponent,
    AssetsUrlPipe,
  ],
  declarations: [ProfileComponent],
  providers: [],
})
export class ProfileModule {}
