import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutDecorator } from '../../../../../../../common/helpers/layout.decorator';
import { LayoutConfigService } from '../../../../../../../common/services/layout-config.service';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';

@Component({
  selector: 'app-builder-intro',
  templateUrl: './builder-intro.component.html',
  styleUrls: ['./builder-intro.component.css'],
})
@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
export class BuilderIntroComponent implements OnInit, OnDestroy {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  constructor(private layoutConfigService: LayoutConfigService) {}

  ngOnInit() {}
  ngOnDestroy() {}
}
