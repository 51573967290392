import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { PopupsManagerService } from '../common/services/popups-manager.service';
import { HeaderMenuService } from '../common/services/header-menu.service';
import { RestaurantMenuService } from '../common/services/restaurant-menu.service';
import { RestaurantMenu } from '../common/types/restaurant-menu';
import { StorageService } from '../common/services/storage.service';
import { CommunicationService } from '../common/services/communication.service';
import { GUEST_ID, TOKEN_DUMMY_USER } from '../common/constants/headers';
import { isMobileOrTablet } from '../common/helpers/point';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private popupsManagerService: PopupsManagerService,
    private communicationService: CommunicationService,
    private headerMenuService: HeaderMenuService,
    private restaurantMenuService: RestaurantMenuService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.storageService.storeButtonRef();
    this.storageService.saveToStorage(GUEST_ID, TOKEN_DUMMY_USER); // TODO autologin
    if (isMobileOrTablet()) {
      this.initPostMessageListener();
      this.restaurantMenuService
        .getMenus()
        .subscribe((restaurantMenus: RestaurantMenu[]) => {
          this.headerMenuService.fetchDiningMenu(restaurantMenus);
        });
    }
    this.popupsManagerService.initialize(this.viewContainerRef);
  }

  private initPostMessageListener() {
    window.addEventListener('message', (event: any) => {
      const data = event.data;
      switch (data.key) {
        case 'COORDS_DATA': {
          const coords = {
            latitude: data.data.latitude,
            longitude: data.data.longitude,
          };
          this.communicationService.setGeoLocationData(coords);
          break;
        }
        case 'COORDS_DATA_ERROR': {
          this.communicationService.throwError(data.data.message);
          break;
        }
      }
    });
  }
}
