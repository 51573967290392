import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../common/guards/auth.guard';
import { UnauthorizedGuard } from '../common/guards/unauthorized.guard';
import { ReceivedTicketGuard } from '../common/guards/received-ticket.guard';
import { DesktopComponent } from './desktop.component';
import { BankrollStoryDesktopComponent } from './modules/bankroll-story/bankroll-story-desktop.component';
const unauthorizedRoutes: Routes = [
  {
    path: '',
    component: BankrollStoryDesktopComponent,
  },
  {
    path: 'schedule',
    loadChildren: () =>
      import(
        './modules/season-ticket-schedule-desktop-page/season-ticket-schedule-desktop.module'
      ).then((m) => m.SeasonTicketScheduleDesktopModule),
  },
  {
    path: 'menu',
    loadChildren: () =>
      import('./modules/menu-desktop/menu-desktop.module').then(
        (m) => m.MenuDesktopModule
      ),
  },
  {
    path: 'static',
    loadChildren: () =>
      import('./modules/static-pages/static-desktop-pages.module').then(
        (m) => m.StaticDesktopPagesModule
      ),
  },
  {
    path: 'private-events',
    loadChildren: () =>
      import(
        './modules/private-events-desktop/private-events-desktop.module'
      ).then((m) => m.PrivateEventsDesktopModule),
  },
  {
    path: 'story',
    loadChildren: () =>
      import('./modules/bankroll-story/bankroll-story-desktop.module').then(
        (m) => m.BankrollStoryDesktopModule
      ),
  },
  {
    path: 'space',
    loadChildren: () =>
      import('./modules/space-desktop/space-desktop.module').then(
        (m) => m.SpaceDesktopModule
      ),
  },
  {
    path: 'partners',
    loadChildren: () =>
      import('./modules/partners-desktop/partners-desktop.module').then(
        (m) => m.PartnersDesktopModule
      ),
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./modules/privacy/privacy-desktop.module').then(
        (m) => m.PrivacyDesktopModule
      ),
  },
];

const authorizedRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/main-desktop/main-desktop.module').then(
        (m) => m.MainDesktopModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/profile-desktop/profile-desktop.module').then(
        (m) => m.ProfileDesktopModule
      ),
  },
  {
    path: 'welcome-drinks',
    loadChildren: () =>
      import(
        './modules/welcome-drink-desktop-page/welcome-drink-desktop-page.module'
      ).then((m) => m.WelcomeDrinkDesktopPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        loadChildren: () =>
          import('./modules/bankroll-story/bankroll-story-desktop.module').then(
            (m) => m.BankrollStoryDesktopModule
          ),
      },
      {
        path: 'privacy',
        loadChildren: () =>
          import('./modules/privacy/privacy-desktop.module').then(
            (m) => m.PrivacyDesktopModule
          ),
      },
    ]),
  ],
  exports: [RouterModule],
})
export class DesktopRoutingModule {}
