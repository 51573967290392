import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../common/services/loader.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../common/components/base/base.component';

@Component({
  selector: 'app-partners-desktop',
  templateUrl: './partners-desktop.component.html',
  styleUrls: ['./partners-desktop.component.css'],
})
export class PartnersDesktopComponent extends BaseComponent implements OnInit {
  constructor(private loaderService: LoaderService) {
    super();
  }

  ngOnInit(): void {
    this.loaderService
      .staticLoader()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }
}
