import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableNumber',
  standalone: true,
})
export class AreaTableNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    if (value && typeof value === 'string') {
      const lastIndex = value.lastIndexOf('/');
      return lastIndex !== -1 ? value.slice(lastIndex + 1) : '-';
    }
    return '-';
  }
}
