import { Subject } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';

@Component({
  template: ``,
})
export class BaseComponent implements OnDestroy {
  public unsubscribe$ = new Subject<void>();

  constructor() {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
