import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare function Clock(countdown?: any, callback?: any): void;

@Component({
  selector: 'app-clock-count-down',
  templateUrl: './clock-count-down.component.html',
  styleUrls: ['./clock-count-down.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ClockCountDownComponent implements AfterViewInit {
  @Input() clockEndDate: number;
  @Input() isMobile = false;
  @Output() clockEnd$: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('counterContainer', { read: ElementRef, static: false })
  public counterContainer?: ElementRef<HTMLCanvasElement>;

  ngAfterViewInit() {
    const deadline = new Date(this.clockEndDate);
    const c = new Clock(deadline, () => {
      this.clockEnd$.emit();
    });
    this.counterContainer.nativeElement.appendChild(c.el);
  }
}
