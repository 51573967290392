import { Pipe, PipeTransform } from '@angular/core';
import { Bets } from '../../types/sharp-sports/bet-slips';

interface Outcome {
  win: number;
  loss: number;
}

@Pipe({
  name: 'parlayWinLoseCounter',
  standalone: true,
})
export class ParlayWinLoseCounterPipe implements PipeTransform {
  transform(bets: Bets[]): string {
    const outcome: Outcome = bets.reduce(
      (count: Outcome, bet: Bets) => {
        if (bet.outcome === 'win') {
          count.win++;
        }
        if (bet.outcome === 'loss') {
          count.loss++;
        }
        return count;
      },
      { win: 0, loss: 0 }
    );
    return `${outcome.win} wins, ${outcome.loss} loss`;
  }
}
