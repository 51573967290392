import { Component, OnInit } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../common/enum/breadcrumb-background';
import { BaseComponent } from '../../../../../common/components/base/base.component';

@Component({
  selector: 'app-info-desktop',
  templateUrl: './info-desktop.component.html',
  styleUrls: ['./info-desktop.component.css'],
})
export class InfoDesktopComponent extends BaseComponent implements OnInit {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
