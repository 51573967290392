import { Pipe, PipeTransform } from '@angular/core';
import { EventDate } from '../types/event';

@Pipe({
  name: 'findNearestEvent',
  standalone: true,
})
export class FindNearestEventPipe implements PipeTransform {
  transform(dates: EventDate[]): string {
    const [nearestEvent] = dates.sort(
      (a: EventDate, b: EventDate) => Date.parse(a.date) - Date.parse(b.date)
    );
    return nearestEvent.date;
  }
}
