import { Component, OnInit } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../common/enum/breadcrumb-background';

@Component({
  selector: 'app-space-area-view',
  templateUrl: './space-area-view.component.html',
  styleUrls: ['./space-area-view.component.scss'],
})
export class SpaceAreaViewComponent implements OnInit {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;

  constructor() {}

  ngOnInit(): void {}
}
