import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LayoutConfig } from '../types/layout-cofig.type';

@Injectable({
  providedIn: 'root',
})
export class LayoutConfigService {
  static defaultConfig = { showFooter: true, showHeader: true };

  private config = new BehaviorSubject<LayoutConfig>(
    LayoutConfigService.defaultConfig
  );

  public layoutConfig: Observable<LayoutConfig> = this.config.asObservable();

  constructor() {}

  changeConfig(config: LayoutConfig): void {
    setTimeout(() => {
      this.config.next(config);
    }, 0);
  }
}
