import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BankrollBuilderDesktopComponent } from './bankroll-builder-desktop.component';
import { BankrollBuilderDesktopRoutingModule } from './bankroll-builder-desktop-routing.module';
import { RouterModule } from '@angular/router';
import { BuilderDashboardDesktopComponent } from './pages/builder-dashboard-desktop/builder-dashboard-desktop.component';
import { BuilderOverviewDesktopComponent } from './pages/builder-dashboard-desktop/pages/builder-overview-desktop/builder-overview-desktop.component';
import { BuilderBetsDesktopComponent } from './pages/builder-dashboard-desktop/pages/builder-bets-desktop/builder-bets-desktop.component';
import { BuilderIntroDesktopComponent } from './pages/builder-intro-desktop/builder-intro-desktop.component';
import { BreadcrumbDesktopComponent } from '../../../components/layout/breadcrumb/breadcrumb-desktop.component';
import { BuilderIntroSharedComponent } from '../../../../../common/components/bankroll-builder/builder-inro-shared/builder-intro-shared.component';
import { BuilderOverviewSharedComponent } from '../../../../../common/components/bankroll-builder/builder-overview-shared/builder-overview-shared.component';
import { BuilderTodayBetsSharedComponent } from '../../../../../common/components/bankroll-builder/builder-today-bets-shared/builder-today-bets-shared.component';
import { BuilderHistorySharedComponent } from '../../../../../common/components/bankroll-builder/builder-history-shared/builder-history-shared.component';
import { BuilderAccountDesktopComponent } from './pages/builder-account-desktop/builder-account-desktop.component';
import { BuilderAccountSharedComponent } from '../../../../../common/components/bankroll-builder/builder-account-shared/builder-account-shared.component';
import { BuilderAccountSelectPopupComponent } from '../../../../../common/components/popups/builder-account-select-popup/builder-account-select-popup.component';
import { BuilderMyBetsSharedComponent } from '../../../../../common/components/bankroll-builder/builder-my-bets-shared/builder-my-bets-shared.component';
import { BuilderFilterPopupComponent } from '../../../../../common/components/popups/builder-filter-popup/builder-filter-popup.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BuilderLeaderboardDesktopComponent } from './pages/builder-dashboard-desktop/pages/builder-leaderboard-desktop/builder-leaderboard-desktop.component';
import { BuilderLeaderboardSharedComponent } from '../../../../../common/components/bankroll-builder/builder-leaderboard-shared/builder-leaderboard-shared.component';
import { LeaderboardPrizesComponent } from '../../../../../common/components/bankroll-builder/leaderboard-prizes/leaderboard-prizes.component';
import { SelectVenueSharedComponent } from '../../../../../common/components/bankroll-builder/select-venue-shared/select-venue-shared.component';
import { ClockCountDownComponent } from '../../../../../common/components/bankroll-builder/clock-count-down/clock-count-down.component';
import { AssetsUrlPipe } from '../../../../../common/pipes/assets-url.pipe';
import { ShowConnectPipe } from '../../../../../common/pipes/builder/show-connect.pipe';
import { KeysOtpPipe } from '../../../../../common/pipes/key-otp.pipe';
import { ContestRulesDesktopComponent } from './pages/contest-rules-desktop/contest-rules-desktop.component';

@NgModule({
  imports: [
    //modules
    CommonModule,
    BankrollBuilderDesktopRoutingModule,
    RouterModule,
    InfiniteScrollModule,
    //components
    BreadcrumbDesktopComponent,
    BuilderIntroSharedComponent,
    BuilderOverviewSharedComponent,
    BuilderTodayBetsSharedComponent,
    BuilderHistorySharedComponent,
    BuilderAccountSharedComponent,
    BuilderAccountSelectPopupComponent,
    BuilderMyBetsSharedComponent,
    BuilderFilterPopupComponent,
    BuilderLeaderboardSharedComponent,
    LeaderboardPrizesComponent,
    SelectVenueSharedComponent,
    ClockCountDownComponent,
    AssetsUrlPipe,
    //pipes
    ShowConnectPipe,
    KeysOtpPipe,
  ],
  declarations: [
    //components
    BankrollBuilderDesktopComponent,
    BuilderDashboardDesktopComponent,
    BuilderOverviewDesktopComponent,
    BuilderBetsDesktopComponent,
    BuilderIntroDesktopComponent,
    BuilderAccountDesktopComponent,
    BuilderLeaderboardDesktopComponent,
    ContestRulesDesktopComponent,
  ],
})
export class BankrollBuilderDesktopModule {}
