import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterLinkWithHref } from '@angular/router';
import { MenuItem } from '../../../../../common/types/menu-items';
import { CommonModule } from '@angular/common';
import { MenuItemsFilter } from '../../../../../common/pipes/filter-menu-items';
import { HeaderBaseComponent } from '../../../../../common/base-controllers/header-base.component';
import { OutsideClickDirective } from '../../../../../common/directives/outside-click.directive';
import { ResyButtonComponent } from '../../../../../components/resy-button/resy-button.component';
import {
  MENU_ITEMS,
  UNAUTHORIZED_MENU_ITEMS,
} from '../../../../../common/helpers/menu-items';
import { NameInitialsPipe } from '../../../../../common/pipes/name-initials.pipe';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MenuItemsFilter,
    RouterLinkWithHref,
    RouterLink,
    OutsideClickDirective,
    ResyButtonComponent,
    NameInitialsPipe,
  ],
})
export class HeaderDesktopComponent
  extends HeaderBaseComponent
  implements OnInit
{
  public selectedMenuItem: MenuItem;
  public menu: MenuItem[] = this.user ? MENU_ITEMS : []; //UNAUTHORIZED_MENU_ITEMS;

  ngOnInit() {
    super.ngOnInit();
  }

  public onMenuClick(menuCategory: MenuItem) {
    if (menuCategory.pages.length > 0) {
      this.selectedMenuItem = menuCategory;
    } else {
      this.router.navigateByUrl(menuCategory?.route);
    }
  }

  public closeSubMenu(): void {
    this.selectedMenuItem = null;
  }

  public async logOut(): Promise<void> {
    await super.logOut();
    this.menu = []; //UNAUTHORIZED_MENU_ITEMS;
  }
}
