import { Component, OnInit } from '@angular/core';
import { PopupsManagerService } from '../../../../../../../common/services/popups-manager.service';

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss'],
})
export class EditAccountComponent implements OnInit {
  constructor(private popupsManagerService: PopupsManagerService) {}

  ngOnInit() {}

  public closePopup(): void {
    this.popupsManagerService.hide();
  }
}
