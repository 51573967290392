import { Component, Input } from '@angular/core';
import { Area, AreaDetails } from '../../../../../../../common/types/area';
import { PopupsManagerService } from '../../../../../../../common/services/popups-manager.service';
import { CommonModule } from '@angular/common';
import { BasePopupComponent } from '../../../../../../../common/components/base/base-popup/base-popup.component';
import { SeasonTicketAreaDetailsComponent } from '../../../../../../../common/components/season-ticket-area-details/season-ticket-area-details.component';

@Component({
  selector: 'app-season-ticket-area-details-popup',
  templateUrl: './season-ticket-area-details-popup.component.html',
  styleUrls: ['./season-ticket-area-details-popup.component.scss'],
  standalone: true,
  imports: [CommonModule, BasePopupComponent, SeasonTicketAreaDetailsComponent],
})
export class SeasonTicketAreaDetailsPopupComponent {
  @Input() public area: Area | AreaDetails;

  constructor(private popupsManagerService: PopupsManagerService) {}

  public closePopup(): void {
    this.popupsManagerService.hide();
  }
}
