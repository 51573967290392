import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TripleseatData} from '../../components/tripleseat/tripleseat-form';
import {PopupService} from './popup.service';
import {environment} from '../../../environments/environment';

const LEAD_FORM_ID = 27500;

@Injectable({
  providedIn: 'root',
})
export class TriplesetaService {

  constructor(public httpClient: HttpClient, private popupService: PopupService) {
  }

  public tripleseatFormSubmit(lead: TripleseatData, token: string): Observable<{ success_message: string, errors: string[] }> {
    return this.httpClient.post<{ success_message: string, errors: string[] }>(
      `${environment.apiUrl}/client/tripleseat`, {
        lead: {
          ...lead,
          event_date: this.getTransformedDate(lead.event_date)
        },
        simple_error_messages: true,
        lead_form_id: LEAD_FORM_ID,
        'g-recaptcha-response': token
      });
  }

  public getTransformedDate(date: Date): string {
    if (!date) return '';
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
  }

}
