import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import {
  BetSkipsGraphRecord,
  BetSlips,
  BetSlipsByGroup,
  BetSlipsGraph,
  BetSlipsGraphByMonthOrYear,
  BetSlipsGroups,
  BetSlipsHistoryFilter,
} from '../../../types/sharp-sports/bet-slips';
import { Pageable } from '../../../types/pageable';
import { BetHistoryDateFilter } from '../../../enum/betHistoryDateFilter';
import { ChartData } from '../../../types/sharp-sports/chart';
import { BetSlipsType } from '../../../enum/bet-slip-type';
import { UnitUsd } from '../../../enum/unit-usd';
import { Week } from '../../../types/week';
import {
  getLastMonthSameDay,
  getWeekData,
  timeZoneFix,
} from '../../../helpers/date-time';
import { LoaderService } from '../../../services/loader.service';
import { CommonModule, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { zip } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { SharpSportsService } from '../../../services/sharp-sports.service';
import { GraphDateLabelPipe } from '../../../pipes/builder/graph-date-label.pipe';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { ParlayWinLoseCounterPipe } from '../../../pipes/builder/parlay-win-lose-counter.pipe';
import { GetBetsFromBetSlipsPipe } from '../../../pipes/builder/get-bets-from-bet-slips.pipe';
import { AddProfitStyleClassPipe } from '../../../pipes/builder/add-profit-style-class.pipe';
import { BetSlipItemComponent } from '../bet-slip-item/bet-slip-item.component';
import { ColorGeneratorPipe } from '../../../pipes/builder/color-generator.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InnerLoaderComponent } from '../../mini-loader/inner-loader.component';
import { ImageUrlFormatterPipe } from '../../../pipes/builder/image-url-formatter.pipe';
import { IsIn365daysPipe } from '../../../pipes/builder/isIn365days.pipe';

@Component({
  selector: 'app-builder-history-shared',
  templateUrl: './builder-history-shared.component.html',
  styleUrls: ['./builder-history-shared.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    InfiniteScrollModule,
    // Components
    LineChartComponent,
    BetSlipItemComponent,
    InnerLoaderComponent,
    // Pipes
    ParlayWinLoseCounterPipe,
    GetBetsFromBetSlipsPipe,
    GraphDateLabelPipe,
    AddProfitStyleClassPipe,
    ColorGeneratorPipe,
    ImageUrlFormatterPipe,
    IsIn365daysPipe,
  ],
  providers: [DatePipe],
})
export class BuilderHistorySharedComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  public weeks: Week[] = getWeekData();
  @Input() isMobile = true;
  @Input() userId;
  @Input() unitSize: number;
  @Input() innerMode = false;
  @Input() filter: BetSlipsHistoryFilter;
  @Input() currentWeekIndex: number = this.weeks.length - 1;
  @Input() currentMonth = new Date().getMonth();
  @Input() currentDateFilter: BetHistoryDateFilter = BetHistoryDateFilter.week;
  @Input() currentCurrency: UnitUsd = UnitUsd.usd;
  @Output() filter$: EventEmitter<BetSlipsHistoryFilter> =
    new EventEmitter<BetSlipsHistoryFilter>();
  @Output() currencyChange$: EventEmitter<UnitUsd> =
    new EventEmitter<UnitUsd>();
  @Output() dateFilterChange$: EventEmitter<BetHistoryDateFilter> =
    new EventEmitter<BetHistoryDateFilter>();
  @Output() currentWeekChange$: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() currentMonthChange$: EventEmitter<number> =
    new EventEmitter<number>();
  public currentBetSlips: Pageable<BetSlips>;
  public currentPage = 0;
  public currentGraphRecord: BetSkipsGraphRecord;
  public betSlipsGroups: BetSlipsGroups;
  public graphRecord: BetSkipsGraphRecord;
  public betHistoryDateFilter = BetHistoryDateFilter;
  public chartLabels: string[];
  public chartData: ChartData[];
  public betSlipsType = BetSlipsType;
  public unitUsd = UnitUsd;
  public now = new Date();
  public showInnerLoader = false;
  private startDay: number;
  private endDay: number;
  public notFoundImages: string[] = [];
  public currentYear = this.now.getFullYear();

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private datePipe: DatePipe,
    private sharpSportsService: SharpSportsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getBetSkipHistory();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (!!changes?.filter && !changes?.filter?.firstChange) ||
      (!!changes?.currentDateFilter &&
        !changes?.currentDateFilter?.firstChange) ||
      (!!changes?.currentWeekIndex &&
        !changes?.currentWeekIndex?.firstChange) ||
      (!!changes?.currentMonth && !changes?.currentMonth?.firstChange)
    ) {
      this.currentPage = 0;
      this.betSlipsGroups = null;
      this.currentBetSlips = null;
      this.currentGraphRecord = null;
      this.getBetSkipHistory();
    }
  }

  public getBetSkipHistory(): void {
    this.loaderService.show();

    switch (this.currentDateFilter) {
      case BetHistoryDateFilter.week:
        this.startDay = Date.parse(
          this.weeks[this.currentWeekIndex].startOfTheWeekDate
        );
        this.endDay = Date.parse(
          this.weeks[this.currentWeekIndex].endOfTheWeekDate
        );
        break;
      case BetHistoryDateFilter.month:
        const todaysDay = new Date().getDate();
        this.startDay = getLastMonthSameDay(
          new Date(this.currentYear, this.currentMonth, todaysDay)
        ).getTime();
        this.endDay = new Date(
          this.currentYear,
          this.currentMonth,
          todaysDay
        ).getTime();
        break;
      case BetHistoryDateFilter.year:
        const lastYear = new Date();
        lastYear.setFullYear(this.currentYear - 1);
        this.startDay = lastYear.getTime();
        this.endDay = Date.now();
        break;
    }

    if (!this.innerMode) {
      zip(
        this.sharpSportsService.getGroupedBetSlips(
          timeZoneFix(this.startDay),
          timeZoneFix(this.endDay)
        ),
        this.sharpSportsService.getBetSlipsGraph(
          timeZoneFix(this.startDay),
          timeZoneFix(this.endDay)
        )
      )
        .pipe(
          takeUntil(this.unsubscribe$),
          finalize(() => this.loaderService.hide())
        )
        .subscribe(
          ([groups, graphRecord]: [BetSlipsGroups, BetSkipsGraphRecord]) => {
            this.betSlipsGroups = groups;
            this.graphRecord = graphRecord;
            this.generateChartData();
          }
        );
    } else {
      const filter = `&${this.filter.key}=${this.filter.groupName}`;
      zip(
        this.sharpSportsService.getBetSlipsByDate(
          timeZoneFix(this.startDay),
          timeZoneFix(this.endDay),
          `${filter}&page.index=${this.currentPage}`
        ),
        this.sharpSportsService.getBetSlipsGraph(
          timeZoneFix(this.startDay),
          timeZoneFix(this.endDay),
          filter
        )
      )
        .pipe(
          takeUntil(this.unsubscribe$),
          finalize(() => this.loaderService.hide())
        )
        .subscribe(
          ([betSlips, graphRecord]: [
            Pageable<BetSlips>,
            BetSkipsGraphRecord
          ]) => {
            this.currentBetSlips = betSlips;
            this.currentGraphRecord = graphRecord;
            this.generateChartData();
          }
        );
    }
  }

  public changeDateFilter(dateFiler: BetHistoryDateFilter): void {
    if (dateFiler === this.currentDateFilter) {
      return;
    }
    this.currentDateFilter = dateFiler;
    this.getBetSkipHistory();
    if (!this.isMobile) {
      this.dateFilterChange$.emit(this.currentDateFilter);
    }
  }

  public generateChartData(): void {
    switch (this.currentDateFilter) {
      case BetHistoryDateFilter.week:
        this.generateChartByWeek();
        break;
      default:
        this.generateChartByMonthOrYear();
    }
  }

  private generateChartByWeek(): void {
    const graphData = this.innerMode
      ? this.currentGraphRecord.betSlipsGraph
      : this.graphRecord.betSlipsGraph;
    const graph = [...graphData];
    this.chartLabels = graph.map((g: BetSlipsGraph) =>
      this.formattingDate(g.day, 'MMM/dd')
    );
    this.chartData = [
      {
        data: graph
          .filter(
            (betSlipGraph: BetSlipsGraph) =>
              Date.now() - Date.parse(betSlipGraph.day) >= 0
          )
          .map((g: BetSlipsGraph) => g.netProfit / 100),
      },
    ];
  }

  private generateChartByMonthOrYear(): void {
    const graphData = this.innerMode
      ? this.currentGraphRecord.betSlipsGraph
      : this.graphRecord.betSlipsGraph;

    const monthOrYearGraphData: BetSlipsGraphByMonthOrYear[] =
      this.currentDateFilter === BetHistoryDateFilter.month
        ? this.createMonthData(graphData, 4)
        : this.createYearData(graphData);

    this.chartLabels = monthOrYearGraphData.map(
      (g: BetSlipsGraphByMonthOrYear) => {
        switch (this.currentDateFilter) {
          case BetHistoryDateFilter.month:
            return g.days.length > 1
              ? `${this.formattingDate(
                  g.days[0],
                  'MMM/dd'
                )}-${this.formattingDate(g.days[g.days.length - 1], 'MMM/dd')}`
              : `${this.formattingDate(g.days[0], 'MMM/dd')}`;
          case BetHistoryDateFilter.year:
            return `${this.formattingDate(g.days[0], 'MMM')}`;
        }
      }
    );

    this.chartData = [
      {
        data: this.filterUntilToday(monthOrYearGraphData).map(
          (g: BetSlipsGraphByMonthOrYear) => g.netProfit
        ),
      },
    ];
  }

  private filterUntilToday(
    graphData: BetSlipsGraphByMonthOrYear[]
  ): BetSlipsGraphByMonthOrYear[] {
    if (this.currentDateFilter !== BetHistoryDateFilter.month) {
      return graphData;
    }
    return graphData.filter((g: BetSlipsGraphByMonthOrYear) =>
      g.days.every((d: string) => Date.now() - Date.parse(d) >= 0)
    );
  }

  private createMonthData(
    graphData: BetSlipsGraph[],
    daysDivider: number
  ): BetSlipsGraphByMonthOrYear[] {
    return graphData.reduce(
      (
        monthOrYearGraphData: BetSlipsGraphByMonthOrYear[],
        g: BetSlipsGraph
      ) => {
        const index = monthOrYearGraphData.length - 1;
        const daysSize = Math.ceil(graphData.length / daysDivider);
        if (
          monthOrYearGraphData.length === 0 ||
          monthOrYearGraphData[index].daysCombined === daysSize
        ) {
          monthOrYearGraphData.push({
            daysCombined: 1,
            netProfit: g.netProfit / 100,
            days: [g.day],
          });
        } else {
          monthOrYearGraphData[index].netProfit += g.netProfit / 100;
          monthOrYearGraphData[index].daysCombined++;
          monthOrYearGraphData[index].days.push(g.day);
        }
        return monthOrYearGraphData;
      },
      []
    );
  }

  private createYearData(
    graphData: BetSlipsGraph[]
  ): BetSlipsGraphByMonthOrYear[] {
    return graphData.reduce(
      (
        monthOrYearGraphData: BetSlipsGraphByMonthOrYear[],
        g: BetSlipsGraph
      ) => {
        const index =
          monthOrYearGraphData.length > 0 ? monthOrYearGraphData.length - 1 : 0;
        if (
          monthOrYearGraphData.length === 0 ||
          (monthOrYearGraphData[index]?.month || 0) !==
            new Date(g.day).getMonth()
        ) {
          monthOrYearGraphData.push({
            month:
              monthOrYearGraphData.length > 0
                ? monthOrYearGraphData[index].month + 1
                : 0,
            netProfit: g.netProfit / 100,
            days: [g.day],
          });
        } else {
          monthOrYearGraphData[index].netProfit += g.netProfit / 100;
          monthOrYearGraphData[index].days.push(g.day);
        }
        return monthOrYearGraphData;
      },
      []
    );
  }

  public selectBetSlipGroup(
    betSlipGroup: BetSlipsByGroup,
    groupName: string,
    key: 'league' | 'marketProposition' | 'team' | 'player'
  ) {
    this.filter = {
      key,
      groupName,
      currentMonth: this.currentMonth,
      currentDateFilter: this.currentDateFilter,
      currentWeekIndex: this.currentWeekIndex,
    };
    if (!this.isMobile) {
      this.filter$.emit({ ...this.filter });
      return;
    }
    this.innerMode = true;
    this.getBetSkipHistory();
  }

  public backButtonHandler(): void {
    if (!this.isMobile) {
      return;
    }
    if (!this.currentGraphRecord && !this.currentBetSlips) {
      this.router.navigateByUrl('/my/builder/dashboard');
      return;
    }
    this.innerMode = false;
    this.currentGraphRecord = null;
    this.currentBetSlips = null;
    this.updateGraph();
  }

  private updateGraph(): void {
    this.generateChartData();
  }

  public navigateDate(step: number): void {
    switch (this.currentDateFilter) {
      case BetHistoryDateFilter.week:
        this.currentWeekIndex += step;
        if (!this.isMobile) {
          this.currentWeekChange$.emit(this.currentWeekIndex);
        }
        break;
      case BetHistoryDateFilter.month:
        this.currentMonth += step;
        if (this.currentMonth === -1) {
          this.currentMonth = 11;
          this.currentYear -= 1;
        }
        if (this.currentMonth === 12) {
          this.currentMonth = 0;
          this.currentYear += 1;
        }
        if (!this.isMobile) {
          this.currentMonthChange$.emit(this.currentMonth);
        }
        break;
    }
    this.getBetSkipHistory();
  }

  private formattingDate(day: string, format: string): string {
    return this.datePipe.transform(day, format);
  }

  public currencyChange(currency: UnitUsd): void {
    this.currentCurrency = currency;
    if (!this.isMobile) {
      this.currencyChange$.emit(this.currentCurrency);
    }
  }

  public onScrollDown(): void {
    if (
      !this.currentBetSlips ||
      this.currentBetSlips?.total === this.currentBetSlips?.items?.length
    ) {
      return;
    }
    this.currentPage++;
    this.fetchBetSlipsOnLazyLoad();
  }

  private fetchBetSlipsOnLazyLoad(): void {
    this.showInnerLoader = true;
    const filter = `&${this.filter.key}=${this.filter.groupName}&page.index=${this.currentPage}`;
    this.sharpSportsService
      .getBetSlipsByDate(
        timeZoneFix(this.startDay),
        timeZoneFix(this.endDay),
        filter
      )
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => {
          this.loaderService.hide();
          this.showInnerLoader = false;
        })
      )
      .subscribe((res: Pageable<BetSlips>) => {
        this.currentBetSlips = {
          ...res,
          items: [...this.currentBetSlips.items, ...res.items],
        };
      });
  }

  public imageNotFoundHandler(name: string) {
    this.notFoundImages.push(name);
  }
}
