import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { UserUpdateReq, UserAccount } from '../types/user';
import { map, tap } from 'rxjs/operators';
import { AUTH_HEADER, GUEST_ID } from '../constants/headers';
import { TOKEN, VENUE_ID } from '../constants/storage-keys';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FireBaseService } from './fire-base.service';
import firebase from 'firebase/compat';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private authUrl = `${environment.apiUrl}/client/auth`;
  public redirectUrl?: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private auth: AngularFireAuth,
    private fireBaseService: FireBaseService,
    private storageService: StorageService,
    private userService: UserService
  ) {}

  get token(): string {
    return this.storageService.getFromStorage(TOKEN);
  }

  public login(token: string): Observable<UserAccount> {
    const guest: string = this.storageService.getFromStorage(GUEST_ID);
    const headers = guest
      ? { ['Guest-id']: guest }
      : { [AUTH_HEADER]: `Bearer ${token}` };
    return this.http
      .get<UserAccount>(`${this.authUrl}`, {
        observe: 'response',
        headers,
      })
      .pipe(
        tap((response: HttpResponse<UserAccount>) => {
          this.userService.user = response.body.account;
          this.updateToken(token);
        }),
        map(
          (userResponse: HttpResponse<UserAccount>) =>
            userResponse.body as UserAccount
        )
      );
  }

  public registration(
    token: string,
    payload: UserUpdateReq
  ): Observable<UserAccount> {
    return this.http
      .post<UserAccount>(`${this.authUrl}`, payload, {
        observe: 'response',
        headers: {
          [AUTH_HEADER]: `Bearer ${token}`,
        },
      })
      .pipe(
        tap((response: HttpResponse<UserAccount>) => {
          this.userService.user = response.body.account;
          this.updateToken(token);
        }),
        map(
          (userResponse: HttpResponse<UserAccount>) =>
            userResponse.body as UserAccount
        )
      );
  }

  public refreshToken(): Promise<void> {
    this.storageService.removeFromStorage(TOKEN);
    return this.auth.currentUser
      .then((user: firebase.User) => user.getIdToken(true))
      .then((token: string) => this.updateToken(token));
  }

  public removeUserDataAndNavigateToAuth(): void {
    this.userService.user = null;
    this.storageService.removeFromStorage(TOKEN);
    this.storageService.removeFromStorage(VENUE_ID);
    this.storageService.removeFromStorage(GUEST_ID);
    location.reload(); // TODO REMOVE
    this.router.navigateByUrl('/');
  }

  private updateToken(token: string): void {
    const t = token.includes('Bearer ') ? token : `Bearer ${token}`;
    this.storageService.saveToStorage(TOKEN, t, localStorage);
  }

  public async logOut(): Promise<void> {
    await this.fireBaseService.signOut();
    this.removeUserDataAndNavigateToAuth();
  }
}
