import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { BankrollBuilderComponent } from './bankroll-builder.component';
import { BuilderDashboardComponent } from './pages/builder-dashboard/builder-dashboard.component';
import { BuilderOverviewComponent } from './pages/builder-dashboard/pages/builder-overview/builder-overview.component';
import { BuilderMyBetsComponent } from './pages/builder-dashboard/pages/builder-my-bets/builder-my-bets.component';
import { BuilderHistoryComponent } from './pages/builder-dashboard/pages/builder-overview/pages/builder-history/builder-history.component';
import { BuilderOverviewHomeComponent } from './pages/builder-dashboard/pages/builder-overview/pages/builder-overview-home/builder-overview-home.component';
import { BuilderIntroComponent } from './pages/builder-intro/builder-intro.component';
import { BankrollBuilderGuard } from '../../../../../common/guards/bankroll-builder.guard';
import { builder } from '../../../../../common/constants/breadcrumbs';
import { SharpSportsComponent } from './pages/sharp-sports/sharp-sports.component';
import { BookSelectComponent } from './pages/sharp-sports/pages/book-select/book-select.component';
import { RegionSelectComponent } from './pages/sharp-sports/pages/region-select/region-select.component';
import { BuilderLeaderboardComponent } from './pages/builder-dashboard/pages/builder-leaderboard/builder-leaderboard.component';
import { BuilderAccountsComponent } from './pages/builder-accounts/builder-accounts.component';
import { BuilderTodayBetsComponent } from './pages/builder-dashboard/pages/builder-overview/pages/builder-today-bets/builder-today-bets.component';
import { ContestRulesDesktopComponent } from '../../../../../desktop/modules/main-desktop/pages/bankroll-builder-desktop/pages/contest-rules-desktop/contest-rules-desktop.component';
import { ContestRulesComponent } from './pages/contest-rules/contest-rules.component';

const routes: Routes = [
  {
    path: '',
    component: BankrollBuilderComponent,
    canActivate: [BankrollBuilderGuard],
    data: { breadcrumb: builder },
    children: [
      {
        path: 'dashboard',
        component: BuilderDashboardComponent,
        data: { breadcrumb: builder },
        children: [
          {
            path: 'overview',
            component: BuilderOverviewComponent,
            data: { breadcrumb: builder },
            children: [
              {
                path: '',
                component: BuilderOverviewHomeComponent,
                data: { breadcrumb: builder },
              },
              {
                path: 'history',
                component: BuilderHistoryComponent,
                data: { breadcrumb: builder },
              },
              {
                path: 'today-bets',
                component: BuilderTodayBetsComponent,
                data: { breadcrumb: builder },
              },
            ],
          },
          {
            path: 'my-bets',
            component: BuilderMyBetsComponent,
            data: { breadcrumb: builder },
          },
          {
            path: 'leaderboard',
            data: { breadcrumb: builder },
            component: BuilderLeaderboardComponent,
          },
          {
            path: '',
            redirectTo: 'overview',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'accounts',
        component: BuilderAccountsComponent,
        data: { breadcrumb: builder },
      },
      {
        path: 'contest-rules',
        component: ContestRulesComponent,
        data: { breadcrumb: builder },
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'intro',
    component: BuilderIntroComponent,
    data: { breadcrumb: builder },
  },
  {
    path: 'sharp-sports',
    component: SharpSportsComponent,
    children: [
      {
        path: 'book-select',
        data: { breadcrumb: builder },
        component: BookSelectComponent,
      },
      {
        path: 'region-select/:bookId',
        data: { breadcrumb: builder },
        component: RegionSelectComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class BankrollBuilderRoutingModule {}
