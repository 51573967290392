import { Event } from '../../../common/types/event';

export enum EventType {
  Featured = 'Featured',
  Calendar = 'Calendar',
}

export interface EventWithCategory {
  [key: string]: Event[];
}
