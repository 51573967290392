import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SeasonTicketDatesPageComponent } from './pages/season-ticket-dates-page/season-ticket-dates-page.component';
import { BuySeasonTicketComponent } from './pages/buy-season-ticket/buy-season-ticket.component';
import { UserSeasonTicketComponent } from './pages/user-season-ticket/user-season-ticket.component';
import { SeasonTicketAreaSelectPageComponent } from './pages/season-ticket-area-select-page/season-ticket-area-select-page.component';
import { CheckoutSeasonTicketPageComponent } from './pages/checkout-season-ticket-page/checkout-season-ticket-page.component';
import { AreaViewPageComponent } from './pages/area-view-page/area-view-page.component';
import {
  areaRoomBreadcrumb,
  areaSelectBreadcrumb,
  buyBreadcrumb,
  checkoutBreadcrumb,
  checkoutGeneralBreadcrumb,
  datesBreadcrumb,
  eventBreadcrumb,
  reservationBreadcrumb,
  stripeBreadcrumb,
  stripeGeneralBreadcrumb,
  ticketsBreadcrumb,
  ticketShareBreadcrumb,
} from '../../../../../common/constants/breadcrumbs';
import { SeasonTicketReservationComponent } from './pages/season-ticket-reservation/season-ticket-reservation.component';
import { SeasonTicketPreviewComponent } from './pages/season-ticket-preview/season-ticket-preview.component';
import { SeasonTicketShareComponent } from './pages/season-ticket-share/season-ticket-share.component';
import { SeasonTicketStripeCheckoutComponent } from './pages/season-ticket-stripe-checkout/season-ticket-stripe-checkout.component';
import { SeasonTicketScheduleComponent } from '../../../season-ticket-schedule/season-ticket-schedule.component';

const routes: Routes = [
  {
    path: '',
    data: { breadcrumb: buyBreadcrumb },
    component: BuySeasonTicketComponent,
  },
  {
    path: 'dates/:eventId',
    data: { breadcrumb: datesBreadcrumb },
    component: SeasonTicketDatesPageComponent,
  },
  {
    path: 'area-select/:eventId',
    data: { breadcrumb: areaSelectBreadcrumb },
    component: SeasonTicketAreaSelectPageComponent,
  },
  {
    path: 'area-room/:eventId/:areaId',
    data: { breadcrumb: areaRoomBreadcrumb },
    component: AreaViewPageComponent,
  },
  {
    path: 'ticket-view/:eventId/:areaId/:tableId',
    data: { breadcrumb: checkoutBreadcrumb },
    component: CheckoutSeasonTicketPageComponent,
  },
  {
    path: 'ticket-view/:eventId',
    data: { breadcrumb: checkoutGeneralBreadcrumb, isGeneral: true },
    component: CheckoutSeasonTicketPageComponent,
  },
  {
    path: 'checkout/:eventId/:areaId/:tableId',
    data: { breadcrumb: stripeBreadcrumb },
    component: SeasonTicketStripeCheckoutComponent,
  },
  {
    path: 'checkout/:eventId/:seatsCount',
    data: { breadcrumb: stripeGeneralBreadcrumb },
    component: SeasonTicketStripeCheckoutComponent,
  },
  {
    path: 'reservations',
    data: { breadcrumb: reservationBreadcrumb },
    component: SeasonTicketReservationComponent,
  },
  {
    path: 'ticket/:ticketId',
    data: { breadcrumb: ticketsBreadcrumb },
    component: SeasonTicketPreviewComponent,
  },
  {
    path: 'share-ticket/:ticketId',
    data: { breadcrumb: ticketShareBreadcrumb },
    component: SeasonTicketShareComponent,
  },
  {
    path: 'schedule',
    data: { breadcrumb: eventBreadcrumb },
    component: SeasonTicketScheduleComponent,
  },
  {
    path: 'my',
    component: UserSeasonTicketComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class SeasonTicketRoutingModule {}
