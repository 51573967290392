import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PurchasedTicket, TicketDate, User } from '../../types/user';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ShareForm } from '../../../mobile/modules/main/pages/season-ticket/pages/season-ticket-share/share-form';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { TicketsService } from '../../services/tickets.service';
import { PopupService } from '../../services/popup.service';
import { LoaderService } from '../../services/loader.service';
import { LayoutConfigService } from '../../services/layout-config.service';
import { filter, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { regexp } from '../../validators/regexp';
import { Message } from '../../enum/message';
import { TicketType } from '../../types/ticket-type';
import { BaseComponent } from '../base/base.component';
import { TicketPreviewDetailsComponent } from '../../../mobile/modules/main/pages/season-ticket/components/ticket-preview-details/ticket-preview-details.component';
import { BreadcrumbComponent } from '../../../mobile/modules/components/layout/breadcrumb/breadcrumb.component';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-share-form-and-ticket',
  standalone: true,
  imports: [
    CommonModule,
    DatePipe,
    BreadcrumbComponent,
    TicketPreviewDetailsComponent,
    ReactiveFormsModule,
    ShareFormAndTicketComponent,
  ],
  templateUrl: './share-form-and-ticket.component.html',
  styleUrls: ['./share-form-and-ticket.component.css'],
})
export class ShareFormAndTicketComponent
  extends BaseComponent
  implements OnInit
{
  @Input() public isMobile: boolean = true;
  @Input() public sharedTicketsCount: number;
  @Input() public ticketDate: TicketDate;
  public ticket: PurchasedTicket;
  public formGroup: FormGroup<ShareForm>;
  public canShare: boolean;
  public user: User = this.userService.user;
  public controlNames: string[];
  public sharedSeatsCount: number;
  public availableSeats = 0;

  constructor(
    private router: Router,
    private userService: UserService,
    private ticketsService: TicketsService,
    private popupService: PopupService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private layoutConfigService: LayoutConfigService
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
    this.fetchTickets();
  }

  public handleShareButton(): void {
    if (!this.formGroup.valid) {
      return;
    }
    this.canShare ? this.handleShareTicket() : this.handleShowTicket();
  }

  private fetchTickets(): void {
    const ticketId = this.activatedRoute.snapshot.paramMap.get('ticketId');
    if (!ticketId) {
      return;
    }
    if (this.isMobile) {
      this.loaderService.show();
    }
    this.ticketsService
      .getUserTicketsById(ticketId)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe((ticket: PurchasedTicket) => {
        this.ticket = ticket;
        this.availableSeats =
          this.ticket.table.seatsCount - this.sharedTicketsCount;
        this.sharedSeatsCount = this.availableSeats > 0 ? 1 : 0;
      });
  }

  private initForm(): void {
    this.formGroup = new FormGroup<ShareForm>({
      firstName: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      lastName: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      phone: new FormControl('', {
        validators: [
          Validators.required,
          Validators.pattern(regexp.phoneNumber),
        ],
        nonNullable: true,
      }),
    });
    this.controlNames = Object.keys(this.formGroup.controls);
  }

  private handleShowTicket() {
    this.canShare = true;
  }

  private handleShareTicket() {
    this.loaderService.show();
    const { firstName, lastName } = this.formGroup.getRawValue();
    this.ticketsService
      .shareTicket(
        this.ticket.id,
        this.formGroup.getRawValue(),
        this.sharedSeatsCount,
        [this.ticketDate.date]
      )
      .pipe(
        filter((response) => response?.message === Message.created),
        switchMap(
          () =>
            this.popupService.showSharedSuccessPopup(
              firstName,
              lastName,
              this.isMobile
            ).action$
        ),
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe(() =>
        this.router.navigate([`/my/season-tickets/reservations`], {
          queryParams: { filterType: TicketType.shared },
        })
      );
  }

  public selectSeatsCount(num: number): void {
    if (
      (this.sharedSeatsCount > 1 && num === -1) ||
      (this.sharedSeatsCount < this.availableSeats && num === 1)
    ) {
      this.sharedSeatsCount += num;
    }
  }
}
