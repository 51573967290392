import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PredictionTournament } from '../types/venue-prediction';
import { Pageable } from '../types/pageable';
import { environment } from '../../../environments/environment';
import {
  AnswerRequest,
  Prediction,
  UserPrediction,
  UserPredictionStatus,
} from '../types/predictions';
import { map } from 'rxjs/operators';
import { ResponseView } from '../enum/response-view';
import { LeaderboardScores } from '../types/leaderboards';

@Injectable({
  providedIn: 'root',
})
export class VenuePredictAndWinService {
  public currentLeaderBoardId: string;

  constructor(private httpClient: HttpClient) {}

  public getPredictionTournaments(
    venueId: string
  ): Observable<Pageable<PredictionTournament>> {
    return this.httpClient.get<Pageable<PredictionTournament>>(
      `${environment.apiUrl}/client/venues/${venueId}/prediction-leaderboards`
    );
  }

  public getUserPredictionsFilteredByStatus(
    predictionTournamentId: string,
    page: number = 0,
    status: UserPredictionStatus = 'resolved',
    pageSize: number = 10
  ): Observable<Pageable<UserPrediction>> {
    return this.httpClient
      .get<Pageable<UserPrediction>>(
        `${environment.apiUrl}/client/my-venue-prediction-leaderboards/${predictionTournamentId}/predictions?page.index=${page}&page.size=${pageSize}`
      )
      .pipe(
        map((res: Pageable<UserPrediction>) => ({
          ...res,
          items: res.items.filter(
            (item: UserPrediction) => item.status === status
          ),
        }))
      );
  }

  public getLeaderboardScores(
    leaderboardId: string,
    currentPage: number = 0,
    resView: ResponseView = ResponseView.full
  ): Observable<Pageable<LeaderboardScores>> {
    return this.httpClient.get<Pageable<LeaderboardScores>>(
      `${environment.apiUrl}/client/venue-prediction-leaderboards/${leaderboardId}/scores?page.index=${currentPage}&page.size=10&response.view=${resView}`
    );
  }

  public submitAnswer(answer: AnswerRequest): Observable<{ message: string }> {
    return this.httpClient.post<{ message: string }>(
      `${environment.apiUrl}/client/my-venue-leaderboard-predictions`,
      answer
    );
  }

  public getPredictions(
    predictionTournamentId: string
  ): Observable<Pageable<Prediction>> {
    return this.httpClient.get<Pageable<Prediction>>(
      `${environment.apiUrl}/client/venue-prediction-leaderboards/${predictionTournamentId}/predictions?page.index=0&page.size=1000`
    );
  }

  public markPredictionAsSeen(
    predictionId: string
  ): Observable<{ message: string }> {
    return this.httpClient.put<{ message: string }>(
      `${environment.apiUrl}/client/my-venue-leaderboard-predictions/${predictionId}/see`,
      null
    );
  }
}
