import { NgModule } from '@angular/core';
import { SelectVenueDesktopComponent } from './select-venue-desktop.component';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SelectVenueSharedComponent } from '../../../../../common/components/bankroll-builder/select-venue-shared/select-venue-shared.component';

const routes: Routes = [
  {
    path: '',
    component: SelectVenueDesktopComponent,
  },
];

@NgModule({
  imports: [
    //modules
    CommonModule,
    RouterModule,
    RouterModule.forChild(routes),
    // Components
    SelectVenueSharedComponent,
  ],
  declarations: [SelectVenueDesktopComponent],
})
export class SelectVenueDesktopModule {}
