import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClubComponent } from './pages/club/club.component';
import { ClubRoutingModule } from './club-routing.module';
import { TripleseatComponent } from '../components/tripleseat/tripleseat.component';
import { ResyButtonComponent } from '../components/resy-button/resy-button.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ClubComponent],
  imports: [
    CommonModule,
    ClubRoutingModule,
    TripleseatComponent,
    ResyButtonComponent,
    ReactiveFormsModule,
  ],
})
export class ClubModule {}
