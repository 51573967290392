import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WelcomeDrinkPageComponent } from './welcome-drink-page.component';
import { WelcomeDrinkComponent } from '../../../common/welcome-drink/welcome-drink.component';

const route = [
  {
    path: '',
    component: WelcomeDrinkPageComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WelcomeDrinkComponent,
    RouterModule.forChild(route),
  ],
  declarations: [WelcomeDrinkPageComponent],
  providers: [],
})
export class WelcomeDrinkPageModule {}
