import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProsNews, ProsNewsType } from '../../../types/pro-news';
import { PopupService } from '../../../services/popup.service';
import { SanitizeHtmlPipe } from '../../../pipes/sanitize-html.pipe';
import { AssetsUrlPipe } from '../../../pipes/assets-url.pipe';
import { Router } from '@angular/router';
import { CommunityService } from '../../../services/community.service';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss'],
  standalone: true,
  imports: [CommonModule, SanitizeHtmlPipe, AssetsUrlPipe],
})
export class NewsItemComponent {
  @Input() public news: ProsNews;
  @Input() public type: ProsNewsType;
  @Input() public isMobile = true;

  constructor(
    private popupService: PopupService,
    private communityService: CommunityService
  ) {}

  public handleItemClick(): void {
    if (this.isMobile) {
      this.showNewsDetailsPopup();
    } else {
      this.communityService.selectNewsHandler(this.news);
    }
  }

  private showNewsDetailsPopup() {
    this.popupService.showNewsDetailsPopup(this.news);
  }
}
