import { Component } from '@angular/core';
import { Venue } from '../../../../../../common/types/sharp-sports/venues';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-venue',
  templateUrl: './select-venue.component.html',
  styleUrls: ['./select-venue.component.css'],
})
export class SelectVenueComponent {
  constructor(private router: Router) {}

  public onVenueSelect(venue: Venue) {
    this.router.navigateByUrl(`/my/predict-and-win/${venue.id}`);
  }
}
