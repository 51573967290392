import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { regexp } from '../../../common/validators/regexp';
import { SubscriptionService } from '../../../common/services/subscription.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.scss'],
})
export class ClubComponent {
  @ViewChild('formPopup', { read: ElementRef, static: true })
  public formPopup: ElementRef<HTMLDivElement>;
  public emailFormControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(regexp.email),
  ]);
  public submitted = false;
  public bigGameRoomResyKey = environment.bigGameRoomResyKey;

  constructor(private subscriptionService: SubscriptionService) {}

  public closeFormPopup(): void {
    this.formPopup.nativeElement.style.display = 'none';
  }

  public openFormPopup(): void {
    this.formPopup.nativeElement.style.display = 'flex';
  }

  public submitEmail(): void {
    const payload = {
      name: 'Email Form',
      source: 'https://app.bankrollclub.com/',
      test: false,
      'fields[Email]': this.emailFormControl.value,
      dolphin: false,
    };

    this.subscriptionService
      .emailSubscription(this.formatToUrlEncodedForm(payload))
      .subscribe(() => (this.submitted = true));
  }

  private formatToUrlEncodedForm(details): any {
    const formBody = [];
    for (const property in details) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }
}
