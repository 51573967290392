import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MainComponent } from './main.component';
import { MainRoutingModule } from './main-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthInterceptor } from '../../../common/interceptors/auth';
import { AccountComponent } from './pages/account/account.component';
import { AccountUpgradeComponent } from './pages/account/components/account-upgrade/account-upgrade.component';
import { CardBenefitsComponent } from './pages/account/components/card-benefits/card-benefits.component';
import { EditAccountComponent } from './pages/account/components/edit-account/edit-account.component';
import { SeasonTicketModule } from './pages/season-ticket/season-ticket.module';
import { AboutComponent } from './pages/about/about.component';
import { CommunityComponent } from './pages/community/community.component';
import { ForumsComponent } from './pages/forums/forums.component';
import { ConciergeComponent } from './pages/concierge/concierge.component';
import { VenueComponent } from './pages/venue/venue.component';
import { TeamComponent } from './pages/team/team.component';
import { CareersComponent } from './pages/careers/careers.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { EventNameAsyncPipe } from '../../../common/pipes/event-name-async.pipe';
import { VerificationPopupComponent } from '../../../common/components/popups/verification-popup/verification-popup.component';
import { CommunityModule } from './pages/community/community.module';
import { BasePopupComponent } from '../../../common/components/base/base-popup/base-popup.component';
import { PredictAndWinService } from '../../../common/services/predict-and-win.service';
import { BreadcrumbComponent } from '../components/layout/breadcrumb/breadcrumb.component';
import { HeaderComponent } from '../components/layout/header/header.component';
import { FooterComponent } from '../components/layout/footer/footer.component';
import { NextTicketComponent } from '../components/layout/next-ticket/next-ticket.component';
import { ResyButtonComponent } from '../../../components/resy-button/resy-button.component';

@NgModule({
  imports: [
    // Modules
    CommonModule,
    FormsModule,
    MainRoutingModule,
    SeasonTicketModule,
    HttpClientModule,
    RouterModule,
    CommunityModule,
    // Pipes
    EventNameAsyncPipe,
    // Components
    VerificationPopupComponent,
    BreadcrumbComponent,
    BasePopupComponent,
    HeaderComponent,
    FooterComponent,
    NextTicketComponent,
    ResyButtonComponent,
  ],
  declarations: [
    MainComponent,
    AccountComponent,
    AccountUpgradeComponent,
    CardBenefitsComponent,
    EditAccountComponent,
    AboutComponent,
    CommunityComponent,
    ForumsComponent,
    ConciergeComponent,
    VenueComponent,
    TeamComponent,
    CareersComponent,
    ContactUsComponent,
  ],
  providers: [
    PredictAndWinService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class MainModule {}
