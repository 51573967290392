import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer-desktop',
  templateUrl: './footer-desktop.component.html',
  styleUrls: ['./footer-desktop.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class FooterDesktopComponent implements OnInit {
  public year: number = new Date().getFullYear();

  constructor() {}

  ngOnInit() {}
}
