import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNotExpired',
  standalone: true,
})
export class IsNotExpiredPipe implements PipeTransform {
  transform(date: string, minutes: number): boolean {
    return Date.now() < Date.parse(date) + minutes * 60 * 1000;
  }
}
