import { BaseComponent } from '../components/base/base.component';
import {
  getDateNDaysAgo,
  getTodayDateAtMidnight,
  timeZoneFix,
} from '../helpers/date-time';
import {
  finalize,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import {
  DailyBetSips,
  OpenSettledCurrentBetSlips,
} from '../types/sharp-sports/bet-slips';
import { SharpSportsUserMetadata } from '../types/sharp-sports/sharp-sports';
import { interval, Observable, of, Subscription } from 'rxjs';
import { UserBookAccounts } from '../types/sharp-sports/sport-books';
import { LoaderService } from '../services/loader.service';
import { SharpSportsService } from '../services/sharp-sports.service';
import { UserService } from '../services/user.service';

export abstract class BuilderOverviewBaseComponent extends BaseComponent {
  public userBookAccounts$: Observable<UserBookAccounts[]>;
  public userCurrentBetSlips$: Observable<OpenSettledCurrentBetSlips>;
  public betSlipsHistory$: Observable<DailyBetSips[]>;
  public unitSize: number;
  public userId = this.userService.user.id;
  private reFetchSub!: Subscription;
  private twoAndHalfMinutesTimestamp = 60 * 2.5 * 1000;

  protected constructor(
    private loaderService: LoaderService,
    private sharpSportsService: SharpSportsService,
    private userService: UserService
  ) {
    super();
  }

  protected init(): void {
    this.fetchData();
    this.reFetchData();
  }

  protected destroy(): void {
    this.reFetchSub?.unsubscribe();
    this.sharpSportsService.skippedAccountConnect = false;
  }

  private fetchData(): void {
    this.loaderService.show();
    const startDate = getDateNDaysAgo(7);
    const yesterday = getDateNDaysAgo(1);
    this.userBookAccounts$ = this.sharpSportsService
      .getUserAccounts(this.userId)
      .pipe(takeUntil(this.unsubscribe$));
    this.userCurrentBetSlips$ = this.sharpSportsService
      .getUserCurrentBetSlips(
        this.userId,
        getTodayDateAtMidnight('start').timestamp
      )
      .pipe(shareReplay(1), takeUntil(this.unsubscribe$));
    this.betSlipsHistory$ = this.sharpSportsService
      .getDailyBetSlips(timeZoneFix(startDate), timeZoneFix(yesterday))
      .pipe(
        shareReplay(1),
        map((res: DailyBetSips[]) =>
          res.sort(
            (a: DailyBetSips, b: DailyBetSips) =>
              Date.parse(b.day) - Date.parse(a.day)
          )
        ),
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      );
    this.sharpSportsService
      .getUserMetadata(this.userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: SharpSportsUserMetadata) => (this.unitSize = res.unitSize)
      );
  }

  private reFetchData(): void {
    this.reFetchSub = interval(this.twoAndHalfMinutesTimestamp)
      .pipe(
        shareReplay(1),
        tap(() => this.fetchData())
      )
      .subscribe();
  }
}
