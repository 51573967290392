import { Pipe, PipeTransform } from '@angular/core';
import { EventWithCategory } from '../../mobile/modules/season-ticket-schedule/event';

@Pipe({
  name: 'eventIsEmpty',
  standalone: true,
})
export class EventIsEmptyPipe implements PipeTransform {
  transform(
    filteredEvents: EventWithCategory,
    featuredEvents: EventWithCategory
  ): boolean {
    if (!filteredEvents || !featuredEvents) {
      return;
    }
    const events = Object.values({
      ...filteredEvents,
      ...featuredEvents,
    })
      .flat()
      .filter((e) => !!e);
    return events.length === 0;
  }
}
