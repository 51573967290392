import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseMessage } from '../types/response-message';

@Injectable({
  providedIn: 'root',
})
export class SalesService {
  constructor(private httpClient: HttpClient) {}

  public buyTicket(
    event: string,
    table: string,
    dates: string[]
  ): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>(
      `${environment.apiUrl}/client/my-tickets`,
      { event, table, dates }
    );
  }
}
