import {Pipe, PipeTransform} from '@angular/core';
import {PurchasedTicket, TicketDate} from '../types/user';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'ticketFilter',
  standalone: true,
})
export class TicketFilterPipe implements PipeTransform {
  constructor(private datePipe: DatePipe
  ) {
  }

  transform(ticket: PurchasedTicket, month: string): TicketDate[] {
    if (!month || !ticket) {
      return [];
    }
    return ticket.dates.filter((date:TicketDate) =>
      month === this.datePipe.transform(date.date, 'MMM')
    );
  }
}
