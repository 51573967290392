import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SharpSportsService } from '../../../services/sharp-sports.service';
import { environment } from '../../../../../environments/environment';
import { openSharpSports } from '../../../helpers/postMessage';
import { UserService } from '../../../services/user.service';
import { TOKEN } from '../../../constants/storage-keys';
import { StorageService } from '../../../services/storage.service';
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'app-builder-intro-shared',
  templateUrl: './builder-intro-shared.component.html',
  styleUrls: ['./builder-intro-shared.component.css'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class BuilderIntroSharedComponent implements OnInit {
  @Input() isMobile = true;
  @Output() showAccountPopup: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sportsService: SharpSportsService,
    private loaderService: LoaderService,
    private userService: UserService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    const redirectUrl = this.sportsService.redirectUrl;
    this.sportsService.redirectUrl =
      this.activatedRoute.snapshot.queryParamMap.get('redirectTo') ??
      redirectUrl;
  }

  public clickHandler(): void {
    if (environment.production) {
      this.isMobile
        ? this.router.navigateByUrl('/my/builder/sharp-sports/book-select')
        : this.builderAccountPopupHandler();
    } else {
      this.loaderService.show();
      openSharpSports({
        uid: this.userService.user?.id,
        token: this.storageService.getFromStorage(TOKEN),
      });
    }
  }

  public builderAccountPopupHandler(): void {
    this.showAccountPopup.emit();
  }

  public skipAccountConnect(): void {
    this.sportsService.skippedAccountConnect = true;
    this.router.navigateByUrl(
      this.sportsService.redirectUrl ?? '/my/builder/dashboard/overview'
    );
  }
}
