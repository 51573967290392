import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { regexp } from '../../common/validators/regexp';
import { GoogleSheetService } from '../../common/services/google-sheet.service';
import { switchMap } from 'rxjs';
import { PopupService } from '../../common/services/popup.service';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../../common/services/loader.service';

@Component({
  selector: 'app-vip-membership-form',
  templateUrl: './vip-membership-form.component.html',
  styleUrls: ['./vip-membership-form.component.css'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
})
export class VipMembershipFormComponent implements OnInit {
  @Input() isMobile = false;
  public vipMembershipFormGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private googleSheetService: GoogleSheetService,
    private popupService: PopupService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.vipMembershipFormGroup = this.initForm();
  }

  private initForm(): FormGroup {
    return this.fb.group({
      membershipType: ['Individual', Validators.required],
      honorific: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthday: ['', Validators.required],
      street: ['', Validators.required],
      city: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(regexp.email)]],
      zip: ['', Validators.required],
      mobile: [
        '',
        [Validators.required, Validators.pattern(regexp.phoneNumber)],
      ],
      state: ['', Validators.required],
      maritalStatus: ['', Validators.required],
      linkedin: [''],
      instagram: [''],
      facebook: [''],
      twitter: [''],
    });
  }

  public submitForm() {
    this.loaderService.show();
    const { membershipType, firstName, lastName } =
      this.vipMembershipFormGroup.value;
    const emailBody = {
      membershipType,
      firstName,
      lastName,
    };
    this.googleSheetService
      .addGoogleSheet([this.vipMembershipFormGroup.value])
      .pipe(
        switchMap(() => this.googleSheetService.sendEmail(emailBody)),
        finalize(() => this.loaderService.hide())
      )
      .subscribe(() => {
        this.popupService.showVipMembershipFormSubmitPopup(this.isMobile);
        this.vipMembershipFormGroup.reset();
      });
  }
}
