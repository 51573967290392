import { Component, OnInit } from '@angular/core';
import { BreadcrumbBackground } from '../enum/breadcrumb-background';
import { LayoutConfigService } from '../services/layout-config.service';
import { StaticPagesBaseComponent } from './static-pages-base.component';
import { StaticPagesService } from '../services/static-pages.service';
import { LoaderService } from '../services/loader.service';
import { DynamicPopupService } from '../services/dynamic-popup.service';
import { PageCode } from '../enum/page-code';

@Component({
  template: '',
})
export abstract class PrivateEventsBaseComponent
  extends StaticPagesBaseComponent
  implements OnInit
{
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  public layoutConfig$ = this.layoutConfigService.layoutConfig;

  protected constructor(
    private layoutConfigService: LayoutConfigService,
    staticPagesService: StaticPagesService,
    loaderService: LoaderService,
    private dynamicPopup: DynamicPopupService
  ) {
    super(staticPagesService, loaderService);
  }

  ngOnInit() {
    this.dynamicPopup.showPagePopups(PageCode.privateEvents);
    this.init();
  }
}
