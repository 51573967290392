import { Pipe, PipeTransform } from '@angular/core';
import { EventWithCategory } from '../../mobile/modules/season-ticket-schedule/event';

@Pipe({
  name: 'concatPipe',
  standalone: true,
})
export class ConcatPipe implements PipeTransform {
  transform(value: object, value2: object): EventWithCategory {
    return { ...(value ?? {}), ...(value2 ?? {}) };
  }
}
