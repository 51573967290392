import { Component } from '@angular/core';
import { BaseComponent } from '../components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { StaticPages } from '../types/static-pages';
import { StaticPagesService } from '../services/static-pages.service';
import { LoaderService } from '../services/loader.service';

@Component({
  template: ``,
})
export abstract class StaticPagesBaseComponent extends BaseComponent {
  protected pageId = '';
  protected staticPageData: StaticPages;

  protected constructor(
    protected staticPagesService: StaticPagesService,
    protected loaderService: LoaderService
  ) {
    super();
  }

  protected init(): void {
    if (this.staticPagesService.staticPages) {
      this.loaderService
        .staticLoader(1000)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe();
      this.findCurrentPageData(this.staticPagesService.staticPages);
    } else {
      this.loaderService.show();
      this.staticPagesService
        .getStaticPages()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.findCurrentPageData(this.staticPagesService.staticPages);
          this.loaderService
            .staticLoader(1000)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe();
        });
    }
  }

  private findCurrentPageData(pages: StaticPages[]): void {
    this.staticPageData = pages.find(
      (s: StaticPages) => s.code === this.pageId
    );
  }
}
