import { Component, Input, OnInit } from '@angular/core';
import { PopupsManagerService } from '../../../../../../../common/services/popups-manager.service';
import { Calendar } from '../../../../../../../common/types/calendar';
import { getDatesInMonth } from '../../../../../../../common/helpers/date-timer';
import { StorageService } from '../../../../../../../common/services/storage.service';
import { CommonModule } from '@angular/common';
import { BasePopupComponent } from '../../../../../../../common/components/base/base-popup/base-popup.component';
import { SeasonTicketCalendarComponent } from '../../../../../../../common/components/season-ticket-calendar/season-ticket-calendar.component';

@Component({
  selector: 'app-ticket-dates-popup',
  templateUrl: './ticket-dates-popup.component.html',
  styleUrls: ['./ticket-dates-popup.component.scss'],
  standalone: true,
  imports: [CommonModule, BasePopupComponent, SeasonTicketCalendarComponent],
})
export class TicketDatesPopupComponent implements OnInit {
  @Input() public dates: string[];

  public calendarMap = new Map<string, Calendar>();
  public calendars: string[];

  constructor(
    private popupsManagerService: PopupsManagerService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.generateCalendar();
  }

  public closePopup(): void {
    this.popupsManagerService.hide();
  }

  private generateCalendar(): void {
    const calendars = this.getCalendars(this.dates);
    this.setCalendarDays(calendars, this.dates);
    this.calendars = Array.from(this.calendarMap.keys());
    this.setEmptyDays();
  }

  private getCalendars(dates: string[]): Set<string> {
    return new Set<string>(
      dates.map((date: string) => {
        const [year, month] = date.split('-');
        return `${year}-${month}`;
      })
    );
  }

  private setCalendarDays(
    calendars: Set<string>,
    highlightedDates: string[]
  ): void {
    Array.from(calendars).forEach((calendar) => {
      const monthYear = calendar.split('-');
      const [year, month] = monthYear;
      this.calendarMap.set(calendar, {
        days: getDatesInMonth(
          month,
          year,
          highlightedDates,
          this.storageService
        ),
        startIndex: new Date(+year, +month, 1).getDay(),
      });
    });
  }

  private setEmptyDays(): void {
    this.calendars.forEach((calendar) => {
      const emptyDays = new Array(
        this.calendarMap.get(calendar).startIndex
      ).fill({
        day: null,
        highlighted: false,
      });
      this.calendarMap.get(calendar).days.unshift(...emptyDays);
    });
  }
}
