export const buyBreadcrumb = [
  { url: '/', label: 'Home' },
  { url: '/my/season-tickets', label: 'Buy tickets' },
];

export const profile = [
  { url: '/my/account', label: 'Home' },
  { url: '/my/profile', label: 'Profile' },
];

export const datesBreadcrumb = [
  ...buyBreadcrumb,
  { url: '/my/season-tickets/dates/:eventId', label: 'Dates' },
];

export const areaSelectBreadcrumb = [
  ...datesBreadcrumb,
  { url: '/my/season-tickets/area-select/:eventId', label: 'Areas' },
];

export const areaSelectDesktopBreadcrumb = [
  { url: '/my/account', label: 'Home' },
  { url: '/my/season-tickets', label: 'Buy tickets' },
  { url: '/my/season-tickets/area-select/:eventId', label: 'Areas' },
];

export const areaRoomBreadcrumb = [
  ...areaSelectBreadcrumb,
  { url: '/my/season-tickets/area-room/:eventId/:areaId', label: 'Area Room' },
];

export const checkoutBreadcrumb = [
  ...areaRoomBreadcrumb,
  {
    url: '/my/season-tickets/ticket-view/:eventId/:areaId/:tableId',
    label: 'Ticket View',
  },
];

export const checkoutGeneralBreadcrumb = [
  ...datesBreadcrumb,
  {
    url: '/my/season-tickets/ticket-view/:eventId',
    label: 'Ticket View',
  },
];

export const checkoutDesktopBreadcrumb = [
  ...areaSelectDesktopBreadcrumb,
  {
    url: '/my/season-tickets/ticket-view/:eventId/:areaId/:tableId',
    label: 'Ticket View',
  },
];

export const checkoutGeneralDesktopBreadcrumb = [
  { url: '/my/account', label: 'Home' },
  { url: '/my/season-tickets', label: 'Buy tickets' },
  {
    url: '/my/season-tickets/ticket-view/:eventId',
    label: 'Ticket View',
  },
];

export const stripeBreadcrumb = [
  ...checkoutDesktopBreadcrumb,
  {
    url: '/my/season-tickets/checkout/:eventId/:areaId/:tableId',
    label: 'Checkout',
  },
];

export const stripeGeneralBreadcrumb = [
  ...checkoutGeneralDesktopBreadcrumb,
  {
    url: '/my/season-tickets/checkout/:eventId/:seatsCount',
    label: 'Checkout',
  },
];

export const reservationBreadcrumb = [
  { url: '/my/season-tickets/reservations', label: 'My Reservations' },
];

export const ticketsBreadcrumb = [
  ...reservationBreadcrumb,
  { url: '/my/season-tickets/ticket/:ticketId', label: 'Tickets' },
];

export const ticketShareBreadcrumb = [
  ...ticketsBreadcrumb,
  { url: '/my/season-tickets/share-ticket/:ticketId', label: 'Share ticket' },
];

export const eventBreadcrumb = [
  { url: '/', label: 'Home' },
  { url: '/my/season-tickets/schedule', label: 'Events' },
];

export const community = [
  { url: '/my/account', label: 'Account' },
  { url: '/my/community', label: 'Community' },
];

export const fromPros = [
  { url: '/my/account', label: 'Account' },
  { url: '/my/community/hear-pros', label: 'Hear from the Pros' },
];

export const builder = [
  { url: '/my/account', label: 'Account' },
  { url: '/my/builder', label: 'Bet Lab' },
];

export const predict = [
  { url: '/my/account', label: 'Account' },
  {
    url: '/my/predict-and-win',
    label: 'Games',
  },
];

export const predictDesktop = [
  {
    url: '/',
    label: 'Account',
  },
  {
    url: '/my/predict-and-win/:venueId',
    label: 'Games',
  },
];

export const leaderboard = [
  { url: '/my/community', label: 'Community' },
  {
    url: '/my/predict-and-win/:venueId',
    label: 'Games',
  },
  {
    url: '/my/predict-and-win/leaderboard/:id',
    label: 'Leaderboard',
  },
];

export const predictHistory = [
  { url: '/my/community', label: 'Community' },
  {
    url: '/my/predict-and-win/:venueId',
    label: 'Games',
  },
  {
    url: '/my/predict-and-win/history',
    label: 'History',
  },
];

export const myReservationsBreadcrumb = [
  { url: '/my/account', label: 'Home' },
  { url: '/my/season-tickets/reservations', label: 'My Reservation' },
];

export const ticketsDesktopBreadcrumb = [
  ...myReservationsBreadcrumb,
  { url: '/my/season-tickets/ticket/:ticketId', label: 'Tickets' },
];

export const privateEventsBreadcrumb = [
  { url: '/', label: 'Home' },
  { url: '/private-events', label: 'Private Events' },
];

export const storyBreadcrumb = [
  { url: '/', label: 'Home' },
  { url: '/story', label: 'The Bankroll Story' },
];

export const spaceDesktop = [
  { url: '/', label: 'Home' },
  { url: '/space', label: 'The Space' },
];

export const spaceAreaSelect = [
  { url: '/', label: 'Home' },
  { url: '/space/area-select', label: 'Area Select' },
];

export const spaceAreaView = [
  ...spaceAreaSelect,
  { url: '/space/area-view/:areaId', label: 'Area View' },
];

export const infoPrivateEvents = [
  { url: '/', label: 'Home' },
  { url: '/static/private-events-info', label: 'Private Events' },
];

export const infoSeasonTickets = [
  { url: '/', label: 'Home' },
  { url: '/static/season-tickets-info', label: 'Season Tickets' },
];

export const infoTicketEvents = [
  { url: '/', label: 'Home' },
  { url: '/static/ticket-events-info', label: 'Ticketed Events' },
];

export const infoVipMembership = [
  { url: '/', label: 'Home' },
  { url: '/static/vip-membership-info', label: 'VIP Membership' },
];

export const restaurant = [
  { url: '/', label: 'Home' },
  { url: '/menu', label: 'Dining' },
];

export const menu = [...restaurant, { url: '/menu/:id', label: '{{menu}}' }];

export const privacyBreadcrumb = [
  { url: '/', label: 'Home' },
  { url: '/privacy', label: 'Privacy' },
];
