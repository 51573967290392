import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { BookRegion } from '../../../types/sharp-sports/sport-books';
import { BaseComponent } from '../../base/base.component';
import { Router, RouterModule } from '@angular/router';
import { SharpSportsService } from '../../../services/sharp-sports.service';
import { LoaderService } from '../../../services/loader.service';
import { UserService } from '../../../services/user.service';
import {
  finalize,
  map,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { alphabeticalSort } from '../../../helpers/alphabetical-sort';
import { CommonModule } from '@angular/common';
import { SharpSportsButtonComponent } from '../../sharp-sports-button/sharp-sports-button.component';
import { ImageUrlFormatterPipe } from '../../../pipes/builder/image-url-formatter.pipe';
import { ColorGeneratorPipe } from '../../../pipes/builder/color-generator.pipe';

@Component({
  selector: 'app-region-select-shared',
  templateUrl: './region-select-shared.component.html',
  styleUrls: ['./region-select-shared.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SharpSportsButtonComponent,
    ImageUrlFormatterPipe,
    ColorGeneratorPipe,
  ],
})
export class RegionSelectSharedComponent
  extends BaseComponent
  implements OnInit
{
  @Input() bookId: string;
  @Input() isMobile = true;
  @Output() back$: EventEmitter<null> = new EventEmitter<null>();
  @Output() closePopup$: EventEmitter<void> = new EventEmitter<void>();
  public bookRegion$: Observable<BookRegion[]>;
  public sportBookName: string;
  private userCheckStream$: Subscription;
  public notFoundImages: string[] = [];

  constructor(
    private router: Router,
    private sharpSportsService: SharpSportsService,
    private loaderService: LoaderService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loaderService.show();
    this.bookRegion$ = this.sharpSportsService
      .getBookRegions(this.bookId, 'active')
      .pipe(
        map((br: BookRegion[]) => alphabeticalSort<BookRegion>(br, 'name')),
        map((res: BookRegion[]) => res.filter((b) => !b.mobileOnly)),
        tap(
          (region: BookRegion[]) => (this.sportBookName = region[0]?.book.name)
        ),
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      );
  }

  public regionButtonClick(): void {
    if (!!this.userService?.user?.sharpsportId) {
      return;
    }
    if (!this.userCheckStream$?.closed) {
      this.userCheckStream$?.unsubscribe();
    }
    this.userCheckStream$ = timer(7000, 2000)
      .pipe(
        switchMap(() =>
          this.sharpSportsService.checkForSharpSportsUser(
            this.userService?.user.id
          )
        ),
        tap((userExists: boolean) => userExists && this.navigateToBuilder()),
        takeWhile((res: boolean) => !res),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private navigateToBuilder(): void {
    this.userService
      .getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() =>
        this.router.navigateByUrl(
          this.sharpSportsService.redirectUrl ||
            '/my/builder/dashboard/overview'
        )
      );
  }

  public navigateBack(): void {
    this.isMobile
      ? this.router.navigateByUrl('/my/builder/sharp-sports/book-select')
      : this.back$.emit(null);
  }

  public closeButtonClickHandler() {
    this.isMobile
      ? this.router.navigateByUrl('/my/builder/dashboard')
      : this.closePopup$.emit();
  }

  public imageNotFoundHandler(name: string) {
    this.notFoundImages.push(name);
  }
}
