import { Pipe, PipeTransform } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { map, shareReplay, takeWhile, finalize } from 'rxjs/operators';
import { DateTimer, dateTimerCalculator } from '../helpers/date-timer';

@Pipe({
  name: 'dateTimer',
  standalone: true,
})
export class DateTimerPipe implements PipeTransform {
  transform(
    endDate: number,
    ms: number,
    onFinish: () => void,
    componentContext: any
  ): Observable<DateTimer> {
    return interval(ms).pipe(
      map(() => dateTimerCalculator(endDate)),
      shareReplay(1),
      takeWhile(() => endDate - Date.now() > 0),
      finalize(() => onFinish.call(componentContext))
    );
  }
}
