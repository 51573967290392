import { Pipe, PipeTransform } from '@angular/core';
import { EventDate } from '../types/event';

@Pipe({
  name: 'eventDate',
  standalone: true,
})
export class EventDatePipe implements PipeTransform {
  transform(dates: EventDate[], date: string): EventDate {
    return !date ? dates[0] : dates.find((d) => d.date === date);
  }
}
