import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkWithHref } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MenuItemsFilter } from '../../../../../common/pipes/filter-menu-items';
import { HeaderBaseComponent } from '../../../../../common/base-controllers/header-base.component';
import { MenuItem } from '../../../../../common/types/menu-items';
import { ResyButtonComponent } from '../../../../../components/resy-button/resy-button.component';
import { NameInitialsPipe } from '../../../../../common/pipes/name-initials.pipe';
import { HeaderMenuService } from '../../../../../common/services/header-menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MenuItemsFilter,
    RouterLinkWithHref,
    RouterLink,
    ResyButtonComponent,
    NameInitialsPipe,
  ],
})
export class HeaderComponent extends HeaderBaseComponent implements OnInit {
  @Input() showLogo = true;
  public open = false;

  constructor(
    protected router: Router,
    public headerMenuService: HeaderMenuService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  public toggleMenu(): void {
    this.open = !this.open;
    if (this.open) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }

  public async navigateTo(url: string): Promise<void> {
    if (url === 'resy') {
      return;
    }
    await super.navigateTo(url);
    this.open = false;
    document.body.classList.remove('no-scroll');
  }

  public menuCategoryClick(menu: MenuItem): void {
    if (menu.pages.length > 0) {
      return;
    }
    this.router.navigateByUrl(menu.route);
  }

  public async logOut(): Promise<void> {
    await super.logOut();
  }
}
