import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutDecorator } from '../../../../../../../common/helpers/layout.decorator';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';
import { LayoutConfigService } from '../../../../../../../common/services/layout-config.service';
import { SeasonTicketDatesBaseComponent } from '../../../../../../../common/base-controllers/season-ticket-dates-base.component';
import { SeasonTicketsService } from '../../../../../../../common/services/season-tickets.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../../../../../../../common/services/loader.service';
import { StorageService } from '../../../../../../../common/services/storage.service';

@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
@Component({
  selector: 'app-season-ticket-dates-desktop-page',
  templateUrl: './season-ticket-dates-page.component.html',
  styleUrls: ['./season-ticket-dates-page.component.scss'],
})
export class SeasonTicketDatesPageComponent
  extends SeasonTicketDatesBaseComponent
  implements OnInit, OnDestroy
{
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  constructor(
    private layoutConfigService: LayoutConfigService,
    seasonTicketsService: SeasonTicketsService,
    activatedRoute: ActivatedRoute,
    router: Router,
    loaderService: LoaderService,
    storageService: StorageService
  ) {
    super(
      seasonTicketsService,
      activatedRoute,
      router,
      loaderService,
      storageService
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
