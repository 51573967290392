import { TOKEN } from '../constants/storage-keys';
import { AuthService } from '../services/auth.service';
import { firstValueFrom, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';
import { GUEST_ID, TOKEN_DUMMY_USER } from '../constants/headers';

export const userInitializer = (
  authService: AuthService,
  storageService: StorageService
): any => {
  const guest = storageService.getFromStorage(GUEST_ID);
  const token = guest
    ? (guest as string)
    : (storageService.getFromStorage(TOKEN) as string);
  return () =>
    firstValueFrom(
      from(
        token
          ? authService.login(token).pipe(catchError(() => of(null)))
          : of(null)
      )
    );
};
