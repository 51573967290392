import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InputEventsService {
  constructor() {}

  public static move(): Observable<Event> {
    return merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'touchmove')
    );
  }

  public static start(element: HTMLElement): Observable<Event> {
    return merge<any>(
      fromEvent<Event>(element || window, 'touchstart'),
      fromEvent<Event>(element || window, 'mousedown')
    ) as any;
  }

  public static end(): Observable<Event> {
    return merge(
      fromEvent(window, 'touchend'),
      fromEvent(window, 'mouseup'),
      fromEvent(window, 'contextmenu')
    );
  }
}
