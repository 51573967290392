import { Component, OnInit } from '@angular/core';
import { PrivateEventsBaseComponent } from '../../../common/base-controllers/private-events-base.component';
import { LoaderService } from '../../../common/services/loader.service';
import { LayoutConfigService } from '../../../common/services/layout-config.service';
import { StaticPagesService } from '../../../common/services/static-pages.service';
import { StaticPages } from '../../../common/types/static-pages';
import { StaticPagesId } from '../../../common/enum/static-pages-id';
import { DynamicPopupService } from '../../../common/services/dynamic-popup.service';

@Component({
  selector: 'app-private-events',
  templateUrl: './private-events.component.html',
  styleUrls: ['./private-events.component.scss'],
})
export class PrivateEventsComponent
  extends PrivateEventsBaseComponent
  implements OnInit
{
  override pageId = StaticPagesId.privateEvents;
  override staticPageData: StaticPages;

  constructor(
    layoutConfigService: LayoutConfigService,
    staticPagesService: StaticPagesService,
    loaderService: LoaderService,
    dynamicPopup: DynamicPopupService
  ) {
    super(layoutConfigService, staticPagesService, loaderService, dynamicPopup);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
