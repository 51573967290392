import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StaticPagesComponent } from './static-pages.component';
import {
  infoSeasonTickets,
  infoTicketEvents,
  infoVipMembership,
} from '../../../common/constants/breadcrumbs';
import { SeasonTicketsInfoComponent } from './pages/season-tickets-info/season-tickets-info.component';
import { TicketEventsInfoComponent } from './pages/ticket-events-info/ticket-events-info.component';
import { VipMembershipsInfoComponent } from './pages/vip-memberships-info/vip-memberships-info.component';

const routes: Routes = [
  {
    path: '',
    component: StaticPagesComponent,
    children: [
      {
        path: 'season-tickets-info',
        component: SeasonTicketsInfoComponent,
        data: { breadcrumb: infoSeasonTickets },
      },
      {
        path: 'ticket-events-info',
        component: TicketEventsInfoComponent,
        data: { breadcrumb: infoTicketEvents },
      },
      {
        path: 'vip-membership-info',
        component: VipMembershipsInfoComponent,
        data: { breadcrumb: infoVipMembership },
      },
      {
        path: '',
        redirectTo: 'season-tickets-info',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class StaticPagesRoutingModule {}
