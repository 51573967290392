import { Component, OnInit } from '@angular/core';
import { LayoutDecorator } from '../../../../../../../common/helpers/layout.decorator';
import { CommonModule } from '@angular/common';
import { ShareFormAndTicketComponent } from '../../../../../../../common/components/share-form/share-form-and-ticket.component';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';
import { ActivatedRoute } from '@angular/router';
import { TicketDate } from '../../../../../../../common/types/user';
import { BreadcrumbComponent } from '../../../../../components/layout/breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-season-ticket-share',
  templateUrl: './season-ticket-share.component.html',
  standalone: true,
  styleUrls: ['./season-ticket-share.component.scss'],
  imports: [CommonModule, BreadcrumbComponent, ShareFormAndTicketComponent],
})
@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
export class SeasonTicketShareComponent implements OnInit {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  public ticketDate: TicketDate;
  public sharedTicketsCount: number;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.ticketDate = JSON.parse(
      this.activatedRoute.snapshot.queryParamMap.get('date')
    );
    this.sharedTicketsCount =
      +this.activatedRoute.snapshot.queryParamMap.get('sharedTicketsCount');
  }
}
