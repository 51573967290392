import {
  ComponentRef,
  EventEmitter,
  Injectable,
  Type,
  ViewContainerRef,
} from '@angular/core';

export interface PopupConfig {
  inputs?: any;
  outputs?: { [key: string]: EventEmitter<any> };
  className?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PopupsManagerService {
  public container!: ViewContainerRef;
  public currentPopup?: ComponentRef<unknown> | null;
  public currentWrapper?: HTMLElement;

  public queue: any[] = [];

  constructor() {}

  static apply(target: any, object: any): void {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        target[key] = object[key];
      }
    }
  }

  static createWrapper(): HTMLElement {
    const element = document.createElement('div');
    element.setAttribute('class', 'popup');
    return element;
  }

  public initialize(container: ViewContainerRef): void {
    this.container = container;
  }

  public show<T>(component: Type<T>, config?: PopupConfig): ComponentRef<T> {
    const popup = this.container.createComponent<T>(component, {
      index: this.currentPopup ? this.queue.length + 1 : 0,
      injector: this.container.injector,
    });
    popup.location.nativeElement.parentNode.removeChild(
      popup.location.nativeElement
    );
    if (config?.inputs) {
      PopupsManagerService.apply(popup.instance, config.inputs);
    }
    if (config?.outputs) {
      PopupsManagerService.apply(popup.instance, config.outputs);
    }
    popup.changeDetectorRef.detectChanges();
    if (this.currentPopup) {
      this.queue.push(popup);
      return popup;
    }
    return this.attachPopup(popup);
  }

  public hide(): void {
    this.currentPopup?.destroy();
    this.currentPopup = null;
    this.currentWrapper?.remove();
    this.checkInQueue();
  }

  private attachPopup<T>(popup: ComponentRef<T>): ComponentRef<T> {
    this.currentPopup = popup;
    this.currentPopup.location.nativeElement.classList.add('popup__block');
    this.currentWrapper = PopupsManagerService.createWrapper();
    document.body.insertBefore(this.currentWrapper, document.body.firstChild);
    this.container.insert(popup.hostView);
    this.currentWrapper.appendChild(this.currentPopup.location.nativeElement);
    return this.currentPopup as ComponentRef<T>;
  }

  private checkInQueue(): void {
    const popup = this.queue.shift();
    if (popup) {
      this.attachPopup(popup);
    } else {
      this.container?.clear();
    }
  }
}
