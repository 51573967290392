import { Component, OnDestroy, OnInit } from '@angular/core';
import { BetSlipsType } from '../../../../../../../../../common/enum/bet-slip-type';
import { BuilderOverviewBaseComponent } from '../../../../../../../../../common/base-controllers/builder-overview-base.component';
import { LoaderService } from '../../../../../../../../../common/services/loader.service';
import { SharpSportsService } from '../../../../../../../../../common/services/sharp-sports.service';
import { UserService } from '../../../../../../../../../common/services/user.service';
import { BetSlipsHistoryFilter } from '../../../../../../../../../common/types/sharp-sports/bet-slips';
import { UnitUsd } from '../../../../../../../../../common/enum/unit-usd';

@Component({
  selector: 'app-builder-overview-desktop',
  templateUrl: './builder-overview-desktop.component.html',
  styleUrls: ['./builder-overview-desktop.component.css'],
})
export class BuilderOverviewDesktopComponent
  extends BuilderOverviewBaseComponent
  implements OnInit, OnDestroy
{
  public currentBetSlipType: BetSlipsType;
  public view: 'today' | 'history';
  public historyInnerFilter: BetSlipsHistoryFilter =
    {} as BetSlipsHistoryFilter;
  public currentTodayCurrency: UnitUsd = UnitUsd.usd;
  public currentHistoryCurrency: UnitUsd = UnitUsd.usd;

  constructor(
    loaderService: LoaderService,
    sharpSportsService: SharpSportsService,
    userService: UserService
  ) {
    super(loaderService, sharpSportsService, userService);
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    super.destroy();
  }

  public todayBetSlipsSwitcher(
    $event: BetSlipsType,
    view: 'today' | 'history'
  ): void {
    this.currentBetSlipType = $event;
    this.view = view;
  }

  public historySwitcher(): void {
    this.view = 'history';
  }
}
