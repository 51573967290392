import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ResyButtonComponent } from '../../../../components/resy-button/resy-button.component';
import { RestaurantMenu } from '../../../types/restaurant-menu';
import { environment } from '../../../../../environments/environment';
import { SevenRoomsWidgetComponent } from '../../../../components/seven-rooms-widget/seven-rooms-widget.component';
import { DynamicPopupService } from '../../../services/dynamic-popup.service';
import { PageCode } from '../../../enum/page-code';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ResyButtonComponent,
    SevenRoomsWidgetComponent,
  ],
})
export class RestaurantComponent implements OnInit {
  @Input() isMobile = true;
  @Input() menuData: RestaurantMenu[] = [];
  public bigGameRoomResyKey = environment.bigGameRoomResyKey;
  public diningRoomResyKey = environment.diningRoomResyKey;

  constructor(private dynamicPopup: DynamicPopupService) {}

  ngOnInit() {
    this.dynamicPopup.showPagePopups(PageCode.dining);
  }
}
