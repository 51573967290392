import { Component, OnInit } from '@angular/core';
import { BuySeasonTicketBaseComponent } from '../../../../../../../common/base-controllers/buy-season-ticket-base.component';
import { SeasonTicketsService } from '../../../../../../../common/services/season-tickets.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../../../../../../../common/services/loader.service';
import { Event } from '../../../../../../../common/types/event';
import { SeasonTicketsPages } from '../../../../../../../common/enum/page-state';
import { PageState } from '../../../../../../../common/types/season-tickets-pagesd';
import { StorageService } from '../../../../../../../common/services/storage.service';

@Component({
  selector: 'app-buy-season-ticket-desktop',
  templateUrl: './buy-season-ticket-desktop.component.html',
  styleUrls: ['./buy-season-ticket-desktop.component.scss'],
})
export class BuySeasonTicketDesktopComponent
  extends BuySeasonTicketBaseComponent
  implements OnInit
{
  public currentEvent: Event;
  private selectedEventsCategoryId: string;

  constructor(
    seasonTicketsService: SeasonTicketsService,
    router: Router,
    loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService
  ) {
    super(seasonTicketsService, router, loaderService);
  }

  ngOnInit(): void {
    this.storageService.removeFromStorage(SeasonTicketsPages.selectedAreaId);
    this.getEventsAndCategories().subscribe(() => this.fetchAndSelectEvent());
  }

  public showDates(event: Event) {
    if (!event?.dates?.length) {
      return;
    }
    this.currentEvent = event;
    this.storageService.removeFromStorage('dates');
    this.storageService.removeFromStorage(SeasonTicketsPages.buyTicket);
  }

  public selectEvents(
    categoryId: string,
    selectedOnClick: boolean = false
  ): void {
    super.selectEvents(categoryId);
    this.selectedEventsCategoryId = categoryId;
    this.currentEvent = null;
    if (!selectedOnClick) {
      return;
    }
    this.storageService.removeFromStorage('dates');
    this.storageService.removeFromStorage(SeasonTicketsPages.buyTicket);
  }

  private fetchAndSelectEvent() {
    this.pageStateHandler();
    const eventId = this.activatedRoute.snapshot.queryParamMap.get('eventId');
    const categoryId =
      this.activatedRoute.snapshot.queryParamMap.get('categoryId');
    if (!categoryId) return;
    this.selectEvents(categoryId);
    this.currentEvent = this.findCurrentEvent(eventId);
  }

  public handleSubmit(): void {
    const pageState: PageState = {
      categoryId: this.selectedEventsCategoryId,
      currentEventId: this.currentEvent.id,
    };
    this.storageService.saveToStorage<PageState>(
      SeasonTicketsPages.buyTicket,
      pageState
    );
  }

  private pageStateHandler(): void {
    const pageState = this.storageService.getFromStorage<PageState>(
      SeasonTicketsPages.buyTicket
    ) as PageState;
    if (!pageState) {
      return;
    }
    const { categoryId, currentEventId } = pageState;
    this.selectEvents(categoryId);
    this.currentEvent = this.findCurrentEvent(currentEventId);
  }

  private findCurrentEvent(currentEventId): Event {
    return (
      Object.values(this.selectedEvents)
        .flat()
        .find((e: Event) => e.id === currentEventId) || null
    );
  }
}
