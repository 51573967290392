import { Component } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-season-ticket-stripe-checkout',
  templateUrl: './season-ticket-stripe-checkout.component.html',
  styleUrls: ['./season-ticket-stripe-checkout.component.css'],
})
export class SeasonTicketStripeCheckoutComponent {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  public seatsCount = +this.activatedRoute.snapshot.params.seatsCount;

  constructor(private activatedRoute: ActivatedRoute) {}
}
