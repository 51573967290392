import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys',
  standalone: true
})
export class KeysOtpPipe implements PipeTransform {
  transform(value: any): string[] | null {
    if (!value) return null;
    return Object.keys(value);
  }
}
