import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isIn365days',
  standalone: true,
})
export class IsIn365daysPipe implements PipeTransform {
  transform(year: number, month: number): boolean {
    const todaysDay = new Date().getDate();
    const currentMonth = month + 1;
    const date = new Date(
      `${year}-${currentMonth < 10 ? '0' + currentMonth : currentMonth}-${
        todaysDay < 10 ? '0' + todaysDay : todaysDay
      }`
    ).getTime();
    const timeDifference = new Date().getTime() - date;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return daysDifference <= 365;
  }
}
