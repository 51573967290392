import { Pipe, PipeTransform } from '@angular/core';
import { BetSlipsGraph } from '../../types/sharp-sports/bet-slips';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'graphDateLabel',
  standalone: true,
})
export class GraphDateLabelPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(graph: BetSlipsGraph[]): string {
    if (!graph) {
      return;
    }
    const firstDay = graph[0]?.day || '';
    const lastDay = graph[graph?.length - 1]?.day || '';
    return `${this.formattingDate(firstDay)}-${this.formattingDate(lastDay)}`;
  }

  private formattingDate(day: string): string {
    return this.datePipe.transform(day, 'MMM/dd/YYYY');
  }
}
