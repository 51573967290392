import { Component, OnInit } from '@angular/core';
import { LayoutConfigService } from '../../../common/services/layout-config.service';
import { PredictAndWinService } from '../../../common/services/predict-and-win.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  public layoutConfig$ = this.layoutConfigService.layoutConfig;
  constructor(
    private layoutConfigService: LayoutConfigService,
    private predictAndWinService: PredictAndWinService
  ) {}

  ngOnInit() {
    // this.predictAndWinService.checkNotifications();
  }
}
