import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { GeocodingService } from '../services/geocoder.service';
import { LoaderService } from '../services/loader.service';
import { GeocoderResponse } from '../helpers/geocoder-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { StaticPagesBaseComponent } from './static-pages-base.component';
import { StaticPagesService } from '../services/static-pages.service';
import { weekDayList } from '../helpers/date-time';
import { DynamicPopupService } from '../services/dynamic-popup.service';
import { PageCode } from '../enum/page-code';

@Component({
  template: ``,
})
export abstract class BankrollStoryBaseComponent
  extends StaticPagesBaseComponent
  implements OnInit
{
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  public zoom = 12;
  geocoderWorking = false;
  public infoContent = '';
  address = '1910 Chestnut St, Philadelphia, PA 19103, United States';
  mapCenter: google.maps.LatLng;

  formattedAddress?: string | null = null;
  locationCoords?: google.maps.LatLng | null = null;

  public center: google.maps.LatLngLiteral;
  mapOptions: google.maps.MapOptions = {
    panControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
    disableDoubleClickZoom: true,
    maxZoom: 19,
    minZoom: 19,
    center: {
      lat: 39.9518205,
      lng: -75.1723735,
    },
    styles: [
      { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#263c3f' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#6b9a76' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#38414e' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#212a37' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9ca5b3' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#746855' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1f2835' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#f3d19c' }],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#2f3948' }],
      },
      {
        featureType: 'transit.station',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#17263c' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#515c6d' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#17263c' }],
      },
    ],
  };
  public markers = [
    {
      position: {
        lat: 39.9518205,
        lng: -75.1723735,
      },
      title: 'Bankrollclub',
    },
  ];

  public weekDays = weekDayList;

  protected constructor(
    private geocodingService: GeocodingService,
    protected loaderService: LoaderService,
    staticPagesService: StaticPagesService,
    protected dynamicPopupService: DynamicPopupService
  ) {
    super(staticPagesService, loaderService);
  }

  ngOnInit(): void {
    super.init();
    this.dynamicPopupService.showPagePopups(PageCode.about);
  }

  findAddress() {
    if (!this.address || this.address.length === 0) {
      return;
    }

    this.geocoderWorking = true;
    this.geocodingService
      .getLocation(this.address)
      .subscribe(
        (response: GeocoderResponse) => {
          if (response.status === 'OK' && response.results?.length) {
            const location = response.results[0];
            const loc: any = location.geometry.location;

            this.locationCoords = new google.maps.LatLng(loc.lat, loc.lng);

            this.mapCenter = location.geometry.location;

            setTimeout(() => {
              if (this.map !== undefined) {
                this.map.panTo(location.geometry.location);
              }
            }, 500);

            this.address = location.formatted_address;
            this.formattedAddress = location.formatted_address;
            this.infoContent = location.formatted_address;
          }
        },
        (err: HttpErrorResponse) => {
          console.error('geocoder error', err);
        }
      )
      .add(() => {
        this.geocoderWorking = false;
      });
  }

  openInfo(marker: MapMarker, content) {
    this.findAddress();
    this.infoContent = content;
    this.info.open(marker);
  }
}
