import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimerPipe } from '../../pipes/date-timer.pipe';
import { PredictionTournament } from '../../types/venue-prediction';

@Component({
  selector: 'app-predict-count-down',
  templateUrl: './predict-count-down.component.html',
  styleUrls: ['./predict-count-down.component.css'],
  standalone: true,
  imports: [CommonModule, DateTimerPipe],
})
export class PredictCountDownComponent {
  @Input() predictionTournament: PredictionTournament;
  @Input() isMobile = true;

  handleTimerEnd() {}
}
