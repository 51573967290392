import { Pipe, PipeTransform } from '@angular/core';
import { BetSlipsFilter } from '../../types/sharp-sports/bet-slips';

@Pipe({
  name: 'betSlipsFilterCount',
  standalone: true,
})
export class BetSlipsFilterCountPipe implements PipeTransform {
  transform(filters: BetSlipsFilter): number {
    const keys = Object.keys(filters);
    return keys.reduce((count: number, key: string) => {
      if (filters[key]?.length > 0 && typeof filters[key] !== 'string') {
        count += filters[key].length;
      }
      return count;
    }, 0);
  }
}
