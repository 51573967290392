import { Component, Input } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { BreadcrumbBackground } from '../../../../../common/enum/breadcrumb-background';

@Component({
  selector: 'app-breadcrumb-desktop',
  templateUrl: './breadcrumb-desktop.component.html',
  styleUrls: ['./breadcrumb-desktop.component.scss'],
  standalone: true,
  imports: [CommonModule, NgDynamicBreadcrumbModule],
})
export class BreadcrumbDesktopComponent {
  @Input() public breadcrumbBackground?: BreadcrumbBackground;

  constructor(private location: Location) {}

  public back(): void {
    this.location.back();
  }
}
