import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {
  WelcomeDrink,
  WelcomeDrinkReq,
  WelcomeDrinkRes,
} from '../types/welcome-drink';
import { Pageable } from '../types/pageable';

@Injectable({
  providedIn: 'root',
})
export class WelcomeDrinkService {
  private apiUrl = `${environment.apiUrl}/client/welcome-drinks`;

  constructor(private http: HttpClient) {}

  public getWelcomeDrinks(): Observable<Pageable<WelcomeDrink>> {
    return this.http.get<Pageable<WelcomeDrink>>(
      `${this.apiUrl}?page.index=0&page.size=20`
    );
  }

  public getWelcomeDrinkById(id: string): Observable<WelcomeDrink> {
    return this.http.get<WelcomeDrink>(`${this.apiUrl}/${id}`);
  }

  public welcomeDrinkSubmit(
    body: WelcomeDrinkReq
  ): Observable<WelcomeDrinkRes> {
    return this.http.post<WelcomeDrinkRes>(
      `${environment.apiUrl}/client/my-welcome-drink`,
      body
    );
  }
}
