import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-season-ticket',
  templateUrl: './season-ticket.component.html',
  styleUrls: ['./season-ticket.component.scss'],
})
export class SeasonTicketComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
