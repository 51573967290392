import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HearFromTheProsPageComponent } from './pages/hear-from-the-pros/hear-from-the-pros-page.component';
import {
  community,
  fromPros,
} from '../../../../../common/constants/breadcrumbs';
import { CommunityItemsComponent } from './pages/community-items/community-items.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: CommunityItemsComponent,
  //   data: { breadcrumb: community },
  // },
  {
    path: '',
    redirectTo: 'hear-pros',
    pathMatch: 'full',
  },
  {
    path: 'hear-pros',
    data: { breadcrumb: fromPros },
    component: HearFromTheProsPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class CommunityRoutingModule {}
