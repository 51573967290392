import { Component } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { PopupService } from '../../../common/services/popup.service';
import { SeasonTicketsService } from '../../../common/services/season-tickets.service';
import { LoaderService } from '../../../common/services/loader.service';
import { SeasonTicketScheduleBaseComponent } from '../../../common/base-controllers/season-ticket-schedule-base.component';
import { UserService } from '../../../common/services/user.service';

@Component({
  selector: 'app-season-ticket-schedule',
  templateUrl: './season-ticket-schedule.component.html',
  styleUrls: ['./season-ticket-schedule.component.scss'],
})
export class SeasonTicketScheduleComponent extends SeasonTicketScheduleBaseComponent {
  constructor(
    private popupService: PopupService,
    seasonTicketsService: SeasonTicketsService,
    loaderService: LoaderService,
    activatedRoute: ActivatedRoute,
    userService: UserService
  ) {
    super(seasonTicketsService, loaderService, activatedRoute, userService);
  }

  public handleShowEventFilterPopup(): void {
    const eventsKeys = Object.keys(this.events);
    if (!eventsKeys.length) {
      return;
    }
    const filteredEventsKeys = Object.keys(this.filteredEvents);
    const filteredKeys =
      eventsKeys.length === filteredEventsKeys.length ? [] : filteredEventsKeys;
    this.popupService
      .showEventFilterPopup(eventsKeys, filteredKeys)
      .action$.pipe(
        filter((res: string[]) => !!res),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((keys: string[]) => this.filterItems(keys));
  }
}
