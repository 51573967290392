import { Component, OnInit } from '@angular/core';
import { StaticPagesService } from '../../../../../common/services/static-pages.service';
import { StaticPages } from '../../../../../common/types/static-pages';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../../common/components/base/base.component';
import { LoaderService } from '../../../../../common/services/loader.service';
import { StaticPagesBaseComponent } from '../../../../../common/base-controllers/static-pages-base.component';
import { StaticPagesId } from '../../../../../common/enum/static-pages-id';
import { PageCode } from '../../../../../common/enum/page-code';
import { DynamicPopupService } from '../../../../../common/services/dynamic-popup.service';

@Component({
  selector: 'app-ticket-events-info',
  templateUrl: './ticket-events-info-desktop.component.html',
  styleUrls: ['./ticket-events-info-desktop.component.css'],
})
export class TicketEventsInfoDesktopComponent
  extends StaticPagesBaseComponent
  implements OnInit
{
  override pageId = StaticPagesId.ticketedEvents;
  override staticPageData: StaticPages;

  constructor(
    staticPagesService: StaticPagesService,
    loaderService: LoaderService,
    private dynamicPopup: DynamicPopupService
  ) {
    super(staticPagesService, loaderService);
  }

  ngOnInit() {
    super.init();
    this.dynamicPopup.showPagePopups(PageCode.ticketedEvents);
  }
}
