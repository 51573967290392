import { Component } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';

@Component({
  selector: 'app-contest-rules',
  templateUrl: './contest-rules.component.html',
  styleUrls: ['./contest-rules.component.css'],
})
export class ContestRulesComponent {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
}
