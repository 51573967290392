import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Event } from '../../types/event';
import { CommonModule } from '@angular/common';
import { DatesComponent } from '../../../mobile/modules/main/pages/season-ticket/components/dates/dates.component';
import { DateObjToStringPipe } from '../../pipes/date-obj-to-string.pipe';

@Component({
  selector: 'app-season-ticket-item',
  templateUrl: './season-ticket-item.component.html',
  styleUrls: ['./season-ticket-item.component.scss'],
  standalone: true,
  imports: [CommonModule, DatesComponent, DateObjToStringPipe],
})
export class SeasonTicketItemComponent {
  @Input() public type: string;
  @Input() public event: Event;
  @Output() public itemClicked: EventEmitter<Event> = new EventEmitter<Event>();
  @Input() public isMobile = false;
  @Input() public selected = false;

  public navigateToDates(event: Event): void {
    this.itemClicked.emit(event);
  }
}
