import { NgModule } from '@angular/core';
import { SeasonTicketScheduleDesktopPageComponent } from './season-ticket-schedule-desktop-page.component';
import { FilterByDateComponent } from '../../../common/components/filter-by-date/filter-by-date.component';
import { EventFilterByCategoryComponent } from '../../../common/components/event-filter-by-category/event-filter-by-category.component';
import { CommonModule } from '@angular/common';
import { FilterCountPipe } from '../../../common/pipes/filter-count.pipe';
import { EventItemComponent } from '../../../mobile/modules/main/pages/season-ticket/components/event-item/event-item.component';
import { CalendarViewPageComponent } from '../../../common/components/calendar-view-page/calendar-view-page.component';
import { EventDetailsDesktopComponent } from '../main-desktop/components/event-details-desktop/event-details-desktop.component';
import { eventBreadcrumb } from '../../../common/constants/breadcrumbs';
import { RouterModule } from '@angular/router';
import { ConcatPipe } from '../../../common/pipes/concat.pipe';
import { ScheduleLayoutComponent } from './schedule-layout/schedule-layout.component';
import { HeaderDesktopComponent } from '../components/layout/header/header-desktop.component';
import { FooterDesktopComponent } from '../components/layout/footer/footer-desktop.component';
import { BreadcrumbDesktopComponent } from '../components/layout/breadcrumb/breadcrumb-desktop.component';
import { EventIsEmptyPipe } from '../../../common/pipes/event-is-empty.pipe';

const routes = [
  {
    path: '',
    component: SeasonTicketScheduleDesktopPageComponent,
    data: { breadcrumb: eventBreadcrumb },
  },
];

@NgModule({
  imports: [
    // Modules
    CommonModule,
    RouterModule.forChild(routes),
    // Components
    BreadcrumbDesktopComponent,
    FilterByDateComponent,
    EventFilterByCategoryComponent,
    EventItemComponent,
    CalendarViewPageComponent,
    EventDetailsDesktopComponent,
    HeaderDesktopComponent,
    FooterDesktopComponent,
    // Pipes
    FilterCountPipe,
    ConcatPipe,
    EventIsEmptyPipe,
  ],
  declarations: [
    SeasonTicketScheduleDesktopPageComponent,
    ScheduleLayoutComponent,
  ],
})
export class SeasonTicketScheduleDesktopModule {}
