import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Subscription, timer } from 'rxjs';
import { BaseComponent } from '../../../base/base.component';
import {
  Navigation,
  ReaderCanvasEvents,
  ReaderMode,
} from '../../../../enum/reader.enum';
import { ReaderService } from '../../../../services/reader.service';
import { InputEventsService } from '../../../../helpers/input-events.service';
import { Point } from '../../../../helpers/point';
import { CanvasEventSubParam } from '../../../../types/reader';

@Component({
  selector: 'app-reader-canvas',
  templateUrl: './reader-canvas.component.html',
  styleUrls: ['./reader-canvas.component.scss'],
})
export class ReaderCanvasComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Output() public setReaderMode = new EventEmitter();
  @ViewChild('canvas', { read: ElementRef, static: false })
  public canvas?: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasContainer', { read: ElementRef, static: false })
  public canvasContainer!: ElementRef<HTMLCanvasElement>;
  public canvasContainerTransform!: string;
  public isMoving!: boolean;
  public navigation = Navigation;
  private ctx?: CanvasRenderingContext2D;
  private activeMouseTimeout?: Subscription;
  private clipPath?: string | null;
  public initialCanvasContainerTransform!: string;

  constructor(public readerService: ReaderService) {
    super();
  }

  @HostBinding('style') get componentStyle(): string {
    return this.clipPath || '';
  }

  ngOnInit(): void {
    this.readerService.canvasComponentEvent$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action) => this.handleEvents(action));
  }

  ngAfterViewInit(): void {
    this.readerService.canvasElement = this.canvas;
  }

  public tryToCenterCanvas(scale?: number): void {
    const canvasTransformConfig = this.readerService.canvasTransformConfig;
    const canvasHeight = Number(this.canvas?.nativeElement.height);
    const canvasWidth = Number(this.canvas?.nativeElement.width);
    const { height, width } =
      this.readerService.wrapper?.nativeElement.getBoundingClientRect() as DOMRect;
    canvasTransformConfig.scale =
      scale || Math.min(width / canvasWidth, height / canvasHeight);
    const renderedHeight = canvasHeight * canvasTransformConfig.scale;
    const renderedWidth = canvasWidth * canvasTransformConfig.scale;
    canvasTransformConfig.translate.y =
      (height - renderedHeight) / (2 * canvasTransformConfig.scale);
    canvasTransformConfig.translate.x =
      (width - renderedWidth) / (2 * canvasTransformConfig.scale);
  }

  public updateCanvasTransform(): void {
    this.canvasContainerTransform = `
      scale(${this.readerService.canvasTransformConfig.scale})
      translate3d(${this.readerService.canvasTransformConfig.translate.x}px, ${this.readerService.canvasTransformConfig.translate.y}px, 0)
      `;
  }

  public drawPage(image: HTMLImageElement): void {
    this.ctx?.clearRect(
      0,
      0,
      this.canvas?.nativeElement.width as number,
      this.canvas?.nativeElement.height as number
    );
    this.updateCanvasDimensions(image);
    this.ctx?.drawImage(image, 0, 0);
  }

  @HostListener('mousemove')
  onMouseMove(): void {
    this.makeNavigationActive();
  }

  public navigate(navigationType: Navigation): void {
    this.makeNavigationActive();
    switch (navigationType) {
      case Navigation.Left:
        this.jumpToPrevPage();
        break;
      case Navigation.Right:
        this.jumpToNextPage();
    }
  }

  public onSwipe(navigate: Navigation): void {
    this.navigate(navigate);
  }

  public async drawCurrentPage(): Promise<void> {
    if (!this.readerService.images?.length) {
      return;
    }
    this.readerService.currentImage =
      this.readerService.images[this.readerService.currentPage];
    await this.readerService.currentImage.decode();
    this.drawPage(this.readerService.currentImage);
    this.fitCanvasInPage();
  }

  private updateCanvasDimensions({
    naturalWidth,
    naturalHeight,
  }: {
    naturalWidth: number;
    naturalHeight: number;
  }): void {
    this.canvas?.nativeElement.setAttribute('width', `${naturalWidth}`);
    this.canvas?.nativeElement.setAttribute('height', `${naturalHeight}`);
  }

  private initializeCanvasContext(): void {
    this.ctx = this.canvas?.nativeElement.getContext(
      '2d'
    ) as CanvasRenderingContext2D;
  }

  private initDragScroll(): void {
    const canvasTransformConfig = this.readerService.canvasTransformConfig;
    let moveSub: any;
    const pos = {
      x: 0,
      y: 0,
    };
    const initial = new Point();
    const element = this.canvasContainer.nativeElement;
    if (element) {
      InputEventsService.start(element)
        .pipe(
          filter(
            (e: any) =>
              !this.isMoving &&
              this.readerService.currentMode === ReaderMode.OnePage
          )
        )
        .subscribe((e: MouseEvent) => {
          e.stopPropagation();
          e.preventDefault();
          pos.x =
            e.pageX -
            canvasTransformConfig.translate.x * canvasTransformConfig.scale;
          pos.y =
            e.pageY -
            canvasTransformConfig.translate.y * canvasTransformConfig.scale;
          (element.childNodes[0] as HTMLDivElement).style.setProperty(
            'transition',
            'none'
          );
          initial.set(e.pageX, e.pageY);
          this.isMoving = true;
          moveSub = InputEventsService.move().subscribe((moveEvent: any) => {
            const dx = moveEvent.pageX - pos.x;
            moveEvent.preventDefault();
            moveEvent.stopPropagation();
            const dy = moveEvent.pageY - pos.y;
            canvasTransformConfig.translate.x =
              dx / canvasTransformConfig.scale;
            canvasTransformConfig.translate.y =
              dy / canvasTransformConfig.scale;
            this.updateCanvasTransform();
          });
        });
      InputEventsService.end()
        .pipe(filter(() => moveSub))
        .subscribe(() => {
          (element.childNodes[0] as HTMLDivElement).style.removeProperty(
            'transition'
          );
          moveSub.unsubscribe();
          this.isMoving = false;
        });
    }
  }

  private fitCanvasInPage(): void {
    const clientRect =
      this.readerService.wrapper?.nativeElement.getBoundingClientRect() as DOMRect;
    const canvasHeight = Number(this.canvas?.nativeElement.height);
    this.tryToCenterCanvas(
      Math.min(
        clientRect.width / Number(this.canvas?.nativeElement.width),
        clientRect.height / canvasHeight
      )
    );
    this.updateCanvasTransform();
    this.initialCanvasContainerTransform = this.canvasContainerTransform;
  }

  private changeCanvasZoomByStep(step: number): void {
    this.tryToCenterCanvas(
      Math.max(
        Math.min(
          this.readerService.canvasTransformConfig.scale + step,
          Math.abs(step * 4)
        ),
        Math.abs(step)
      )
    );
    if (this.readerService.canvasTransformConfig.scale <= 0.5) {
      this.canvasContainerTransform = this.initialCanvasContainerTransform;
      return;
    }
    this.updateCanvasTransform();
  }

  private makeNavigationActive(): void {
    this.readerService.mouseActive = true;
    this.setMouseActiveTimer();
  }

  private setMouseActiveTimer(): void {
    this.activeMouseTimeout?.unsubscribe();
    this.activeMouseTimeout = timer(2000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => (this.readerService.mouseActive = false));
  }

  private jumpToExactPage(pageIndex: number): void {
    this.readerService.lastPage = false;
    this.readerService.currentPage = pageIndex;
    this.drawCurrentPage();
  }

  private jumpToPrevPage(): void {
    this.readerService.lastPage = false;
    this.readerService.currentPage = Math.max(
      this.readerService.currentPage - 1,
      0
    );
    this.drawCurrentPage();
  }

  private jumpToNextPage(): void {
    this.readerService.currentPage = Math.min(
      this.readerService.currentPage + 1,
      this.readerService.images?.length - 1 || 1
    );
    if (
      this.readerService.currentPage < (this.readerService.images?.length || 1)
    ) {
      this.readerService.lastPage = false;
      this.drawCurrentPage();
    } else {
      this.readerService.lastPage = true;
    }
  }

  private handleEvents(event: CanvasEventSubParam): void {
    const { type, defaultReaderMode, page } = event;
    switch (type) {
      case ReaderCanvasEvents.FitCanvasInPage:
        this.fitCanvasInPage();
        break;
      case ReaderCanvasEvents.ZoomIn:
        this.changeCanvasZoomByStep(0.5);
        break;
      case ReaderCanvasEvents.ZoomOut:
        this.changeCanvasZoomByStep(-0.5);
        break;
      case ReaderCanvasEvents.InitializeCanvasContext:
        this.initializeCanvasContext();
        break;
      case ReaderCanvasEvents.InitDragScroll:
        this.initDragScroll();
        break;
      case ReaderCanvasEvents.NavigateLeft:
        this.navigate(Navigation.Left);
        break;
      case ReaderCanvasEvents.NavigateRight:
        this.navigate(Navigation.Right);
        break;
      case ReaderCanvasEvents.JumpToExactPage:
        if (page) {
          this.jumpToExactPage(page.pageIndex);
        }
        break;
      case ReaderCanvasEvents.InitCurrentPage:
        this.drawCurrentPage().then(() => {
          if (defaultReaderMode) {
            this.setReaderMode.emit();
          }
        });
        break;
      case ReaderCanvasEvents.UpdatePageForMode:
        this.fitCanvasInPage();
        break;
    }
  }
}
