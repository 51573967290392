import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'include',
  standalone: true
})
export class IncludePipe implements PipeTransform {

  transform(value: string | number, values: (string | number)[]): boolean {
    return values.includes(value);
  }

}
