import { ChangeDetectorRef, Injectable } from '@angular/core';
import { RestaurantMenu } from '../types/restaurant-menu';
import { MenuItem } from '../types/menu-items';
import { UNAUTHORIZED_MOBILE_MENU_ITEMS } from '../helpers/menu-items';

@Injectable({
  providedIn: 'root',
})
export class HeaderMenuService {
  public mobileMenu: MenuItem[] = UNAUTHORIZED_MOBILE_MENU_ITEMS;

  public fetchDiningMenu(restaurantMenus: RestaurantMenu[]): void {
    const dinningIndex = this.mobileMenu.findIndex(
      (m: MenuItem) => m.title === 'Dining'
    );
    restaurantMenus.forEach((rm: RestaurantMenu) => {
      this.mobileMenu[dinningIndex].pages.push({
        name: rm.name,
        route: `menu/${rm.id}`,
      });
      this.mobileMenu = structuredClone(this.mobileMenu);
    });
  }
}
