import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SpaceDesktopComponent } from './space-desktop.component';
import { SeasonTicketAreaSelectDesktopComponent } from '../components/season-ticket-area-select-desktop/season-ticket-area-select-desktop.component';
import { spaceDesktop } from '../../../common/constants/breadcrumbs';
import { HeaderDesktopComponent } from '../components/layout/header/header-desktop.component';
import { FooterDesktopComponent } from '../components/layout/footer/footer-desktop.component';

const routes: Routes = [
  {
    path: '',
    component: SpaceDesktopComponent,
    data: { breadcrumb: spaceDesktop },
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    HeaderDesktopComponent,
    FooterDesktopComponent,
    SeasonTicketAreaSelectDesktopComponent,
  ],
  declarations: [SpaceDesktopComponent],
})
export class SpaceDesktopModule {}
