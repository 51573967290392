import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {TripleseatForm} from './tripleseat-form';
import {regexp} from '../../common/validators/regexp';
import {TriplesetaService} from '../../common/services/tripleseta.service';
import {DatepickerModule} from 'ng2-datepicker';
import {TimePickerComponent} from '../../common/components/time-picker/time-picker.component';
import {catchError, takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../common/components/base/base.component';
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import {environment} from '../../../environments/environment';
import {PopupService} from '../../common/services/popup.service';
import {throwError} from 'rxjs';

@Component({
  selector: 'app-tripleseat',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DatepickerModule,
    TimePickerComponent,
    RecaptchaModule,
    FormsModule,
    RecaptchaFormsModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
        theme: 'dark',
      } as RecaptchaSettings,
    },
  ],
  templateUrl: './tripleseat.component.html',
  styleUrls: ['./tripleseat.component.css']
})
export class TripleseatComponent extends BaseComponent implements OnInit {
  @Input() public isMobile = true;
  public formGroup: FormGroup<TripleseatForm>;
  public date = new Date();
  public token: string | undefined;
  public message: string;

  constructor(private triplesetaService: TriplesetaService, private popupService: PopupService) {
    super();
    this.token = undefined;
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.formGroup = new FormGroup<TripleseatForm>({
      first_name: new FormControl('', {
        validators: [Validators.required]
      }),
      last_name: new FormControl('', {
        validators: [Validators.required]
      }),
      email_address: new FormControl('', {
        validators: [Validators.required, Validators.pattern(regexp.email),]
      }),
      phone_number: new FormControl(null, {
        validators: [
          Validators.pattern(regexp.phoneNumber),
        ]
      }),
      contact_preference: new FormControl(''),
      company: new FormControl(''),
      event_description: new FormControl(''),
      event_date: new FormControl(this.date),
      start_time: new FormControl(''),
      end_time: new FormControl(''),
      guest_count: new FormControl(null, {
        validators: [Validators.required, Validators.min(1)]
      }),
      additional_information: new FormControl(''),
    });
  }

  public handleFormSubmit(): void {
    this.formGroup.markAsDirty();
    if (!this.formGroup.valid) return;
    this.triplesetaService.tripleseatFormSubmit(this.formGroup.getRawValue(), this.token)
      .pipe(
        catchError((err) => {
          this.popupService.showErrorResponsePopup('Something went wrong. Please try again.');
          this.token = undefined;
          return throwError(err);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((res) => {
        if (res.success_message) {
          this.message = res.success_message;
        } else {
          if (res.errors?.length) {
            this.popupService.showErrorResponsePopup(res.errors[0]);
          }
        }
        this.token = undefined;
      });
  }
}
