import { Component, OnInit } from '@angular/core';
import { StaticPagesBaseComponent } from '../../../../../common/base-controllers/static-pages-base.component';
import { StaticPages } from '../../../../../common/types/static-pages';
import { StaticPagesService } from '../../../../../common/services/static-pages.service';
import { LoaderService } from '../../../../../common/services/loader.service';
import { StaticPagesId } from '../../../../../common/enum/static-pages-id';
import { DynamicPopupService } from '../../../../../common/services/dynamic-popup.service';
import { PageCode } from '../../../../../common/enum/page-code';

@Component({
  selector: 'app-vip-memberships-info-m',
  templateUrl: './vip-memberships-info.component.html',
  styleUrls: ['./vip-memberships-info.component.css'],
})
export class VipMembershipsInfoComponent
  extends StaticPagesBaseComponent
  implements OnInit
{
  override pageId = StaticPagesId.vipMembership;
  override staticPageData: StaticPages;

  constructor(
    staticPagesService: StaticPagesService,
    loaderService: LoaderService,
    private dynamicPopupService: DynamicPopupService
  ) {
    super(staticPagesService, loaderService);
  }

  ngOnInit() {
    super.init();
    this.dynamicPopupService.showPagePopups(PageCode.vip);
  }
}
