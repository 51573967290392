import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../base/base.component';
import { ReaderService } from '../../../../services/reader.service';
import {
  Navigation,
  ReaderCanvasEvents,
  ReaderMode,
} from '../../../../enum/reader.enum';
import { Assets } from '../../../../types/assets';
import { LoaderService } from '../../../../services/loader.service';

@Component({
  selector: 'app-reader-default',
  templateUrl: './reader-default.component.html',
  styleUrls: ['./reader-default.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ReaderDefaultComponent
  extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() public assets: Assets;
  public defaultReaderMode: ReaderMode | undefined;
  public isMobile = false;
  @ViewChild('wrapper', { read: ElementRef, static: false })
  private wrapper?: ElementRef<HTMLCanvasElement>;

  constructor(
    private router: Router,
    public readerService: ReaderService,
    private loaderService: LoaderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.initPages();
  }

  ngAfterViewInit(): void {
    this.readerService.wrapper = this.wrapper;
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    if (
      this.wrapper?.nativeElement &&
      this.readerService.canvasElement?.nativeElement
    ) {
      switch (this.readerService.currentMode) {
        case ReaderMode.OnePage:
          this.readerService.canvasSubjectHandler({
            type: ReaderCanvasEvents.FitCanvasInPage,
          });
          break;
        case ReaderMode.Guided:
        case ReaderMode.Panel:
          this.readerService.canvasSubjectHandler({
            type: ReaderCanvasEvents.DrawCurrentPanel,
          });
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowLeft': {
        this.readerService.canvasSubjectHandler({
          type: ReaderCanvasEvents.NavigateLeft,
        });
        break;
      }
      case 'ArrowRight': {
        this.readerService.canvasSubjectHandler({
          type: ReaderCanvasEvents.NavigateRight,
        });
        break;
      }
      case '+': {
        this.readerService.canvasSubjectHandler({
          type: ReaderCanvasEvents.ZoomIn,
        });
        break;
      }
      case '-': {
        this.readerService.canvasSubjectHandler({
          type: ReaderCanvasEvents.ZoomOut,
        });
        break;
      }
    }
  }

  public navigate(event: any): void {
    switch (event) {
      case Navigation.Left:
        this.readerService.canvasSubjectHandler({
          type: ReaderCanvasEvents.NavigateLeft,
        });
        break;
      case Navigation.Right:
        this.readerService.canvasSubjectHandler({
          type: ReaderCanvasEvents.NavigateRight,
        });
        break;
    }
  }

  private initPages(): void {
    this.loaderService.show();
    const pages = Object.keys(this.assets)
      .sort()
      .map((key: string) => this.assets[key].url);
    this.preloadPages(pages)
      .pipe(delay(500))
      .subscribe((images: HTMLImageElement[]) => {
        this.readerService.images = images;
        this.handleLoadingComplete();
      });
  }

  private preloadPages(pages: string[]): Observable<any> {
    return new Observable<any>((observer) => {
      let loadedCount = 0;
      const images: any[] = [];
      pages.forEach((p, i: number) => {
        const image = new Image();
        image.src = p;
        images.push(image);
        image.onload = () => {
          if (pages.length - 1 === loadedCount) {
            observer.next(images);
            observer.complete();
          }
          loadedCount++;
        };
      });
    });
  }

  private handleLoadingComplete(): void {
    this.readerService.loading = false;
    this.loaderService.hide();
    this.readerService.currentPage = 0;
    this.changeDetectorRef.detectChanges();
    this.readerService.canvasSubjectHandler({
      type: ReaderCanvasEvents.InitializeCanvasContext,
    });
    this.readerService.canvasSubjectHandler({
      type: ReaderCanvasEvents.InitCurrentPage,
      defaultReaderMode: this.defaultReaderMode,
    });
    if (!this.isMobile) {
      this.readerService.canvasSubjectHandler({
        type: ReaderCanvasEvents.InitDragScroll,
      });
    }
  }
}
