import { Pipe, PipeTransform } from '@angular/core';
import { TournamentLeaderboard } from '../../types/sharp-sports/tournament-leaderboard';
import { UserService } from '../../services/user.service';

@Pipe({
  name: 'showConnect',
  standalone: true,
})
export class ShowConnectPipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(tournaments: TournamentLeaderboard[]): boolean {
    const userId = this.userService.user.id;
    return !tournaments?.some((t) => t.user.id === userId);
  }
}
