import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PartnersDesktopComponent } from './partners-desktop.component';
import { HeaderDesktopComponent } from '../components/layout/header/header-desktop.component';
import { FooterDesktopComponent } from '../components/layout/footer/footer-desktop.component';

const routes: Routes = [
  {
    path: '',
    component: PartnersDesktopComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    HeaderDesktopComponent,
    FooterDesktopComponent,
  ],
  declarations: [PartnersDesktopComponent],
})
export class PartnersDesktopModule {}
