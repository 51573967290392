import { Component } from '@angular/core';
import { PopupService } from '../../../../../common/services/popup.service';
import { AreaDetailsEvent } from '../../../../../common/types/area';
import { BreadcrumbBackground } from '../../../../../common/enum/breadcrumb-background';

@Component({
  selector: 'app-space-area-select',
  templateUrl: './space-area-select.component.html',
  styleUrls: ['./space-area-select.component.scss'],
})
export class SpaceAreaSelectComponent {
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  constructor(private popupService: PopupService) {}

  public showAreaDetailsPopup({ event, area }: AreaDetailsEvent): void {
    event?.stopPropagation();
    this.popupService.showAreaDetailsPopup(area);
  }
}
