import { Component } from '@angular/core';

@Component({
  selector: 'app-home-cards',
  templateUrl: './home-cards.component.html',
  styleUrls: ['./home-cards.component.css'],
})
export class HomeCardsComponent {
  public showLandingTicket = false;
}
