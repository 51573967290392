import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { StripeService } from '../../services/stripe.service';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import {
  PaymentState,
  StripePaymentResponse,
} from '../../types/stripe/stripe-payment';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '../base/base.component';
import { LayoutDecorator } from '../../helpers/layout.decorator';
import { LayoutConfigService } from '../../services/layout-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SeasonTicketsService } from '../../services/season-tickets.service';
import { SalesService } from '../../services/sales.service';
import { PopupService } from '../../services/popup.service';
import { LoaderService } from '../../services/loader.service';
import { PopupsManagerService } from '../../services/popups-manager.service';
import { SeasonTicketsPages } from '../../enum/page-state';
import { StorageService } from '../../services/storage.service';
import { BreadcrumbComponent } from '../../../mobile/modules/components/layout/breadcrumb/breadcrumb.component';
import { TicketValidationReq } from '../../types/event';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
  standalone: true,
  imports: [CommonModule, BreadcrumbComponent],
})
@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
export class StripeComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() public isMobile = true;
  @Input() public seatCount: number;

  public eventId: string;
  public tableId: string;
  public dates: string[];

  public errorMessage?: string;
  public isInitialized: boolean = false;
  public loading: boolean;
  private areaId: string;

  constructor(
    private router: Router,
    private salesService: SalesService,
    private popupService: PopupService,
    private popupsManagerService: PopupsManagerService,
    private stripeService: StripeService,
    private loaderService: LoaderService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private seasonTicketsService: SeasonTicketsService,
    private layoutConfigService: LayoutConfigService
  ) {
    super();
  }

  ngOnInit() {
    this.init();
    this.subscribeToPaymentResponse();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private init(): void {
    this.loaderService.show();
    this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId');
    this.areaId = this.activatedRoute.snapshot.paramMap.get('areaId');
    this.tableId = this.activatedRoute.snapshot.paramMap.get('tableId');
    this.dates = this.seasonTicketsService.getSeasonDates();
    if (this.eventId && this.dates?.length) {
      this.stripeService
        .initializeStripe(
          this.eventId,
          this.tableId,
          this.dates,
          this.seatCount
        )
        .pipe(
          takeUntil(this.unsubscribe$),
          finalize(() => this.loaderService.hide())
        )
        .subscribe();
    } else {
      this.handleCancel();
      this.loaderService.hide();
    }
  }

  public handleCancel(): void {
    this.router.navigateByUrl(
      `/my/season-tickets/ticket-view/${this.eventId}/${this.areaId}/${this.tableId}`
    );
  }

  public async handlePurchaseStart(event: MouseEvent): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    const tableIdOrSeatsCount = this.tableId || this.seatCount;
    if (!this.eventId || !tableIdOrSeatsCount || !this.dates) {
      return;
    }
    this.loading = true;
    this.loaderService.show();
    if (this.tableId) {
      const response = await this.checkValidity();
      if (!response) {
        this.loaderService.hide();
        this.loading = false;
        return;
      }
    }
    this.stripeService
      .payWithElement()
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => {
          this.loading = false;
          this.loaderService.hide();
        })
      )
      .subscribe();
  }

  private checkValidity(): Promise<void | { message: string }> {
    const validityCheckingData: TicketValidationReq = {
      event: this.eventId,
      table: this.tableId,
      dates: this.dates,
    };
    return firstValueFrom(
      this.seasonTicketsService.checkTicketValidity(validityCheckingData)
    ).catch((err) => {
      console.error(err.message);
    });
  }

  private subscribeToPaymentResponse(): void {
    this.stripeService.paymentResponse$
      .pipe(
        switchMap(({ state, message }: StripePaymentResponse) => {
          this.loading = false;
          this.loaderService.hide();
          switch (state) {
            case PaymentState.success:
              return this.popupService.showCheckoutSuccessPopup(this.isMobile)
                .action$;
            case PaymentState.fail:
              this.errorMessage = message;
              return this.popupService.showCheckoutErrorPopup(this.isMobile)
                .action$;
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.clearStorage();
        this.router.navigateByUrl('/my/season-tickets/reservations');
      });
  }

  private clearStorage(): void {
    this.storageService.removeFromStorage(SeasonTicketsPages.buyTicket);
    this.storageService.removeFromStorage(SeasonTicketsPages.selectedAreaId);
    this.storageService.removeFromStorage('dates');
  }
}
