import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { WelcomeDrinkService } from '../services/welcome-drink.service';
import { BaseComponent } from '../components/base/base.component';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { WelcomeDrink } from '../types/welcome-drink';
import { Pageable } from '../types/pageable';
import { AssetsUrlPipe } from '../pipes/assets-url.pipe';
import { LoaderService } from '../services/loader.service';
import { UserService } from '../services/user.service';
import { RESY_TOKEN } from '../constants/storage-keys';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-welcome-drink',
  templateUrl: './welcome-drink.component.html',
  styleUrls: ['./welcome-drink.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, AssetsUrlPipe],
})
export class WelcomeDrinkComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() public isMobile = true;
  public drinks: WelcomeDrink[];
  public selectedDrinkId: string;
  public editMode = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private welcomeDrinkService: WelcomeDrinkService,
    private loaderService: LoaderService,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit() {
    const drinkId = this.activatedRoute.snapshot.queryParams?.id;
    if (drinkId) {
      this.selectedDrinkId = drinkId;
      this.editMode = true;
    }
    this.loaderService.show();
    this.welcomeDrinkService
      .getWelcomeDrinks()
      .pipe(
        map((res: Pageable<WelcomeDrink>) => res.items),
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe((welcomeDrinks) => (this.drinks = welcomeDrinks));
  }

  public submitDrink(): void {
    this.loaderService.show();
    const resyToken = this.storageService.getFromStorage<string>(RESY_TOKEN);
    const payload = {
      welcomeDrinkId: this.selectedDrinkId,
      ...(resyToken && { resyToken }),
    };
    this.welcomeDrinkService
      .welcomeDrinkSubmit(payload)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe(() => {
        this.storageService.removeFromStorage(RESY_TOKEN);
        this.router.navigateByUrl(
          this.editMode ? '/my/profile' : '/my/account'
        );
      });
  }

  public selectDrink(drinkId: string) {
    this.selectedDrinkId = drinkId;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.storageService.removeFromStorage(RESY_TOKEN);
  }
}
