import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-ticket-present-qr',
  templateUrl: './ticket-present-qr.component.html',
  standalone: true,
  styleUrls: ['./ticket-present-qr.component.css']
})
export class TicketPresentQrComponent   {
  @Input() public isMobile: boolean = true;
  @Input() public index: number;
  @Input() public firstName: string;
  @Input() public lastName: string;
  @Input() public qrUrl: string;
}
