import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DesktopRoutingModule } from './desktop-routing.module';
import { DesktopComponent } from './desktop.component';

@NgModule({
  imports: [CommonModule, FormsModule, DesktopRoutingModule],
  declarations: [DesktopComponent],
  providers: [],
})
export class DesktopModule {}
