import { Pipe, PipeTransform } from '@angular/core';
import { singleDigitFormatting } from '../helpers/date-timer';

@Pipe({
  name: 'isToday',
  standalone: true,
})
export class IsTodayPipe implements PipeTransform {
  transform(date: string, today: Date): boolean {
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const now = `${today.getFullYear()}-${singleDigitFormatting(
      month
    )}-${singleDigitFormatting(day)}`;
    return date === now;
  }
}
