import { Component, OnDestroy, OnInit } from '@angular/core';
import { BuilderLeaderboardBaseComponent } from '../../../../../../../../../common/base-controllers/builder-leaderboard-base.component';
import { PopupService } from '../../../../../../../../../common/services/popup.service';
import { VenueLeaderboardService } from '../../../../../../../../../common/services/venue-leaderboard.service';
import { LoaderService } from '../../../../../../../../../common/services/loader.service';
import { StorageService } from '../../../../../../../../../common/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../../../../../../../common/services/user.service';
import { SharpSportsService } from '../../../../../../../../../common/services/sharp-sports.service';
import { CommunicationService } from '../../../../../../../../../common/services/communication.service';

@Component({
  selector: 'app-builder-leaderboard',
  templateUrl: './builder-leaderboard.component.html',
  styleUrls: ['./builder-leaderboard.component.scss'],
})
export class BuilderLeaderboardComponent
  extends BuilderLeaderboardBaseComponent
  implements OnInit, OnDestroy
{
  constructor(
    popupService: PopupService,
    venueLeaderboardService: VenueLeaderboardService,
    loaderService: LoaderService,
    storageService: StorageService,
    activatedRoute: ActivatedRoute,
    router: Router,
    userService: UserService,
    sharpSportsService: SharpSportsService,
    communicationService: CommunicationService
  ) {
    super(
      popupService,
      venueLeaderboardService,
      loaderService,
      storageService,
      activatedRoute,
      router,
      userService,
      sharpSportsService,
      communicationService
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
