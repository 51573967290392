import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthPipe } from '../../../../../../../common/pipes/month.pipe';
import { PopupService } from '../../../../../../../common/services/popup.service';
import {TicketDate} from '../../../../../../../common/types/user';

@Component({
  selector: 'app-dates',
  templateUrl: './dates.component.html',
  standalone: true,
  imports: [CommonModule, MonthPipe],
  styleUrls: ['./dates.component.scss'],
})
export class DatesComponent {
  @Input() public dates: (string | TicketDate)[];
  @Input() public fullDate = true;

  constructor(private popupService: PopupService) {}

  public openSelectedDatesPopup(): void {
    if (!this.dates) {
      return;
    }
    const dates = typeof this.dates[0] === 'string' ? this.dates : this.dates.map((d: TicketDate) => d.date)
    this.popupService.showSelectedDatesPopup(dates as string[]);
  }
}
