import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbBackground } from '../../../../common/enum/breadcrumb-background';
import {
  Area,
  AreaDetails,
  AreaDetailsEvent,
} from '../../../../common/types/area';
import { StorageService } from '../../../../common/services/storage.service';
import { SeasonTicketsPages } from '../../../../common/enum/page-state';
import { CommonModule } from '@angular/common';
import { SeasonTicketAreaDetailsComponent } from '../../../../common/components/season-ticket-area-details/season-ticket-area-details.component';
import { AreaViewComponent } from '../../../../common/components/area-view/area-view.component';
import { SeasonTicketAreaSelectComponent } from '../../../../common/components/season-ticket-area-select/season-ticket-area-select.component';
import { BreadcrumbDesktopComponent } from '../layout/breadcrumb/breadcrumb-desktop.component';

@Component({
  selector: 'app-season-ticket-area-select-desktop',
  templateUrl: './season-ticket-area-select-desktop.component.html',
  styleUrls: ['./season-ticket-area-select-desktop.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    SeasonTicketAreaDetailsComponent,
    AreaViewComponent,
    SeasonTicketAreaSelectComponent,
    BreadcrumbDesktopComponent,
  ],
})
export class SeasonTicketAreaSelectDesktopComponent implements OnInit {
  @Input() public readOnly = false;
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  public selectedArea: Area | AreaDetails;
  constructor(private storageService: StorageService) {}

  ngOnInit(): void {}

  public showAreaDetails({ event, area }: AreaDetailsEvent): void {
    event?.stopPropagation();
    this.selectedArea = area;
    this.storageService.saveToStorage<string>(
      SeasonTicketsPages.selectedAreaId,
      area.id
    );
  }
}
