import { Component } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';

@Component({
  selector: 'app-contest-rules-desktop',
  templateUrl: './contest-rules-desktop.component.html',
  styleUrls: ['./contest-rules-desktop.component.css'],
})
export class ContestRulesDesktopComponent {
  public breadcrumbBackground: BreadcrumbBackground.withHeader;
}
