import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../components/base/base.component';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from '../services/user.service';
import { PopupService } from '../services/popup.service';
import { Router } from '@angular/router';
import { FireBaseService } from '../services/fire-base.service';
import { LoaderService } from '../services/loader.service';
import { regexp } from '../validators/regexp';
import {
  catchError,
  finalize,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { fileToBase64 } from '../helpers/fileToBase64';
import { from, Observable, of, zip } from 'rxjs';
import { base64ToFile } from '../helpers/base64ToFile';
import { HttpErrorResponse } from '@angular/common/http';
import firebase from 'firebase/compat/app';
import { WelcomeDrink } from '../types/welcome-drink';
import { WelcomeDrinkService } from '../services/welcome-drink.service';
import { capitalize } from '../helpers/capitalize';
import { environment } from '../../../environments/environment';
import { phonePrefixHandler } from '../helpers/phone-prefix';

@Component({
  template: '',
})
export abstract class ProfileBaseComponent
  extends BaseComponent
  implements OnInit
{
  public userFormGroup: UntypedFormGroup;
  public imageUrl: string;
  public imageBase64?: string;
  public currentPhoneNumber: string;
  private verificationId?: string;
  public isMobile = true;
  public welcomeDrink: WelcomeDrink;
  public showProfileImage = false;

  protected constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private popupService: PopupService,
    private router: Router,
    private fireBaseService: FireBaseService,
    private loaderService: LoaderService
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
    this.fetchUser();
  }

  private initForm(): void {
    this.userFormGroup = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      username: ['', Validators.required],
      image: [''],
      email: ['', [Validators.pattern(regexp.email)]],
      phone: [
        '',
        [Validators.required, Validators.pattern(regexp.phoneNumber)],
      ],
    });
  }

  private fetchUser(): void {
    this.loaderService.show();
    this.userService
      .getUser()
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe(() => this.patchValue());
  }

  private patchValue(): void {
    const { firstName, lastName, username, image, email, phone, welcomeDrink } =
      this.userService.user;
    this.currentPhoneNumber = phone;
    this.userFormGroup.patchValue({
      firstName,
      lastName,
      username,
      image,
      email,
      phone,
    });

    this.imageUrl = image || 'https://via.placeholder.com/600x600.png';

    this.welcomeDrink = welcomeDrink;
  }

  public openCropImagePopup(): void {
    this.popupService
      .showCropImagePopup(this.imageBase64, this.isMobile)
      .crop$.pipe(takeUntil(this.unsubscribe$))
      .subscribe((croppedImage: string) => {
        this.imageBase64 = croppedImage;
        this.userFormGroup.get('image').setValue(croppedImage);
      });
  }

  public async choseImage(e) {
    if (e.target.files.length > 0) {
      const base64 = await fileToBase64(e.target.files[0]);
      if (base64) {
        this.imageBase64 = base64 as string;
        this.openCropImagePopup();
      }
    }
  }

  public submitForm(): void {
    this.autocapitalizeNames();
    const phoneNumber = this.userFormGroup.get('phone').value;
    this.currentPhoneNumber !== phoneNumber
      ? this.updatePhoneNumber(phoneNumber)
      : this.updateUser();
  }

  private updateUser(): void {
    this.loaderService.show();
    const withImage = this.imageBase64
      ? from(base64ToFile(this.imageBase64, 'img', 'image')).pipe(
          switchMap((file: File) => this.userService.uploadImage(file)),
          tap(([url]) => this.patchUrlInForm(url)),
          catchError((err: HttpErrorResponse) => this.handleError(err))
        )
      : of({});

    withImage
      .pipe(
        switchMap(() => this.userService.updateUser(this.userFormGroup.value)),
        switchMap(() => this.userService.getUser()),
        finalize(() => this.loaderService.hide()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.router.navigateByUrl('/my/account');
      });
  }

  private patchUrlInForm(url: string) {
    const image = url.slice(0, url.indexOf('?'));
    this.userFormGroup.patchValue({ image });
  }

  private updatePhoneNumber(phone: string): void {
    this.fireBaseService
      .verifyPhoneNumber(phone)
      .then((verificationId) => {
        this.verificationId = verificationId;
        this.openVerificationPopup();
      })
      .catch((err) => this.popupService.showErrorResponsePopup(err.message));
  }

  private openVerificationPopup(): void {
    const phoneNumber = this.userFormGroup.get('phone').value;
    zip([
      this.popupService.showVerificationPopup(phoneNumber, this.isMobile)
        .verificationCode$,
      this.fireBaseService.fireBaseUser(),
    ])
      .pipe(
        switchMap(([code, user]) => {
          const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
            this.verificationId,
            code
          );
          return from(user.updatePhoneNumber(phoneCredential));
        }),
        catchError((err: HttpErrorResponse) => this.handleError(err)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.updateUser();
      });
  }

  private handleError(err): Observable<never> {
    this.popupService.showErrorResponsePopup(err.message);
    throw new Error(err);
  }

  private autocapitalizeNames(): void {
    const firstName = this.userFormGroup.get('firstName');
    const lastName = this.userFormGroup.get('lastName');
    firstName.setValue(capitalize(firstName.value));
    lastName.setValue(capitalize(lastName.value));
  }

  public onKeyup(): void {
    if (!environment.production) {
      return;
    }
    phonePrefixHandler(this.userFormGroup.get('phone') as FormControl);
  }
}
