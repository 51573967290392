import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AssetInfo } from '../../types/assets';
import { Area, AreaDetails } from '../../types/area';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-season-ticket-area-details',
  templateUrl: './season-ticket-area-details.component.html',
  styleUrls: ['./season-ticket-area-details.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SeasonTicketAreaDetailsComponent implements OnInit, OnChanges {
  @Input() public area: Area | AreaDetails;
  @Input() public isMobile = true;
  public index = 0;
  public assets: AssetInfo[] = [];

  constructor() {}

  ngOnInit() {
    this.generateSliderImages();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.area.currentValue) {
      this.generateSliderImages();
    }
  }

  public handleImageSlide(slide: number): void {
    if (
      !(
        (this.index === this.assets.length - 1 && slide === 1) ||
        (this.index === 0 && slide === -1)
      )
    ) {
      this.index += slide;
    }
  }

  private generateSliderImages(): void {
    this.assets = Object.values(this.area?.assets).filter(
      (asset: AssetInfo) => asset.code === 'images'
    );
  }
}
