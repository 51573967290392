import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-desktop',
  templateUrl: './menu-desktop.component.html',
  styleUrls: ['./menu-desktop.component.scss'],
})
export class MenuDesktopComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
