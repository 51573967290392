import { Component, Input, OnInit } from '@angular/core';
import { NgChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { ChartData } from '../../../types/sharp-sports/chart';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  standalone: true,
  imports: [CommonModule, NgChartsModule],
})
export class LineChartComponent implements OnInit {
  @Input() public data: ChartData[] = [];
  @Input() public labels: string[] = [];
  options: any = {
    responsive: true,
    borderColor: '#C9C9C9',
    pointBorderColor: '#fff',
    pointBackgroundColor: '#fff',
    pointHoverBackgroundColor: '#A3B08A',
    pointHoverBorderColor: '#fff',
    tension: 0.2,
  };
  legend = false;
  type = 'line';

  constructor() {}

  ngOnInit(): void {}
}
