import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { zip } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { AssetsUrlPipe } from '../../pipes/assets-url.pipe';
import { SeatsCountPipe } from '../../pipes/seats-count-trasform.pipe';
import { Area, AreaDetails, AreaDetailsEvent } from '../../types/area';
import { SeasonTicketsService } from '../../services/season-tickets.service';
import { LoaderService } from '../../services/loader.service';
import { Areas } from '../../enum/area';
import { Event } from '../../types/event';
import { SeasonTicketsPages } from '../../enum/page-state';
import { StorageService } from '../../services/storage.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-season-ticket-area-select',
  templateUrl: './season-ticket-area-select.component.html',
  styleUrls: ['./season-ticket-area-select.component.scss'],
  standalone: true,
  imports: [CommonModule, AssetsUrlPipe, SeatsCountPipe],
})
export class SeasonTicketAreaSelectComponent
  extends BaseComponent
  implements OnInit
{
  @Input() public isMobile = true;
  @Input() public selectedAreaId: string;
  @Input() public readOnly = false;
  @Output() public areaDetailsClick: EventEmitter<AreaDetailsEvent> =
    new EventEmitter<AreaDetailsEvent>();
  public areas: (Area | AreaDetails)[];
  private eventId: string;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private seasonTicketsService: SeasonTicketsService,
    private loaderService: LoaderService,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  public navigateToAreaRoom(event: MouseEvent, area: Area | AreaDetails): void {
    this.isMobile
      ? this.router.navigateByUrl(
          this.readOnly
            ? `/space/area-view/${area.id}`
            : `/my/season-tickets/area-room/${this.eventId}/${area.id}`
        )
      : this.areaDetailsClick.emit({ event, area });
  }

  private init(): void {
    this.loaderService.show();
    this.eventId = this.activatedRoute.snapshot.params.eventId;
    if (!this.eventId && this.readOnly) {
      this.fetchAllAreas();
      return;
    }
    this.seasonTicketsService
      .getEvent(this.eventId)
      .pipe(
        switchMap((event: Event) => {
          const dates = this.seasonTicketsService.getSeasonDates();
          if (event.areaSelection === Areas.all) {
            return this.seasonTicketsService.getAreas(dates);
          }
          return zip(
            ...event.areas.map((areaId) =>
              this.seasonTicketsService.getAreaById(areaId, dates)
            )
          );
        }),
        finalize(() => this.loaderService.hide())
      )
      .subscribe((areas: (Area | AreaDetails)[]) => {
        this.areas = areas;
        this.handlePageSate();
      });
  }

  private fetchAllAreas(): void {
    this.seasonTicketsService
      .getAllAreas()
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe((areas: AreaDetails[]) => (this.areas = areas));
  }

  private handlePageSate(): void {
    this.selectedAreaId = this.storageService.getFromStorage<string>(
      SeasonTicketsPages.selectedAreaId
    );
    if (this.selectedAreaId) {
      const selectedArea = this.areas.find(
        (area: Area | AreaDetails) => area.id === this.selectedAreaId
      );
      this.areaDetailsClick.emit({ area: selectedArea });
    }
  }
}
