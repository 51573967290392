import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestaurantMenu } from '../types/restaurant-menu';
import { Pageable } from '../types/pageable';
import { environment } from '../../../environments/environment';
import { LoaderService } from './loader.service';
import { finalize, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RestaurantMenuService {
  constructor(
    private httpClient: HttpClient,
    private loaderService: LoaderService
  ) {}

  public getMenus(): Observable<RestaurantMenu[]> {
    return this.httpClient
      .get<Pageable<RestaurantMenu>>(`${environment.apiUrl}/client/menus`)
      .pipe(map((res) => res.items));
  }

  public getMenuById(id: string): Observable<RestaurantMenu> {
    this.loaderService.show();
    return this.httpClient
      .get<RestaurantMenu>(`${environment.apiUrl}/client/menus/${id}`)
      .pipe(finalize(() => this.loaderService.hide()));
  }
}
