import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Event, EventDate } from '../../types/event';
import { CalendarView, CalendarViewDate } from '../../types/calendar';
import { getCalendars } from '../../helpers/calendar';
import {
  getAllDatesInMonth,
  singleDigitFormatting,
} from '../../helpers/date-timer';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IsTodayPipe } from '../../pipes/is-today.pipe';
import { EventNamePipe } from '../../pipes/event-name.pipe';
import { EventTimePipe } from '../../pipes/event-duration.pipe';
import { filter, takeUntil } from 'rxjs/operators';
import { PopupService } from '../../services/popup.service';
import { BaseComponent } from '../base/base.component';
import { Router } from '@angular/router';
import { EventWithCategory } from '../../../mobile/modules/season-ticket-schedule/event';

@Component({
  selector: 'app-calendar-view-page',
  templateUrl: './calendar-view-page.component.html',
  styleUrls: ['./calendar-view-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IsTodayPipe,
    EventNamePipe,
    EventTimePipe,
  ],
})
export class CalendarViewPageComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() filteredEvents: EventWithCategory;
  @Input() isMobile = true;
  @Output() dateAction$: EventEmitter<string> = new EventEmitter<string>();
  @Output() eventAction$: EventEmitter<Event> = new EventEmitter<Event>();
  public events: Event[];
  public calendars: string[];
  private allDates: EventDate[];
  public calendarMap = new Map<string, CalendarView>();
  public selectedCalendar: string;
  public today = new Date();

  constructor(private popupService: PopupService, private router: Router) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filteredEvents?.currentValue) {
      this.events = Object.values(this.filteredEvents).flat();
      this.allDates = this.getAllDates();
      this.calendars = Array.from(getCalendars(this.allDates)).sort(
        (a, b) => Date.parse(a) - Date.parse(b)
      );
      this.generateCalendar();
      this.selectedCalendar = this.calendars[0];
    }
  }

  private getAllDates(): EventDate[] {
    return this.events.reduce((allEvents: EventDate[], event: Event) => {
      if (event && event.dates) {
        allEvents.push(...event.dates);
      }
      return allEvents;
    }, []);
  }

  private generateCalendar(): void {
    this.calendars.forEach((calendar: string) => {
      const monthYear = calendar.split('-');
      const [year, month] = monthYear;
      const daysInMonth = getAllDatesInMonth(+year, +month);
      const startIndex = new Date(+year, +month - 1, 1).getDay();
      const days: CalendarViewDate[] = daysInMonth.map((date: string) => ({
        day: date,
        event: this.allDates.filter((e: EventDate) => e.date === date),
      }));
      const emptyDays = this.getEmptyDays(startIndex);
      days.unshift(...emptyDays);
      this.calendarMap.set(calendar, {
        days,
        startIndex,
      });
    });
  }

  private getEmptyDays(startIndex: number): CalendarViewDate[] {
    return new Array(startIndex).fill({
      day: null,
      event: [],
    });
  }

  public switchToCurrentMonth() {
    const month = this.today.getMonth() + 1;
    const todayDate = `${this.today.getFullYear()}-${singleDigitFormatting(
      month
    )}`;
    const calendar = this.calendars.find((c) => c === todayDate);
    if (calendar) {
      this.selectedCalendar = calendar;
    }
  }

  public handleDateClick(date: string): void {
    this.dateAction$.emit(date);
  }

  public handleEventClick(eventId: string): void {
    const event = this.events?.find((e: Event) => e.id === eventId);
    this.isMobile
      ? this.showEventDetailsPopup(event)
      : this.eventAction$.emit(event);
  }

  private showEventDetailsPopup(event: Event): void {
    this.popupService
      .showEventDetailsPopup(event)
      .action$.pipe(
        filter((res) => !!res),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() =>
        this.router.navigateByUrl(`/my/season-tickets/dates/${event.id}`)
      );
  }
}
