import { NgModule } from '@angular/core';
import { MenuDesktopComponent } from './menu-desktop.component';
import { CommonModule } from '@angular/common';
import { MenuDesktopRoutingModule } from './menu-desktop-routing.module';
import { RestaurantDesktopPageComponent } from './pages/restaurant-page/restaurant-desktop-page.component';
import { RouterModule } from '@angular/router';
import { RestaurantDesktopMenuPageComponent } from './pages/restaurant-menu-page/restaurant-desktop-menu-page.component';
import { RestaurantComponent } from '../../../common/components/menu/restaurant/restaurant.component';
import { MenuLayoutComponent } from './menu-layout/menu-layout.component';
import { HeaderDesktopComponent } from '../components/layout/header/header-desktop.component';
import { FooterDesktopComponent } from '../components/layout/footer/footer-desktop.component';
import { BreadcrumbDesktopComponent } from '../components/layout/breadcrumb/breadcrumb-desktop.component';
import { MenuImagesSliderComponent } from '../../../common/components/menu/menu-images-slider/menu-images-slider.component';

@NgModule({
  declarations: [
    MenuDesktopComponent,
    RestaurantDesktopPageComponent,
    RestaurantDesktopMenuPageComponent,
    MenuLayoutComponent,
  ],
  imports: [
    // Modules
    CommonModule,
    MenuDesktopRoutingModule,
    RouterModule,
    // Components
    RestaurantComponent,
    HeaderDesktopComponent,
    FooterDesktopComponent,
    BreadcrumbDesktopComponent,
    MenuImagesSliderComponent,
  ],
})
export class MenuDesktopModule {}
