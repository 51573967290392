import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isYesterday',
  standalone: true,
})
export class IsYesterdayPipe implements PipeTransform {
  transform(date: string): boolean {
    const today = new Date();
    const yesterdayDay = new Date(today.setDate(today.getDate() - 1)).getDate();
    const day = new Date(date).getDate();
    return yesterdayDay === day;
  }
}
