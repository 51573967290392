import { Component, Input, OnInit } from '@angular/core';
import { BetSlips } from '../../../types/sharp-sports/bet-slips';
import { CommonModule } from '@angular/common';
import { BetSlipsType } from '../../../enum/bet-slip-type';
import { UnitUsd } from '../../../enum/unit-usd';
import { ParlayWinLoseCounterPipe } from '../../../pipes/builder/parlay-win-lose-counter.pipe';
import { AddProfitStyleClassPipe } from '../../../pipes/builder/add-profit-style-class.pipe';
import { ShowBetSlipOddsAmericanPipe } from '../../../pipes/builder/show-betslip-odds-american.pipe';

@Component({
  selector: 'app-bet-slip-item',
  templateUrl: './bet-slip-item.component.html',
  styleUrls: ['./bet-slip-item.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ParlayWinLoseCounterPipe,
    AddProfitStyleClassPipe,
    ShowBetSlipOddsAmericanPipe,
  ],
})
export class BetSlipItemComponent implements OnInit {
  @Input() public betSlip: BetSlips;
  @Input() public currentBetSlipType: BetSlipsType = BetSlipsType.open;
  @Input() public unitSize: number;
  @Input() public currentCurrency: UnitUsd = UnitUsd.usd;
  @Input() public index = 0;
  public betSlipsType = BetSlipsType;
  public unitUsd = UnitUsd;

  constructor() {}

  ngOnInit(): void {}
}
