import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addProfitStyleClass',
  standalone: true,
})
export class AddProfitStyleClassPipe implements PipeTransform {
  transform(value: number): string {
    return value < 0 ? 'c-error' : value === 0 ? 'c-text-3' : 'c-price';
  }
}
