import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-season-ticket',
  templateUrl: './user-season-ticket.component.html',
  styleUrls: ['./user-season-ticket.component.scss'],
})
export class UserSeasonTicketComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
