import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuComponent } from './menu.component';
import { RestaurantPageComponent } from './pages/restaurant-page/restaurant-page.component';
import { RestaurantMenuPageComponent } from './pages/restaurant-menu-page/restaurant-menu-page.component';
import { menu, restaurant } from '../../../common/constants/breadcrumbs';
import { RestaurantMenuTypes } from '../../../common/enum/restaurant-menu-types';

const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children: [
      {
        path: '',
        data: { breadcrumb: restaurant },
        component: RestaurantPageComponent,
      },
      {
        path: ':id',
        component: RestaurantMenuPageComponent,
        data: { type: RestaurantMenuTypes.dinning, breadcrumb: menu },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class MenuRoutingModule {}
