import { Component, inject, OnInit } from '@angular/core';
import { User } from '../types/user';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  template: '',
})
export abstract class HeaderBaseComponent implements OnInit {
  protected router = inject(Router);
  protected userService = inject(UserService);
  protected authService = inject(AuthService);

  public user: User = this.userService.user;
  private editButtonRoutes = ['/my/account'];
  public showAccountEditButton = this.editButtonRoutes.includes(
    this.router.url
  );
  public bigGameRoomResyKey = environment.bigGameRoomResyKey;

  ngOnInit() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.showAccountEditButton = this.editButtonRoutes.includes(e.url);
      }
    });
  }

  get image(): string | undefined {
    return this.userService.user?.image;
  }

  public async navigateTo(url: string): Promise<void> {
    await this.router.navigateByUrl(url);
  }

  public async logOut(): Promise<void> {
    await this.authService.logOut();
    this.user = null;
  }

  public navigateToAccount(): void {
    this.router.navigateByUrl(this.user ? '/my/account' : '/auth');
  }
}
