import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupsManagerService } from '../../../services/popups-manager.service';
import { UserService } from '../../../services/user.service';
import { isMobileOrTablet } from '../../../helpers/point';

@Component({
  selector: 'app-download-app-popup',
  templateUrl: './download-app-popup.component.html',
  styleUrls: ['./download-app-popup.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class DownloadAppPopupComponent {
  public isMobile = isMobileOrTablet();
  private popupsManagerService = inject(PopupsManagerService);
  private userService = inject(UserService);
  private appStoreLink = `https://itunes.apple.com/us/app/testflight/id899247664?advp=10000&ct=JXSXFUTB7S&mt=8&platform=ios`;
  private appScheme = 'br-leaderboard://';

  public closePopup() {
    this.popupsManagerService.hide();
  }

  public async openIosApp(): Promise<void> {
    await this.canLaunchApp();
  }

  private canLaunchApp(): Promise<any> {
    const { id, firstName, lastName } = this.userService.user;
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(new Error('App launch timed out'));
        location.href = `${this.appStoreLink}?${id}?${firstName}?${lastName}`;
      }, 700);

      location.href = `${this.appScheme}${id}?${firstName}?${lastName}`;
      window.addEventListener('blur', () => {
        clearTimeout(timeoutId);
        resolve(true);
      });
    });
  }
}
