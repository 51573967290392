import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuySeasonTicketComponent } from './pages/buy-season-ticket/buy-season-ticket.component';
import { SeasonTicketDatesPageComponent } from './pages/season-ticket-dates-page/season-ticket-dates-page.component';
import { SeasonTicketComponent } from './season-ticket.component';
import { SeasonTicketRoutingModule } from './saeson-ticket-routing.module';
import { RouterModule } from '@angular/router';
import { CheckoutSeasonTicketPageComponent } from './pages/checkout-season-ticket-page/checkout-season-ticket-page.component';
import { AreaViewPageComponent } from './pages/area-view-page/area-view-page.component';
import { UserSeasonTicketComponent } from './pages/user-season-ticket/user-season-ticket.component';
import { SeasonTicketAreaSelectPageComponent } from './pages/season-ticket-area-select-page/season-ticket-area-select-page.component';
import { SeasonTicketAreaDetailsPopupComponent } from './components/season-ticket-area-details-popup/season-ticket-area-details-popup.component';
import { TicketDatesPopupComponent } from './components/ticket-dates-popup/ticket-dates-popup.component';
import { SeasonTicketReservationComponent } from './pages/season-ticket-reservation/season-ticket-reservation.component';
import { TicketPurchasedComponent } from './components/ticket-purchased/ticket-purchased.component';
import { DatesComponent } from './components/dates/dates.component';
import { CarouselWrapperComponent } from '../../../../../common/components/carousel-wrapper/carousel-wrapper.component';
import { CarouselItemDirective } from '../../../../../common/components/carousel-wrapper/carousel-item.directive';
import { SeasonTicketPreviewComponent } from './pages/season-ticket-preview/season-ticket-preview.component';
import { TicketFilterPipe } from '../../../../../common/pipes/ticket-filter.pipe';
import { SeasonTicketShareComponent } from './pages/season-ticket-share/season-ticket-share.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TicketPreviewDetailsComponent } from './components/ticket-preview-details/ticket-preview-details.component';
import { TicketSharedComponent } from './components/ticket-shared/ticket-shared.component';
import { TicketReceivedComponent } from './components/ticket-received/ticket-received.component';
import { AreaTableNumberPipe } from '../../../../../common/pipes/area-table-number.pipe';
import { BaseTicketComponent } from './components/base-ticket/base-ticket.component';
import { EventNameAsyncPipe } from '../../../../../common/pipes/event-name-async.pipe';
import { SharedTicketsCountPipe } from '../../../../../common/pipes/shared-tickets-count.pipe';
import { TicketPresentQrComponent } from './components/ticket-present-qr/ticket-present-qr.component';
import { DateObjToStringPipe } from '../../../../../common/pipes/date-obj-to-string.pipe';
import { EventItemComponent } from './components/event-item/event-item.component';
import { FilterCountPipe } from '../../../../../common/pipes/filter-count.pipe';
import { KeysOtpPipe } from '../../../../../common/pipes/key-otp.pipe';
import { SanitizeHtmlPipe } from '../../../../../common/pipes/sanitize-html.pipe';
import { ItemsFilterComponent } from './components/items-filter/items-filter.component';
import { AssetsUrlPipe } from '../../../../../common/pipes/assets-url.pipe';
import { BasePopupComponent } from '../../../../../common/components/base/base-popup/base-popup.component';
import { SeasonTicketItemComponent } from '../../../../../common/components/season-ticket/season-ticket-item.component';
import { SeasonTicketCalendarComponent } from '../../../../../common/components/season-ticket-calendar/season-ticket-calendar.component';
import { SeatsCountPipe } from '../../../../../common/pipes/seats-count-trasform.pipe';
import { AreaViewComponent } from '../../../../../common/components/area-view/area-view.component';
import { SeasonTicketAreaDetailsComponent } from '../../../../../common/components/season-ticket-area-details/season-ticket-area-details.component';
import { SeasonTicketAreaSelectComponent } from '../../../../../common/components/season-ticket-area-select/season-ticket-area-select.component';
import { CheckoutSeasonTicketComponent } from '../../../../../common/components/checkout-season-ticket/checkout-season-ticket.component';
import { TicketDetailsComponent } from '../../../../../common/components/ticket-details/ticket-details.component';
import { FilterByDateComponent } from '../../../../../common/components/filter-by-date/filter-by-date.component';
import { EventTimePipe } from '../../../../../common/pipes/event-duration.pipe';
import { CalendarViewPageComponent } from '../../../../../common/components/calendar-view-page/calendar-view-page.component';
import { ConcatPipe } from '../../../../../common/pipes/concat.pipe';
import { SeasonTicketStripeCheckoutComponent } from './pages/season-ticket-stripe-checkout/season-ticket-stripe-checkout.component';
import { StripeComponent } from '../../../../../common/components/stripe-popup/stripe.component';
import { BreadcrumbDesktopComponent } from '../../../../../desktop/modules/components/layout/breadcrumb/breadcrumb-desktop.component';
import { BreadcrumbComponent } from '../../../components/layout/breadcrumb/breadcrumb.component';

@NgModule({
  imports: [
    //modules
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SeasonTicketRoutingModule,
    AssetsUrlPipe,
    //components
    DatesComponent,
    BreadcrumbComponent,
    TicketDetailsComponent,
    TicketReceivedComponent,
    CarouselWrapperComponent,
    TicketPresentQrComponent,
    TicketPreviewDetailsComponent,
    ItemsFilterComponent,
    BasePopupComponent,
    SeasonTicketItemComponent,
    TicketPurchasedComponent,
    SeasonTicketShareComponent,
    SeasonTicketCalendarComponent,
    TicketSharedComponent,
    SeasonTicketAreaSelectComponent,
    SeasonTicketAreaDetailsComponent,
    EventItemComponent,
    AreaViewComponent,
    CheckoutSeasonTicketComponent,
    CalendarViewPageComponent,
    SeasonTicketAreaDetailsPopupComponent,
    TicketDatesPopupComponent,
    //pipes
    EventNameAsyncPipe,
    TicketFilterPipe,
    SharedTicketsCountPipe,
    DateObjToStringPipe,
    SeatsCountPipe,
    FilterCountPipe,
    AreaTableNumberPipe,
    EventTimePipe,
    //directives
    CarouselItemDirective,
    KeysOtpPipe,
    SanitizeHtmlPipe,
    FilterByDateComponent,
    BreadcrumbDesktopComponent,
    ConcatPipe,
    StripeComponent,
  ],
  declarations: [
    //components
    AreaViewPageComponent,
    BaseTicketComponent,
    SeasonTicketComponent,
    BuySeasonTicketComponent,
    UserSeasonTicketComponent,
    SeasonTicketDatesPageComponent,
    SeasonTicketPreviewComponent,
    CheckoutSeasonTicketPageComponent,
    SeasonTicketAreaSelectPageComponent,
    SeasonTicketReservationComponent,
    SeasonTicketStripeCheckoutComponent,
  ],
})
export class SeasonTicketModule {}
