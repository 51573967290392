import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Pageable } from '../types/pageable';
import { map, shareReplay } from 'rxjs/operators';
import { Area, AreaDetails } from '../types/area';
import {
  Event,
  EventCategory,
  GeneralFormAction,
  TicketValidationReq,
} from '../types/event';
import { ResponseView } from '../enum/response-view';
import { UserSettings } from '../types/settings';
import { TableTemplate } from '../types/area';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class SeasonTicketsService {
  public events: Observable<Pageable<Event>> = this.getAllEvents(
    ResponseView.compact
  ).pipe(shareReplay(1));

  private generalTicketFormSubmit: Subject<GeneralFormAction> =
    new Subject<GeneralFormAction>();
  public generalTicketFormSubmit$ = this.generalTicketFormSubmit.asObservable();

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService
  ) {}

  public getAreas(dates: string[]): Observable<Area[]> {
    return this.httpClient
      .post<Pageable<Area>>(
        `${environment.apiUrl}/client/areas-query`,
        { dates },
        { params: { 'response.view': 'tables-availability' } }
      )
      .pipe(map((res: Pageable<Area>) => res.items));
  }

  public getAreaById(areaId: string, dates: string[]): Observable<AreaDetails> {
    return this.httpClient.post<AreaDetails>(
      `${environment.apiUrl}/client/areas/${areaId}`,
      {
        dates,
      }
    );
  }

  public getAllAreas(
    resView: ResponseView = ResponseView.reservationsFull
  ): Observable<AreaDetails[]> {
    return this.httpClient
      .get<Pageable<AreaDetails>>(
        `${environment.apiUrl}/client/areas?response.view=${resView}&page.index=0&page.size=100`
      )
      .pipe(map((a) => a.items));
  }

  public getAllEvents(
    resView: ResponseView = ResponseView.default
  ): Observable<Pageable<Event>> {
    return this.httpClient.get<Pageable<Event>>(
      `${environment.apiUrl}/client/events?page.index=0&page.size=1000&response.view=${resView}`
    );
  }

  public getEvent(eventId: string): Observable<Event> {
    return this.httpClient.get<Event>(
      `${environment.apiUrl}/client/events/${eventId}`
    );
  }

  public getEventCategories(): Observable<Pageable<EventCategory>> {
    return this.httpClient.get<Pageable<EventCategory>>(
      `${environment.apiUrl}/client/event-categories`
    );
  }

  public getSettings(): Observable<UserSettings> {
    return this.httpClient.get<UserSettings>(
      `${environment.apiUrl}/client/settings`
    );
  }

  public getTableTemplates(): Observable<TableTemplate[]> {
    return this.httpClient
      .get<Pageable<TableTemplate>>(
        `${environment.apiUrl}/client/table-templates?page.index=0&page.size=100000`
      )
      .pipe(map((response) => response.items));
  }

  public getSeasonDates(): string[] {
    const item = this.storageService.getFromStorage<string[]>(
      'dates'
    ) as string[];
    return item ?? [];
  }

  public submitGeneralForm(value: GeneralFormAction): void {
    this.generalTicketFormSubmit.next(value);
  }

  public checkTicketValidity(
    payload: TicketValidationReq
  ): Observable<{ message: string }> {
    return this.httpClient.post<{ message: string }>(
      `${environment.apiUrl}/client/my-tickets-sales-validate`,
      payload
    );
  }
}
