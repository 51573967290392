import { Component, Input, OnInit } from '@angular/core';
import { BetSlips } from '../../../types/sharp-sports/bet-slips';
import { Pageable } from '../../../types/pageable';
import { BetSlipsType } from '../../../enum/bet-slip-type';
import { UnitUsd } from '../../../enum/unit-usd';
import { SharpSportsService } from '../../../services/sharp-sports.service';
import { PopupService } from '../../../services/popup.service';
import { UserService } from '../../../services/user.service';
import { LoaderService } from '../../../services/loader.service';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, finalize, map, takeUntil } from 'rxjs/operators';
import { SharpSportsUserMetadata } from '../../../types/sharp-sports/sharp-sports';
import { LoaderTypes } from '../../../enum/loader-types';
import { timeZoneFix } from '../../../helpers/date-time';
import { BaseComponent } from '../../base/base.component';
import { CommonModule } from '@angular/common';
import { BetSlipsFilterCountPipe } from '../../../pipes/builder/bet-slips-filter-count.pipe';
import { BetSlipItemComponent } from '../bet-slip-item/bet-slip-item.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InnerLoaderComponent } from '../../mini-loader/inner-loader.component';

@Component({
  selector: 'app-builder-my-bets-shared',
  templateUrl: './builder-my-bets-shared.component.html',
  styleUrls: ['./builder-my-bets-shared.component.css'],
  standalone: true,
  imports: [
    // Modules
    CommonModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    // Components
    BetSlipItemComponent,
    InnerLoaderComponent,
    // Pipes
    BetSlipsFilterCountPipe,
  ],
})
export class BuilderMyBetsSharedComponent
  extends BaseComponent
  implements OnInit
{
  @Input() isMobile = true;
  public betSlips: Pageable<BetSlips>;
  public betSlipsType = BetSlipsType;
  public unitSize: number;
  public currentCurrency: UnitUsd = UnitUsd.usd;
  public betSlipLeagues: string[];
  public currentPage = 0;
  public searchFormControl = this.fb.control('');
  public showInnerLoader = false;

  constructor(
    public sharpSportsService: SharpSportsService,
    private popupService: PopupService,
    private userService: UserService,
    private loaderService: LoaderService,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.loaderService.show();
    const userId = this.userService.user.id;

    this.getBetSlips()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((betSlips: Pageable<BetSlips>) => {
        this.betSlips = betSlips;
      });

    this.getLeagues()
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe((leagueNames: string[]) => {
        this.betSlipLeagues = leagueNames;
      });

    this.sharpSportsService
      .getUserMetadata(userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: SharpSportsUserMetadata) => (this.unitSize = res.unitSize)
      );
    // this.searchHandler();
  }

  public openFilterPopup() {
    this.popupService
      .showBuilderFilterPopup(this.betSlipLeagues)
      .submit$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.onFilterPopupSubmit();
      });
  }

  public onFilterPopupSubmit(): void {
    this.currentPage = 0;
    this.fetchBetSlips(false, LoaderTypes.main);
  }

  private getLeagues(): Observable<string[]> {
    const currentYear = new Date().getFullYear();
    const lastYear = new Date(); //TODO
    lastYear.setFullYear(currentYear - 1);
    const startDay = lastYear.getTime();
    const endDay = Date.now();
    return this.sharpSportsService
      .getGroupedBetSlips(timeZoneFix(startDay), timeZoneFix(endDay))
      .pipe(map(({ betSlipsByLeagues }) => Object.keys(betSlipsByLeagues)));
  }

  private getBetSlips(
    loaderType?: LoaderTypes
  ): Observable<Pageable<BetSlips>> {
    switch (loaderType) {
      case LoaderTypes.main:
        this.loaderService.show();
        break;
      case LoaderTypes.inner:
        this.showInnerLoader = true;
        break;
    }
    const filter = this.sharpSportsService.selectedFilters;
    const filterQuery = this.generateFilterQuery();
    return this.sharpSportsService.getBetSlipsByDate(
      timeZoneFix(filter.startDate),
      timeZoneFix(filter.endDate),
      filterQuery
    );
  }

  private generateFilterQuery(): string {
    const filters = this.sharpSportsService.selectedFilters;
    const keys = Object.keys(filters);
    return (
      keys.reduce((query: string, key: string) => {
        if (filters[key]?.length > 0 && typeof filters[key] !== 'string') {
          query += `&${key}=${filters[key].join(',')}`;
        }
        return query;
      }, '') +
      `&status=${this.sharpSportsService.selectedFilters.status}` +
      `&page.index=${this.currentPage}` +
      `${
        !!this.searchFormControl.value
          ? '&search=' + this.searchFormControl.value
          : ''
      }`
    );
  }

  public onScrollDown(): void {
    if (
      !this.betSlips ||
      this.betSlips?.total === this.betSlips?.items?.length
    ) {
      return;
    }
    this.currentPage++;
    this.fetchBetSlips(true, LoaderTypes.inner);
  }

  public statusChange(type: BetSlipsType): void {
    this.sharpSportsService.selectedFilters.status = type;
    this.sharpSportsService.selectedFilters = structuredClone(
      this.sharpSportsService.selectedFilters
    );
    this.currentPage = 0;
    this.fetchBetSlips(false, LoaderTypes.main);
  }

  private fetchBetSlips(lazyLoad: boolean = false, loader?: LoaderTypes): void {
    this.getBetSlips(loader)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => {
          this.loaderService.hide();
          this.showInnerLoader = false;
        })
      )
      .subscribe((res: Pageable<BetSlips>) => {
        if (lazyLoad) {
          this.betSlips = {
            ...res,
            items: [...this.betSlips.items, ...res.items],
          };
        } else {
          this.betSlips = res;
        }
      });
  }

  private searchHandler() {
    this.searchFormControl.valueChanges
      .pipe(debounceTime(300), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.currentPage = 0;
        this.betSlips = null;
        this.fetchBetSlips(false, LoaderTypes.inner);
      });
  }

  public submitSearch(e): void {
    e.preventDefault();
    e.stopPropagation();
    this.currentPage = 0;
    this.betSlips = null;
    this.fetchBetSlips(false, LoaderTypes.inner);
  }
}
