import { Pipe, PipeTransform } from '@angular/core';
import { Table } from '../../../types/area';

@Pipe({
  name: 'tableStyle',
  standalone: true,
})
export class TableStylePipe implements PipeTransform {
  transform(table: Table): string {
    return `
      transform: translate(${table.rect.left}px, ${table.rect.top}px) rotate(${table.rect.rotation}deg)`;
  }
}
