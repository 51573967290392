import { Pipe, PipeTransform } from '@angular/core';
import { Bets, BetSlips } from '../../types/sharp-sports/bet-slips';

@Pipe({
  name: 'showBetSlipOddsAmerican',
  standalone: true,
})
export class ShowBetSlipOddsAmericanPipe implements PipeTransform {
  transform(betSips: BetSlips): boolean {
    return !betSips.bets.some((b: Bets) => !!b.oddsAmerican);
  }
}
