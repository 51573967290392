import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterConfig } from './filter-config';
import { CommonModule, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-items-filter',
  templateUrl: './items-filter.component.html',
  styleUrls: ['./items-filter.component.scss'],
  standalone: true,
  imports: [CommonModule, TitleCasePipe],
})
export class ItemsFilterComponent implements OnInit {
  @Input() public filterType: FilterConfig[];
  @Input() public isSticky = true;
  @Input() public isMobile = true;

  @Input() public currentType: string;
  @Output() public filterType$: EventEmitter<string> =
    new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    this.fetchFilterType();
  }

  public selectType(type: FilterConfig): void {
    this.currentType = type.value;
    this.filterType$.emit(type.value);
  }

  public fetchFilterType(): void {
    if (!this.filterType?.length || this.currentType) {
      return;
    }
    this.currentType = this.filterType[0].value;
  }
}
