import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { AreaDetails, Table, TableTemplate } from '../../types/area';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutConfigService } from '../../services/layout-config.service';
import { SeasonTicketsService } from '../../services/season-tickets.service';
import { LoaderService } from '../../services/loader.service';
import {
  delay,
  finalize,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { fromEvent, of, timer, zip } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TableNumberStylePipe } from './pipes/table-number-style.pipe';
import { TableStylePipe } from './pipes/table-style.pipe';
import { AreaTableNumberPipe } from '../../pipes/area-table-number.pipe';

interface Point {
  x: number;
  y: number;
}

const INITIAL_SCALE = 1;
const MAXIMUM_SCALE = 2.5;
const AREA_DETAILS_WIDTH = 190;
const AREA_DETAILS_HEIGHT = 94;
const NEXT_BUTTON_HEIGHT = 68;

@Component({
  selector: 'app-area-view',
  templateUrl: './area-view.component.html',
  styleUrls: ['./area-view.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TableNumberStylePipe,
    TableStylePipe,
    AreaTableNumberPipe,
  ],
})
export class AreaViewComponent
  extends BaseComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() public areaId: string;
  @Input() public isMobile = true;
  @Input() public readOnly = false;
  public areaTransform = '';
  @ViewChild('areaRef', { read: ElementRef, static: true })
  public areaRef: ElementRef<HTMLDivElement>;
  @ViewChild('areaContainerRef', { read: ElementRef, static: true })
  public areaContainerRef: ElementRef<HTMLDivElement>;
  public tables: Table[] = [];
  public selectedTable: Table;
  public backgroundDimensions: { width: number; height: number };
  public areaImageSrc: string;
  public areaDetails: AreaDetails;
  public tableDetailsPosition: Point = { x: 0, y: 0 };
  public isPrivate: boolean;
  public initialAnimation = false;
  private scale = INITIAL_SCALE;
  private lastScale = INITIAL_SCALE;
  private minScale = INITIAL_SCALE;
  private areaPosition: Point = { x: 0, y: 0 };
  private lastPosition: Point = { x: 0, y: 0 };
  private lastEvent: string;
  private eventId: string;
  private fixHammerjsDeltaIssue: Point;
  private initialScale: number;

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private layoutConfigService: LayoutConfigService,
    private seasonTicketsService: SeasonTicketsService,
    private loaderService: LoaderService
  ) {
    super();
  }

  ngOnInit() {
    document.body.classList.add('no-scroll');
    this.initializeHammer();
    const { areaId, eventId } = this.activatedRoute.snapshot.params;
    this.areaId = areaId ?? this.areaId;
    this.eventId = eventId;
    this.fetchArea();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.areaId?.firstChange) {
      this.selectedTable = null;
      this.fetchArea();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    document.body.classList.remove('no-scroll');
  }

  private fetchArea(): void {
    this.loaderService.show();
    const dates = this.seasonTicketsService.getSeasonDates();
    this.seasonTicketsService
      .getTableTemplates()
      .pipe(
        switchMap((tableTemplates: TableTemplate[]) =>
          zip(
            this.seasonTicketsService.getAreaById(this.areaId, dates),
            of(tableTemplates)
          )
        ),
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe(([area, tableTemplates]: [AreaDetails, TableTemplate[]]) =>
        this.handleAreaLoaded(area, tableTemplates)
      );
  }

  public handleTableClick(table: Table, tableRef: HTMLImageElement): void {
    if (this.readOnly) {
      return;
    }
    this.selectedTable = table;
    this.updateTableInfoPosition(tableRef);
  }

  public handleOverlayClick(): void {
    this.selectedTable = null;
  }

  public handleNavigateToCheckout(): void {
    if (!this.areaDetails || !this.selectedTable?.available) {
      return;
    }
    this.router.navigateByUrl(
      `/my/season-tickets/ticket-view/${this.eventId}/${this.areaId}/${this.selectedTable.id}`
    );
  }

  private startInitialZoomAnimation(): void {
    timer(1600)
      .pipe(
        takeWhile(() => this.selectedTable == null),
        tap(() => {
          const scale = this.getInitialScale();
          this.scale = this.lastScale = scale;
          this.updateAreaPosition(this.areaPosition);
          this.initialAnimation = true;
        }),
        delay(800),
        tap(() => {
          this.handlePanEnd();
          this.initialAnimation = false;
        })
      )
      .subscribe();
  }

  private handleAreaLoaded(
    area: AreaDetails,
    tableTemplates: TableTemplate[]
  ): void {
    this.isPrivate = area.privacyType === 'private';
    this.areaDetails = area;
    this.tables = area.tables.map((table) => ({
      ...table,
      templateDetails: tableTemplates.find((tt) => tt.id === table.template),
    }));
    this.areaImageSrc =
      Object.values(area.assets).find((item) => item.code === 'layout')?.url ||
      '';
    this.loadAreaImage();
    if (this.isPrivate) {
      this.selectedTable = this.tables[0];
    }
  }

  private getInitialScale(): number {
    const areaContainerRect =
      this.areaContainerRef.nativeElement.getBoundingClientRect();
    const areaRect = this.areaRef.nativeElement.getBoundingClientRect();
    const scale =
      (areaContainerRect.height - areaRect.height) / areaContainerRect.height;
    return this.scale + 1.5 * scale;
  }

  private updateTableInfoPosition(tableRef: HTMLImageElement): void {
    const tableDimensions = tableRef.getBoundingClientRect();
    const areaContainerRect =
      this.areaContainerRef.nativeElement.getBoundingClientRect();
    const relativePosX = tableDimensions.x - areaContainerRect.x;
    const tableDetailsMaxX = areaContainerRect.width - AREA_DETAILS_WIDTH;
    const tableDetailsMaxY =
      areaContainerRect.height - AREA_DETAILS_HEIGHT - NEXT_BUTTON_HEIGHT;
    let selectedTableDetailsTopPosition =
      tableDimensions.y - areaContainerRect.y;
    const selectedTableDetailsLeftPosition = Math.max(
      Math.min(
        relativePosX - AREA_DETAILS_WIDTH / 2 + tableDimensions.width / 2,
        tableDetailsMaxX
      ),
      0
    );
    if (
      selectedTableDetailsTopPosition + tableDimensions.height >
      tableDetailsMaxY
    ) {
      selectedTableDetailsTopPosition -= AREA_DETAILS_HEIGHT;
    } else {
      selectedTableDetailsTopPosition += tableDimensions.height;
    }
    this.tableDetailsPosition = {
      x: selectedTableDetailsLeftPosition,
      y: selectedTableDetailsTopPosition,
    };
  }

  private updateAreaPosition({ x, y }: Point): void {
    const containerRect =
      this.areaContainerRef.nativeElement.getBoundingClientRect();
    const areaHeight = this.backgroundDimensions.height * this.scale;
    const areaWidth = this.backgroundDimensions.width * this.scale;
    const maximumX = (areaWidth - areaWidth / this.scale - 1) / 2;
    const minimumX = areaWidth - containerRect.width - maximumX;
    const maximumY = (areaHeight - areaHeight / this.scale - 1) / 2;
    const minimumY = areaHeight - containerRect.height - maximumY;
    this.areaPosition = {
      x: Math.min(Math.max(x, -minimumX), maximumX),
      y: Math.min(Math.max(y, -minimumY), maximumY),
    };
    this.areaTransform = `translate(${this.areaPosition.x}px, ${this.areaPosition.y}px) scale(${this.scale})`;
  }

  private initializeHammer() {
    const hammer = new (window as any).Hammer(this.areaRef.nativeElement, {
      domEvents: true,
    });
    hammer.get('pinch').set({ enable: true });
    hammer.on('pan', (e) => this.handlePan(e));
    hammer.on('pinch', (e) => this.handlePinch(e));
    hammer.on('panend', () => this.handlePanEnd());
    fromEvent(this.areaRef.nativeElement, 'touchend')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.handlePanEnd());
  }

  private handlePan(e): void {
    if (this.lastEvent !== 'pan') {
      this.fixHammerjsDeltaIssue = {
        x: e.deltaX,
        y: e.deltaY,
      };
    }
    this.lastEvent = 'pan';
    this.updateAreaPosition({
      x: this.lastPosition.x + e.deltaX - this.fixHammerjsDeltaIssue.x,
      y: this.lastPosition.y + e.deltaY - this.fixHammerjsDeltaIssue.y,
    });
  }

  private handlePinch(e): void {
    this.lastEvent = 'pinch';
    this.scale = Math.min(
      Math.max(this.minScale, e.scale * this.lastScale),
      MAXIMUM_SCALE
    );
    this.updateAreaPosition(this.areaPosition);
  }

  public zoomHandler(step: number): void {
    this.scale = Math.min(
      Math.max(this.initialScale, step + this.scale),
      MAXIMUM_SCALE
    );
    this.selectedTable = null;
    this.updateAreaPosition(this.areaPosition);
  }

  private handlePanEnd(): void {
    this.lastPosition = this.areaPosition;
    this.lastEvent = 'panend';
    this.lastScale = this.scale;
  }

  private loadAreaImage() {
    const image = new Image();
    image.src = this.areaImageSrc;
    image.onload = () => {
      this.backgroundDimensions = {
        width: image.naturalWidth,
        height: image.naturalHeight,
      };
      const containerWidth = window.document.documentElement.clientWidth;
      this.scale =
        this.lastScale =
        this.minScale =
          containerWidth / image.naturalWidth;
      this.updateAreaPosition({ x: 0, y: 0 });
      if (this.isMobile) {
        this.startInitialZoomAnimation();
      } else {
        this.scale = this.initialScale = this.generateDesktopScale(
          image.naturalWidth
        );
        this.updateAreaPosition(this.areaPosition);
      }
    };
  }

  private generateDesktopScale(imageWidth: number): number {
    const areaContainerRect =
      this.areaContainerRef.nativeElement.getBoundingClientRect();
    return areaContainerRect.width / imageWidth;
  }
}
