export const openSharpSports = ({ token, uid }) => {
  window.parent.postMessage(
    { key: 'OPEN_SHARPSPORTS', data: { token, uid } },
    '*'
  );
};

export const getUserCoords = () => {
  window.parent.postMessage({ key: 'GET_COORDS', data: {} }, '*');
};
