import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { TicketComponent } from '../../../mobile/modules/main/pages/season-ticket/components/ticket/ticket.component';
import { BaseComponent } from '../base/base.component';
import { AreaDetails, Table } from '../../types/area';
import { User } from '../../types/user';
import { SalesService } from '../../services/sales.service';
import { PopupService } from '../../services/popup.service';
import { SeasonTicketsService } from '../../services/season-tickets.service';
import { LoaderService } from '../../services/loader.service';
import { UserSettings } from '../../types/settings';
import { Event } from '../../types/event';
import { UserService } from '../../services/user.service';
import { GeneralTicketFormComponent } from '../general-ticket-form/general-ticket-form.component';

@Component({
  selector: 'app-checkout-season-ticket',
  templateUrl: './checkout-season-ticket.component.html',
  styleUrls: ['./checkout-season-ticket.component.scss'],
  standalone: true,
  imports: [CommonModule, TicketComponent, GeneralTicketFormComponent],
})
export class CheckoutSeasonTicketComponent
  extends BaseComponent
  implements OnInit
{
  @Input() public isMobile = true;
  @Input() public isGeneral = false;
  public area?: AreaDetails;
  public subTotal: number;
  public taxes: number;
  public selectedTable: Table;
  public user: User;
  public event: Event;
  private tax: number;
  public dates: string[] = [];
  public seatsCount: number;

  constructor(
    private router: Router,
    private userService: UserService,
    private popupService: PopupService,
    private salesService: SalesService,
    private activatedRoute: ActivatedRoute,
    private seasonTicketsService: SeasonTicketsService,
    private loaderService: LoaderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.user = this.userService.user;
    this.activatedRoute.snapshot.data.isGeneral
      ? this.fetchEvent()
      : this.fetchAreasAndEvent();
  }

  public handleCheckout(): void {
    if (this.event && this.selectedTable && this.dates?.length) {
      this.router.navigateByUrl(
        `/my/season-tickets/checkout/${this.event.id}/${this.area.id}/${this.selectedTable.id}`
      );
    }
  }

  public handleGeneralCheckout(): void {
    this.router.navigateByUrl(
      `/my/season-tickets/checkout/${this.event.id}/${this.seatsCount}`
    );
  }

  private fetchAreasAndEvent(): void {
    const { areaId, eventId, tableId } = this.activatedRoute.snapshot.params;
    if (!areaId || !eventId || !tableId) {
      return;
    }
    this.dates = this.seasonTicketsService.getSeasonDates();
    this.loaderService.show();
    this.seasonTicketsService
      .getAreaById(areaId, this.dates)
      .pipe(
        switchMap((area) => {
          this.area = area;
          return this.seasonTicketsService.getSettings();
        }),
        switchMap((res: UserSettings) => {
          this.tax = res.sales.tax;
          return this.seasonTicketsService.getEvent(eventId);
        }),
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe((event: Event) => {
        this.event = event;
        this.selectedTable = this.area.tables.find(
          (table: Table) => table.id === tableId
        );
        this.subTotal =
          this.selectedTable.seatsCount *
          this.area.seatPrice *
          this.dates.length;
        this.taxes = (this.subTotal * this.tax) / 100;
      });
  }

  private fetchEvent(): void {
    const { eventId } = this.activatedRoute.snapshot.params;
    this.dates = this.seasonTicketsService.getSeasonDates();
    this.seasonTicketsService
      .getEvent(eventId)
      .pipe(
        switchMap((e: Event) => {
          this.event = e;
          return this.seasonTicketsService.getSettings();
        })
      )
      .subscribe((res: UserSettings) => {
        this.tax = res.sales.tax;
      });
  }

  public calcSubTotal(seatCount): void {
    this.seatsCount = seatCount;
    this.subTotal = seatCount * this.dates.length * this.event.price;
    this.taxes = (this.subTotal * this.tax) / 100;
  }
}
