import {Component} from '@angular/core';
import {AccountBaseComponent} from '../../../../../common/base-controllers/account-base.component';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent extends AccountBaseComponent {

}
