import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UserService } from '../../../common/services/user.service';
import { PopupService } from '../../../common/services/popup.service';
import { AuthService } from '../../../common/services/auth.service';
import { Router } from '@angular/router';
import { FireBaseService } from '../../../common/services/fire-base.service';
import { LoaderService } from '../../../common/services/loader.service';
import { BreadcrumbBackground } from '../../../common/enum/breadcrumb-background';
import { ProfileBaseComponent } from '../../../common/base-controllers/profile-base.component';
import { WelcomeDrinkService } from '../../../common/services/welcome-drink.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends ProfileBaseComponent implements OnInit {
  public breadcrumbBackground = BreadcrumbBackground.withoutHeader;
  constructor(
    fb: UntypedFormBuilder,
    userService: UserService,
    popupService: PopupService,
    authService: AuthService,
    router: Router,
    fireBaseService: FireBaseService,
    loaderService: LoaderService
  ) {
    super(
      fb,
      userService,
      popupService,
      router,
      fireBaseService,
      loaderService
    );
  }
}
