import { Component, OnDestroy, OnInit } from '@angular/core';
import { BuilderOverviewBaseComponent } from '../../../../../../../../../../../common/base-controllers/builder-overview-base.component';
import { LoaderService } from '../../../../../../../../../../../common/services/loader.service';
import { SharpSportsService } from '../../../../../../../../../../../common/services/sharp-sports.service';
import { UserService } from '../../../../../../../../../../../common/services/user.service';

@Component({
  selector: 'app-builder-overview-home',
  templateUrl: './builder-overview-home.component.html',
  styleUrls: ['./builder-overview-home.component.scss'],
})
export class BuilderOverviewHomeComponent
  extends BuilderOverviewBaseComponent
  implements OnInit, OnDestroy
{
  constructor(
    loaderService: LoaderService,
    sharpSportsService: SharpSportsService,
    userService: UserService
  ) {
    super(loaderService, sharpSportsService, userService);
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    super.destroy();
  }
}
