import { Injectable } from '@angular/core';
import { interval, Observable, Subject, take } from 'rxjs';
import { LoaderState } from '../types/loader-state';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loaderSubject = new Subject<LoaderState>();
  public loaderState = this.loaderSubject.asObservable();

  constructor() {}

  public show(): void {
    this.loaderSubject.next({ show: true });
  }

  public hide(): void {
    this.loaderSubject.next({ show: false });
  }

  public staticLoader(milliseconds = 700): Observable<number> {
    this.show();
    return interval(milliseconds).pipe(
      tap(() => this.hide()),
      take(1)
    );
  }
}
