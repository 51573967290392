import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Assets } from '../../../types/assets';
import { ReaderModule } from '../../reader/reader.module';
import { ReaderService } from '../../../services/reader.service';
import { ReaderCanvasEvents } from '../../../enum/reader.enum';

@Component({
  selector: 'app-menu-images-slider',
  templateUrl: './menu-images-slider.component.html',
  styleUrls: ['./menu-images-slider.component.css'],
  standalone: true,
  imports: [CommonModule, ReaderModule],
})
export class MenuImagesSliderComponent implements OnChanges {
  @Input() public assets: Assets;
  @Input() public isMobile = true;
  public size = 0;
  public readerCanvasEvents = ReaderCanvasEvents;

  constructor(public readerService: ReaderService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assets.currentValue) {
      this.size = Object.values(this.assets).length;
    }
  }

  public action(type: ReaderCanvasEvents): void {
    this.readerService.canvasSubjectHandler({ type });
  }
}
