import { Component, OnInit } from '@angular/core';
import { DynamicPopupService } from '../../../common/services/dynamic-popup.service';
import { PageCode } from '../../../common/enum/page-code';

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.css'],
})
export class SpaceComponent implements OnInit {
  constructor(private dynamicPopupService: DynamicPopupService) {}

  ngOnInit() {
    this.dynamicPopupService.showPagePopups(PageCode.theSpace);
  }
}
