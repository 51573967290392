import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GeoLocationCoord } from '../types/event';

@Injectable({ providedIn: 'root' })
export class CommunicationService {
  private geolocationDataSub = new Subject<GeoLocationCoord>();
  public geolocationData: Observable<GeoLocationCoord> =
    this.geolocationDataSub.asObservable();

  public setGeoLocationData(coords: { latitude: number; longitude: number }) {
    this.geolocationDataSub.next(coords);
  }

  public throwError(message: string) {
    this.geolocationDataSub.error(message);
  }
}
