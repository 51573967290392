import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageUrlFormatter',
  standalone: true,
})
export class ImageUrlFormatterPipe implements PipeTransform {
  transform(
    imageName: string,
    folder: 'Leagues' | 'Teams' | 'Sportbooks',
    type: 'split' | 'join'
  ): string {
    switch (type) {
      case 'split':
        imageName = imageName.split(' ').join('-').toLowerCase();
        break;
      case 'join':
        imageName = imageName.split(' ').join('');
        break;
    }
    return `https://bankrollclub-sharpsports-assets.s3.amazonaws.com/${folder}/${imageName}.png`;
  }
}
