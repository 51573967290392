import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { CommunityService } from '../../../../../../../common/services/community.service';
import { BaseComponent } from '../../../../../../../common/components/base/base.component';
import { ProsNews } from '../../../../../../../common/types/pro-news';

@Component({
  selector: 'app-hear-from-the-pros-desktop-page',
  templateUrl: './hear-from-the-pros-desktop-page.component.html',
  styleUrls: ['./hear-from-the-pros-desktop-page.component.css'],
})
export class HearFromTheProsDesktopPageComponent
  extends BaseComponent
  implements OnInit
{
  public selectedNews: ProsNews;
  constructor(private communityService: CommunityService) {
    super();
  }

  ngOnInit(): void {
    this.communityService.selectNews$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((n: ProsNews) => {
        this.selectedNews = n;
      });
  }
}
