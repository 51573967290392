import { Component, OnInit } from '@angular/core';
import { Event, EventCategory } from '../types/event';
import { BreadcrumbBackground } from '../enum/breadcrumb-background';
import { SeasonTicketsService } from '../services/season-tickets.service';
import { ResponseView } from '../enum/response-view';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../components/base/base.component';
import { Pageable } from '../types/pageable';
import { LoaderService } from '../services/loader.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  EventType,
  EventWithCategory,
} from '../../mobile/modules/season-ticket-schedule/event';
import { User } from '../types/user';
import { UserService } from '../services/user.service';

@Component({
  template: ``,
})
export abstract class SeasonTicketScheduleBaseComponent
  extends BaseComponent
  implements OnInit
{
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  public user: User = this.userService.user;
  public eventType = EventType;
  public currentEventType: EventType = EventType.Calendar;
  public events: EventWithCategory;
  public filteredEvents: EventWithCategory;
  public featuredEvents: EventWithCategory;
  public calendarData: EventWithCategory;
  public keys: string[];
  public eventMaxYear: number;
  public filteredKeys: string[] = [];
  public currentSelectedDate: string;
  public allEvents: Pageable<Event>;
  public showCalendarView = false;
  public eventDetails: Event | null;

  protected constructor(
    private seasonTicketsService: SeasonTicketsService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  public filterByFeaturedEvents(): void {
    if (this.currentEventType === this.eventType.Calendar) {
      this.currentEventType = this.eventType.Featured;
      this.filteredKeys = Object.keys(this.keys);
      this.filteredEvents = {};
    } else {
      this.currentEventType = this.eventType.Calendar;
      this.filterItems(Object.keys(this.events));
    }
    this.filterFeaturedEvents();
  }

  public trackByEventsCategory(index: number, item: { key: string }): string {
    return item.key;
  }

  private init(): void {
    this.loaderService.show();
    this.seasonTicketsService
      .getAllEvents(ResponseView.full)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: Pageable<Event>) => {
        event.items.sort(
          (a, b) => Date.parse(a.dates[0].date) - Date.parse(b.dates[0].date)
        );
        this.loaderService.staticLoader(300).subscribe();
        this.allEvents = event;
        this.events = this.getEventCategoriesWithKeys(event).events;
        this.featuredEvents =
          this.getEventCategoriesWithKeys(event).featuredEvents;
        this.filteredEvents = this.events;
        this.calendarData = { ...this.filteredEvents, ...this.featuredEvents };
        this.keys = Object.keys(this.events);
        this.eventMaxYear = Math.max(
          ...(event.items as any).flatMap((e) =>
            e.dates.map((d) => +d.date.split('-')[0])
          )
        );
        this.showEventDetails();
      });
  }

  private getEventCategoriesWithKeys(event: Pageable<Event>): {
    events: EventWithCategory;
    featuredEvents: EventWithCategory;
  } {
    const events = {};
    const featuredEvents = {};
    for (const item of event.items) {
      const key: string = (item.category as EventCategory).code;
      if (key !== 'featured') {
        events[key] = events[key] || [];
        events[key].push(item);
      } else {
        featuredEvents[key] = featuredEvents[key] || [];
        featuredEvents[key].push(item);
      }
    }
    return { events, featuredEvents };
  }

  public filterItems(keys: string[]): void {
    this.currentEventType = this.eventType.Calendar;
    this.filteredEvents = keys.reduce(
      (obj: Record<string, Event[]>, key: string) => {
        obj[key] = !this.currentSelectedDate
          ? this.events[key]
          : this.filterEventsByDate(this.events[key]);
        return obj;
      },
      {}
    );
  }

  public filterEventsByDate(events: Event[]): Event[] {
    return events?.filter((e: Event) =>
      e.dates.some((d) => d.date === this.currentSelectedDate)
    );
  }

  public filterByDate(date: string): void {
    this.showCalendarView = false;
    this.currentSelectedDate = date;
    this.filterItems(Object.keys(this.filteredEvents));
    this.filterFeaturedEvents();
  }

  public filterFeaturedEvents(): void {
    const featuredEvents = this.getEventCategoriesWithKeys(
      this.allEvents
    ).featuredEvents;
    this.featuredEvents = !this.currentSelectedDate
      ? featuredEvents
      : { featured: this.filterEventsByDate(featuredEvents.featured) };
  }

  public calendarViewCheckboxHandler() {
    this.showCalendarView = !this.showCalendarView;
  }

  private showEventDetails(): void {
    this.activatedRoute.queryParamMap.subscribe((paramMap: ParamMap) => {
      const eventId = paramMap.get('eventId');
      if (!eventId) {
        return;
      }
      this.eventDetails =
        this.allEvents.items.find((e: Event) => e.id === eventId) || null;
    });
  }
}
