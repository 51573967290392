import { Component, inject } from '@angular/core';
import { BaseComponent } from '../components/base/base.component';
import { LayoutConfigService } from '../services/layout-config.service';
import { VenuePredictAndWinService } from '../services/venue-predict-and-win.service';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '../services/loader.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { PredictionTournament } from '../types/venue-prediction';
import { Pageable } from '../types/pageable';
import { PredictionStatus } from '../enum/prediction-status';

@Component({
  template: ``,
})
export abstract class VenuePredictAndWinBaseComponent extends BaseComponent {
  protected layoutConfigService = inject(LayoutConfigService);
  protected venuePredictAndWinService = inject(VenuePredictAndWinService);
  protected activatedRoute = inject(ActivatedRoute);
  protected loaderService = inject(LoaderService);
  public currentTournament: PredictionTournament;
  public venueId = this.activatedRoute.snapshot.params.venueId;
  public status: PredictionStatus = PredictionStatus.none;
  public predictionStatus = PredictionStatus;

  protected constructor() {
    super();
  }

  protected init(): void {
    this.loaderService.show();
    this.venuePredictAndWinService
      .getPredictionTournaments(this.venueId)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe(
        (res: Pageable<PredictionTournament>) =>
          (this.currentTournament = this.getCurrentTournament(res.items))
      );
  }

  private getCurrentTournament(predictionTournament: PredictionTournament[]) {
    const now = Date.now();
    const sortedByStartDate = predictionTournament.sort(
      (a: PredictionTournament, b: PredictionTournament) =>
        a.leaderboardStartDate - b.leaderboardStartDate
    );

    const currentTournamentIndex = sortedByStartDate.findIndex(
      (t: PredictionTournament) =>
        t.leaderboardEndDate > now && now > t.leaderboardStartDate
    );

    const nextTournamentIndex = sortedByStartDate.findIndex(
      (t: PredictionTournament) => t.leaderboardStartDate > now
    );

    if (currentTournamentIndex !== -1) {
      this.status = PredictionStatus.onGoing;
      return predictionTournament[currentTournamentIndex];
    }

    if (nextTournamentIndex !== -1) {
      this.status = PredictionStatus.next;
      return predictionTournament[nextTournamentIndex];
    }

    this.status = PredictionStatus.none;
    return null;
  }
}
