export enum PageCode {
  home = 'home',
  dining = 'dining',
  ticketedEvents = 'ticketed-events',
  privateEvents = 'private-events',
  vip = 'vip-memberships-details',
  seasonTickets = 'season-tickets',
  theSpace = 'the-space',
  about = 'about',
  builder = 'builder',
}
