import { Component, Input } from '@angular/core';
import { SharpSportsService } from '../../services/sharp-sports.service';
import { LoaderService } from '../../services/loader.service';
import { UserService } from '../../services/user.service';
import { BaseComponent } from '../base/base.component';
import { finalize, takeUntil } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sharp-sports-button',
  templateUrl: './sharp-sports-button.component.html',
  styleUrls: ['./sharp-sports-button.component.scss'],
  standalone: true,
})
export class SharpSportsButtonComponent extends BaseComponent {
  @Input() public bookRegionId: string;

  constructor(
    private sharpSportsService: SharpSportsService,
    private loaderService: LoaderService,
    private userService: UserService
  ) {
    super();
  }

  public async clickHandler() {
    if (!this.userService.user) {
      return;
    }
    const windowRef = this.openPopup('', 500, 600);
    this.loaderService.show();
    const internalId = JSON.stringify({ internalId: this.userService.user.id });
    const res = await firstValueFrom(
      this.sharpSportsService
        .getPopupCid(environment.sharpSportsToken, internalId)
        .pipe(
          takeUntil(this.unsubscribe$),
          finalize(() => this.loaderService.hide())
        )
    );
    windowRef.location = `https://ui.sharpsports.io/link/${res.cid}/region/${this.bookRegionId}/login`;
  }

  private openPopup(url, w, h): Window {
    return window.open(
      url,
      '_blank',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=20, left=20`
    );
  }
}
