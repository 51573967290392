import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PopupsManagerService } from '../../../services/popups-manager.service';
import { RegionSelectSharedComponent } from '../../bankroll-builder/region-select-shared/region-select-shared.component';
import { BookSelectSharedComponent } from '../../bankroll-builder/book-select-shared/book-select-shared.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-builder-account-select-popup',
  templateUrl: './builder-account-select-popup.component.html',
  styleUrls: ['./builder-account-select-popup.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    RegionSelectSharedComponent,
    BookSelectSharedComponent,
  ],
})
export class BuilderAccountSelectPopupComponent implements OnInit {
  public bookId: string;
  @Output() closePopup$: EventEmitter<void> = new EventEmitter<void>();

  constructor(private popupsManagerService: PopupsManagerService) {}

  ngOnInit(): void {}

  public closePopup() {
    this.closePopup$.emit();
    this.popupsManagerService.hide();
  }
}
