import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../common/enum/breadcrumb-background';
import { LayoutDecorator } from '../../../../../common/helpers/layout.decorator';
import { BaseComponent } from '../../../../../common/components/base/base.component';
import { Observable } from 'rxjs';
import { RestaurantMenu } from '../../../../../common/types/restaurant-menu';
import { RestaurantMenuService } from '../../../../../common/services/restaurant-menu.service';

@Component({
  selector: 'app-restaurant-page',
  templateUrl: './restaurant-page.component.html',
  styleUrls: ['./restaurant-page.component.scss'],
})
@LayoutDecorator('layoutConfigService', { showHeader: true, showFooter: false })
export class RestaurantPageComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  breadcrumbBackground = BreadcrumbBackground.withHeader;
  public menuData$: Observable<RestaurantMenu[]>;

  constructor(private restaurantMenuService: RestaurantMenuService) {
    super();
  }

  ngOnInit(): void {
    this.menuData$ = this.restaurantMenuService.getMenus();
  }

  ngOnDestroy() {}
}
