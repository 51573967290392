import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { environment } from '../../environments/environment';
import { isMobileOrTablet } from '../common/helpers/point';

export const MOBILE: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('src/app/mobile/mobile.module').then((mod) => mod.MobileModule),
  },
];

export const DESKTOP: Route[] = [
  {
    path: '',
    loadChildren: () =>
      import('src/app/desktop/desktop.module').then((mod) => mod.DesktopModule),
  },
];

export const CLUB: Route[] = [
  {
    path: '',
    loadChildren: () =>
      import('src/app/club/club.module').then((mod) => mod.ClubModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      environment.club ? CLUB : isMobileOrTablet() ? MOBILE : DESKTOP,
      {
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
        relativeLinkResolution: 'legacy',
        useHash: false,
      }
    ),
  ],
  exports: [RouterModule],
})
export class RootRoutingModule {}
