import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../components/base/base.component';
import { ResponseView } from '../enum/response-view';
import { SeasonTicketsService } from '../services/season-tickets.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Event } from '../types/event';
import { Pageable } from '../types/pageable';
import { LoaderService } from '../services/loader.service';
import { SubscriptionService } from '../services/subscription.service';
import { FormControl, Validator, Validators } from '@angular/forms';
import { regexp } from '../validators/regexp';
import { PageCode } from '../enum/page-code';
import { DynamicPopupService } from '../services/dynamic-popup.service';

@Component({
  template: '',
})
export abstract class HomeBaseComponent
  extends BaseComponent
  implements OnInit
{
  public topEvents: Event[];
  public emailFormControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(regexp.email),
  ]);
  public submitted = false;

  protected constructor(
    private seasonTicketsService: SeasonTicketsService,
    private loaderService: LoaderService,
    private subscriptionService: SubscriptionService,
    private dynamicPopup: DynamicPopupService
  ) {
    super();
  }

  ngOnInit() {
    this.loaderService.show();
    this.seasonTicketsService
      .getAllEvents(ResponseView.full)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe((res: Pageable<Event>) => {
        this.topEvents = res.items.filter((e: Event) => e.showOnHomePage);
        this.dynamicPopup.showPagePopups(PageCode.home);
      });
  }

  public submitEmail(): void {
    const payload = {
      name: 'Email Form',
      source: 'https://app.bankrollclub.com/',
      test: false,
      'fields[Email]': this.emailFormControl.value,
      dolphin: false,
    };

    this.subscriptionService
      .emailSubscription(this.formatToUrlEncodedForm(payload))
      .subscribe(() => (this.submitted = true));
  }

  private formatToUrlEncodedForm(details): any {
    const formBody = [];
    for (const property in details) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }
}
