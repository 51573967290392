import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReceivedTicket} from '../../../types/user';
import {TicketStatus} from '../../../enum/ticket-status';
import {CommonModule} from '@angular/common';
import {
  TicketReceivedComponent
} from '../../../../mobile/modules/main/pages/season-ticket/components/ticket-received/ticket-received.component';
import {BaseComponent} from '../../base/base.component';
import {PopupsManagerService} from '../../../services/popups-manager.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {LoaderService} from '../../../services/loader.service';
import {TicketsService} from '../../../services/tickets.service';
import {BasePopupComponent} from '../../base/base-popup/base-popup.component';

@Component({
  selector: 'app-received-ticket-popup',
  standalone: true,
  imports: [
    CommonModule,
    TicketReceivedComponent,
    BasePopupComponent,
  ],
  templateUrl: './received-ticket-popup.component.html',
  styleUrls: ['./received-ticket-popup.component.css'],
})
export class ReceivedTicketPopupComponent extends BaseComponent {
  @Input() public receivedTicket: ReceivedTicket;
  @Input() public isMobile: boolean;

  @Output() public action$: EventEmitter<TicketStatus> =
    new EventEmitter<TicketStatus>();

  public receiveStatus = TicketStatus;

  constructor(
    private loaderService: LoaderService,
    private ticketsService: TicketsService,
    private popupManagerService: PopupsManagerService
  ) {
    super();
  }

  public close(status?: TicketStatus): void {
    this.action$.emit(status);
    this.popupManagerService.hide();
  }

  public handleAction(status: TicketStatus): void {
    this.loaderService.show();
    this.ticketsService
      .changeReceivedTicketStatus(this.receivedTicket.id, status)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loaderService.hide())
      )
      .subscribe(() => this.close(status));
  }
}
