import { Component } from '@angular/core';
import { Event, EventCategory } from '../types/event';
import { BreadcrumbBackground } from '../enum/breadcrumb-background';
import { SeasonTicketsService } from '../services/season-tickets.service';
import { Router } from '@angular/router';
import { LoaderService } from '../services/loader.service';
import { Observable, tap, zip } from 'rxjs';
import { ResponseView } from '../enum/response-view';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../components/base/base.component';
import { Pageable } from '../types/pageable';

@Component({
  template: ``,
})
export abstract class BuySeasonTicketBaseComponent extends BaseComponent {
  public eventCategories: EventCategory[];
  public allEvents: { [key: string]: { top: Event[]; others: Event[] } };
  public selectedEvents: { top: Event[]; others: Event[] };
  public breadcrumbBackground = BreadcrumbBackground.withHeader;

  protected constructor(
    private seasonTicketsService: SeasonTicketsService,
    private router: Router,
    private loaderService: LoaderService
  ) {
    super();
  }

  protected getEventsAndCategories(): Observable<
    [Pageable<EventCategory>, Pageable<Event>]
  > {
    this.loaderService.show();
    return zip(
      this.seasonTicketsService.getEventCategories(),
      this.seasonTicketsService.getAllEvents(ResponseView.compactCalendar)
    ).pipe(
      takeUntil(this.unsubscribe$),
      tap(([categories, events]) => {
        this.eventCategories = this.featuredAtFirstSort(categories.items);
        this.filterEventsData(events.items.filter((e: Event) => e?.sellable));
      }),
      finalize(() => this.loaderService.hide())
    );
  }

  private filterEventsData(events: Event[]): void {
    const categoryObj = {};
    this.eventCategories.forEach((category: EventCategory) => {
      categoryObj[category.id] = { top: [], others: [] };
    });

    events.forEach((event: Event) => {
      const categoryEvent =
        typeof event.category === 'string'
          ? categoryObj[event.category]
          : categoryObj[event.category.id];
      if (event.topGame) {
        categoryEvent.top.push(event);
      } else {
        categoryEvent.others.push(event);
      }
    });

    this.allEvents = categoryObj;
  }

  protected selectEvents(categoryId: string): void {
    this.selectedEvents = this.allEvents[categoryId];
  }

  protected navigateToDates(event: Event): void {
    if (!event?.dates?.length) {
      return;
    }
    this.router.navigateByUrl(`/my/season-tickets/dates/${event?.id}`);
  }

  private featuredAtFirstSort(categories: EventCategory[]): EventCategory[] {
    const featuredIndex = categories.findIndex((c) => c.code === 'featured');
    const excludeFeaturedList = categories.filter((c) => c.code !== 'featured');
    return [categories[featuredIndex], ...excludeFeaturedList];
  }
}
