import { Injectable } from '@angular/core';
import { from, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { filter, tap } from 'rxjs/operators';
import {
  PaymentState,
  StripePaymentResponse,
} from '../types/stripe/stripe-payment';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Stripe: any;

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  // Stripe payment test card number 4242424242424242
  private readonly stripe = Stripe(environment.stripeKey);
  private readonly appearance = {
    variables: {
      spacingGridColumn: '24px',
      spacingGridRow: '24px',
      colorPrimary: '#F2F2F2',
      colorBackground: '#383838',
      backgroundColor: '#383838',
      colorText: '#A1A1A1',
      colorTextPlaceholder: '#A1A1A1',
      colorDanger: '#d73434',
      fontFamily: '"Roboto", sans-serif',
      spacingUnit: '0',
      borderRadius: '4px',
      fontSizeBase: '16px',
      fontSizeSm: '12px',
    },

    rules: {
      '.Input': {
        fontWeight: '500',
        padding: '9px 8px',
      },

      '.Input:focus': {
        boxShadow: 'none',
        color: '#F2F2F2',
      },
    },
  };

  private paymentResponseSub = new Subject<StripePaymentResponse>();
  public paymentResponse$ = this.paymentResponseSub.asObservable();
  private element: any;

  constructor(private httpClient: HttpClient) {}

  public initializeStripe(
    event: string,
    table?: string,
    dates?: string[],
    seatsCount?: number
  ): Observable<string> {
    const payload = seatsCount
      ? { event, seatsCount, dates }
      : { event, table, dates };
    return this.httpClient
      .post(`${environment.apiUrl}/client/my-tickets-stripe-sales`, payload)
      .pipe(
        filter((intentData: any) => !!intentData.clientSecret),
        tap((intentData: any) =>
          this.createPaymentElement(intentData.clientSecret)
        )
      );
  }

  public payWithElement(): Observable<unknown> {
    return from(
      this.stripe.confirmPayment({
        elements: this.element,
        confirmParams: {
          // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
          return_url: `https://bankrollclub.com`,
        },
        redirect: 'if_required',
      })
    ).pipe(
      tap(({ error }: { error: { message: string } }) => {
        if (error) {
          this.paymentResponseSub.next({
            state: PaymentState.fail,
            message: error.message,
          });
        } else {
          this.paymentResponseSub.next({
            state: PaymentState.success,
          });
        }
      })
    );
  }

  private createPaymentElement(clientSecret: string): void {
    this.element = this.stripe.elements({
      clientSecret,
      appearance: this.appearance,
      fonts: [
        {
          cssSrc:
            'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap',
        },
      ],
    });
    const paymentElement = this.element.create('payment');
    paymentElement.mount('#payment-element');
  }
}
