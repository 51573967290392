import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { SharpSportsService } from '../../../../../../../../../../../common/services/sharp-sports.service';
import { BaseComponent } from '../../../../../../../../../../../common/components/base/base.component';
import { UserService } from '../../../../../../../../../../../common/services/user.service';
import { SharpSportsUserMetadata } from '../../../../../../../../../../../common/types/sharp-sports/sharp-sports';

@Component({
  selector: 'app-builder-history',
  templateUrl: './builder-history.component.html',
  styleUrls: ['./builder-history.component.scss'],
})
export class BuilderHistoryComponent extends BaseComponent implements OnInit {
  public userId = this.userService.user.id;
  public unitSize: number;

  constructor(
    private sharpSportsService: SharpSportsService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.sharpSportsService
      .getUserMetadata(this.userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: SharpSportsUserMetadata) => (this.unitSize = res.unitSize)
      );
  }
}
