import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PopupsManagerService} from '../../../services/popups-manager.service';
import {CommonModule} from '@angular/common';
import {BasePopupComponent} from '../../base/base-popup/base-popup.component';
import {EventFilterByCategoryComponent} from '../../event-filter-by-category/event-filter-by-category.component';

@Component({
  selector: 'app-events-filter-popup',
  standalone: true,
  templateUrl: './events-filter-popup.component.html',
  imports: [CommonModule, BasePopupComponent, EventFilterByCategoryComponent],
  styleUrls: ['./events-filter-popup.component.css'],
})
export class EventsFilterPopupComponent {
  @Input() public keys: string[];
  @Input() public filteredKeys: string[];

  @Output() public action$: EventEmitter<string[]>;

  constructor(private popupsManagerService: PopupsManagerService) {
  }

  public close(): void {
    this.action$.emit();
    this.popupsManagerService.hide();
  }

  public handleEmitAction($event: string[]): void {
    this.action$.emit($event);
    this.popupsManagerService.hide();
  }
}
