import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-static-pages',
  templateUrl: './static-desktop-pages.component.html',
  styleUrls: ['./static-desktop-pages.component.css'],
})
export class StaticDesktopPagesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
