import { Component, OnInit } from '@angular/core';
import { BreadcrumbBackground } from '../../../../../../../common/enum/breadcrumb-background';
import { ActivatedRoute } from '@angular/router';
import { SeasonTicketsService } from '../../../../../../../common/services/season-tickets.service';
import { BaseComponent } from '../../../../../../../common/components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { GeneralFormAction } from '../../../../../../../common/types/event';

@Component({
  selector: 'app-checkout-season-ticket-page-desktop-page',
  templateUrl: './checkout-season-ticket-desktop-page.component.html',
  styleUrls: ['./checkout-season-ticket-desktop-page.component.scss'],
})
export class CheckoutSeasonTicketDesktopPageComponent
  extends BaseComponent
  implements OnInit
{
  public breadcrumbBackground = BreadcrumbBackground.withHeader;
  public showStripeBlock = false;
  public isGeneral = false;
  public seatCount: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private seasonTicketsService: SeasonTicketsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isGeneral = this.activatedRoute.snapshot.data.isGeneral;
    this.showStripeBlock = !this.isGeneral;
    this.seasonTicketsService.generalTicketFormSubmit$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: GeneralFormAction) => {
        this.seatCount = value.seatsCount;
        this.showStripeBlock = value.showStripe;
      });
  }
}
